import { Component, OnInit, ViewChild, ViewChildren, Input, OnChanges, ElementRef, EventEmitter, Output } from '@angular/core';
import { MatPaginator, MatTableDataSource, MatDialog, MatSnackBar, MatSort, MatSelect } from '@angular/material';
import { ProjectsService } from '../../api/projects.service';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { SetttingsService } from '../../api/setttings.service';
import { ProjectsTaskDialogComponent } from 'src/app/projects/projects-task-dialog/projects-task-dialog.component';
import { DeleteConfirmationDialogComponent } from '../../extra/delete-confirmation-dialog/delete-confirmation-dialog.component';
import { SimpleChanges } from '@angular/core/src/metadata/lifecycle_hooks';
import { Console } from 'console';

@Component({
    selector: 'app-kanban',
    templateUrl: './kanban-page.component.html',
    styleUrls: ['./kanban-page.component.scss']
  })

export class KanbanPageComponent implements OnInit, OnChanges {
    taskDataSource = new MatTableDataSource();
	taskStatuses;
    taskTypes;
    users;
	assignedUsers;
    clients;
    fieldRows: Object = new Object();
    allLoaded: boolean = false;

	searchFilterInputBox: string;

	@Input() searchFilterText = '';
    @Input() filters: object[];
    @Input() addATask: boolean = false;
	@Input() clickTicketObject;
	@Input() tasksList;

    @Output() refreshBoardDelete = new EventEmitter<any>();
    @Output() labelMilestoneUpdate = new EventEmitter<any>();

    @ViewChild(MatSelect) filterSelectProject;
    @ViewChild(MatSelect) filterSelectUser;
    @ViewChild(MatSelect) filterSelectType;
	@ViewChild(MatSelect) filterSelectSearch;
	@ViewChild(MatSelect) filterSelectStatus;
	@ViewChild(MatSelect) filterSelectLabel;
	@ViewChild(MatSelect) filterSelectMilestone;

    constructor(private _projectsService: ProjectsService,
        public dialog: MatDialog, public snackBar: MatSnackBar) {
    }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges) {
        if(this.addATask){
            this.addATask = false;
            this.getTasks();
        }
		else {
            //This triggers once before it loads that data so have
            //to specify that only after the data source has data
            if (this.taskDataSource.data.length != 0) {
                this.refreshFilter();
            }
        }
		if(changes.clickTicketObject && changes.clickTicketObject['currentValue']){
			this.taskStatuses =this.clickTicketObject['taskStatuses'];
			this.allLoaded = true;
		}
    }

	filterTasksBySearchInput(searchInput){
		if(searchInput != undefined)
		{
			this.taskDataSource.filterPredicate =
			this.changeFilterPredicateToFilterTasksAndProjectName();

			this.taskDataSource.filter = searchInput.trim().toLowerCase();
		}
	}

    updateTaskCount(taskGroup){
        if(taskGroup.length > 0)
            for(var i = 0; i < this.taskStatuses.length; i++){
                this.taskStatuses[i]['taskCount'] = taskGroup[i].length;
            }
    }

	changeFilterPredicateToFilterTasksAndProjectName(){
		return (task,filters:string) =>
		{
			const searchInput = filters.trim().toLowerCase();
			const colTaskName = task.name;
			const colProjectName = task.projectName;

			const boolTaskExists = colTaskName.trim().toLowerCase().includes(searchInput);
			const boolProjectNameExists = colProjectName.trim().toLowerCase().includes(searchInput);
			return (boolProjectNameExists || boolTaskExists);
		}
	}

    refreshFilter(){
        var tempFilter = Object.assign({}, this.filters);
        //Refreshes filters to propegate to task-group
        this.filters = tempFilter;
    }

     //method to allow task updates to update the label and milestone filters
     getLabelMilstoneUpdates() {
        this.labelMilestoneUpdate.emit();
    }

    getTasks(){
		this.allLoaded = false;
        this.tasksList = [];
        this._projectsService.getProjectTaskNameIdStatus().subscribe(
            data => {
                    if (data != null) {
					let tempdata = Object.keys(data).map(i => data[i]);
                    for(var i = 0; i < this.taskStatuses.length; i++){
                        var temp = tempdata.filter(x => x.taskStatus == this.taskStatuses[i].statusId)
                        //sorting by priority descending, then commit date asc, finally by due date asc
                        temp.sort((a, b)=> {
                            if (a.priority === b.priority){
                                if (a.commitDate === b.commitDate){
                                    return a.plannedEndDate < b.plannedEndDate ? -1 : 1
                                } else {
                                    return a.commitDate < b.commitDate ? -1 : 1
                                }
                            } else {
                                return b.priority < a.priority ? -1 : 1
                            }
                        });
                        this.tasksList.push(temp);
                        this.taskStatuses['taskCount'] = temp.length;
                    }
                    this.refreshFilter();
                    this.refreshBoardDelete.emit();
					this.allLoaded = true;
                }
            },
            err => console.error(err)
        );
    }
}
