import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormControl } from '@angular/forms';
import { ChangeDetectorRef, AfterContentChecked} from '@angular/core';

var cloneDeep = require('lodash.clonedeep');

@Component({
    selector: 'app-sprint-dialog',
    templateUrl: './sprint-dialog.component.html',
    styleUrls: ['./sprint-dialog.component.scss']
})
export class SprintDialogComponent implements OnInit {
    tasks: object[];
    clients;
    sprintDatasTasks: object[] = [];
    initialPopulatorForTasks: object[] = [];

    checkedItems: object[] = [];

    searchFilterInputBox: string = "";
    selectedProjects: any = [];

    id = new FormControl();
    name = new FormControl();
    projectId: FormControl = new FormControl();
    status: FormControl = new FormControl(true);

    constructor(public dialogRef: MatDialogRef<SprintDialogComponent>, private cdref: ChangeDetectorRef,
        @Inject(MAT_DIALOG_DATA) public sprintData) { }

    ngOnInit() {
        this.tasks = this.sprintData.tasks;
        this.clients = this.sprintData.clients;
        this.selectedProjects = this.sprintData.clients;

        if(this.sprintData.SprintInfo != null){
            this.id.setValue(this.sprintData.SprintInfo.id); 
            this.name.setValue(this.sprintData.SprintInfo.name); 
            this.projectId.setValue(this.sprintData.SprintInfo.projectId); 
            this.status.setValue(this.sprintData.SprintInfo.status); 

            this.refreshTaskFilter();

            var taskList = this.sprintData.SprintInfo.CurrentTasks;
            for(var i = 0; i < taskList.length; i++){
                for(var j = 0; j < taskList[i].length; j++){
                    this.sprintDatasTasks.push(taskList[i][j].id);
                    this.initialPopulatorForTasks.push(taskList[i][j].id);
                    this.checkedItems.push({taskId: taskList[i][j].id});
                }
            }
        }
    }

    ngAfterContentChecked() {
        this.cdref.detectChanges();
      }

    closeDialog() {
        this.dialogRef.close();
    }

    //Sets focus of the combobox inputs on click
	setFocus(inputId: string) {
		if (document.getElementById(inputId) != null) {
			document.getElementById(inputId).focus();
		}
	}

    selectToggle(type, selectRef) {
        type ? selectRef.selectAll() : selectRef.deselectAll();
        this.checkedItems = [];
        if(type){
            selectRef.selectedOptions.selected.forEach(task => {
                this.checkedItems.push({taskId: task.value});
            });
        }
    }

    isChecked(id, selectRef): boolean {
        if(this.initialPopulatorForTasks.length != 0){
            //Initially Populates Checkboxes
            const exists = this.initialPopulatorForTasks.some(taskid => taskid == id);
            if(exists) {
                this.initialPopulatorForTasks = this.initialPopulatorForTasks.filter(taskid => taskid != id);
            }
            return exists;
        }
        else {
            if(selectRef != null){
                for(var i = 0; i < this.checkedItems.length; i++){
                    if(id == this.checkedItems[i]["taskId"]){
                        return true;
                    }
                }
            }
            return false;
        }
    }

    onChange(event: any) {
        if(event.option.selected){
            //Makes sure not to duplicate entry somehow by filtering it out first
            this.checkedItems = this.checkedItems.filter(item => item['taskId'] != event.option.value);
            this.checkedItems.push({taskId: event.option.value});
        }
        else {
            this.checkedItems = this.checkedItems.filter(item => item['taskId'] != event.option.value);
        }
    }

    refreshTaskFilter(){
        let projectFilter = [];
        if(this.projectId.value != null){
            projectFilter.push(this.projectId.value);
        }

        let searchFilter = this.searchFilterInputBox;

        this.tasks = cloneDeep(this.sprintData.tasks);
        this.tasks = this.tasks.filter(function (data) {
            return (projectFilter.length == 0 || projectFilter.indexOf(data['projectId']) !== -1) &&
                (searchFilter == "" || data["id"].toString().includes(searchFilter)
                    || data["name"].toLowerCase().includes(searchFilter))
        });
    }

    onKeyProjects(value) {
		this.selectedProjects = this.filterProjects(value);
	}

	filterProjects(value: string) {
		let filter = value.toLowerCase();

		let filteredClientsAndProjects = this.clients.filter(option => option['name'].toLowerCase().includes(filter));
		for(var i = 0; i < this.clients.length; i++){
			if(filteredClientsAndProjects.find(option => option['id'] == this.clients[i]['id']) == undefined){
				if(this.clients[i]['projects'] != null){
					let tempClient = cloneDeep(this.clients[i]);
					tempClient['projects'] = tempClient['projects'].filter(project => project['name'].toLowerCase().includes(filter))
					if(tempClient['projects'].length > 0){
						filteredClientsAndProjects.push(tempClient);   
					}
				}
			}
		}
		return filteredClientsAndProjects.sort((a, b) => { return (a['name'] > b['name']) ? 1 : ((b['name'] > a['name']) ? -1 : 0); });
	}

    invalidEntry(){
        if(this.name.value == null || this.name.value == ''){
            return true;
        }
        if(this.projectId.value == null){
            return true;
        }
        return false;
    }

    saveSprint(selectRef) {
        //Removes search results so already selected tasks will not be filtered out
        this.searchFilterInputBox = '';
        this.refreshTaskFilter()

        var addedTasks = [];
        var removedTasks = [];
        for(var i = 0; i < this.tasks.length; i++){
            var taskSelectedOnStart = this.sprintDatasTasks.some(item => item == this.tasks[i]['id']);
            var taskSelected = this.checkedItems.some(item => item['taskId'] == this.tasks[i]['id']);
            if(!taskSelectedOnStart && taskSelected){
                //Item Added
                addedTasks.push({taskId: this.tasks[i]['id']});
            }
            else if(taskSelectedOnStart && !taskSelected){
                //Item Removed
                removedTasks.push({taskId: this.tasks[i]['id']});
            }
        }

        //Adds to the Remove Task List all checkedItems that are now part of a different project
        for(var i = 0; i < this.sprintData.tasks.length; i++){
            var taskSelectedOnStart = this.sprintDatasTasks.some(item => item == this.sprintData.tasks[i]['id']);
            if(taskSelectedOnStart && this.sprintData.tasks[i]['projectId'] != this.projectId.value){
                removedTasks.push({taskId: this.sprintData.tasks[i]['id']});
            }
        }

        let returnModel = {
            id: this.id.value,
            name: this.name.value,
            projectId: this.projectId.value,
            status: this.status.value ? 1 : 0,
            AddedTasks: addedTasks,
            RemovedTasks: removedTasks,
        };
        this.dialogRef.close({ delete: false, returnModel: returnModel });
    }

    delete() {
        this.dialogRef.close({ delete: true });
    }

}