import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '../../../node_modules/@angular/common/http';
import { GlobalService } from '../api/globalVars';


const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    withCredentials: true
};

@Injectable({
  providedIn: 'root'
})
export class ReportService {
    path = this.globalVar.retrievePath();

    constructor(public globalVar: GlobalService, private _http: HttpClient) { }

    generateReport(model) {
        const posturl = this.path + 'reportexpense';
        return this._http.post(posturl, model , httpOptions);
    }
    generatePTOReport(model) {
        const posturl = this.path + 'ptoreport';
		return this._http.post(posturl, model , httpOptions);
    }
    retrieveReport(fileName) {
        const geturl = this.path + 'reportexpense?fileName=' + fileName;
        return this._http.get(geturl, httpOptions);
    }
    generateStatusReport(model) {
        const posturl = this.path + 'statusreport';
        return this._http.post(posturl, model, httpOptions);
    }
    generateTimeoffReport(model) {
        const posturl = this.path + 'timeoffreport';
        return this._http.post(posturl, model, httpOptions);
    }
}
