import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({ name: 'TransformUTCToLocalDate' })
export class TransformUTCToLocalDate implements PipeTransform {
  transform(selectedDate): Date {
    if (selectedDate instanceof Date) {
      return selectedDate;
    }
    else if (selectedDate == null) {
      return null;
    }
    else {
      return moment(selectedDate.slice(0, 10)).local(true).toDate();
    }
  }
}