import { Injectable } from "@angular/core";
import createAuth0Client, {
	GetTokenSilentlyOptions,
	GetUserOptions,
	IdToken,
	RedirectLoginResult,
} from "@auth0/auth0-spa-js";
import Auth0Client from "@auth0/auth0-spa-js/dist/typings/Auth0Client";
import { from, of, Observable, BehaviorSubject, throwError, iif } from "rxjs";
import {
	tap,
	catchError,
	concatMap,
	shareReplay,
	map,
	take,
} from "rxjs/operators";
import { Router } from "@angular/router";
import { Location } from "@angular/common";
import { ThemeService } from "../theme/theme.service";
import { SetttingsService } from "../../api/setttings.service";

@Injectable({
	providedIn: "root",
})
export class AuthService {
	// Declare and initialize an observable instance of the Auth0 client.
	auth0Client: Observable<Auth0Client> = (
		from(
			createAuth0Client({
				domain: "login.prosightlabs.com",
				client_id: "rZDsy5qYL8vz7MQkmHI4P56St7c7i1xp",
				redirect_uri: `${window.location.origin}/home`,
				connection: this.getTenantSubdomain(),
				useRefreshTokens: true,
				cacheLocation: "localstorage"
			})
		) as Observable<Auth0Client>
	).pipe(
		shareReplay(1), // Every subscription receives the same shared value
		catchError((err) => throwError(err))
	);

	// Observable for Auth0 SDK isAuthenticated() function.
	isAuthenticated = this.auth0Client.pipe(
		concatMap((client: Auth0Client) => from(client.isAuthenticated()))
	);

	// Observable for Auth0 SDK handleRedirectCallback() function.
	handleRedirectCallback = this.auth0Client.pipe(
		concatMap((client: Auth0Client) =>
			from(client.handleRedirectCallback())
		)
	);

	// A subject and public observable of user profile data
	private userProfileSubject = new BehaviorSubject<any>(null);
	userProfile = this.userProfileSubject.asObservable();

	tenantFeatures = [];
	homeViewNames = [];

	constructor(
		private router: Router,
		private location: Location,
		private themeService: ThemeService,
		private _settingsService: SetttingsService
	) {}

	/**
	 * Gets the user's access token from Auth0 "silently" (i.e., without
	 * requiring user intervention).
	 * @param options A nullable GetTokenSilentlyOptions object used to pass
	 * options to the Auth0 SDK getTokenSilently() function. See
	 * https://auth0.github.io/auth0-spa-js/interfaces/gettokensilentlyoptions.html
	 * for details about the available options.
	 * @returns An observable string representing the user's access token.
	 */
	getAccessTokenSilently(
		options?: GetTokenSilentlyOptions
	): Observable<string> {
		return this.auth0Client.pipe(
			concatMap((client: Auth0Client) =>
				from(client.getTokenSilently(options))
			)
		);
	}

	/**
	 * Gets and returns the name of the user's tenant using getTenantSubdomain().
	 * @returns A string representing the name of the user's tenant.
	 */
	getTenantName(): string {
		return this.getTenantSubdomain();
	}

	/**
	 * Gets the user's user object from Auth0 (decoded from the id_token).
	 * @param options A nullable GetUserOptions object used to pass options to
	 * the Auth0 SDK getUser() function. See
	 * https://auth0.github.io/auth0-spa-js/interfaces/getuseroptions.html for
	 * details about the available options.
	 * @returns An observable object representing the user object.
	 */
	getUser(options?: GetUserOptions): Observable<any> {
		return this.auth0Client.pipe(
			concatMap((client: Auth0Client) => from(client.getUser(options))),
			tap((user) => this.userProfileSubject.next(user))
		);
	}

	getTenantFeatures() {
		return this.tenantFeatures;
	}

	getHomeViews(){
		return this.homeViewNames;
	}

	setHomeViews(){
		if(this.tenantFeatures.includes(1)) this.homeViewNames.push({value: "time-entry", name: "Time Entry"});
		if(this.tenantFeatures.includes(2)) this.homeViewNames.push({value: "kanban-board", name: "Kanban Board"});
		if(this.tenantFeatures.includes(3)) this.homeViewNames.push({value: "project-portfolios", name: "PPM"});
		if(this.tenantFeatures.includes(4)) {
		  this.homeViewNames.push({value: "time-off", name: "Time Off"});
		  this.homeViewNames.push({value: "team-calendar", name: "Team Calendar"});
		}
		if(this.tenantFeatures.includes(5)) this.homeViewNames.push({value: "expenses", name: "Expenses"});
	}

	getTenantFeaturesPipe(): Observable<number[]> {
		if (this.tenantFeatures.length == 0) {
			return this._settingsService.getTenantFeatures().pipe(
				map((data) => {
					if(this.homeViewNames.length == 0){
						this.setHomeViews();
					}
					return Object.assign([], data);
				})
			);
		} else {
			if(this.homeViewNames.length == 0){
				this.setHomeViews();
			}
			return of(this.tenantFeatures);
		}
	}

	/**
	 * Gets the user's user ID as an observable string.
	 * @returns An observable string value representing the user's user ID.
	 */
	getUserId(): Observable<string> {
		return this.userProfile.pipe(
			map((userProfile) => {
				return userProfile["https://prosightlabs.com/user_id"];
			})
		);
	}

	/**
	 * Gets the user's JWT as an observable IdToken.
	 * @returns An observable IdToken object representing the user's JWT.
	 */
	getUserJwt(): Observable<IdToken> {
		return this.auth0Client.pipe(
			concatMap((client: Auth0Client) => from(client.getIdTokenClaims()))
		);
	}

	/**
	 * Gets the user's full name.
	 * @returns A string representing the user's full name.
	 */
	getUserName(): string {
		let userName: string = null;

		if (this.userProfile !== null) {
			this.userProfile.subscribe((user) => {
				if (user !== null && user.name !== null) {
					userName = user.name;
				}
			});
		}

		return userName;
	}

	/**
	 * Gets the user's signin expiration.
	 * @returns A number representing the user's seconds to expiration.
	 */
	getExpiredUserSeconds(): Observable<number> {
		return this.userProfile.pipe(
			map((user) => {
				if(user != null && user.updated_at != null){
					var now = new Date();
					var timeAuthenticated = new Date(user.updated_at);
					var timeExpired = timeAuthenticated.setHours(timeAuthenticated.getHours() + 7);
					timeExpired = timeExpired + (57 * 1000 * 60); //Add in 57 minutes to have a total of 7 hours and 57 minutes
					var diffMs = timeExpired - now.getTime(); // milliseconds between now & expiration
					return Math.floor(diffMs/1000);
				}
				else {
					return null;
				}
			})
		);
	}

	/**
	 * Gets the user's assigned permissions as an observable string array.
	 * @returns An observable string array containing the user's assigned
	 * permissions.
	 */
	getUserPermissions(): Observable<string[]> {
		return this.getUserJwt().pipe(
			map((userJwt) => {
				if (userJwt !== undefined) {
					return userJwt["https://prosightlabs.com/user_permissions"];
				}
			})
		);
	}

	/**
	 * Attempts to derive the subdomain through which a tenant user is accessing
	 * ProSight from and either returns the subdomain as a string or null (based
	 * on whether or not a subdomain could be found).
	 * @returns A string representing the tenant's subdomain (e.g., teamtng).
	 */
	private getTenantSubdomain(): string {
		let origin: string = window.location.origin;
		let subdomainRegExMatches: RegExpMatchArray = origin.match(
			"(?=http[s]?://)?[A-Za-z0-9-]+(?=.{1}(prosightlabs.com|teamtng01.thenormandygroup.com|localhost))"
		);

		if (
			subdomainRegExMatches !== null &&
			subdomainRegExMatches.length > 0
		) {
			return subdomainRegExMatches[0];
		} else {
			return "teamtng-test";
		}
	}

	/**
	 * Calls the Auth0 login process then redirects to the given path on success.
	 * @param redirectPath Router path to redirect to on login.
	 */
	login(redirectPath: string = "/home"): Observable<void> {
		return this.auth0Client.pipe(
			concatMap((client: Auth0Client) =>
				// Call Auth0 login.
				from(
					client.loginWithRedirect({
						redirect_uri: `${window.location.origin}/home`,
						appState: { target: redirectPath },
					})
				)
			)
		);
	}

	/**
	 * Handles the callback to ProSight from Auth0 after a user has authenticated.
	 * If the url includes code then it will check for authentication.
	 */
	handleAuthCallback(): Observable<{ loggedIn: boolean; targetUrl: string }> {
		return of(window.location.search).pipe(
			concatMap((params: string) =>
				// Check to see if url includes code or state to see if it should check auth.
				iif(
					() => params.includes("code=") && params.includes("state="),
					// Handle Auth0 callback.
					this.handleRedirectCallback.pipe(
						concatMap((callbackResponse: RedirectLoginResult) =>
							// Check if authenticated through Auth0.
							this.isAuthenticated.pipe(
								take(1),
								// Return authenticated check result and callback url.
								map((loggedIn: boolean) => ({
									loggedIn,
									targetUrl:
										callbackResponse.appState &&
										callbackResponse.appState.target
											? callbackResponse.appState.target
											: "/home",
								})),
								tap((loggedIn: boolean) => {
									if (loggedIn) {
										this.getUser().subscribe();

										this.themeService
											.getAppThemeFromDB()
											.subscribe((data) => {
												this.themeService.setAppTheme(
													data["response"].toString(),
													false,
													true
												);
											});

										this._settingsService
											.getTenantFeatures()
											.subscribe(
												(data) =>
													(this.tenantFeatures =
														Object.assign([], data))
											);
									}
								})
							)
						)
					),
					this.isAuthenticated.pipe(
						take(1),
						map((loggedIn: boolean) => ({
							loggedIn,
							targetUrl: null,
						})),
						tap((loggedIn: boolean) => {
							if (loggedIn) {
								this.getUser().subscribe();

								if(this.themeService.getAppThemeFromCookie() == null){
									this.themeService
										.getAppThemeFromDB()
										.subscribe((data) => {
											this.themeService.setAppTheme(
												data["response"].toString(),
												false,
												true
											);
										});
								}

								this._settingsService
									.getTenantFeatures()
									.subscribe(
										(data) =>
											(this.tenantFeatures =
												Object.assign([], data))
									);
							}
						})
					)
				)
			)
		);
	}

	/**
	 * Logs the user out from ProSight through the Auth0 service.
	 */
	logout(): void {
		// Ensure Auth0 client instance exists
		this.auth0Client.subscribe((client: Auth0Client) => {
			// Call method to log out
			client.logout({
				client_id: "rZDsy5qYL8vz7MQkmHI4P56St7c7i1xp",
				returnTo: `${window.location.origin}`,
			});
		});
	}
}
