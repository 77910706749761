import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-breadcrumb-nav',
  templateUrl: './breadcrumb-nav.component.html',
  styleUrls: ['./breadcrumb-nav.component.scss']
})

export class BreadcrumbNavComponent implements OnInit {
  private pathMappings: Object;
  private routerPath: string;
  private pathArray: string[];

  constructor(private router: Router) {
    // Initialize a list of path label mappings for labels which may not be dynamically generated
    this.pathMappings = {
      '': 'Home'
    };
  }

  ngOnInit() {
    // Grab the current URL from Angular's router and create an array of the parts of that path
    this.routerPath = this.router.url;
    this.pathArray = this.routerPath.split('\/');

    var breadcrumbNavs = document.querySelectorAll("[id='breadcrumb-nav']");

    for (var i = 0; i < breadcrumbNavs.length; i++) {
      this.buildBreadcrumb(breadcrumbNavs[i]);
    }
  }

  // Dynamically builds the navigation structure from sections of the current router path
  buildBreadcrumb(breadcrumbNav) {
    var path: string = '/'; // Set the path variable to initially point to the application root
    var pathSectionLabel: string = ''; // Set the path section label to an empty string

    // Iterate over the path array to process the path section-by-section
    for (var i = 1; i < this.pathArray.length; i++) {
      let navLink = document.createElement('a');
      let listItem = document.createElement('li');

      // Add the breadcrumb-step class to the breadcrumb list item HTML element
      listItem.classList.add('breadcrumb-step');

      /* Append the path section to the path and set pathSection label to the
      path section so it can be manipulated to a more human-readable label. */
      path += this.pathArray[i] + '/';
      pathSectionLabel = this.pathArray[i];

      if (pathSectionLabel !== '') {
        /* Check if the path section is in the path mappings object, and use
        the mapped string as the anchor element's label if so. Otherwise,
        proceed to manipulate the path section into a more human-readable
        label. */
        if (this.pathMappings[pathSectionLabel]) {
          navLink.innerHTML = this.pathMappings[pathSectionLabel];
        } else {
          /* Replace any dashes in the path section label with a space, remove
          any query components from the path section label, and set the inner
          HTML of the anchor element to the manipulated path section label. */
          pathSectionLabel = pathSectionLabel.replace(/-/g, ' ');
          pathSectionLabel = pathSectionLabel.replace(/\?.*$/g, '');
          navLink.innerHTML = pathSectionLabel;
        }

        // Set the HTML anchor element's href to the path
        navLink.href = path;

        /* Add the HTML anchor element as a child of the HTML list item element
        and add the completed breadcrumb to the breadcrumb nav structure. */
        listItem.appendChild(navLink);
        breadcrumbNav.appendChild(listItem);
      }
    }
  }

}
