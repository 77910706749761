import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatDialog, MatSnackBar, MatButtonToggleGroup, MatButton, MatButtonToggle } from '../../../../node_modules/@angular/material';

@Component({
    selector: 'app-name-table',
    templateUrl: './name-table.component.html',
    styleUrls: ['./name-table.component.scss']
})
export class NameTableComponent implements OnInit {

    displayedColumns = ['name', 'state', 'options'];
    dataArray = [];
    dataSource = new MatTableDataSource();
    dataSourceLoading: boolean;

    constructor(public dialog: MatDialog,
        public snackBar: MatSnackBar) { }

    @ViewChild(MatPaginator) paginator;
    @ViewChild(MatButtonToggleGroup) buttonToggleGroup;
    @Input() getFunction;
    @Input() updateFunction;
    @Input() expenseUpdateFunction;
    @Input() type: string;
    @Input() dialogComponent;
    @Input() dialogWidth;

    ngOnInit() {
        this.getData();
    }

    updateFilter(toggleValue: string, searchValue: string) {
        let dataContainer = [];
        let booleanMaker = { active: true, inactive: false };
        this.dataArray.forEach(entry => {
            let searchBoolean = entry['name'].toLowerCase().includes(searchValue) || searchValue == '';
            let toggleBoolean = entry['isActive'] == booleanMaker[toggleValue] || toggleValue == 'all';
            if (searchBoolean && toggleBoolean) {
                dataContainer.push({
                    name: entry['name'],
                    state: entry['isActive'],
                    id: entry['id']
                });
                dataContainer = dataContainer.sort(function(a, b) {
                    var aName = a.name;
                    var bName = b.name;
                    // Makes it so that inactive is lower priority
                    var aStatus = a.state == 0 ? 1 : 0;
                    var bStatus = b.state == 0 ? 1 : 0;

                    if (aStatus == bStatus) {
                        return (aName < bName) ? -1 : (aName > bName) ? 1 : 0;
                    } else {
                        return (aStatus < bStatus) ? -1 : 1;
                    }
                });
            }
        });
        this.dataSource = new MatTableDataSource(dataContainer);
        this.dataSource.paginator = this.paginator;
    }

    loadDialog(entryId) {
        if (this.dialogComponent) {
            let width = this.dialogWidth ? this.dialogWidth : '585px';
            let data = new Object();
            data['newData'] = true;

            if (this.type == 'Policy') {
                data['policySetting'] = Object();
                data['policyClients'] = [];
                data['policyClasses'] = [];
                data['policySetting']['policyId'] = null;
            }

            if (entryId != 'newData') {
                data = this.findEntry(entryId);
			}
            const dialogRef = this.dialog.open(this.dialogComponent, {
                width: width,
                height: 'auto',
                data: data
            });
            dialogRef.afterClosed().subscribe(result => {
                if (result !== undefined) {
                    if (result['submit']) {
                        this.updateData(result['data']);
                    }
                }
            });
        } else {
        }
    }

    findEntry(entryId) {
        return this.dataArray.find(entry => entry['id'] == entryId);
    }

    stateChange(entryId, state) {
        let returnObject = this.findEntry(entryId);
        returnObject['isActive'] = state;
        this.updateData(returnObject);
    }

    processData(data) {
        let dataContainer = [];
        data.forEach(entry => {
            dataContainer.push({
                name: entry['name'],
                state: entry['isActive'],
                id: entry['id']
            });
        });
        this.dataSource = new MatTableDataSource(dataContainer);
        this.dataSource.paginator = this.paginator;
        this.updateFilter(this.buttonToggleGroup.value, '');

    }

    getData() {
        if (this.getFunction) {
            this.dataSource.data = [];
            this.dataSourceLoading = true;
            this.getFunction.subscribe(
                data => {
                    this.dataArray = [];
                    this.dataArray.push.apply(this.dataArray, data);
                    this.processData(data);
                    this.dataSourceLoading = false;

                }, err => {
                    this.dataSource = new MatTableDataSource(this.dataArray);
                    this.dataSource.paginator = this.paginator;
                    this.snackBar.open('Error Retrieving Data', '', { duration: 2000 });
                    console.error(err);
                    this.dataSourceLoading = false;
                }
            );
        } else {
        }
    }

    updateData(entry) {
        if (this.updateFunction) {
            this.updateFunction(entry).subscribe(
                data => {
                    this.getData();
                    this.snackBar.open('Updated ' + this.type, '', { duration: 2000 });
                }, err => {
                    this.getData();
                    this.snackBar.open('Error Updating Data', '', { duration: 2000 });
                    console.error(err);
                }
            );
        } else {
        }
    }
}
