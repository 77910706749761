import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatSnackBar } from '@angular/material';
import { GlobalService } from '../api/globalVars';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    withCredentials: true
};

@Injectable({
    providedIn: 'root'
})
export class QuickbooksService {
    path = this.globalVar.retrievePath();

    constructor(private _cookieService: CookieService,
        private _http: HttpClient, public globalVar: GlobalService, 
        public snackBar: MatSnackBar) { }

    // Retrieves Quickbooks Config
    getQuickbookConfig() {
        const geturl = this.path + 'quickbookconfig?configFlag=1';
        return this._http.get(geturl, httpOptions);
    }

    // Retrieves Quickbooks OAuth
    getQuickbookOAuth() {
        const geturl = this.path + 'quickbookoauth?oauthFlag=1';
        return this._http.get(geturl, httpOptions);
    }

    // Retrieves Quickbooks OAuth
    refreshQuickbookOAuth() {
        const puturl = this.path + 'quickbookoauth';
        return this._http.put(puturl, httpOptions);
    }

    // Retrieves Relations for Quickbooks
    getRelations(type: number) {
        const geturl = this.path + 'quickbookdata?relationFlag=' + type;
        return this._http.get(geturl, httpOptions);
    }

    // Gets Database data for Quickbooks
    getProSightData(qb_type) {
        var geturl = this.path + qb_type.path;
        if(qb_type.label != "Employee" && qb_type.label != "Vendor")
            geturl = geturl + '?allFlag=1';
        return this._http.get(geturl, httpOptions);
    }

    // Gets Quickbooks data for Quickbooks
    getQuickbookData(qb_type) {
        const geturl = this.path + 'quickbookdata?command=' + qb_type.value;
        return this._http.get(geturl, httpOptions);
    }

    // Gets Quickbooks data for Quickbooks
    importQB(qb_type) {
        const geturl = this.path + 'quickbookdata?fromQB=true&syncFlag=' + qb_type.value;
        return this._http.post(geturl, {something: 'something'}, httpOptions);
    }

    // Saves the Quickbooks Config
    saveQuickbooksConfig(qb_config_sent) {
        const geturl = this.path + 'quickbookconfig';
        return this._http.post(geturl, qb_config_sent, httpOptions);
    }

    // Request/Refresh Token
    requestToken(email) {
        const geturl = this.path + 'quickbookoauth';
		return this._http.get(geturl, httpOptions);
    }

    // Retrieves the Time Off Data
    getTimeOffFilter() {
        const geturl = this.path + 'quickbooktimeoff?filterFlag=1';
        return this._http.get(geturl, httpOptions);
    }

    // Retrieves the Time Off Relations between Category and Service
    getTimeOffRelation() {
        const geturl = this.path + 'quickbooktimeoff?relationFlag=1';
        return this._http.get(geturl, httpOptions);
    }

    // Saves Time Off Relationship
    saveTimeOffRelation(item) {
        const geturl = this.path + 'quickbooktimeoff?linkFlag=1';
        return this._http.post(geturl, item, httpOptions);
    }

    // Deletes Time Off Relationship
    deleteTimeOffRelation(serviceId, categoryId) {
        const geturl = this.path + 'quickbooktimeoff?serviceId=' + serviceId + '&categoryId=' + categoryId;
        return this._http.delete(geturl, httpOptions);
    }

    // Delete the Quickbooks Relation
    deleteRelation(qb_type, item) {
        const deleteurl = this.path + 'quickbookdata?rowId=' + item.relationId;
        return this._http.delete(deleteurl, httpOptions);
    }

    // Syncs item between prosight and quickbooks
    saveRelation(qb_type, item, add) {
        let posturl = '';
        if (add) {
            posturl = this.path + 'quickbookdata?syncFlag=' + qb_type + '&addition=' + add;
        } else {
            posturl = this.path + 'quickbookdata?syncFlag=' + qb_type;
        }
        return this._http.post(posturl, item, httpOptions);
    }

    updateQBOAuth(parameters){
        const geturl = this.path + 'quickbookoauth' + parameters;
        return this._http.get(geturl, httpOptions);
    }
}