import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatDialog, MatSnackBar, MatButtonToggleGroup } from '@angular/material';
import { SetttingsService } from '../../../api/setttings.service';
import { ManagementTasksTypeDialogComponent } from '../management-tasks-type-dialog/management-tasks-type-dialog.component';
import { DeleteConfirmationDialogComponent } from '../../../extra/delete-confirmation-dialog/delete-confirmation-dialog.component';

@Component({
    selector: 'app-management-tasks-type',
    templateUrl: './management-tasks-type.component.html',
    styleUrls: ['./management-tasks-type.component.scss']
})

export class ManagementTasksTypeComponent implements OnInit {
    displayedColumns = ['name', 'order', 'options', 'delete'];
    taskTypesArray = [];
    dataSourceLoading: boolean;
    taskTypeDataSource = new MatTableDataSource();

    constructor(private _settingsService: SetttingsService,
        public dialog: MatDialog,
        public snackBar: MatSnackBar) { }

    @ViewChild(MatPaginator) paginator;
    @ViewChild(MatButtonToggleGroup) buttonToggleGroup;

    ngOnInit() {
        this.dataSourceLoading = true;
        this.getTaskTypeData();
    }

    getTaskTypeData() {
        this.taskTypeDataSource = new MatTableDataSource([]);
        this.dataSourceLoading = true;
        this._settingsService.getTaskTypes().subscribe(
            data => {
                if(data != null){
                    let tempdata = Object.keys(data).map(i => data[i]);
                    this.taskTypeDataSource = new MatTableDataSource(tempdata);
                    this.taskTypeDataSource.paginator = this.paginator;
                    this.dataSourceLoading = false;
                    //Code for eventual matsortcolumns
                    /*
                    this.taskTypeDataSource.sort = this.sort.first;
                    this.taskTypeDataSource.sortingDataAccessor = (item, property) => {
                        switch (property) {
                            case 'typeName': return item['typeName'];
                            case 'order': return item['order'];
                            default: return item[property];
                        }
                    };
                    */
                }
                else{
                    this.displayedColumns = [];
                    this.taskTypeDataSource = new MatTableDataSource(); // No work item types exist for tenant
                    this.dataSourceLoading = false;
                }

            }, err => {
                this.snackBar.open(err.statusText, '', { duration: 2000 });
                this.taskTypeDataSource.paginator = this.paginator;
                this.dataSourceLoading = false;
                console.error(err);
            }
        );
    }

    getTaskTypeDataById(typeId){
        return this.taskTypeDataSource.data.find(taskType => taskType['typeId'] == typeId);
	}

	/* This method given a taskType object and a boolean value to signal if it is a delete
	   or a add/update will update the table source and refresh the work item type table in the admin view so that
	   it is populated with the most current set of data */
	   refreshTable(taskType, isRemoval:boolean){
		if(isRemoval){
			var index = this.taskTypeDataSource.data.indexOf(taskType);
			this.taskTypeDataSource.data.splice(index);
			this.taskTypeDataSource._updateChangeSubscription();
		}
		else{
			this.taskTypeDataSource.data.push(taskType);
			this.taskTypeDataSource._updateChangeSubscription();
		}
	}



    updateTaskTypeData(taskType){
        this.snackBar.open('Updating Data', '', { duration: 2000 });
        this._settingsService.putTaskType(taskType).subscribe(
            data => {
				this.refreshTable(taskType, false);
                this.snackBar.open('Updated', '', { duration: 2000 });
            }, err => {
                this.snackBar.open(err.statusText, '', { duration: 2000 });
            }
        );
    }

    deleteTaskType(taskType){
        const dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
            width: '585px',
            height: 'auto'
        });
        dialogRef.afterClosed().subscribe(result => {// Check if the entry is to be deleted
            if (result !== undefined) {
                if (result['toDelete']) {
                    this.snackBar.open('Deleting Data', '', { duration: 2000 });
                    this._settingsService.deleteTaskType(taskType.typeId).subscribe(
                        data => {
							this.refreshTable(taskType, true);
                            this.snackBar.open('Deleted', '', { duration: 2000 });
                        }, err => {
                            this.snackBar.open(err.statusText, '', { duration: 2000 });
                        }
                    );
                }
            }
        });
    }

    loadDialog(entryId) {
        let sendData = { newData: true };
        sendData['newData'] = true;
        let entry = new Object();

        sendData['entry'] = entry;
        sendData['entry']['isActive'] = true;

        if (entryId != 'newData') {
            sendData['entry'] = this.getTaskTypeDataById(entryId);
            sendData['newData'] = false;
        }

        const dialogRef = this.dialog.open(ManagementTasksTypeDialogComponent, {
            width: '800px',
            height: 'auto',
            data: sendData  //This is where data is passed to dialog
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result !== undefined) {
                if (result['submit']) {
                    this.updateTaskTypeData(result['data']);
                }
            }
        });
    }

}
