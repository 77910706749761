import { Component, OnInit, ViewChild, ViewChildren, Input, Output, EventEmitter } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatDialog, MatSnackBar, MatSort } from '@angular/material';
import { FormControl } from '@angular/forms';
import { PortfolioService } from '../../api/portfolio.service';
import { DeleteConfirmationDialogComponent } from '../../extra/delete-confirmation-dialog/delete-confirmation-dialog.component';
import { SimpleChanges } from '@angular/core/src/metadata/lifecycle_hooks';
import { SelectionModel } from '@angular/cdk/collections';
import { ProjectPortfolioRelationshipDialogComponent } from '../project-portfolio-relationship-dialog/project-portfolio-relationship-dialog.component';
var cloneDeep = require('lodash.clonedeep');

@Component({
    selector: 'app-project-portfolios-details',
    templateUrl: './project-portfolios-details.component.html',
    styleUrls: ['./project-portfolios-details.component.scss']
})
export class ProjectPortfoliosDetailsComponent implements OnInit {
    @Input() portfolioData = {
        clients: [],
        selectedProjects: [],
        entry: {
            id: -1
        }
    };
    @Input() portfolioList = [];
    @Output() toggleRelations = new EventEmitter<any>();
    @Output() updateProjectPortfolio = new EventEmitter<any>();
    @Output() deleteProjectPortfolio = new EventEmitter<any>();
    @Output() refreshProjectPortfolio = new EventEmitter<any>();

    @ViewChild('sortCol1') sortCol1: MatSort;
    @ViewChild('sortCol2') sortCol2: MatSort;

    @ViewChildren(MatPaginator) paginators;

    projectId: FormControl = new FormControl(null);

    id: FormControl = new FormControl({ value: null, disabled: true });
    portfolioName: FormControl = new FormControl(null);
    duration: FormControl = new FormControl({ value: null, disabled: true });
    completion: FormControl = new FormControl({ value: null, disabled: true });
    plannedHours: FormControl = new FormControl({ value: null, disabled: true });
    actualHours: FormControl = new FormControl({ value: null, disabled: true });
    status: FormControl = new FormControl(null);
    plannedStartDate: FormControl = new FormControl(null);
    startDate: FormControl = new FormControl(null);
    plannedEndDate: FormControl = new FormControl(null);
    endDate: FormControl = new FormControl(null);

    portfolioValid: boolean = true;
    portfolioValidationErrorMessage: String;

    displayedColumns = ['select', 'projectNumber', 'name', 'clientName', 'status', 'projectStartDate', 'projectEndDate',
        'plannedHours', 'actualHours'];

    displayedJoinColumns = ['select', 'id', 'name', 'status', 'relationship', 'completion', 'plannedHours', 'actualHours',
     'options', 'delete'];

    portfolioRelationList = [];
    portfolioJoinRelationList = [];
    filterProjectSelectionList = [];

    dataSource = new MatTableDataSource();
    dataSourceLoading: boolean = true;
    selection = new SelectionModel(true, []);

    dataJoinSource = new MatTableDataSource();
    dataJoinSourceLoading: boolean = true;
    joinSelection = new SelectionModel(true, []);

    portfolioStatuses = [
        {
            name: 'On Track',
            id: 1
        },
        {
            name: 'Slight Risk',
            id: 2
        },
        {
            name: 'High Risk',
            id: 3
        }];

    constructor(private _portfolioService: PortfolioService, public dialog: MatDialog, public snackBar: MatSnackBar) { }

    ngOnInit() {
		this.filterProjectSelectionList = Object.assign([], JSON.parse(JSON.stringify(this.portfolioData.selectedProjects)));
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.portfolioData != null) {
            this.id.setValue(this.portfolioData['entry']['id']);
            this.duration.setValue(parseFloat(this.portfolioData['entry']['duration'].toFixed(2)));
            this.completion.setValue(parseFloat(this.portfolioData['entry']['completion'].toFixed(2)));
            this.plannedHours.setValue(parseFloat(this.portfolioData['entry']['plannedHours'].toFixed(2)));
            this.actualHours.setValue(parseFloat(this.portfolioData['entry']['actualHours'].toFixed(2)));
            this.portfolioName.setValue(this.portfolioData['entry']['name']);
            this.status.setValue(this.portfolioData['entry']['status']);
            this.plannedStartDate.setValue(this.portfolioData['entry']['plannedStart']);
            this.startDate.setValue(this.portfolioData['entry']['actualStart']);
            this.plannedEndDate.setValue(this.portfolioData['entry']['plannedCompletion']);
            this.endDate.setValue(this.portfolioData['entry']['actualCompletion']);

            this.getProjectRelationships(this.id.value);
            this.getProjectJoinRelationships(this.id.value);
        }
        else {
            //Default Set
            this.portfolioData = {
                clients: [],
                selectedProjects: [],
                entry: {
                    id: -1
                }
            };
        }
    }

    getProjectRelationships(portfolioId, updateNeeded = false) {
        this.dataSourceLoading = true;
        this._portfolioService.getAllPortfolioRelations(portfolioId).subscribe(
            portfolioRelationData => {
                this.dataSourceLoading = false;
                this.portfolioRelationList = Object.assign([], portfolioRelationData);
                this.filterProjectSelectionList = Object.assign([], JSON.parse(JSON.stringify(this.portfolioData.selectedProjects)));
                this.filterProjectList();

                this.dataSource = new MatTableDataSource(this.portfolioRelationList);
                this.sortProjectsList();
                this.dataSource.paginator = this.paginators.toArray()[0];
            },
            portfolioRelationDataErr => {
                this.dataSourceLoading = false;
            }
        );
        if (updateNeeded) {
            this._portfolioService.getPortfolioDetails(portfolioId).subscribe(
                portfolioDetailsData => {
                    this.duration.setValue(parseFloat(portfolioDetailsData[0]["DURATION"].toFixed(2)));
                    this.completion.setValue(parseFloat(portfolioDetailsData[0]["COMPLETION"].toFixed(2)));
                    this.plannedHours.setValue(parseFloat(portfolioDetailsData[0]["PLANNEDHOURS"].toFixed(2)));
                    this.actualHours.setValue(parseFloat(portfolioDetailsData[0]["ACTUALHOURS"].toFixed(2)));
                },
                portfolioDetailsErr => {
                }
            );
            this.refreshProjectPortfolio.emit();
        }
    }

    getProjectJoinRelationships(portfolioId) {
        this.dataJoinSourceLoading = true;
        this._portfolioService.getAllPortfolioJoinRelations(portfolioId).subscribe(
            portfolioJoinRelationData => {
                this.dataJoinSourceLoading = false;
                this.portfolioJoinRelationList = Object.assign([], portfolioJoinRelationData);

                this.dataJoinSource = new MatTableDataSource(this.portfolioJoinRelationList);
                this.sortPortfolioJoinList();
                this.dataJoinSource.paginator = this.paginators.toArray()[1];
            },
            portfolioJoinRelationDataErr => {
                this.dataJoinSourceLoading = false;
            }
        );
    }

    filterProjectList() {
        for (var i = 0; i < this.filterProjectSelectionList.length; i++) {
            for (var j = 0; j < this.portfolioRelationList.length; j++) {
                if (this.filterProjectSelectionList[i].projects != null) {
                    this.filterProjectSelectionList[i].projects =
                        this.filterProjectSelectionList[i].projects.filter(project => project.id != this.portfolioRelationList[j].projectId);
                    if (this.filterProjectSelectionList[i].projects.length == 0)
                        this.filterProjectSelectionList[i].projects = null;
                }
            }
        }
    }

    addProjectRelationship() {
        if (this.projectId.value != undefined) {
            this.dataSourceLoading = true;
            this.dataSource = new MatTableDataSource([]);
            var portfolioRelation = { "portfolioId": this.id.value, "projectId": this.projectId.value };
            this._portfolioService.putPortfolioRelation(portfolioRelation).subscribe(
                portfolioRelationData => {
                    this.projectId.setValue(null);
                    this.getProjectRelationships(this.id.value, true);
                },
                portfolioRelationErr => {
                    this.projectId.setValue(null);
                    this.dataSourceLoading = false;
                }
            );
        }
    }

    toggleAllProjectsSelected() {
        this.isAllProjectsSelected() ?
            this.selection.clear() :
            this.dataSource.data.forEach(row => this.selection.select(row));
    }

    isAllProjectsSelected() {
        return this.selection.selected.length == this.dataSource.data.length;
    }

    toggleAllRelationsSelected() {
        this.isAllRelationsSelected() ?
            this.joinSelection.clear() :
            this.dataJoinSource.data.forEach(row => row['relationship'] != 'Indirect' ? this.joinSelection.select(row) : null);
    }

    isAllRelationsSelected() {
        return this.joinSelection.selected.length == this.dataJoinSource.data.filter(row => row['relationship'] != 'Indirect').length;
    }

    deleteAllSelectedProjectRelationships() {
        if (this.selection.selected.length > 0) {
            const dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
                width: '585px',
                height: 'auto',
                data: {
                    title: "Confirm Unlink Project(s)",
                    button: "Unlink"
                }
            });
            dialogRef.afterClosed().subscribe(result => {// Check if the entry is to be deleted
                if (result !== undefined) {
                    if (result['toDelete']) {
                        var relationIdList = [];
                        for (var i = 0; i < this.selection.selected.length; i++) {
                            relationIdList.push(this.selection.selected[i].id);
                        }
                        this.dataSource = new MatTableDataSource([]);
                        this.dataSourceLoading = true;
                        this._portfolioService.deletePortfolioRelations(relationIdList).subscribe(
                            deleteRelationData => {
                                this.selection.clear();
                                this.getProjectRelationships(this.id.value, true);
                            },
                            deleteRelationErr => {
                                this.selection.clear();
                                this.dataSourceLoading = false;
                            }
                        );
                    }
                }
            });
        }
    }

    deletePortfolioRelationships(){
        if (this.joinSelection.selected.length > 0) {
            const dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
                width: '585px'
            });
            dialogRef.afterClosed().subscribe(result => {// Check if the entry is to be deleted
				if (result !== undefined) {
                    if (result['toDelete']) {
                        var relationIdList = [];
                        for (var i = 0; i < this.joinSelection.selected.length; i++) {
                            relationIdList.push(this.joinSelection.selected[i].id);
                        }
                        this.dataJoinSource = new MatTableDataSource([]);
                        this.dataJoinSourceLoading = true;
                        this._portfolioService.deletePortfolioJoinRelations(relationIdList).subscribe(
                            deleteJoinRelationData => {
                                this.joinSelection.clear();
                                this.getProjectJoinRelationships(this.id.value);
                            },
                            deleteJoinRelationErr => {
                                this.joinSelection.clear();
                                this.dataJoinSourceLoading = false;
                            }
                        );
                    }
                }
            });
        }
    }

    refreshProjectsFilter(searchValue?: string) {
        if (searchValue != null)
            searchValue = searchValue.toLowerCase();
        let filteredProjectData = [];
        this.portfolioRelationList.forEach(entry => {
            if (String(entry['projectNumber']).toLowerCase().includes(searchValue) ||
                entry['projectName'].toLowerCase().includes(searchValue) ||
                entry['clientName'].toLowerCase().includes(searchValue) || searchValue == '') {
                filteredProjectData.push(entry);
            }
        });

        if (searchValue == undefined || searchValue == '') {
            this.dataSource = new MatTableDataSource(this.portfolioRelationList);
            this.sortProjectsList();
        }
        else {
            this.dataSource = new MatTableDataSource(filteredProjectData);
            this.sortProjectsList();
        }
    }

    refreshPortfolioJoinFilter(searchValue?: string) {
        if (searchValue != null)
            searchValue = searchValue.toLowerCase();
        let filteredJoinPortfolioData = [];
        this.portfolioJoinRelationList.forEach(entry => {
            if (String(entry['portfolio']['id']).toLowerCase().includes(searchValue) ||
                entry['portfolio']['name'].toLowerCase().includes(searchValue) ||  searchValue == '') {
                    filteredJoinPortfolioData.push(entry);
            }
        });
        if (searchValue == undefined || searchValue == '') {
            this.dataJoinSource = new MatTableDataSource(this.portfolioJoinRelationList);
            this.sortPortfolioJoinList();
        }
        else {
            this.dataJoinSource = new MatTableDataSource(filteredJoinPortfolioData);
            this.sortPortfolioJoinList();
        }
    }

    sortProjectsList() {
        this.dataSource.sort = this.sortCol1;
        this.dataSource.sortingDataAccessor = (item, property) => {
            switch (property) {
                case 'projectNumber': return item['projectNumber'];
                case 'name': return item['projectName'];
                case 'clientName': return item['clientName'];
                case 'status': return item['status'];
                case 'projectStartDate': return item['projectStartDate'];
                case 'projectEndDate': return item['projectEndDate'];
                case 'plannedHours': return item['plannedHours'];
                case 'actualHours': return item['actualHours'];
                default: return item[property];
            }
        };
        this.sortCol1.sort({
            id: 'projectNumber',
            start: 'asc',
            disableClear: true,
        });
    }

    loadDetails(portfolioRelationship = null){
        var filteredPortfolios = Object.assign([], JSON.parse(JSON.stringify(this.portfolioList)));;
        for(var i = 0; i < this.portfolioJoinRelationList.length; i++){
            filteredPortfolios = filteredPortfolios.filter(portfolio => portfolio.id != this.portfolioJoinRelationList[i]['portfolio'].id)
        }
        filteredPortfolios = filteredPortfolios.filter(portfolio => portfolio.id != this.id.value);

        let sendData = {};
        if(portfolioRelationship == null){
            sendData = { newData: true, entry: {
                portfolio: null,
                relationshipTypeId: null,
                portfolioList: filteredPortfolios,
                portfolioCurrentId: this.id.value,
                id: null
            }};
        }
        else {
            sendData = { newData: false, entry: {
                portfolio: portfolioRelationship.portfolio,
                relationshipTypeId: portfolioRelationship.relationshipTypeId,
                portfolioList: filteredPortfolios,
                portfolioCurrentId: this.id.value,
                id: portfolioRelationship.id
            }};
        }

        const dialogRef = this.dialog.open(ProjectPortfolioRelationshipDialogComponent, {
            width: '500px',
            height: 'auto',
            data: sendData
        });
        dialogRef.afterClosed().subscribe(dialogResult => {
            if (dialogResult !== undefined) {
                if (dialogResult['submit']) {
                    this.putPortfolioJoinData(dialogResult['data']);
                }
            }
        });
    }

    putPortfolioJoinData(portfolioJoinData){
        this.dataJoinSourceLoading = true;
        this.dataJoinSource = new MatTableDataSource([]);
        var portfolioJoinRelation = {
            "portfolioRelation1": portfolioJoinData.portfolioCurrentId,
            "portfolioRelation2": portfolioJoinData.portfolio.id,
            "relationshipTypeId": portfolioJoinData.relationshipTypeId,
            "id": portfolioJoinData.id
             };
        this._portfolioService.putPortfolioJoinRelation(portfolioJoinRelation).subscribe(
            portfolioRelationData => {
                this.getProjectJoinRelationships(this.id.value);
            },
            portfolioRelationErr => {
                this.dataJoinSourceLoading = false;
            }
        );
    }

    loadPortolioDetails(portfolioData){
        this.dataSource = new MatTableDataSource([]);
        this.dataJoinSource = new MatTableDataSource([]);
        this.selection.clear();
        this.joinSelection.clear();
        (<HTMLInputElement>document.getElementsByClassName('search-input')[0]).value = "";
        (<HTMLInputElement>document.getElementsByClassName('search-input')[1]).value = "";
        this.projectId.setValue(null);

        var updatedPortfolio = this.portfolioList.find(portfolio => portfolio.id == portfolioData.id);

        this.id.setValue(updatedPortfolio['id']);
        this.duration.setValue(parseFloat(updatedPortfolio['duration'].toFixed(2)));
        this.completion.setValue(parseFloat(updatedPortfolio['completion'].toFixed(2)));
        this.plannedHours.setValue(parseFloat(updatedPortfolio['plannedHours'].toFixed(2)));
        this.actualHours.setValue(parseFloat(updatedPortfolio['actualHours'].toFixed(2)));
        this.portfolioName.setValue(updatedPortfolio['name']);
        this.status.setValue(updatedPortfolio['status']);
        this.plannedStartDate.setValue(updatedPortfolio['plannedStart']);
        this.startDate.setValue(updatedPortfolio['actualStart']);
        this.plannedEndDate.setValue(updatedPortfolio['plannedCompletion']);
        this.endDate.setValue(updatedPortfolio['actualCompletion']);

        this.getProjectRelationships(updatedPortfolio.id);
        this.getProjectJoinRelationships(updatedPortfolio.id);
    }

    sortPortfolioJoinList() {
        this.dataJoinSource.sort = this.sortCol2;
        this.dataJoinSource.sortingDataAccessor = (item, property) => {
            switch (property) {
                case 'id': return item['portfolio']['id'];
                case 'name': return item['portfolio']['name'];
                case 'status': return item['portfolio']['status'];
                case 'relationship': return item['relationship'];
                case 'completion': return item['portfolio']['completion'];
                case 'plannedHours': return item['portfolio']['plannedHours'];
                case 'actualHours': return item['portfolio']['actualHours'];
                default: return item[property];
            }
        };
        this.sortCol2.sort({
            id: 'id',
            start: 'asc',
            disableClear: true,
        });
    }

    sortData1(sort){
        if(sort.direction == ""){
            this.sortCol1.sort({
                id: 'projectNumber',
                start: 'asc',
                disableClear: true,
            });
        }
    }

    sortData2(sort){
        if(sort.direction == ""){
            this.sortCol2.sort({
                id: 'id',
                start: 'asc',
                disableClear: true,
            });
        }
    }

    saveDialog() {
        if (this.isPortfolioDetailsValid()) {
            this.portfolioData['entry']['name'] = this.portfolioName.value;
            this.portfolioData['entry']['status'] = this.status.value;
            this.portfolioData['entry']['plannedStart'] = this.plannedStartDate.value;
            this.portfolioData['entry']['plannedCompletion'] = this.plannedEndDate.value;
            this.portfolioData['entry']['actualStart'] = this.startDate.value;
            this.portfolioData['entry']['actualCompletion'] = this.endDate.value;

            this.updateProjectPortfolio.emit(this.portfolioData['entry']);
        }
    }

    deleteDialog() {
        this.deleteProjectPortfolio.emit(this.id.value);
        this.closeDialog();
    }

    closeDialog() {
        this.toggleRelations.emit(false);
    }

    isPortfolioDetailsValid() {
        this.portfolioValidationErrorMessage = null;
        if (this.portfolioName.value == null || this.portfolioName.value == '') {
            this.portfolioValidationErrorMessage = 'Portfolio Name is required';
            return false;
        }
        if (this.status.value == null) {
            this.portfolioValidationErrorMessage = 'Status is required';
            return false;
        }
        if (this.plannedStartDate.value == null) {
            this.portfolioValidationErrorMessage = 'Planned Start Date is required';
            return false;
        }
        if (this.plannedEndDate.value == null) {
            this.portfolioValidationErrorMessage = 'Planned End Date is required';
            return false;
        } else {
            this.portfolioValid = true;
            return true;
        }
    }

    onKeyProjects(value) {
        let filter = value.toLowerCase();

		let filteredClientsAndProjects = this.portfolioData.clients.filter(option => option['name'].toLowerCase().includes(filter));
		for(var i = 0; i < this.portfolioData.clients.length; i++){
			if(filteredClientsAndProjects.find(option => option['id'] == this.portfolioData.clients[i]['id']) == undefined){
				if(this.portfolioData.clients[i]['projects'] != null){
					let tempClient = cloneDeep(this.portfolioData.clients[i]);
					tempClient['projects'] = tempClient['projects'].filter(project => project['name'].toLowerCase().includes(filter))
					if(tempClient['projects'].length > 0){
						filteredClientsAndProjects.push(tempClient);   
					}
				}
			}
		}
		this.filterProjectSelectionList = filteredClientsAndProjects.sort((a, b) => { return (a['name'] > b['name']) ? 1 : ((b['name'] > a['name']) ? -1 : 0); });	
    }
}
