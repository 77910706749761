import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tenant-admin-information',
  templateUrl: './tenant-admin-information.component.html',
  styleUrls: ['./tenant-admin-information.component.scss']
})
export class TenantAdminInformationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
