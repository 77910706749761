import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-expense-policy-confirm-dialog',
  templateUrl: './expense-policy-confirm-dialog.component.html',
  styleUrls: ['./expense-policy-confirm-dialog.component.scss']
})
export class ExpensePolicyConfirmDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ExpensePolicyConfirmDialogComponent>) { }

  ngOnInit() {
  }

  confirmSelection() {
    this.dialogRef.close(true);
  }

  cancel(){
	this.dialogRef.close(false);
  }
}
