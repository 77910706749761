import { Component, OnInit, ViewChild, ViewChildren, Input, Output, OnChanges, ElementRef, EventEmitter, NgZone } from '@angular/core';
import { MatPaginator, MatTableDataSource, MatDialog, MatSnackBar, MatSort, MatSelect } from '@angular/material';
import { ProjectsService } from '../../api/projects.service';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { SetttingsService } from '../../api/setttings.service';
import { SprintService } from '../../api/sprint.service';
import { Auth0ManagementApiService } from '../../services/auth0-management-api/auth0-management-api.service';
import { DeleteConfirmationDialogComponent } from '../../extra/delete-confirmation-dialog/delete-confirmation-dialog.component';
import { SimpleChanges } from '@angular/core/src/metadata/lifecycle_hooks';
import { AuthService } from '../../services/auth/auth.service';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatAutocompleteSelectedEvent, MatAutocomplete} from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';
import {Observable} from 'rxjs';
import { ShareProject } from '../../services/sharing/share-project.service';
var async = require('async');
import {map, startWith, take} from 'rxjs/operators';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';

@Component({
    selector: 'app-tasks',
    templateUrl: './task-page.component.html',
    styleUrls: ['./task-page.component.scss']
})

export class TasksPageComponent implements OnInit, OnChanges {
    taskDataSource = new MatTableDataSource();
    columnsToDisplay = ['id', 'name', 'projectName'];
    dataSourceLoading: boolean;
    taskTypes;
    taskStatuses;
    users;
    TaskEntry: object;
    UserProjects: object[];
    EmptyUserProjects: object[];
    count: number;
    filteredUsersByProject: object[] = [];
    usersByProject: object[] = undefined;
    priorities = [{name: "Lowest", id:1},{name: "Low", id:2},
        {name: "Medium", id:3},{name: "High", id:4},{name: "Highest", id:5}]
    entry: object = null;
    //If there are no tasks found, hides the details of the tasks
    hideDetailsPage: boolean = true;

    filteredProjects: object = [];
    filteredUsers: object = [];
    filteredTypes: object = [];
    filteredStatuses: object = [];

	id: FormControl = new FormControl();
    name: FormControl = new FormControl();
    reporter: FormControl = new FormControl();
    taskType: FormControl = new FormControl();
    taskStatus: FormControl = new FormControl();
    assignedUser: FormControl = new FormControl();
    plannedStartDate: FormControl = new FormControl();
    actualStartDate: FormControl = new FormControl();
    plannedEndDate: FormControl = new FormControl();
    actualEndDate: FormControl = new FormControl();
    commitDate: FormControl = new FormControl();
    description: FormControl = new FormControl();
    projectId: FormControl = new FormControl();
    priority: FormControl = new FormControl();
    sprint: FormControl = new FormControl();
    valid: boolean = true;
    error: String;
    fullyLoaded: boolean = false;
    canDeleteTasks: boolean = false;

    disableComment: boolean = false;
    visible = true;
    selectable = true;
    removable = true;
    separatorKeysCodes: number[] = [ENTER, COMMA];
    labelCtrl = new FormControl();
	milestoneCtrl = new FormControl();
    filteredLabels: Observable<any[]>;
	filteredMilestones: Observable<any[]>;
    selectedSprints: any[];
    labels: any[];
	milestones: any[];
    allLabels: any[] = [];
	allMilestones: any[] = [];
    allSprints: object[] = [];;

	comments: any = [];
	commentTextarea = "";

    unassignedUserValue: string = "unassignedId";
    unassignedValue = null;
	currentUserId;
	currentUserFullName = 'User';

    original =[];

	@Input() searchFilterText = '';
    @Input() filters: object[] = [];
    @Input() addATask: boolean = false;
    @Input() extraFields: object;
    @Output() labelUpdate = new EventEmitter<any>();
	@Output() milestoneUpdate = new EventEmitter<any>();
    @Output() refreshBoardDelete = new EventEmitter<any>();
    @Output() labelUniqueUpdate = new EventEmitter<any>();
	@Output() milestoneUniqueUpdate = new EventEmitter<any>();

    @ViewChild(MatSelect) filterSelectUser;
    @ViewChild(MatSelect) filterSelectProject;
    @ViewChild(MatSelect) filterSelectType;
    @ViewChild(MatSelect) filterSelectStatus;
	@ViewChild(MatSelect) filterSelectAssignees;
    @ViewChild('labelInput') labelInput: ElementRef<HTMLInputElement>;
	@ViewChild('milestoneInput') milestoneInput: ElementRef<HTMLInputElement>;
    //@ViewChild('auto') matAutocomplete: MatAutocomplete;
    @ViewChild('autosize') autosize: CdkTextareaAutosize;

    @ViewChild(MatPaginator) projectsPaginator: MatPaginator;
    @ViewChildren(MatSort) sort;
	isReadyToComment: boolean;
	clients: any[];
	selectedProjects: any[];

    constructor(private _projectsService: ProjectsService,
        private _settingsService: SetttingsService,
		private _auth0ManagementApiService: Auth0ManagementApiService,
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        public router: Router,
        private authService: AuthService,
        private shareProject: ShareProject,
        private _sprintService: SprintService,
        private _ngZone: NgZone) {
    }

    ngOnInit() {
        this.fullyLoaded = false;
        
        this.authService.getUserPermissions().subscribe(userPermissions => this.canDeleteTasks = userPermissions.includes('manage:projects'));
		this.getClients();
		this.getUserIdAndFullName();
		this.getUserProjects(true);
        this.getTaskTypesAndStatuses();
        this.getLabels();
		this.getMilestones();
        this.getProjectsWithUsers();
        this.getSprints();

        this._projectsService.entryGrabbed$.subscribe((entry) => {
            this.TaskEntry = entry;
            this.UserProjects.push(this.TaskEntry[0]);
            this.RefreshTable();
          });

          this._projectsService.tableUpdated$.subscribe(() => {
            this.taskDataSource = new MatTableDataSource(this.EmptyUserProjects);
            this.dataSourceLoading = true;
          });
        
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.addATask) {
            this.addATask = false;
            this.getUserProjects();
        }
        else {
            //This triggers once before it loads that data so have
            //to specify that only after the data source has data
            if (this.taskDataSource.data.length != 0) {
                this.refreshFilter();
            }
        }
    }

    getProjectsWithUsers() {
		var sharedProjectUserMappings = this.shareProject.getProjectUserMappings();
        if(sharedProjectUserMappings == null || sharedProjectUserMappings == undefined){
			this._settingsService.getProjectsWithUsers().subscribe(data => {
				this.usersByProject = Object.assign([], data);
			});
		}else this.usersByProject = sharedProjectUserMappings;
    }

    refreshFilter(selectTask: boolean = true) {
        this.taskDataSource.filterPredicate = (data:
            { id: string }, filter: string) => {
            let searchlist =  JSON.parse(filter);
            var found = false;

            var foundLabel = false;
            for(var i = 0; i < data["labels"].length; i++){
                if(searchlist[4].indexOf(data["labels"][i]["id"]) !== -1){
                    foundLabel = true;
                    break;
                }
            }

			var foundMilestone = false;
			for(var i = 0; i < data["milestones"].length; i++) {
				if(searchlist[5].indexOf(data["milestones"][i]["id"]) !== -1) {
					foundMilestone = true;
					break;
				}
			}

            if ((searchlist[0].length == 0 || searchlist[0].indexOf(data["projectId"]) !== -1) &&
                (searchlist[1].length == 0 || searchlist[1].indexOf(data["assignedUserId"]) !== -1) &&
                (searchlist[2].length == 0 || searchlist[2].indexOf(data["typeId"]) !== -1) &&
                (searchlist[3].length == 0 || searchlist[3].indexOf(data["taskStatus"]) !== -1) &&
                (searchlist[4].length == 0 || foundLabel) &&
				(searchlist[5].length == 0 || foundMilestone) &&
				(searchlist[6].length == 0 || data['name'].trim().toLowerCase().includes(searchlist[6][0]) ||
					data['projectName'].trim().toLowerCase().includes(searchlist[6][0]) ||
					data['id'].toString().includes(searchlist[6][0])))
				{
                found = true;
            }
            return found
		}

        if (this.entry == null || this.taskDataSource.filteredData.filter(x => x["id"] == this.entry["id"]).length == 0) {
            if (this.taskDataSource.data.length == 0) {
                this.hideDetailsPage = true;
            }
            else if (this.taskDataSource.filteredData.length == 0) {
                this.hideDetailsPage = false;
				if(selectTask) this.selectTask(this.taskDataSource.data[0]);
            }
            else {
                this.hideDetailsPage = false;
                if(selectTask) this.selectTask(this.taskDataSource.filteredData[0]);
            }
        }

		if(this.searchFilterText.trim().toLowerCase() != undefined)
			this.filters[6] = [this.searchFilterText.trim().toLowerCase()];
		this.taskDataSource.filter = JSON.stringify(this.filters);

        var maxPageAmount = Math.ceil(this.taskDataSource.filteredData.length / this.taskDataSource.paginator.pageSize);
        var actualPage = this.taskDataSource.paginator.pageIndex + 1;
        if (actualPage > maxPageAmount) {
            if (maxPageAmount == 0) {
                this.projectsPaginator.pageIndex = 0;
                this.taskDataSource.paginator = this.projectsPaginator;
            }
            else {
                this.projectsPaginator.pageIndex = maxPageAmount - 1;
                this.taskDataSource.paginator = this.projectsPaginator;
            }
        }
    }

    filterUsersByProject(projectId) {
        var sprintList = this.allSprints.filter(x => x['projectId'] == projectId);
        this.selectedSprints = sprintList;
        
        var projectUserList = this.usersByProject.filter(x => x['projectId'] == projectId);
        if (projectUserList != null && projectUserList.length > 0) {
            this.filteredUsersByProject = this.users.filter(function (user) {
                return projectUserList[0]['userIds'].includes(user.userId)
            });
        }
        else {
            this.filteredUsersByProject = [];
        }
    }

	getClients() {
		var sharedClients = this.shareProject.getClients();
		if(sharedClients == undefined){
			this.clients = [];
			this.selectedProjects = [];
			let result = [];
			this._settingsService.getClients().subscribe(data => {
				result = Object.keys(data).map((key) => [Number(key), data[key]]);
				this.clients = [];

				async.forEach(result,(client,inner_callback)=>{
					this.clients.push(client['1']);
					inner_callback(null);
				},()=>{
					this.getProjects();
				});
			});
		}else this.clients = sharedClients;

	  }

	  getProjects() {
		  this._settingsService.getProjectsByClientId(this.clients, true, true).subscribe(data => {
			  async.forEach(this.clients,(client,inner_callback)=>{
				if (data[client['id']].length > 0) client['projects'] = data[client['id']];
				inner_callback(null);
			  },()=> this.shareProject.setClients(this.clients));
		  });
	  }

    getTaskTypesAndStatuses() {
		// tasks statuses
        var count = 0;
		var sharedTaskStatuses = this.shareProject.getTaskStatuses();
		if(sharedTaskStatuses==undefined){
			this._settingsService.getStatuses().subscribe(data => {
				this.taskStatuses = data;
				this.taskStatuses = this.taskStatuses.sort(function (a, b) {
					return a.order - b.order;
				});
				this.shareProject.setTaskStatuses(this.taskStatuses);
				count = count + 1;
				if (count == 3) this.fullyLoaded = true;
			});
		}else{
			this.taskStatuses = sharedTaskStatuses;
			this.shareProject.setTaskStatuses(this.taskStatuses);
			count = count + 1;
			if (count == 3) this.fullyLoaded = true;
		}

		// task types
		var sharedTaskTypes = this.shareProject.getTaskTypes();
		if(sharedTaskTypes==undefined){
			this._settingsService.getTaskTypes().subscribe(data => {
				this.taskTypes = data;
				this.shareProject.setTaskTypes(this.taskTypes);
				count = count + 1;
				if (count == 3) this.fullyLoaded = true;
			});
		}else{
			this.taskTypes = sharedTaskTypes;
			count = count + 1;
			if (count == 3) this.fullyLoaded = true;
		}

		// users
		var sharedUsers = this.shareProject.getUsers();
		if(sharedUsers==undefined){
			this._settingsService.getUsers(1).subscribe(data => {
				this.users = data;
				this.shareProject.setUsers(this.users);
				count = count + 1;
				if (count == 3) this.fullyLoaded = true;
			});
		}else{
			this.users = sharedUsers;
			count = count + 1;
			if (count == 3) this.fullyLoaded = true;
		}
    }

    selectTask(item) {
		this.getComments(item.id);
        this._projectsService.getProjectTaskById(item.id).subscribe(
            data => {
                this.loadDialog(data);
            },
            err => {
                this.snackBar.open("Error Retrieving Work Item", "", { duration: 2000 });
            }
        );
    }

    getLabels() {
		var sharedLabels = this.shareProject.getLabels();
		if(sharedLabels ==undefined){
			let result = [];
			this._projectsService.getAllLabels().subscribe(data => {
				result = Object.keys(data).map(function (key) {
					return data[key];
				});
				this.allLabels = result.sort((a, b) => (a.name > b.name) ? 1 : -1);
				this.shareProject.setLabels(this.allLabels);
			});
		} else this.allLabels = sharedLabels;

    }

	getMilestones() {
		let result = [];
		this._projectsService.getAllMilestones().subscribe(data => {
            result = Object.keys(data).map(function (key) {
                return data[key];
            });
            this.allMilestones = result.sort((a, b) => (a.name > b.name) ? 1 : -1);
        });
	}

    getSprints() {
		let result = []
		this._sprintService.getSprints().subscribe(data => {
			result = Object.keys(data).map(function (key) {
				return data[key];
			});
			result = result.sort((a, b) => (a.name > b.name) ? 1 : -1);
			this.allSprints = result;

            var sprintList = this.allSprints.filter(x => x['projectId'] == this.projectId.value);
            this.selectedSprints = sprintList;
		});
	}

    clearLabels(){
        this.labels = [];
        this.labelCtrl.setValue(null);
    }

	clearMilestones() {
		this.milestones = [];
        this.milestoneCtrl.setValue(null);
	}

    add(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;

        if ((value || '').trim()) {
          this.labels.push({id: -1, name: value.trim()});
        }
        // Reset the input value
        if (input) {
          input.value = '';
        }
        this.labelCtrl.setValue(null);
    }

	addMilestone(event: MatChipInputEvent): void {
		const input = event.input;
		const value = event.value;
 
		if ((value || '').trim()) {
			this.milestones.push({id: -1, name: value.trim()});
		  }
		  // Reset the input value
		  if (input) {
			input.value = '';
		  }
		  this.milestoneCtrl.setValue(null);
	}

    remove(label: any): void {
        if(label.id == -1){
            const index = this.labels.indexOf(label);

            if (index >= 0) {
              this.labels.splice(index, 1);
            }
        }
        else {
            this.labels = this.labels.filter(curr => curr.id != label.id)
            this.labelCtrl.setValue(null);
        }
    }

	removeMilestone(milestone: any): void {
		if(milestone.id == -1){
            const index = this.milestones.indexOf(milestone);

            if (index >= 0) {
              this.milestones.splice(index, 1);
            }
        }
        else {
            this.milestones = this.milestones.filter(curr => curr.id != milestone.id)
            this.milestoneCtrl.setValue(null);
        }
	}

    selected(event: MatAutocompleteSelectedEvent): void {
        this.labels.push(event.option.value);
        this.labelInput.nativeElement.value = '';
        this.labelCtrl.setValue(null);
    }

	selectedMilestone(event: MatAutocompleteSelectedEvent): void {
        this.milestones.push(event.option.value);
        this.milestoneInput.nativeElement.value = '';
        this.milestoneCtrl.setValue(null);
    }

    private _filter(value): any[] {
        if(typeof value == 'string'){
            const filterValue = value.toLowerCase();
            let tempLabels = JSON.parse(JSON.stringify(this.allLabels));
            if(this.labels != undefined)
                for(var i = 0; i < this.labels.length; i++){
                    tempLabels = tempLabels.filter(tempLabel => tempLabel.id != this.labels[i].id)
                }
            return tempLabels.filter(label => label.name.toLowerCase().indexOf(filterValue) != -1);
        }
        else {
            return this._filterCurrent();
        }
    }

	private _filterMilestone(value): any[] {
        if(typeof value == 'string'){
            const filterValue = value.toLowerCase();
            let tempMilestones = JSON.parse(JSON.stringify(this.allMilestones));
            if(this.milestones != undefined)
                for(var i = 0; i < this.milestones.length; i++){
                    tempMilestones = tempMilestones.filter(tempMilestone => tempMilestone.id != this.milestones[i].id)
                }
            return tempMilestones.filter(milestone => milestone.name.toLowerCase().indexOf(filterValue) != -1);
        }
        else {
            return this._filterCurrentMilestone();
        }
    }

    private _filterCurrent(): any[] {
        let tempLabels = JSON.parse(JSON.stringify(this.allLabels));
        if(this.labels != undefined){
            for(var i = 0; i < this.labels.length; i++){
                tempLabels = tempLabels.filter(tempLabel => tempLabel.id != this.labels[i].id)
            }
        }
        return tempLabels.slice()
    }

	private _filterCurrentMilestone(): any[] {
        let tempMilestones = JSON.parse(JSON.stringify(this.allMilestones));
        if(this.milestones != undefined){
            for(var i = 0; i < this.milestones.length; i++){
                tempMilestones = tempMilestones.filter(tempMilestone => tempMilestone.id != this.milestones[i].id)
            }
        }
        return tempMilestones.slice()
    }

    deleteTask(item) {
        if(this.canDeleteTasks){
            const dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
                width: '585px',
                height: 'auto'
            });
            dialogRef.afterClosed().subscribe(result => {// Check if the entry is to be deleted
                if (result !== undefined) {
                    if (result['toDelete']) {
                        this.snackBar.open('Deleting Work Item', '', { duration: 2000 });
                        this._projectsService.deleteTaskById(item.id).subscribe(
                            data => {
                                this.snackBar.open("Work Item Deleted", "", { duration: 2000 });
                                this.getUserProjects(true);
                                this.refreshBoardDelete.emit();
                            },
                            err => {
                                this.snackBar.open("Error Deleting Work Item", "", { duration: 2000 });
                            }
                        );
                    }
                }
            });
        }
    }

    loadDialog(entry) {
      
        this.entry = entry;
		this.id.setValue(entry.id);
        this.name.setValue(entry.name);
        this.taskType.setValue(entry.typeId);
        this.taskStatus.setValue(entry.taskStatus);
        this.assignedUser.setValue(entry.assignedUser);
        this.plannedStartDate.setValue(entry.plannedStartDate);
        this.actualStartDate.setValue(entry.actualStartDate);
        this.plannedEndDate.setValue(entry.plannedEndDate);
        this.actualEndDate.setValue(entry.actualEndDate);
        this.commitDate.setValue(entry.commitDate);
        this.reporter.setValue(entry.reporter);
        this.description.setValue(entry.description);
        this.projectId.setValue(entry.projectId);
        this.priority.setValue(entry.priority);
        this.sprint.setValue(entry.sprintId);
        this.error = '';
        this.labels = entry.labels;
		this.milestones = entry.milestones;
        this.filterUsersByProject(entry.projectId);

        this.filteredLabels = this.labelCtrl.valueChanges.pipe(
            startWith(null),
            map((label: string | null) => label ? this._filter(label) : this._filterCurrent()));

		this.filteredMilestones = this.milestoneCtrl.valueChanges.pipe(
			startWith(null),
			map((milestone: string | null) => milestone ? this._filterMilestone(milestone) : this._filterCurrentMilestone()));

           this.original = entry;
           
    }

    RefreshTable() {
        this.taskDataSource = new MatTableDataSource([]);
        let tempdata = this.UserProjects;
        if (tempdata != null && tempdata.length > 0) {
            this.fullyLoaded = true;
       

            this.taskDataSource = new MatTableDataSource(tempdata);
            this.taskDataSource.paginator = this.projectsPaginator;
            this.dataSourceLoading = false;
    
            this.taskDataSource.sort = this.sort.first;
            this.taskDataSource.sortingDataAccessor = (item, property) => {
                switch (property) {
                    case 'name': return item['name'];
                    default: return item[property];
                }
            };
            this.refreshFilter(false); 
        } else {
            this.columnsToDisplay = [];
            this.taskDataSource = new MatTableDataSource(); 
            this.dataSourceLoading = false;
        }
    }
   
   
    getUserProjects(firstLoad = false) {
        this.taskDataSource = new MatTableDataSource([]);
        this.dataSourceLoading = true;
        this._projectsService.getProjectTaskNameId().subscribe(
            data => {
				this.fullyLoaded = true;
                if (data != null) {
                    let tempdata = Object.keys(data).map(i => data[i]);
                    let tempFilter = Object.keys(this.filters).map(i => this.filters[i]);
                    //This used because if a filter was applied before getting into this method
                    //it will have a run condition with the filter's selection
                    let filterEmpty = tempFilter[0].length == 0 && tempFilter[1].length == 0 &&
                        tempFilter[2].length == 0 && tempFilter[3].length == 0 && tempFilter[4].length == 0;

                    if (firstLoad && tempdata != null && tempdata.length > 0 && filterEmpty) {
                        this.selectTask(tempdata[0]);
                    } else if (firstLoad && tempdata != null && tempdata.length > 0) {
                        let userTempData = tempdata.filter(data => data.assignedUserId == tempFilter[1][0]);
                        if(userTempData.length > 0)
                            this.selectTask(userTempData[0]);
                    }

                    this.taskDataSource = new MatTableDataSource(tempdata);
                    this.UserProjects = tempdata;
                    this.taskDataSource.paginator = this.projectsPaginator;
                    this.dataSourceLoading = false;

                    this.taskDataSource.sort = this.sort.first;
                    this.taskDataSource.sortingDataAccessor = (item, property) => {
                        switch (property) {
                            case 'name': return item['name'];
                            default: return item[property];
                        }
                    };
                    this.refreshFilter(false); // false so the task isn't selected again
                }
                else {
                    this.columnsToDisplay = [];
                    this.taskDataSource = new MatTableDataSource(); // User isn't involved in any projects
                    this.dataSourceLoading = false;
                }
            },
            err => console.error(err)
        );
    }

    checkAndUpdate() {
        if (this.isValid()) {
            this.addUpdateTask();
        }
    }

    isValid() {
        if (this.name.value == null || this.name.value == '') {
            this.error = 'Work Item Name is required';
            return false;
        }
        if (this.taskStatus.value == null) {
            this.error = 'Work Item Status is required';
            return false;
        }
        if(this.projectId.value == null){
            this.error = 'Project is required';
            return false;
        }
        if (this.priority.value == null) {
            this.error = 'Priority is required';
            return false;
        }
        if (this.plannedStartDate.value != null && this.plannedEndDate.value != null && this.plannedStartDate.value > this.plannedEndDate.value) {
            this.error = 'Planned Start Date must be before Planned End Date';
            return false;
        }
        if (this.actualStartDate.value != null && this.actualEndDate.value != null && this.actualStartDate.value > this.actualEndDate.value) {
            this.error = 'Actual Start Date must be before Actual End Date';
            return false;
        }
        else {
            this.error = '';
            this.valid = true;
            return true;
        }
    }

    addUpdateTask(entry = null) {
        let updates = [];
        if (entry == null) {
            //An Update for a task
            entry = {
                id: this.entry["id"],
                tenantId: this.entry["tenantId"],
                projectId: this.projectId.value,
                priority: this.priority.value,
                isActive: this.entry["isActive"],
                name: this.name.value,
                typeId: this.taskType.value,
                reporter: this.reporter.value,
                taskStatus: this.taskStatus.value,
                assignedUser: this.assignedUser.value,
                plannedStartDate: this.plannedStartDate.value,
                actualStartDate: this.actualStartDate.value,
                plannedEndDate: this.plannedEndDate.value,
                actualEndDate: this.actualEndDate.value,
                commitDate: this.commitDate.value,
                description: this.description.value,
                sprintId: this.sprint.value,
                labels: this.labels,
				milestones: this.milestones,
				modifiedDate: new Date()
            }
        } 

        updates = this.getChanges(entry, this.original);
        entry['changes'] = updates;

        this._projectsService.putProjectTask(entry).subscribe(
            data => {
                this.snackBar.open("Work Item Updated Successfully", "", { duration: 2000 });
                this.labelUpdate.emit();
				this.milestoneUpdate.emit();
                this.labelUniqueUpdate.emit();
				this.milestoneUniqueUpdate.emit();
				this.getMilestones();
                this.getLabels();
                this.getUserProjects();
            },
            err => {
                this.snackBar.open("Error Updating Work Item", "", { duration: 2000 });
                this.getUserProjects();
            }
        );
    }

	getComments(taskId){
		this._projectsService.getTaskCommentsByTaskId(taskId).subscribe(
			data =>{
				this.comments = [];
				if(data == null || data == undefined){
					//no comments
				}else{
					this.putNewCommentsIntoCommentSection(data);
					this.commentTextarea = '';
				}
			},
			err =>{
				this.snackBar.open("Error Getting Work Item", "", { duration: 2000 });
			}
		)
	}

	addComment(commentContent:string){
		if (commentContent.length <= 500){
            this.disableComment = true;
			let addCommentObj = {taskId: this.id.value, comment: this.escapeSingleQuote(commentContent), userId: this.currentUserId, fullName: this.currentUserFullName};
			this._projectsService.putCommentToTask(addCommentObj).subscribe(
				data=>{
                    this.disableComment = false;
					if (data==null){
                        this.commentTextarea = '';
						this.getComments(this.id.value);
					}
                    else {
                        this.snackBar.open("Error Posting Comment", "", { duration: 2000 });
                    }
				},
				err=>{
                    this.disableComment = false;
					this.snackBar.open("Error Posting Comment", "", { duration: 2000 });
				}
			);
		}
	}

	putNewCommentsIntoCommentSection(commentsData){
		for (let i=0;i<Object.keys(commentsData).length;i++){
			commentsData[i].timestamp = new Date(commentsData[i].timestamp);
			this.comments.push(commentsData[i]);
		}
	}

	// escapes single quote substring of any length
	// used to properly query
	escapeSingleQuote(string){
		while (string.includes("''")){
			string = string.replace("''","'")
		}
		return string.replace("'","''")
	}

	getUserIdAndFullName(){
		this.isReadyToComment = false;
		this.authService.getUserId().subscribe(userId=>{
			this.currentUserId = userId
			this._auth0ManagementApiService.getUserDetails(this.currentUserId).subscribe(details => {
				this.currentUserFullName = details.fullName
				this.isReadyToComment = true;
			});
		});
	}
    
    triggerResize() {
        // Wait for changes to be applied, then trigger textarea resize.
        this._ngZone.onStable.pipe(take(1)).subscribe(() => this.autosize.resizeToFitContent(true));
    }

   getChanges(result, origin){
        let changes = [];
        var originalTask = this.taskStatuses.find(t => t.statusId == origin['taskStatus']);
        var originalType = this.taskTypes.find(t => t.typeId == origin['typeId']);
        var originalPriority = this.priorities.find(p => p.id == origin['priority']);
        var project = this.taskDataSource.filteredData.find(p => p['projectId'] == origin['projectId']);
        var updatedTask = this.taskStatuses.find(t => t.statusId == result['taskStatus']);
        var updatedType = this.taskTypes.find(t => t.typeId == result['typeId']);
        var updatedPriority = this.priorities.find(p => p.id == result['priority']);

        if(origin['plannedStartDate'] == null) var plannedStartDate = 'Empty'; else plannedStartDate = new Date(origin['plannedStartDate']).toDateString();
        if(origin['plannedEndDate'] == null) var plannedEndDate  = 'Empty'; else plannedEndDate = new Date(origin['plannedEndDate']).toDateString();
        if(origin['actualStartDate'] == null) var actualStartDate = 'Empty'; else actualStartDate = new Date(origin['actualStartDate']).toDateString();
        if(origin['actualEndDate'] == null) var actualEndDate = 'Empty'; else actualEndDate = new Date(origin['actualEndDate']).toDateString();
        if(origin['commitDate'] == null) var commitDate = 'Empty'; else commitDate = new Date(origin['commitDate']).toDateString();
        if(result['plannedStartDate'] == null) var updatedPlannedStartDate = 'Empty'; else updatedPlannedStartDate = new Date(result['plannedStartDate']).toDateString();
        if(result['plannedEndDate'] == null) var updatedPlannedEndDate = 'Empty'; else updatedPlannedEndDate = new Date(result['plannedEndDate']).toDateString();
        if(result['actualStartDate'] == null) var updatedActualStartDate = 'Empty'; else updatedActualStartDate = new Date(result['actualStartDate']).toDateString();
        if(result['actualEndDate'] == null) var updatedActualEndDate = 'Empty'; else updatedActualEndDate = new Date(result['actualEndDate']).toDateString();
        if(result['commitDate'] == null) var updatedCommitDate = 'Empty'; else updatedCommitDate = new Date(result['commitDate']).toDateString();

        if(origin['name'] != result['name']) changes.push("<strong>Original name: </strong>"  + origin['name'] + '   <strong>Updated name: </strong>' + result['name']);
        if(origin['description'] != result['description']) changes.push('<strong>Updated description: </strong>' + result['description']);
        if(origin['taskStatus'] != result['taskStatus']) changes.push("<strong>Original task status: </strong>" + originalTask.statusName + '   <strong>Updated task status: </strong>' + updatedTask.statusName);   
        if(origin['priority'] != result['priority']) changes.push("<strong>Original priority: </strong>" + originalPriority.name + '   <strong>Updated priority: </strong>' + updatedPriority.name);
        if(origin['typeId'] != result['typeId']){ if(originalType == null){changes.push("<strong>Original type: </strong>" + "No type" + '   <strong>Updated type: </strong>' + updatedType.typeName);} else{changes.push("<strong>Original type: </strong>" + originalType.typeName + '   <strong>Updated type: </strong>' + updatedType.typeName);}}
        if(origin['plannedStartDate'] != result['plannedStartDate']) changes.push("<strong>Original planned start date: </strong>" + plannedStartDate + '   <strong>Updated planned start date: </strong>' + updatedPlannedStartDate);
        if(origin['plannedEndDate'] != result['plannedEndDate']) changes.push("<strong>Original planned end date: </strong>" + plannedEndDate + '   <strong>Updated planned end date: </strong>' + updatedPlannedEndDate);
        if(origin['actualStartDate'] != result['actualStartDate']) changes.push("<strong>Original actual start date: </strong>" + actualStartDate + '   <strong>Updated actual start date: </strong>' + updatedActualStartDate);
        if(origin['actualEndDate'] != result['actualEndDate']) changes.push("<strong>Original actual end date: </strong>" + actualEndDate + '   <strong>Updated actual end date: </strong>' + updatedActualEndDate);
        if(origin['commitDate'] != result['commitDate']) changes.push("<strong>Original commit date: </strong>" + commitDate + '   <strong>Updated commit date: </strong>' + updatedCommitDate);
        if(!(this.arraysEqual(origin['labels'], result['labels']))) { origin['labels'].forEach((element, index) => { changes.push("Original Label "+[index+1] + ": " +  element.name )}); if(result['labels'].length == 0) {changes.push("Labels Removed");}  else {result['labels'].forEach((element, index) => {changes.push("Updated Label "+[index+1] + ": " +  element.name ) });}}
        if(!(this.arraysEqual(origin['milestones'], result['milestones']))) { origin['milestones'].forEach((element, index) => { changes.push("Original Milestone "+[index+1] + ": " +  element.name ) }); if(result['milestones'].length == 0) {changes.push("Milestones Removed");}  else {result['milestones'].forEach((element, index) => {changes.push("Updated Milestone "+[index+1] + ": " +  element.name ) });}}
        if(origin['assignedUser'] != result['assignedUser']) changes.push("Assigned User has been changed");
        if(origin['reporter'] != result['reporter']) changes.push("Reported User has been changed");
        if(origin['sprintId'] != result['sprintId']) changes.push("Sprint has been changed");

        result['projectName'] = "project : " + project['projectName'];
        return changes;
    }

    arraysEqual(a, b) {
        if (a == b) return true;
        if (a == null || b == null) return false;
        if (a.length != b.length) return false;
      
        for (var i = 0; i < a.length; ++i) {
          if (a[i].id != b[i].id){
              return false;
          }
        }
        return true;
      }
    
}


