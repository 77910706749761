import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { MatSnackBar } from '@angular/material';
import { FormControl } from '@angular/forms';
import { ExpensesService } from '../../api/expenses.service';

@Component({
	selector: 'app-expense-move-dialog',
	templateUrl: './expense-move-dialog.component.html',
	styleUrls: ['./expense-move-dialog.component.scss']
})
export class ExpensesMoveDialogComponent implements OnInit {

	expenseData: any[] = Object.keys(this.data.allExpenseReports).map(i => this.data.allExpenseReports[i]);
	reportId: FormControl = new FormControl();


	constructor(public dialogRef: MatDialogRef<ExpensesMoveDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data,
		public snackBar: MatSnackBar,
		private _expenseService: ExpensesService) { }

	ngOnInit() {


				this.expenseData = this.expenseData.filter(d => d.isApproved != true && d.id != this.data.expenseDetailData.reportId);
				this.expenseData.forEach(row => {
					this.data.clients.forEach(client => {// Add client name to data for the client id

						if (row.reportPolicy.policyClients[0] != undefined) {
							if (row.reportPolicy.policyClients[0].clientId == client['clientId']) {
								row.client = client['clientName'];
							}
						}
					});

					this.data.projects.forEach(project => {// Add project name to data for hte project id
						if (row.projectId == project['id']) {
							row.project = project['name'];
						}
					});



				});
				//Sorts the expense reports in the following order: periodEnd asc, client asc, project asc, and totalAmount, asc
				this.expenseData.sort(function (ex1, ex2) {

					if (new Date(ex1.periodEnd).toLocaleDateString() > new Date(ex2.periodEnd).toLocaleDateString()) return 1;
					if (new Date(ex1.periodEnd).toLocaleDateString() < new Date(ex2.periodEnd).toLocaleDateString()) return -1;

					if (ex1.client > ex2.client) return 1;
					if (ex1.client < ex2.client) return -1;

					if (ex1.project > ex2.project) return 1;
					if (ex1.project < ex2.project) return -1;

					if (ex1.totalAmount > ex2.totalAmount) return -1;
					if (ex1.totalAmount < ex2.totalAmount) return 1;
				});




	}

	closeDialog() {
		this.dialogRef.close();
	}

	saveAndCloseDialog() {
		let oldId = this.data.expenseDetailData.expenseReportId;
		this.data.expenseDetailData.reportId = this.reportId.value;
		this.dialogRef.close({pastId: oldId, data: this.data.expenseDetailData});
	}
}
