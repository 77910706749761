import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { SimpleChanges } from '@angular/core/src/metadata/lifecycle_hooks';
import { MatPaginator, MatTableDataSource, MatDialog, MatSnackBar, MatSort } from '@angular/material';
import { ProjectsTaskDialogComponent } from 'src/app/projects/projects-task-dialog/projects-task-dialog.component';
import { ProjectsService } from '../../../api/projects.service';

import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Console } from 'console';



var async = require('async');
@Component({
    selector: 'app-task-group',
    templateUrl: './task-group.component.html',
    styleUrls: ['./task-group.component.scss']
})
export class TaskGroupComponent implements OnInit, OnChanges {
	clients;

    @Input() data_row: object[][] = [];
    @Input() data_status_order: object[] = [];
    @Input() extraFields: object;
    @Input() data_label: string;
    @Input() filterComponents: object[] = [];
    @Output() updatedTasksFilter = new EventEmitter<any>();
    @Output() refreshBoardDelete = new EventEmitter<any>();
    @Output() labelMilestoneUpdate = new EventEmitter<any>();

    filteredDataRow: object[][] = [];
    disableClickOnLoad: boolean = false;
    connectedToLists: string[] = [];
    priorities = [{name: "Lowest", id:1},{name: "Low", id:2},
        {name: "Medium", id:3},{name: "High", id:4},{name: "Highest", id:5}]

    @Input() collapseFlag = false;
	todaysDate: string;
	thirtyDaysBefore: string;

    constructor(private _projectsService: ProjectsService, public snackBar: MatSnackBar,
        public dialog: MatDialog, private route: Router) { }

    ngOnInit() {
        //This is not Array.from because it needs to share updates
        //until it can be filled with data
        this.filteredDataRow = this.data_row;

        for (let i = 0; i < this.data_status_order.length; i++) this.connectedToLists.push('cdk-drop-list-' + i);

		this.todaysDate = this.formatDate(null);
    	this.thirtyDaysBefore = this.formatDate(new Date().setDate(new Date(this.todaysDate).getDate() -30));
        //PS-270 filters applied on inital load of board are not being applied due to refresh filter method not being called.
        //This line seem to have solved the issue 02/23/22
        this.refreshFilter();
    }

    ngOnChanges(changes: SimpleChanges) {
        this.refreshFilter();
    }

	formatDate(date){
		if(date == null) return new Date().toISOString().slice(0, 19).replace('T', ' ');
		else return new Date(date).toISOString().slice(0, 19).replace('T', ' ');
	}

    drop(event: CdkDragDrop<string[]>) {
		if (event.previousContainer !== event.container){
            let oldStatus = parseInt(event.previousContainer.id.replace("cdk-drop-list-", "")) % this.data_status_order.length;
			let statusColumnId = parseInt(event.container.id.replace("cdk-drop-list-", "")) % this.data_status_order.length; 
			event.item.data['status'] = this.data_status_order[statusColumnId]['statusId'];
			event.item.data['taskStatus'] = this.data_status_order[statusColumnId]['statusId'];
            event.item.data['oldTask'] = this.data_status_order[oldStatus]['statusName'];
            event.item.data['newTask'] = this.data_status_order[statusColumnId]['statusName'];
			this.updateTask(event.item.data, true);
		}
    }

    refreshFilter() {
        //This if statement is here to prevent the inital ngOnChanges that happens
        //at the start of this process from decoupling the data_row and the filteredDataRow
        if (this.filterComponents.length != 0) {
            //This filteredDataRow setting is to separate it from data_row before filtering starts
            this.filteredDataRow = Array.from(this.data_row);

            //This is to pull out the Assigned User name to add to the Kanban cards
            this.filteredDataRow.forEach(column => {
                column.forEach(task => {
                    let assignedUser = this.extraFields["users"].find(user => user["userId"] == task["assignedUser"]);
                    task["assignedUserName"] = assignedUser !== undefined ? assignedUser["fullName"] : "Unassigned";

                    let assignedProject = this.extraFields["projects"].find(project => project["id"] == task["projectId"]); 
                    task["projectName"] = assignedProject !== undefined ? assignedProject["name"] : "";

                    let cardTaskType = task["typeId"];
                    if (cardTaskType == "1") {
                        task["isTask"] = true;
                    }
                    else if (cardTaskType == "2") {
                        task["isBug"] = true;
                    }
                    else if (cardTaskType == "3") {
                        task["isEnhancement"] = true;
                    }


                    let cardPriority = task["priority"];
                    if (cardPriority == "5") {
                        task["isHighest"] = true;
                    }
                    else if (cardPriority == "4") {
                        task["isHigh"] = true;
                    }
                    else if (cardPriority == "3") {
                        task["isMedium"] = true;
                    }
                    else if (cardPriority == "2") {
                        task["isLow"] = true;
                    }
                    else if (cardPriority == "1") {
                        task["isLowest"] = true;
                    }

                });
            });

            let projectFilter = Object.keys(this.filterComponents[0]).map(i => this.filterComponents[0][i]);
            let userFilter = Object.keys(this.filterComponents[1]).map(i => this.filterComponents[1][i]);
            let typeFilter = Object.keys(this.filterComponents[2]).map(i => this.filterComponents[2][i]);
            let searchFilter = this.filterComponents[3].toString().toLowerCase();
			let statusFilter = Object.keys(this.filterComponents[4]).map(i => this.filterComponents[4][i]);
			let labelFilter = Object.keys(this.filterComponents[5]).map(i => this.filterComponents[5][i]);
			let milestoneFilter = Object.keys(this.filterComponents[6]).map(i => this.filterComponents[6][i]);
            let sprintFilter = Object.keys(this.filterComponents[7]).map(i => this.filterComponents[7][i]);

            for (var i = 0; i < this.filteredDataRow.length; i++) {
                this.filteredDataRow[i] = this.filteredDataRow[i].filter(function (data) {
                    return (projectFilter.length == 0 || projectFilter.indexOf(data['projectId']) !== -1) &&
                        (userFilter.length == 0 || userFilter.indexOf(data['assignedUser']) !== -1) &&
                        (typeFilter.length == 0 || typeFilter.indexOf(data['typeId']) !== -1) &&
                        (searchFilter == ""|| data["name"].toLowerCase().includes(searchFilter)
							|| data["assignedUserName"].toLowerCase().includes(searchFilter)
							|| data["id"].toString().includes(searchFilter)) &&
						(statusFilter.length == 0 || statusFilter.indexOf(data['taskStatus']) !== -1) &&
						(labelFilter.length == 0 || data['labels'].find(label => labelFilter.indexOf(label.id)  !== -1) != undefined) &&
						(milestoneFilter.length == 0 || data['milestones'].find(milestone => milestoneFilter.indexOf(milestone.id)  !== -1) != undefined) &&
						(sprintFilter.length == 0 || sprintFilter.indexOf(data['sprintId']) !== -1);

                });
            }
            this.updatedTasksFilter.emit(this.filteredDataRow);
        }
    }

    toggleCollapse() {
        this.collapseFlag = !this.collapseFlag;
    }

    loadDialog(entryId) {
        let changes = [];
        if (this.disableClickOnLoad != true) {
            this.disableClickOnLoad = true;
            let sendData = {};
            sendData['newData'] = true;
            sendData['taskTypes'] = this.extraFields['taskTypes'];
            sendData['taskStatuses'] = this.extraFields['taskStatuses'];
            sendData['users'] = this.extraFields['users'];
			sendData['clients'] = this.clients;
            sendData['taskTypes'] = this.extraFields['taskTypes'];
            sendData['taskStatuses'] = this.extraFields['taskStatuses'];
            sendData['users'] = this.extraFields['users'];
            sendData['Sprints'] = this.extraFields['sprints'];

            this._projectsService.getProjectTaskById(entryId).subscribe(
                
                data => {
                    sendData['entry'] = data;
                    const origin = JSON.parse(JSON.stringify(sendData['entry'])); //deep copies the object 
                    
                    const dialogRef = this.dialog.open(ProjectsTaskDialogComponent, {
                        width: '800px',
                        height: 'auto',
                        data: sendData
                    });

                    dialogRef.afterClosed().subscribe(result => {
                        this.disableClickOnLoad = false;
                        if (result !== undefined) {
                            if (result['submit']) {
                                result['data'].assignedUserId = result['data'].assignedUser;
                               changes = this.getChanges(result, origin, sendData);
                               
                               result['data'].changes = changes;
                                this.updateTask(result['data']);
                            }
                            if (result['delete']) {
                                this.refreshBoardDelete.emit();
                            }
                        }
                    });
                },
                err => {
                    this.snackBar.open("Error Retrieving Task", "", { duration: 2000 });
                }
            );
        }
    }

     arraysEqual(a, b) {
        if (a == b) return true;
        if (a == null || b == null) return false;
        if (a.length != b.length) return false;
      
        for (var i = 0; i < a.length; ++i) {
          if (a[i].id != b[i].id){
              return false;
          }
        }
        return true;
      }


    getChanges(result, origin, sendData){
        let changes = [];
        var originalTask = sendData['taskStatuses'].find(t => t.statusId == origin['taskStatus']);
        var originalType = sendData['taskTypes'].find(t => t.typeId == origin['typeId']);
        var originalPriority = this.priorities.find(p => p.id == origin['priority']);
        var project = this.extraFields["projects"].find(project => project["id"] == origin["projectId"]);
        var updatedTask = sendData['taskStatuses'].find(t => t.statusId == result['data']['taskStatus']);
        var updatedType = sendData['taskTypes'].find(t => t.typeId == result['data']['typeId']);
        var updatedPriority = this.priorities.find(p => p.id == result['data']['priority']);
        result['data'].projectName = ("project: " + project['name']);
    

        if(origin['plannedStartDate'] == null) var plannedStartDate = 'Empty'; else plannedStartDate = new Date(origin['plannedStartDate']).toDateString();
        if(origin['plannedEndDate'] == null) var plannedEndDate  = 'Empty'; else plannedEndDate = new Date(origin['plannedEndDate']).toDateString();
        if(origin['actualStartDate'] == null) var actualStartDate = 'Empty'; else actualStartDate = new Date(origin['actualStartDate']).toDateString();
        if(origin['actualEndDate'] == null) var actualEndDate = 'Empty'; else actualEndDate = new Date(origin['actualEndDate']).toDateString();
        if(origin['commitDate'] == null) var commitDate = 'Empty'; else commitDate = new Date(origin['commitDate']).toDateString();
        if(result['data']['plannedStartDate'] == null) var updatedPlannedStartDate = 'Empty'; else updatedPlannedStartDate = new Date(result['data']['plannedStartDate']).toDateString();
        if(result['data']['plannedEndDate'] == null) var updatedPlannedEndDate = 'Empty'; else updatedPlannedEndDate = new Date(result['data']['plannedEndDate']).toDateString();
        if(result['data']['actualStartDate'] == null) var updatedActualStartDate = 'Empty'; else updatedActualStartDate = new Date(result['data']['actualStartDate']).toDateString();
        if(result['data']['actualEndDate'] == null) var updatedActualEndDate = 'Empty'; else updatedActualEndDate = new Date(result['data']['actualEndDate']).toDateString();
        if(result['data']['commitDate'] == null) var updatedCommitDate = 'Empty'; else updatedCommitDate = new Date(result['data']['commitDate']).toDateString();

   

        if(origin['name'] != result['data']['name']) changes.push("<strong>Original name: </strong>"  + origin['name'] + '   <strong>Updated name: </strong>' + result['data']['name']);
        if(origin['description'] != result['data']['description']) changes.push('<strong>Updated description: </strong>' + result['data']['description']);
        if(origin['taskStatus'] != result['data']['taskStatus']) changes.push("<strong>Original task status: </strong>" + originalTask.statusName + '   <strong>Updated task status: </strong>' + updatedTask.statusName);   
        if(origin['priority'] != result['data']['priority']) changes.push("<strong>Original priority: </strong>" + originalPriority.name + '   <strong>Updated priority: </strong>' + updatedPriority.name);
        if(origin['typeId'] != result['data']['typeId']){ if(originalType == null){changes.push("<strong>Original type: </strong>" + "No type" + '   <strong>Updated type: </strong>' + updatedType.typeName);} else{changes.push("<strong>Original type: </strong>" + originalType.typeName + '   <strong>Updated type: </strong>' + updatedType.typeName);}}
        if(origin['plannedStartDate'] != result['data']['plannedStartDate']) changes.push("<strong>Original planned start date: </strong>" + plannedStartDate + '   <strong>Updated planned start date: </strong>' + updatedPlannedStartDate);
        if(origin['plannedEndDate'] != result['data']['plannedEndDate']) changes.push("<strong>Original planned end date: </strong>" + plannedEndDate + '   <strong>Updated planned end date: </strong>' + updatedPlannedEndDate);
        if(origin['actualStartDate'] != result['data']['actualStartDate']) changes.push("<strong>Original actual start date: </strong>" + actualStartDate + '   <strong>Updated actual start date: </strong>' + updatedActualStartDate);
        if(origin['actualEndDate'] != result['data']['actualEndDate']) changes.push("<strong>Original actual end date: </strong>" + actualEndDate + '   <strong>Updated actual end date: </strong>' + updatedActualEndDate);
        if(origin['commitDate'] != result['data']['commitDate']) changes.push("<strong>Original commit date: </strong>" + commitDate + '   <strong>Updated commit date: </strong>' + updatedCommitDate);
        if(!(this.arraysEqual(origin['labels'], result['data']['labels']))) { origin['labels'].forEach((element, index) => { changes.push("Original Label "+[index+1] + ": " +  element.name )}); if(result['data']['labels'].length == 0) {changes.push("Labels Removed");}  else {result['data']['labels'].forEach((element, index) => {changes.push("Updated Label "+[index+1] + ": " +  element.name ) });}}
        if(!(this.arraysEqual(origin['milestones'], result['data']['milestones']))) { origin['milestones'].forEach((element, index) => { changes.push("Original Milestone "+[index+1] + ": " +  element.name ) }); if(result['data']['milestones'].length == 0) {changes.push("Milestones Removed");}  else {result['data']['milestones'].forEach((element, index) => {changes.push("Updated Milestone "+[index+1] + ": " +  element.name ) });}}
        if(origin['assignedUser'] != result['data']['assignedUser']) changes.push("Assigned User has been changed");
        if(origin['reporter'] != result['data']['reporter']) changes.push("Reported User has been changed");
        if(origin['sprintId'] != result['data']['sprintId']) changes.push("Sprint has been changed");

        return changes;
    }

    updateTask(entry, updateStatusOnly = false) {
        this._projectsService.putProjectTask(entry, updateStatusOnly).subscribe(
            data => {
                var newStatus = false;
                for (var i = 0; i < this.data_row.length; i++) {
                    for (var j = 0; j < this.data_row[i].length; j++) {
                        if (this.data_row[i][j]['id'] == entry.id) {
                            if (entry.plannedStartDate instanceof Date) entry.plannedStartDate = entry.plannedStartDate.toISOString();
							if (entry.plannedEndDate instanceof Date) entry.plannedEndDate = entry.plannedEndDate.toISOString();
                            if (entry.commitDate instanceof Date) entry.commitDate = entry.commitDate.toISOString();
                            entry.projectName = this.data_row[i][j]['projectName'];

                            if (updateStatusOnly) {
                                this.data_row[i] = this.data_row[i].filter(x => x['id'] != entry.id);
                                newStatus = true;
                                break;
                            }
                            else if (this.data_row[i][j]['taskStatus'] == entry.taskStatus) {
                                this.data_row[i][j] = entry;
                                break;
                            }
                            else {
                                this.data_row[i] = this.data_row[i].filter(x => x['id'] != entry.id);
                                newStatus = true;
                                break;
                            }
                        }
                    }
                }
                //If a status has been changed, moves entry
                if (newStatus) {
					async.forEachOf(this.data_row,(data,i,inner_callback)=>{
						if (this.data_row.length > 0 && this.data_status_order[i]['statusId'] == entry.taskStatus) {
							// if task is resolved and finished for thirty days, remove it
							if(this.data_status_order[i]['isResolved'] == true && this.formatDate(entry.actualEndDate) < this.thirtyDaysBefore) return inner_callback(null);
                            this.data_row[i].push(entry);
                            inner_callback(null);
                        }
					})
                }
                //Refreshes the length of the statuses
				async.forEachOf(this.data_status_order,(data,i,inner_callback)=>{
					this.data_status_order[i]['taskCount'] = this.data_row[i].length;
					inner_callback(null);
				});

                //creating temp objects to be accessed for sorting.
                let tempdata = Object.keys(this.data_row).map(i => this.data_row[i]);
                let tempStatus = Object.keys(this.data_status_order).map(i => this.data_status_order[i]);

                //removes extra slot on tempStatus array, that arises in editing a new task
                if (tempStatus.length > tempdata.length) {
                    tempStatus.pop();
                }

                //creating promise to ensure tasks are sorted before starting the refresh filter method
                var taskSortPromise = new Promise((resolve,reject)=>{
                    try{
                        //for loop to sort tasks in respected data_row status column
                        for(let i = 0; i < tempStatus.length; i++) {
                            var temp = tempdata[i].filter(x => x.taskStatus == tempStatus[i].statusId);
                            //sorting by priority descending, then commit date asc, finally by due date asc
                            temp.sort((a, b)=> {
                                if (a.priority === b.priority){
                                    if (a.commitDate === b.commitDate){
                                        return a.plannedEndDate < b.plannedEndDate ? -1 : 1
                                    } else {
                                        return a.commitDate < b.commitDate ? -1 : 1
                                    }
                                } else {
                                return b.priority < a.priority ? -1 : 1
                                }
                            });
                            
                            //assigning the data row status column to new sorted tasks.
                            this.data_row[i] = temp
                        }
                        resolve('done')
                    }catch(ex){
                        reject(ex);
                    }
                });

                //ensure promise completes before sending data back to kanban component
                Promise.all([taskSortPromise]).then(()=>{
                    //refresh filter set afterwards to make sure new data_row is sent back to the kanban board.
                    this.refreshFilter();
                    //if task is updated let kanban board know to update label filters.
                    this.labelMilestoneUpdate.emit();
                    this.snackBar.open("Work Item Updated Successfully", "", { duration: 4000 });
                });
            },
            err => {
                this.snackBar.open("Error Updating Work Item", "", { duration: 4000 });
            }
        );
    }
}
