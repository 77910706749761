import { Component, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { SetttingsService } from '../../api/setttings.service';
import { MatTableDataSource, MatPaginator, MatSnackBar, MatDialog, MatButtonToggleGroup } from '@angular/material';
import { ManagementClassDialogComponent } from './management-class-dialog/management-class-dialog.component';
import { ManagementServiceDialogComponent } from './management-service-dialog/management-service-dialog.component';

@Component({
    selector: 'app-management-classes-services',
    templateUrl: './management-classes-services.component.html',
    styleUrls: ['./management-classes-services.component.scss']
})
export class ManagementClassesServicesComponent implements OnInit {

    classDialogComponent = ManagementClassDialogComponent;

    displayedColumns = ['name', 'billableState', 'activeState', 'options'];
    dataArray = [];
    dataSource = new MatTableDataSource();
    dataSourceLoading: boolean;

    constructor(private _settingsService: SetttingsService,
        public dialog: MatDialog,
        public snackBar: MatSnackBar) { }

    @ViewChild(MatPaginator) paginator;
    @ViewChildren(MatButtonToggleGroup) servicesButtonToggleGroups;

    ngOnInit() {
        this.getServiceData();
    }

    updateServiceFilter(toggleActiveGroup: string, toggleBillableGroup: string, searchValue: string) {
        let dataContainer = [];
        let booleanActiveMaker = { active: true, inactive: false };
        let booleanBillableMaker = { billable: true, nonBillable: false };
        this.dataArray.forEach(entry => {
            let searchBoolean = entry['name'].toLowerCase().includes(searchValue) || searchValue == '';
            let toggleBillableBoolean = entry['isBillable'] == booleanBillableMaker[toggleBillableGroup] || toggleBillableGroup == 'all';
            let toggleActiveBoolean = entry['isActive'] == booleanActiveMaker[toggleActiveGroup] || toggleActiveGroup == 'all';
            if (searchBoolean && toggleActiveBoolean && toggleBillableBoolean) {
                dataContainer.push({
                    name: entry['name'],
                    billableState: entry['isBillable'],
                    activeState: entry['isActive'],
                    id: entry['id']
                });
            }
        });
        this.dataSource = new MatTableDataSource(dataContainer);
        this.dataSource.paginator = this.paginator;
    }


    findEntry(entryId) {
        return this.dataArray.find(entry => entry['id'] == entryId);
    }

    loadDialog(entryId) {
        let data = { newData: true };
        if (entryId != 'newData') {
            data = this.findEntry(entryId);
        }
        const dialogRef = this.dialog.open(ManagementServiceDialogComponent, {
            width: '585px',
            height: 'auto',
            data: data
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result !== undefined) {
                if (result['submit']) {
                    this.updateServiceData(result['data']);
                }
            }
        });
    }

    stateChange(entryId, state, type) {
        let returnObject = this.findEntry(entryId);
        if (type == 'billable') {
            returnObject['isBillable'] = state;
        } else {
            returnObject['isActive'] = state;
        }
        this.updateServiceData(returnObject);
    }

    processData(data) {
        let dataContainer = [];
        data.forEach(entry => {
            dataContainer.push({
                name: entry['name'],
                billableState: entry['isBillable'],
                activeState: entry['isActive'],
                id: entry['id']
            });
        });
        this.dataSource = new MatTableDataSource(dataContainer);
        this.dataSource.paginator = this.paginator;
        this.updateServiceFilter(this.servicesButtonToggleGroups.toArray()[1].value, this.servicesButtonToggleGroups.toArray()[0].value, '');
    }

    getServiceData() {
        this.dataSource.data = [];
        this.dataSourceLoading = true;
        this._settingsService.getServices().subscribe(
            data => {
                this.dataArray = [];
                this.dataArray.push.apply(this.dataArray, data);
                this.dataSourceLoading = false;
                this.processData(data);

            }, err => {
                this.dataSource = new MatTableDataSource(this.dataArray);
                this.dataSource.paginator = this.paginator;
                this.snackBar.open('Error Loading Services', '', { duration: 2000 });
                console.error(err);
                this.dataSourceLoading = false;
            }
        );
    }

    updateServiceData(entry) {
        this._settingsService.putService(entry).subscribe(
            data => {
                let temp = {};
                temp['activeState'] = entry['isActive'];
                temp['billableState'] = entry['isBillable'];
                temp['id'] = entry['id'];
                temp['name'] = entry['name'];

                let present = false;
                for(let i = 0; i < this.dataSource.data.length; i++){
                    if(this.dataSource.data[i]['id'] === entry.id){
                        present = true;
                        this.dataSource.data[i] = temp;
                        this.dataSource._updateChangeSubscription();
                    }
                }

                if(!present){
                    this.getServiceData();
                }


                this.snackBar.open('Updated', '', { duration: 2000 });
            }, err => {
                this.getServiceData();
                this.snackBar.open(err.statusText, '', { duration: 2000 });
            }
        );
    }

    getClassFunction() {
        return this._settingsService.getClasses();
    }

    putClassFunction() {
        return (classModel) => this._settingsService.putClasses(classModel);
    }

}
