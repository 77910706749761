import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { User } from "../../models/user";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { GlobalService } from "../../api/globalVars";
import { Password } from "src/app/models/password";

@Injectable({
	providedIn: "root",
})
export class Auth0ManagementApiService {
	private apiPath: string;

	constructor(
		private globalService: GlobalService,
		public httpClient: HttpClient
	) {
		this.apiPath = this.globalService.retrievePath();
	}

	/**
	 * Adds new user to the back end\
	 * @param tenantName A string to represent where the new user is being created
	 * @param userDetails User object with user to be added
	 * @param verifyEmail Boolean value to automatically verify user email when created (i.e. does not send verification email to user's email)\
 	*/
	createNewUser(tenantName:string, userDetails:User, verifyEmail:boolean = false) {
		//TODO: PROS-889 needs to pass object.
		let user: User = userDetails;
		userDetails['verifyEmail'] = verifyEmail;
		let requestUrl = `${this.apiPath}auth0/managementapi/createuser/${tenantName}`;
		let requestOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};

		return this.httpClient
		.post(
			requestUrl,
			JSON.stringify(userDetails),
			requestOptions
		)
		.pipe(
			map((response) => {
				user = {
					userId: response["userId"],
					email: response["email"],
					fullName: response["fullName"],
					firstName: response["firstName"],
					middleInitial: response["middleInitial"],
					lastName: response["lastName"],
					title: response["title"],
					employeePositionId: response["employeePositionId"],
					isActive: response["isActive"],
					isContractor: response["isContractor"],
					defaultRate: response["defaultRate"],
				};

				return user;
			})
		);
	}

	/**
	 * Gets an array of users associated with a given tenant.
	 * @param tenantName A string representing the name of the tenant to get
	 * users from.
	 * @returns An observable array of User objects representing the users
	 * associated with the provided tenant.
	 */
	getUsers(tenantName: string): Observable<User[]> {
		let requestUrl = `${this.apiPath}auth0/managementapi/getusers/${tenantName}`;
		let requestOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};

		return this.httpClient.get(requestUrl, requestOptions).pipe(
			map((response) => {
				// Declare
				let responseArray: object[] = response as object[];

				// Declare an array of users associated with the given tenant
				let users: User[] = [];

				/**
				 * Iterate over the objects returned in the response to parse these to
				 * an array of User objects.
				 */
				responseArray.forEach((userObj) => {
					let user: User = {
						userId: userObj["userId"],
						email: userObj["email"],
						fullName: userObj["fullName"],
						firstName: userObj["firstName"],
						middleInitial: userObj["middleInitial"],
						lastName: userObj["lastName"],
						title: userObj["title"],
						employeePositionId: userObj["employeePositionId"],
						isActive: userObj["isActive"],
						isContractor: userObj["isContractor"],
						defaultRate: userObj["defaultRate"],
						hireDate: response["hireDate"],
						terminationDate: response["terminationDate"],
					};

					users.push(user);
				});

				return users;
			})
		);
	}

	/**
	 * Gets details about a specific user by their user ID from Auth0.
	 * @param userId A string representing the user ID for a user to get user
	 * details for.
	 * @returns A User object representing details about the current user
	 * (e.g., user ID, name, title, etc.).
	 */
	getUserDetails(userId: string): Observable<User> {
		// Declare and initialize a null User object
		let user: User = null;

		// Declare and initialize request parameters
		let requestUrl = `${this.apiPath}auth0/managementapi/getuserdetails/${userId}`;
		let requestOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};

		/**
		 * Request user details from the back-end (which in turn requests these
		 * details from Auth0) and return an observable UserDetails object.
		 */
		return this.httpClient.get(requestUrl, requestOptions).pipe(
			map((response) => {
				user = {
					userId: response["userId"],
					email: response["email"],
					fullName: response["fullName"],
					firstName: response["firstName"],
					middleInitial: response["middleInitial"],
					lastName: response["lastName"],
					title: response["title"],
					employeePositionId: response["employeePositionId"],
					isActive: response["isActive"],
					isContractor: response["isContractor"],
					defaultRate: response["defaultRate"],
					hireDate: response["hireDate"],
					terminationDate: response["terminationDate"],
				};

				return user;
			})
		);
	}

	updateUserDetails(
		userId: string,
		updatedUserDetails: User
	): Observable<User> {
		// Declare and initialize a null UserDetails object
		let user: User = null;

		// Declare and initialiaze an object representing request options
		let requestOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};

		/**
		 * Send updated user details to the back-end (which in turn sends the
		 * updated user details to Auth0) and return an observable UserDetails
		 * object.
		 */
		return this.httpClient
			.post(
				`${this.apiPath}auth0/managementapi/updateuserdetails/${userId}`,
				JSON.stringify(updatedUserDetails),
				requestOptions
			)
			.pipe(
				map((response) => {
					user = {
						userId: response["userId"],
						email: response["email"],
						fullName: response["fullName"],
						firstName: response["firstName"],
						middleInitial: response["middleInitial"],
						lastName: response["lastName"],
						title: response["title"],
						employeePositionId: response["employeePositionId"],
						isActive: response["isActive"],
						isContractor: response["isContractor"],
						defaultRate: response["defaultRate"],
						hireDate: response["hireDate"],
						terminationDate: response["terminationDate"],
					};

					return user;
				})
			);
	}

	updateUserPassword(
		userId: string,
		updatedUserPassword: Password
	): Observable<Password> {
		let password: Password = null;

		let requestOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};

		return this.httpClient
			.post(
				`${this.apiPath}auth0/managementapi/updateuserpassword/${userId}`,
				JSON.stringify(updatedUserPassword),
				requestOptions
			)
			.pipe(
				map((response) => {
					password = {
						password: response["password"],
					};

					return password;
				})
			);
	}
}
