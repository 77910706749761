import { Component, OnInit, ViewChild, ViewChildren, ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort, MatDialog, MatSnackBar } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { SetttingsService } from '../../api/setttings.service';
import { ExpensesService } from '../../api/expenses.service';
import { TimesheetService } from '../../api/timesheet.service';
import { ExpensesDetailDialogComponent } from '../expenses-detail-dialog/expenses-detail-dialog.component';
import * as moment from 'moment';
import { AuthService } from '../../services/auth/auth.service';
import { QuickbooksService } from '../../api/quickbooks.service';

@Component({
	selector: 'app-expenses-approval',
	templateUrl: './expenses-approval.component.html',
	styleUrls: ['./expenses-approval.component.scss']
})
export class ExpensesApprovalComponent implements OnInit, AfterContentChecked {
	displayedColumns = ['select', 'date', 'employee', 'project', 'expenses', 'view', 'approve', 'deny', 'policy'];
	detailDisplayColums = ['date', 'name', 'category', 'reimbursable', 'billable', 'amount', 'edit', 'policy'];
	selection = new SelectionModel(true, []);
	dataSource = new MatTableDataSource();
	expenseReportApprovalDetailsDataSource = new MatTableDataSource();
	expenseReportApprovalDataSources = {
		'all': new MatTableDataSource(),
		'pending': new MatTableDataSource(),
		'approved': new MatTableDataSource(),
		'denied': new MatTableDataSource()
	};
	clients: object[]; // array of client objects for the selectors
	classes: object[]; // array of class objects for the selectors
	services: object[]; // array of service objects for the selectors
	projects: object[] = []; // array of project objects for the selectors
	expenseCategories;
	approving: boolean = false;
	expenseDetailOpen: boolean = false;
	isExpenseReportApprovalDataLoading: boolean;
	isDeniedAndApprovedLoading: boolean;
	expenseReport: boolean = true;
	isManager: boolean = false;
	reportDetails;
	currentPolicy;
	viewDate: Date = moment().toDate(); // Current date selected for the calendar
	dateRange: string; // Formated range for the date range button
	expenseAge: number = 0;

	constructor(private _expenseService: ExpensesService,
		public dialog: MatDialog, private _settingsService: SetttingsService,
		private _timesheetService: TimesheetService,
		public snackBar: MatSnackBar, private authService: AuthService, private quickbooksService: QuickbooksService, private changeDetector: ChangeDetectorRef) { }


	@ViewChildren(MatPaginator) paginators;
	@ViewChildren(MatSort) sort;
	ngOnInit() {
		this.formatDateRange();
		this.authService.getUserPermissions().subscribe(userPermissions => this.isManager = userPermissions.includes('manage:expenses'));
		this.getClientClassServiceData();
		this.getExpenseReportsApprovalData();
		this.getExpenseCategories();

		this._expenseService.getExpensePolicy().subscribe(data => {
			this.currentPolicy = data['0'];
		});

	}

	ngAfterContentChecked(): void {
		this.changeDetector.detectChanges();
	  }

	/** Whether the number of selected elements matches the total number of rows. */
	isAllSelected(type) {
		const numSelected = this.selection.selected.length;
		const numRows = this.expenseReportApprovalDataSources[type].data.length;
		return numSelected === numRows;
	}

	/** Selects all rows if they are not all selected; otherwise clear selection. */
	masterToggle(type, event?) {
		if (type == 'none') {
			// This allows for all select toswitch properly with tabs

			// if ((this.isAllSelected('all') && event.value !== 'all') || (this.isAllSelected('pending') && event.value !== 'pending') || (this.isAllSelected('approved') && event.value !== 'approved')) {
			// 	this.selection.clear();
			// 	this.masterToggle(event.value);
			// } else {
			// 	this.selection.clear();
			// }

			this.selection.clear();
		} else {
			this.isAllSelected(type) ?
				this.selection.clear() :
				this.expenseReportApprovalDataSources[type].data.forEach(row => this.selection.select(row));
		}

	}

	loadExpenseDetailDialog(expenseDetailData) {
		this.expenseDetailOpen = true;
		this._expenseService.getExpenseStops(expenseDetailData.id).subscribe(stopsData => {
			expenseDetailData['isNotEditable'] = true;
			expenseDetailData['categories'] = this.expenseCategories;
			expenseDetailData['rate'] = this.currentPolicy['ratePerMile'];
			expenseDetailData['stops'] = stopsData;
			const dialogRef = this.dialog.open(ExpensesDetailDialogComponent, {
				width: '585px',
				height: 'auto',
				data: expenseDetailData

			});
			dialogRef.afterClosed().subscribe(result => {
				this.expenseDetailOpen = false;
			});
		});
	}

	processExpenseReportsApprovalData(data) {
		data.forEach(report => {
			let project = this.projects.find(p => p['id'] == report['projectId']);
			report['projectName'] = project != undefined ? project['name'] : 'No Projects Match';
		});
		return data;
	}

	getAge(date1, date2) {
		// To calculate the time difference of two dates
		var Difference_In_Time = date2.getTime() - date1.getTime();

		// To calculate the no. of days between two dates
		var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
		return Difference_In_Days
	}

	getExpenseReportsApprovalData() {
		// Mark expense report approval data as loading
		this.isExpenseReportApprovalDataLoading = true;
		this.isDeniedAndApprovedLoading = true;

        /**
         * Empties out current tables
         */
		this.expenseReportApprovalDataSources['pending'] = new MatTableDataSource([]);
		this.expenseReportApprovalDataSources['approved'] = new MatTableDataSource([]);
		this.expenseReportApprovalDataSources['denied'] = new MatTableDataSource([]);
		this.expenseReportApprovalDataSources['all'] = new MatTableDataSource([]);

		let pendingExpenseReports = [];
		//Pending expense reports are retreived and loaded in a seperate calls.
		this._expenseService.getExpenseReportApprovalData(true).subscribe(data => {

			// Declare and initialize a variable containing raw expense report data
			let expenseReports = this.processExpenseReportsApprovalData(Object.keys(data).map(i => data[i]));

			// Iterate over the expense reports and sort them into different arrays based on their status (pending, approved, denied)
			for (let expenseReport of expenseReports) {
				this.expenseAge = Math.abs(this.getAge(new Date(expenseReport['date']), new Date));
				if (expenseReport['totalAmount'] > expenseReport['reportPolicy']['maxAmount']
					|| this.expenseAge > expenseReport['reportPolicy']['maxAge']) {
					expenseReport['hasViolation'] = true;
				}
				else {
					expenseReport['hasViolation'] = false;
				}



				// If the expense report has a submission date, continue to sort the report
				if (expenseReport.submittedDate != null) {
					// If the expense report is pending approval, add it to the pending array
					if (expenseReport.approvalStatusId !== 2 && expenseReport.approvalStatusId !== 3) {
						pendingExpenseReports.push(expenseReport);
					}
				}
			}







			// Associate expense report data with data sources for the view's tables
			this.expenseReportApprovalDataSources['pending'] = new MatTableDataSource(pendingExpenseReports);
			this.expenseReportApprovalDataSources['pending'].paginator = this.paginators.toArray()[1];

			// Mark expense report approval data as no longer loading
			this.isExpenseReportApprovalDataLoading = false;

			this.loadApprovedAndDeniedReports(pendingExpenseReports);


		}, err => {
			this.snackBar.open('Error Loading Expense Reports', '', { duration: 2000 });
			this.loadApprovedAndDeniedReports(pendingExpenseReports);
			this.isExpenseReportApprovalDataLoading = false;
		});
	}

	loadApprovedAndDeniedReports(pendingExpenseReports) {
		let allExpenseReports = [];
		let approvedExpenseReports = [];
		let deniedExpenseReports = [];
		//The remaining expense reports are called and loaded
		this._expenseService.getExpenseReports(this.viewDate).subscribe(
			data => {
				// Declare and initialize a variable containing raw expense report data
				let expenseReports = this.processExpenseReportsApprovalData(Object.keys(data).map(i => data[i]));

				// Iterate over the expense reports and sort them into different arrays based on their status (pending, approved, denied)
				for (let expenseReport of expenseReports) {
					for(let expense of expenseReport){
						if(expenseReport['reportPolicy']['policySetting']['eReceipt'] == true && expense['hasReceipt'] == false){
							expenseReport['hasViolation'] = true;
						}
					}
					this.expenseAge = Math.abs(this.getAge(new Date(expenseReport['date']), new Date));
					if (expenseReport['totalAmount'] > expenseReport['reportPolicy']['maxAmount']
						|| this.expenseAge > expenseReport['reportPolicy']['maxAge']) {
						expenseReport['hasViolation'] = true;
					}
					else {
						expenseReport['hasViolation'] = false;
					}



					// If the expense report has a submission date, continue to sort the report
					if (expenseReport.submittedDate != null) {
						// If the expense report is pending approval, add it to the pending array
						if (expenseReport.approvalStatusId === 2 && expenseReport.approvalStatusId !== 3) {
							approvedExpenseReports.push(expenseReport);
						} else if (expenseReport.approvalStatusId !== 2 && expenseReport.approvalStatusId === 3) {
							deniedExpenseReports.push(expenseReport);
						}
					}
				}

				// Build a sorted array of all expense reports by pushing expense report arrays in the desired display order (pending, approved, denied)
				allExpenseReports.push(...pendingExpenseReports);
				allExpenseReports.push(...approvedExpenseReports);
				allExpenseReports.push(...deniedExpenseReports);

				// Associate expense report data with data sources for the view's tables
				//this.expenseReportApprovalDataSources['pending'] = new MatTableDataSource(pendingExpenseReports);
				this.expenseReportApprovalDataSources['approved'] = new MatTableDataSource(approvedExpenseReports);
				this.expenseReportApprovalDataSources['denied'] = new MatTableDataSource(deniedExpenseReports);
				this.expenseReportApprovalDataSources['all'] = new MatTableDataSource(allExpenseReports);

				// Set up pagination for the expense report data sources
				this.expenseReportApprovalDataSources['pending'].paginator = this.paginators.toArray()[1];
				this.expenseReportApprovalDataSources['approved'].paginator = this.paginators.toArray()[2];
				this.expenseReportApprovalDataSources['denied'].paginator = this.paginators.toArray()[3];
				this.expenseReportApprovalDataSources['all'].paginator = this.paginators.toArray()[0];
				this.sortingSources();

				// Mark expense report approval data as no longer loading
				this.isDeniedAndApprovedLoading = false;

			}, err => {
				this.snackBar.open('Error Loading Expense Reports', '', { duration: 2000 });
				this.isDeniedAndApprovedLoading = false;
			}

		);
	}

	sortingSources() {
		this.expenseReportApprovalDataSources['pending'].sort = this.sort.first;
		this.expenseReportApprovalDataSources['pending'].sortingDataAccessor = (item, property) => {
			switch (property) {
				case 'date': return item['periodEnd'];
				case 'employee': return item['user']['fullName'];
				case 'project': return item['projectName'];
				case 'expenses': return item['totalAmount'];
				default: return item[property];
			}
		};

		this.expenseReportApprovalDataSources['pending'].data =
			this.expenseReportApprovalDataSources['pending'].data.sort((a, b) => (a['periodEnd'] < b['periodEnd']) ? 1 : -1);

		this.expenseReportApprovalDataSources['approved'].sort = this.sort.first;
		this.expenseReportApprovalDataSources['approved'].sortingDataAccessor = (item, property) => {
			switch (property) {
				case 'date': return item['periodEnd'];
				case 'employee': return item['user']['fullName'];
				case 'project': return item['projectName'];
				case 'expenses': return item['totalAmount'];
				default: return item[property];
			}
		};
		this.expenseReportApprovalDataSources['approved'].data =
			this.expenseReportApprovalDataSources['approved'].data.sort((a, b) => (a['periodEnd'] < b['periodEnd']) ? 1 : -1);

		this.expenseReportApprovalDataSources['denied'].sort = this.sort.first;
		this.expenseReportApprovalDataSources['denied'].sortingDataAccessor = (item, property) => {
			switch (property) {
				case 'date': return item['periodEnd'];
				case 'employee': return item['user']['fullName'];
				case 'project': return item['projectName'];
				case 'expenses': return item['totalAmount'];
				default: return item[property];
			}
		};

		this.expenseReportApprovalDataSources['denied'].data =
			this.expenseReportApprovalDataSources['denied'].data.sort((a, b) => (a['periodEnd'] < b['periodEnd']) ? 1 : -1);

		this.expenseReportApprovalDataSources['all'].sort = this.sort.first;
		this.expenseReportApprovalDataSources['all'].sortingDataAccessor = (item, property) => {
			switch (property) {
				case 'date': return item['periodEnd'];
				case 'employee': return item['user']['fullName'];
				case 'project': return item['projectName'];
				case 'expenses': return item['totalAmount'];
				default: return item[property];
			}
		};

		this.expenseReportApprovalDataSources['all'].data =
			this.expenseReportApprovalDataSources['all'].data.sort((a, b) => (a['periodEnd'] < b['periodEnd']) ? 1 : -1);
	}

	getClientClassServiceData() {
		this._timesheetService.getClientServiceClassData(false).subscribe(
			data => {
				this.clients = data['clientProjectList'];
				this.services = data['serviceList'];
				this.classes = data['workClassList'];
				if (this.classes != null)
					this.classes.sort((a, b) => { return (a['name'] > b['name']) ? 1 : ((b['name'] > a['name']) ? -1 : 0); }); // sorts in alphabetical order becuase not done on backend for some reason
				if (this.services != null)
					this.services.sort((a, b) => { return (a['name'] > b['name']) ? 1 : ((b['name'] > a['name']) ? -1 : 0); }); // sorts in alphabetical order becuase not done on backend for some reason
				if (this.classes != null)
					this.clients.forEach(client => {
						if (client['projects'].length > 0) {// adds all of the projects for a client to a single array
							this.projects.push(...client['projects']); // Spread syntax ...client['projects'] expands the array to be pushed
						}
					});
			},
			err => {
				this.snackBar.open('Error Retrieving Classes and Services', '', { duration: 2000 });
				console.error(err);
			}
		);
	}

	isRowShown(row, toggle) {
		switch (toggle.value) {
			case 'all': return false;
			case 'pending': return row['isApproved'] == true;
			case 'approved': return row['isApproved'] == false;
			default: return false;
		}
	}

	approveSelected() {
		this.approving = true;
		this.isExpenseReportApprovalDataLoading = true;
		this.isDeniedAndApprovedLoading = true;
		// Associate expense report data with data sources for the view's tables
		let tempDataSources = {
			'approved': this.expenseReportApprovalDataSources['approved'].data,
			'denied': this.expenseReportApprovalDataSources['denied'].data,
			'pending': this.expenseReportApprovalDataSources['pending'].data,
			'all': this.expenseReportApprovalDataSources['all'].data
		};
		this.expenseReportApprovalDataSources['pending'] = new MatTableDataSource([]);
		this.expenseReportApprovalDataSources['approved'] = new MatTableDataSource([]);
		this.expenseReportApprovalDataSources['denied'] = new MatTableDataSource([]);
		this.expenseReportApprovalDataSources['all'] = new MatTableDataSource([]);
		this.snackBar.open('Approving expense reports', '', { duration: 2000 });

		this.quickbooksService.refreshQuickbookOAuth().subscribe(data => {
			let idList = [];
			for (let expense of this.selection.selected) {
				if(expense.approvalStatusId == 1){ //prior if statement not checking correct field 
					idList.push(expense.id);
				}
			}
			this._expenseService.approveExpenseReports(idList).subscribe(
				data => {
						this.snackBar.open('Successfully approved expense reports', '', { duration: 2000 });
						for(let report of this.selection.selected){
							report['isApproved'] = true;
							report['approvalStatusId'] = 2;
							if(report['isDenied']){
								report['isDenied'] = false;
								tempDataSources['denied'].splice(report, 0);
							}
							else{
								tempDataSources['pending'].splice(report, 0);
							}
							tempDataSources['approved'].push(report);
						}

						this.approving = false;
						this.isExpenseReportApprovalDataLoading = false;
						this.isDeniedAndApprovedLoading = false;

						this.expenseReportApprovalDataSources['pending'].data = tempDataSources['pending'];
						this.expenseReportApprovalDataSources['approved'].data= this.expenseReportApprovalDataSources['approved'].data.concat(tempDataSources['approved']);
						this.expenseReportApprovalDataSources['denied'].data = this.expenseReportApprovalDataSources['denied'].data.concat(tempDataSources['denied']);
						this.expenseReportApprovalDataSources['all'].data = this.expenseReportApprovalDataSources['all'].data.concat(tempDataSources['all']);

						this.expenseReportApprovalDataSources['pending']._updateChangeSubscription();
						this.expenseReportApprovalDataSources['approved']._updateChangeSubscription();
						this.expenseReportApprovalDataSources['denied']._updateChangeSubscription();
						this.expenseReportApprovalDataSources['all']._updateChangeSubscription();
				},
				err => {
					if (err.status == 422) {
						this.snackBar.open('Error due to items not synced with Quickbooks', '', { duration: 2000 });
					} else {
						this.snackBar.open('Expense Reports Could Not Be Approved', '', { duration: 2000 });
					}
					this.approving = false;
					this.getExpenseReportsApprovalData();
				}
			);
		});
	}
	approveReport(report) {
		this.approving = true;
		this.isExpenseReportApprovalDataLoading = true;
		this.isDeniedAndApprovedLoading = true;
		this.selection.clear();

		// Associate expense report data with data sources for the view's tables
		let tempDataSources = {
			'approved': this.expenseReportApprovalDataSources['approved'].data,
			'denied': this.expenseReportApprovalDataSources['denied'].data,
			'pending': this.expenseReportApprovalDataSources['pending'].data,
			'all': this.expenseReportApprovalDataSources['all'].data
		};
		 this.expenseReportApprovalDataSources['pending'] = new MatTableDataSource([]);
		 this.expenseReportApprovalDataSources['approved'] = new MatTableDataSource([]);
		 this.expenseReportApprovalDataSources['denied'] = new MatTableDataSource([]);
		 this.expenseReportApprovalDataSources['all'] = new MatTableDataSource([]);

		let idList = [];
		idList.push(report.id);
		this.snackBar.open('Approving Expense Report', '', { duration: 2000 });
		this._expenseService.approveExpenseReports(idList).subscribe(data => {
			this.snackBar.open('Successfully approved expense report', '', { duration: 2000 });
			report['isApproved'] = true;
			report['approvalStatusId'] = 2;
			if(report['isDenied'] == false){
				tempDataSources['pending'].splice(report, 0);
			}
			else{
				report['isDenied'] = false;
				tempDataSources['denied'].splice(report, 1);
			}


			tempDataSources['approved'].push(report);

			this.expenseReportApprovalDataSources['pending'].data = tempDataSources['pending'];
			this.expenseReportApprovalDataSources['approved'].data = this.expenseReportApprovalDataSources['approved'].data.concat(tempDataSources['approved']);
			this.expenseReportApprovalDataSources['denied'].data = this.expenseReportApprovalDataSources['denied'].data.concat(tempDataSources['denied']);
			this.expenseReportApprovalDataSources['all'].data = this.expenseReportApprovalDataSources['all'].data.concat(tempDataSources['all']);


			this.expenseReportApprovalDataSources['pending']._updateChangeSubscription();
			this.expenseReportApprovalDataSources['approved']._updateChangeSubscription();
			this.expenseReportApprovalDataSources['denied']._updateChangeSubscription();
			this.expenseReportApprovalDataSources['all']._updateChangeSubscription();

			this.approving = false;
			this.isExpenseReportApprovalDataLoading = false;
			this.isDeniedAndApprovedLoading = false;

		},
			err => {
				if (err.status == 422) {
					if(err.error['Error'] != undefined) {
						this.snackBar.open(err.error.Error[0]['Detail'], '', { duration: 6000 });
					} else {
						this.snackBar.open('Error due to items not synced with Quickbooks', '', { duration: 2000 });
					}
				} else {
					this.snackBar.open('Error Approving Expense Report', '', { duration: 2000 });
				}
				this.approving = false;
				this.getExpenseReportsApprovalData();
			});
	}
	denyReport(report) {
		this.isExpenseReportApprovalDataLoading = true;
		this.isDeniedAndApprovedLoading = true;
		// Associate expense report data with data sources for the view's tables
		let tempDataSources = {
			'approved': this.expenseReportApprovalDataSources['approved'].data,
			'denied': this.expenseReportApprovalDataSources['denied'].data,
			'pending': this.expenseReportApprovalDataSources['pending'].data,
			'all': this.expenseReportApprovalDataSources['all'].data
		};
		this.expenseReportApprovalDataSources['pending'] = new MatTableDataSource([]);
		this.expenseReportApprovalDataSources['approved'] = new MatTableDataSource([]);
		this.expenseReportApprovalDataSources['denied'] = new MatTableDataSource([]);
		this.expenseReportApprovalDataSources['all'] = new MatTableDataSource([]);

		if (!report.isApproved && !report.isDenied) {
			this.snackBar.open('Denying expense report', '', { duration: 2000 });
			this._expenseService.denyExpenseReport(report.id).subscribe(
				data => {
					this.snackBar.open('Successfully denied expense report', '', { duration: 2000 });
					this.selection.clear();
					report['isDenied'] = true;
					report['approvalStatusId'] = 3;
					tempDataSources['denied'].push(report);
					tempDataSources['pending'].splice(report, 1);

					this.expenseReportApprovalDataSources['pending'].data = tempDataSources['pending'];
					this.expenseReportApprovalDataSources['approved'].data = this.expenseReportApprovalDataSources['approved'].data.concat(tempDataSources['approved']);
					this.expenseReportApprovalDataSources['denied'].data = this.expenseReportApprovalDataSources['denied'].data.concat(tempDataSources['denied']);
					this.expenseReportApprovalDataSources['all'].data = this.expenseReportApprovalDataSources['all'].data.concat(tempDataSources['all']);


					this.expenseReportApprovalDataSources['pending']._updateChangeSubscription();
					this.expenseReportApprovalDataSources['approved']._updateChangeSubscription();
					this.expenseReportApprovalDataSources['denied']._updateChangeSubscription();
					this.expenseReportApprovalDataSources['all']._updateChangeSubscription();

					this.isExpenseReportApprovalDataLoading = false;
					this.isDeniedAndApprovedLoading = false;

				}, err => {
					this.snackBar.open('Error Denying Expense Report', '', { duration: 2000 });
				});
		}

	}

	proessExpenseDetailData(data) {
		data.forEach(row => {
			row['categoryName'] = this.expenseCategories.find(c => row['categoryId'] == c['id'])['name'];
		});
		return data;
	}

	getExpenseCategories() {
		this._expenseService.getExpenseCategories().subscribe(
			data => {
				this.expenseCategories = data;
			},
			err => {
				this.snackBar.open('Error Retrieving Expense Categories', '', { duration: 2000 });
			}
		);
	}

	toggleExpenseReportDetail(rowData) {
		if (rowData['id'] != undefined) {
			this._expenseService.getExpenseDetails(rowData['id']).subscribe(data => {
				this.reportDetails = data;
				if (this.reportDetails !== undefined) {
					rowData['reportExpenses'] = this.reportDetails;
				}
				this.expenseReport = !this.expenseReport;
				if (!this.expenseReport) {
					if (rowData['reportExpenses']) {
						rowData['reportExpenses'].forEach(expense => {
							this.expenseAge = Math.abs(this.getAge(new Date(expense['date']), new Date));
							if (expense['amount'] > rowData['reportPolicy']['amount']
								|| this.expenseAge > rowData['reportPolicy']['maxAge']) {
								expense['hasViolation'] = true;
							}
							else {
								expense['hasViolation'] = false;
							}
						});
						this.expenseReportApprovalDetailsDataSource = new MatTableDataSource(this.proessExpenseDetailData(rowData['reportExpenses']));
						this.expenseReportApprovalDetailsDataSource.paginator = this.paginators.toArray()[4];
						this.expenseReportApprovalDetailsDataSource.sortingDataAccessor = (item, property) => {
							switch (property) {
								case 'date': return item['date'];
								case 'name': return item['merchantName'];
								case 'category': return item['categoryName'];
								case 'reimbursable': return item['isReimbursable'];
								case 'billable': return item['isBillable'];
								case 'amount': return item['amount'];
								default: return item[property];
							}
						};
					} else {
						this.expenseReportApprovalDetailsDataSource = new MatTableDataSource();
					}
				}
			});
		} else {
			this.expenseReport = !this.expenseReport; // Flips the boolean on which view to display
		}
	}

	increaseDate() {
		this.viewDate = moment(this.viewDate).add(1, 'month').toDate();
		this.formatDateRange();
		this.getExpenseReportsApprovalData();
	}

	decreaseDate() {
		this.viewDate = moment(this.viewDate).subtract(1, 'month').toDate();
		this.formatDateRange();
		this.getExpenseReportsApprovalData();
	}

	setDate(selectedDate: Date) {
		this.viewDate = moment(selectedDate).toDate();
		this.formatDateRange();
		this.getExpenseReportsApprovalData();
	}

	formatDateRange() {
		// Get the first day of the selected month
		let monthStart = moment(this.viewDate).startOf('month').toDate();

		// Set the date range string
		this.dateRange = moment(monthStart).format('MMMM YYYY');
	}

}
