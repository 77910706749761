import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth/auth.service';
import { SetttingsService } from "../api/setttings.service";

@Component({
    selector: 'app-management',
    templateUrl: './management.component.html',
    styleUrls: ['./management.component.scss']
})
export class ManagementComponent implements OnInit {
    userPermissions: string[];
    showQuickbooks: boolean = false;

    constructor(private authService: AuthService, private _settingsService: SetttingsService) {
        this.userPermissions = [];
    }

    ngOnInit() {
        this.authService.getUserPermissions()
            .subscribe(userPermissions => this.userPermissions = userPermissions);

        this._settingsService.getTenantConfigFinancialSystem()
            .subscribe(data => {
                if(data != null && data['response'] == "Quickbooks"){
                    this.showQuickbooks = true;
                }
            });
    }
}
