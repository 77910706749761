import { Component, OnInit, Inject } from '@angular/core';
import { FormControl } from '../../../../../node_modules/@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatSnackBar } from '../../../../../node_modules/@angular/material';

@Component({
  selector: 'app-management-timeoff-category-dialog',
  templateUrl: './management-timeoff-category-dialog.component.html',
  styleUrls: ['./management-timeoff-category-dialog.component.scss']
})
export class ManagementTimeoffCategoryDialogComponent implements OnInit {

    nameFormControl: FormControl = new FormControl(this.ptoData['name']);
    daysFormControl: FormControl = new FormControl(this.ptoData['defaultMaxDay']);
    isActiveFormControl: FormControl = new FormControl(this.ptoData['isActive'] == null ? true : this.ptoData['isActive']);
	selectedClients: object[];
	selectedClasses: object[];
	selectedProjects: object[];
	selectedServices: object[];
	error: String;

    constructor(public snackBar: MatSnackBar, public dialogRef: MatDialogRef<ManagementTimeoffCategoryDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public ptoData) { }

    ngOnInit() {

		this.selectedClients = this.ptoData['clients'];
		this.selectedClasses = this.ptoData['classes'];
		this.selectedProjects = this.ptoData['projects'];
		this.selectedServices = this.ptoData['services'];
    }

    closeDialog() {
        this.dialogRef.close();
    }

	onKeyClient(value) {
		this.selectedClients = this.filterClients(value);
	}

	filterClients(value: string) {
	  let filter = value.toLowerCase();
	  return this.ptoData['clients'].filter(option => option['clientName'].toLowerCase().includes(filter));
	}

	onKeyClass(value) {
		this.selectedClasses = this.filterClasses(value);
	}

	filterClasses(value: string) {
	  let filter = value.toLowerCase();
	  return this.ptoData['classes'].filter(option => option['name'].toLowerCase().includes(filter));
	}

	onKeyProject(value) {
		this.selectedProjects = this.filterProjects(value);
	}

	filterProjects(value: string) {
	  let filter = value.toLowerCase();
	  return this.ptoData['projects'].filter(option => option['name'].toLowerCase().includes(filter));
	}

	onKeyService(value) {
		this.selectedServices = this.filterServices(value);
	}

	filterServices(value: string) {
	  let filter = value.toLowerCase();
	  return this.ptoData['services'].filter(option => option['name'].toLowerCase().includes(filter));
	}

	//Sets focus of the combobox inputs on click
	setFocus(inputId:string){
		if(document.getElementById(inputId) != null){
			document.getElementById(inputId).focus();
		}
	}

    save() {
        if (this.ptoData['newData']) {
            this.ptoData['isActive'] = this.isActiveFormControl.value;
        }
        this.ptoData['name'] = this.nameFormControl.value;
		this.ptoData['defaultMaxDay'] = this.daysFormControl.value;
        if (this.validator()) {
            this.dialogRef.close({ submit: true, data: this.ptoData });
		} else {
            this.snackBar.open('All fields are not filled', '', { duration: 2000 });
			this.error = "All fields must be filled";
		}
    }

    validator() {
        if (this.ptoData['name'] != null && this.ptoData['defaultMaxDay'] != null &&
            this.ptoData['client'] != null && this.ptoData['service'] != null &&
            this.ptoData['project'] != null && this.ptoData['workClass'] != null) {
            return true;
        } else {
            return false;
        }
    }
}
