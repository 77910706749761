import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../../services/auth/auth.service';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})

export class AdminGuard implements CanActivate {

    constructor(private authService: AuthService, private _router: Router) { }

    /**
     * Checks if the user can access certaina admin-related views and resources
     * based on the permissions assigned to the user.
     * @param next A "snapshot" of the actived Angular route.
     * @param state A "snapshot" of the Angular router state.
     * @returns An observable boolean or promise boolean or boolean indicating
     * whether or not the user can access the requested view or resource.
     */
    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return this.authService.getUserPermissions().pipe(
            map(userPermissions => {
                if ((userPermissions != null) && userPermissions.length > 0) {
                    switch (state.url) {
                        case '/approval/time-entry-approval':
                            return userPermissions.includes('approve:time-entries');
                        case '/approval/time-off-approval':
                            return userPermissions.includes('approve:time-off');
                        case '/approval/expenses-approval':
                            return userPermissions.includes('approve:expenses');
                        case '/administration':
                            return userPermissions.includes('manage-application-settings:administration') ||
                                userPermissions.includes('manage:expenses') ||
                                userPermissions.includes('manage:project-portfolios') ||
                                userPermissions.includes('manage:projects') ||
                                userPermissions.includes('manage:time-entries') ||
                                userPermissions.includes('manage:time-off');;
                    }

                    /**
                     * Checks if the user is a tenant admin and permits access to any
                     * part of the application if so.
                     */
                    return userPermissions.includes('administer-tenant:administration');
                }
                else {
                    return false;
                }
            })
        );
    }
}
