import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatSnackBar } from '@angular/material';
import { ReportService } from '../../api/report.service';
import * as moment from 'moment';
import { AuthService } from '../../services/auth/auth.service';
import {ViewSDKClient} from '../view-sdk.service'
var cloneDeep = require('lodash.clonedeep');

@Component({
    selector: 'app-expense-report',
    templateUrl: './expense-report.component.html',
    styleUrls: ['./expense-report.component.scss']
})
export class ExpenseReportComponent implements OnInit, OnChanges {
	projects: any;
	users: any;
	selectedUsers: any[];
    reportData = Object();
    billable = 1;
    reimbursable = 1;
    approved = true;
    display: any;
    title: any;
    clients;
    adminAccess: boolean = false;
    currentId;
	isUsersLoading: boolean = true;
	isProjectDataLoading: boolean = true;
    isReportLoading: boolean = false;
    url: any;

    projectId: FormControl = new FormControl(this.reportData['projectId']);
    userId: FormControl = new FormControl(this.reportData['userId']);
    startDate: FormControl = new FormControl(this.reportData['start']);
    endDate: FormControl = new FormControl(this.reportData['end']);

	@Input() senderClientsProjects = [];
	clientsForFilter: any[];

	@Input() senderUsers = [];
	usersForFilter:any[];

    dateRangeFilter = (d: Date): boolean => {
        if (this.startDate.value) {
            let isSameOrAfter = moment(d).isSameOrAfter(moment(this.startDate.value));
            return isSameOrAfter;
        } else {
            return false;
        }
    }


    constructor(private _reportService: ReportService, public snackBar: MatSnackBar,
        private authService: AuthService,private viewSDKClient: ViewSDKClient) { }
    ngOnInit() {
        this.authService.getUserPermissions().subscribe(userPermissions =>
            {
                this.adminAccess = userPermissions.includes('manage-application-settings:administration');
            });
	}

	ngOnChanges(changes: SimpleChanges) {
		if(changes.senderClientsProjects != undefined){
			this.clientsForFilter = this.senderClientsProjects;
			this.clients = this.senderClientsProjects;
			this.isProjectDataLoading = false;
		}else if(changes.senderUsers != undefined){
			this.usersForFilter = this.senderUsers;
			this.users = this.senderUsers;
			this.isUsersLoading = false;
		}
	}

	onKeyUser(value) {
		this.users = this.filterUsers(value);
	}

	filterUsers(value: string) {
	  let filter = value.toLowerCase();
	  return this.usersForFilter.filter(option => option['fullName'].toLowerCase().includes(filter));
	}

	onKeyProject(value) {
		this.clients = this.filterProjects(value);
	}

	filterProjects(value: string) {
        let filter = value.toLowerCase();

        let filteredClientsAndProjects = this.clientsForFilter.filter(option => option['name'].toLowerCase().includes(filter));
        for(var i = 0; i < this.clientsForFilter.length; i++){
          if(filteredClientsAndProjects.find(option => option['id'] == this.clientsForFilter[i]['id']) == undefined){
              if(this.clientsForFilter[i]['projects'] != null){
                  let tempClient = cloneDeep(this.clientsForFilter[i]);
                  tempClient['projects'] = tempClient['projects'].filter(project => project['name'].toLowerCase().includes(filter))
                  if(tempClient['projects'].length > 0){
                      filteredClientsAndProjects.push(tempClient);   
                  }
              }
          }
        }
        return filteredClientsAndProjects.sort((a, b) => { return (a['name'] > b['name']) ? 1 : ((b['name'] > a['name']) ? -1 : 0); });
	}

	//Sets focus of the combobox inputs on click
	setFocus(inputId:string){
		if(document.getElementById(inputId) != null){
			document.getElementById(inputId).focus();
		}
	}

    generateReport() {
        if (this.adminAccess) {
            this.reportData['userId'] = this.userId.value;
        } else {
            this.reportData['userId'] = this.currentId;
        }
        if (this.projectId.value != null && this.startDate.value != null) {
            this.isReportLoading = true;
            this.reportData['projectId'] = this.projectId.value;
            this.reportData['start'] = this.startDate.value;
            this.reportData['end'] = this.endDate.value == null ? new Date() : this.endDate.value;
            this.reportData['isBillable'] = this.billable;
            this.reportData['isReimbursable'] = this.reimbursable;
            this.reportData['approved'] = this.approved;
            this._reportService.generateReport(this.reportData).subscribe(data => {
                this.isReportLoading = false;
                var pdfData = data['response'];
                this.title = data['name'];
                this.url = data['response'];
                this.display = true;
                const previewConfig = {
                    embedMode: "FULL_WINDOW",
                    showDownloadPDF: false,
                    showPrintPDF: false
                 }
                const adobeDCView = new ViewSDKClient();
                adobeDCView.ready().then(() => {
                    adobeDCView.previewFile('pdf-div4',previewConfig, pdfData, this.title)
                        .then(() => {

                        })
                        .catch(error => {
                            console.error('Error loading PDF:', error);
                        });
                });
    
            }, err => {
                this.isReportLoading = false;
                this.snackBar.open('No Report Data Found', '', { duration: 2000 });
            });
        } else {
            this.isReportLoading = false;
            this.snackBar.open('Not all fields filled', '', { duration: 2000 });
        }
    }

    downloadReport() {
        let reportUrl: string = this.url;
        var a = document.createElement('a');
        a.href = reportUrl;
        a.setAttribute('download', this.title);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    toggleBillable(event) {
        this.billable = event.value;
    }

    toggleReimbursable(event) {
        this.reimbursable = event.value;
    }

    toggleApproved(event) {
        this.approved = event.value;
    }
}



