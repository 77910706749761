import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatTableDataSource, MatSnackBar } from '@angular/material';
import { FormControl } from '@angular/forms';
import * as moment from 'moment';

@Component({
	selector: 'app-timeoff-entry-dialog',
	templateUrl: './timeoff-entry-dialog.component.html',
	styleUrls: ['./timeoff-entry-dialog.component.scss']
})

export class TimeoffEntryDialogComponent implements OnInit {
	typeId: FormControl = new FormControl({ value: this.timeoffData['typeId'], disabled: (this.timeoffData.viewOnly) });
	fromDate: FormControl = new FormControl({ value: this.timeoffData['fromDate'], disabled: (this.timeoffData.viewOnly) });
	toDate: FormControl = new FormControl({ value: this.timeoffData['toDate'], disabled: (this.timeoffData.viewOnly) });
	description: FormControl = new FormControl({ value: this.timeoffData['description'], disabled: (this.timeoffData.viewOnly) });

	@ViewChild('desc')

	conflictText;
	descriptionElement: any;
	error: string = null;
	isManager: boolean = false;
	selectedTypes: any[];

	dateRangeFilter = (d: Date): boolean => {
		if (this.fromDate.value !== undefined) {
			let isSameOrAfter = moment(d).isSameOrAfter(moment(this.fromDate.value), 'day');
			return isSameOrAfter;
		} else {
			return false;
		}
	}

	displayedColumns = ['date', 'duration', 'shift']; // Needed for the operation of the mat table
	dataSource = new MatTableDataSource();
	dateSplit: boolean = this.timeoffData['split'];

	constructor(public dialogRef: MatDialogRef<TimeoffEntryDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public timeoffData,
		//public returnEvent,
		) { }

	ngOnInit() {
		this.conflictText = null;
		if(this.timeoffData.conflictBlackOut){
			this.conflictText = 'blackout date';
		}

		if (this.timeoffData.isManager != null) {
			this.isManager = this.timeoffData.isManager;
		}

		this.populateDataSource();
		this.selectedTypes = this.timeoffData['allTypes'];
	}

	onKeyType(value) {
		this.selectedTypes = this.filterType(value);
	}

	filterType(value: string) {
		let filter = value.toLowerCase();

		return this.timeoffData['allTypes'].filter(option => option['name'].toLowerCase().includes(filter));
	}

	//Sets focus of the combobox inputs on click
	setFocus(inputId: string) {
		if (document.getElementById(inputId) != null) {
			document.getElementById(inputId).focus();
		}
	}

	split() {
		let daterange = moment(this.toDate.value).diff(moment(this.fromDate.value), 'days');

		if ((this.fromDate.value && this.toDate.value) && daterange >= 0) {
			this.dateSplit = !this.dateSplit; // Toggle split area being displayed
			this.error = ''; 
		} else {
			this.dateSplit = false;

			if (daterange < 0) {
				this.error = "Invalid Date Range";
			} else {
				this.error = 'Please Select Date Range First';
			}
		}
		
	}

	checkInColumn(activeColumns, currentColumn) {
		return !activeColumns.some(e => e.id === currentColumn.id);
	}

	populateDataSource() {
		let splitData = [];
		let dayType = { 0: 'full', 1: 'half', 2: 'excluded' };
		let counter = 0;
		if (this.fromDate.value && this.toDate.value) {
			for (let date = moment(this.fromDate.value).utc(); date.isSameOrBefore(moment(this.toDate.value).utc(), 'day'); date.add(1, 'day')) {
				let dayFormat = date.format('MM/DD/YYYY');
				let durationFormat = 'full';
				let shiftFormat = 'morning';
				if (this.timeoffData['fullData'] && counter < this.timeoffData['fullData']['dateList'].length) {
						if(this.timeoffData['fullData']['dateList'][counter]['isMorning'] === true){
							durationFormat = 'half';
							shiftFormat = 'morning';
						}
						else if(this.timeoffData['fullData']['dateList'][counter]['isAfternoon'] === true){
							durationFormat = 'half';
							shiftFormat = 'afternoon';
						}
						else if(this.timeoffData['fullData']['dateList'][counter]['isExcluded'] === true){
							durationFormat = 'excluded';
						}
						counter += 1;
				}

				splitData.push({
					date: dayFormat,
					duration: durationFormat,
					shift: shiftFormat,
					viewOnly: this.timeoffData['viewOnly']
				});
			}
		}

		this.dataSource = new MatTableDataSource(splitData);
		this.error = '';
	}

	formatReturnData() {
		let returnData = this.timeoffData['fullData'] ? this.timeoffData['fullData'] : {};
		returnData['categoryId'] = this.typeId.value;
		returnData['startDate'] = moment(this.fromDate.value).utc().format();
		returnData['endDate'] = moment(this.toDate.value).utc().format();
		if(this.description.value != undefined){
			returnData['description'] = this.description.value;
		} else {
			returnData['description'] = "  ";
		}
		
		returnData['dateList'] = [];
		let counter = 0;
		let dayType = {
			full: 0,
			half: 1,
			excluded: 2
		};

		let status = 0;
		let morning = 1;
		let afternoon = 1;
		let excluded = 1;

		// This for loop will loop through all of the dates between start and end inclusive
		for (let date = moment(this.fromDate.value); date.isSameOrBefore(moment(this.toDate.value), 'day'); date.add(1, 'day')) {

			if (this.dateSplit) {
				status = dayType[this.dataSource.data[counter]['duration']];
				morning = this.dataSource.data[counter]['shift'] != 'afternoon' ? 1 : 0;
			}



			if(this.dataSource.data[counter]['duration'] == 'half'){
				if(this.dataSource.data[counter]['shift'] == 'afternoon'){
					afternoon = 1;
					morning = 0;
					excluded = 0;
				}
				else if(this.dataSource.data[counter]['shift'] == 'morning'){
					afternoon = 0;
					morning = 1;
					excluded = 0;
				}
			}
			else if(this.dataSource.data[counter]['duration'] == 'excluded'){
				afternoon = 0;
				morning = 0;
				excluded = 1;
			}
			else{
				afternoon = 0;
				morning = 0;
				excluded = 0;
			}

			returnData['dateList'].push({
				entryDate: date.utc().format(),
				isExcluded: excluded,
				isMorning: morning,
				isAfternoon: afternoon,
				fullHalfExcludedStatus: status
			});

			counter += 1;
		}

		return returnData;
	}

	closeDialog() {
		this.dialogRef.close();
	}

	delete() {
		this.dialogRef.close({ delete: true, entryId: this.timeoffData['fullData']['id'] });
	}

	save() {
		let returnEvent = this.formatReturnData();

		if (moment(this.fromDate.value).isSameOrBefore(moment(this.toDate.value))) {
			if (this.isValidTimeOff(returnEvent)) {
				this.dialogRef.close({ draft: true, data: returnEvent });
			}
		} else {
			this.error = "Invalid Date Range";
		}
	}

	isValidTimeOff(returnEvent) {
		if (this.typeId.value == undefined) {
			this.error = 'Type is required';
			return false;
		}

		if (this.fromDate.value == undefined) {
			this.error = 'From Date is required';
			return false;
		}

		if (this.toDate.value == undefined) {
			this.error = 'To Date is required';
			return false;
		}

		if (moment(this.toDate.value).isBefore(moment(this.fromDate.value))) {
			this.error = 'To Date must be the same or later than From Date';
			return false;
		}

		if(this.conflictText == null){
			for(var i = 0; i < returnEvent.dateList.length; i++){
				if(returnEvent.dateList[i].isExcluded != 1){
					if(this.timeoffData.blackOutDates.find(date => date.blackOutDate.substring(0, 10) ==
						returnEvent.dateList[i].entryDate.substring(0, 10))){
							this.conflictText = 'blackout date';
							return false;
					}
					if(this.timeoffData.holidayDates.find(date => date.holidayDate.substring(0, 10) ==
						returnEvent.dateList[i].entryDate.substring(0, 10))){
							this.conflictText = 'holiday';
							return false;
					}
				}
			}
		}

		//regex to check string for letters and that it can't be undefined
		if(this.description.value == undefined) {
			this.error = 'Timeoff Description can not be blank.';
			return false;
		} else if (!(/[a-zA-Z]/.test(this.description.value))) {
			this.error = 'Timeoff Description must contain letters.';
			return false;
		}

		return true;
	}

	submit() {

		if (this.fromDate.value != null && this.toDate.value != null && moment(this.fromDate.value).isSameOrBefore(moment(this.toDate.value))) {
			let returnEvent = this.formatReturnData();

			if (this.isValidTimeOff(returnEvent)) {
				this.dialogRef.close({ submit: true, data: returnEvent });
			}
		} else {
			this.error = "Invalid Date Range";
		}
	}

	saveUpdate() {
		if (moment(this.fromDate.value).isSameOrBefore(moment(this.toDate.value))) {
			let returnEvent = this.formatReturnData();

			if (this.isValidTimeOff(returnEvent)) {
				this.dialogRef.close({ update: true, data: returnEvent });
			}
		} else {
			this.error = "Invalid Date Range";
		}
	}

	reject() {
		this.dialogRef.close({ reject: true, data: this.formatReturnData() });
	}

	approve() {
		this.dialogRef.close({ approve: true, data: this.formatReturnData() });
	}
}
