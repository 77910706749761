import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-management-timeoff-user-dialog',
  templateUrl: './management-timeoff-user-dialog.component.html',
  styleUrls: ['./management-timeoff-user-dialog.component.scss']
})
export class ManagementTimeoffUserDialogComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  closeDialog() {
    // TODO: Implement closeDialog()!
  }

}
