import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SetttingsService } from '../api/setttings.service';
import { Router } from '@angular/router';
import { MatPaginator, MatTableDataSource, MatDialog, MatSnackBar, MatSort, MatSelect } from '@angular/material';
import { ProjectsTaskDialogComponent } from '../projects/projects-task-dialog/projects-task-dialog.component';
import { SprintDialogComponent } from './sprint-dialog/sprint-dialog.component';
import { ProjectsService } from '../api/projects.service';
import { SprintService } from '../api/sprint.service';
import { ShareProject } from '../services/sharing/share-project.service';
import { AuthService } from '../services/auth/auth.service';

var async = require("async");
var cloneDeep = require('lodash.clonedeep');

@Component({
	selector: 'app-kanban-board',
	templateUrl: './kanban-board.component.html',
	styleUrls: ['./kanban-board.component.scss']
})
export class KanbanBoardComponent implements OnInit, OnDestroy {
	taskTypes;
	taskStatuses;
	users;
	clients;
	projects = [];
	entry: object = null;
	allFilters: object[] = [[], [], [], [], [], [], [], []]; //Projects, Assignees, Types, Search, Status, Label, Milestone, Sprint
	allLabels: any[] = [];
	allMilestones: any[] = [];
	allTasks: object[][] = [];
	allSprints: object[] = [];

	tasksForSprints: object[] = [];

	filteredProjects: object = [];
	filteredAssignees: object = [];
	filteredTypes: object = [];
	filteredStatuses: object = [];

	selectedTypes: any = [];
	selectedUsers: any = [];
	selectedProjects: any = [];
	selectedAssignees: any = [];
	selectedLabels: any = [];
	selectedMilestones: any = [];
	selectedSprints: any = [];
	userHasActiveProjects: boolean = false;
	taskAdd: boolean = false;
	kanbanAdd: boolean = false;
	myTasks: boolean = false;
	isManager: boolean = false;
	currentUserId = [];
	clickTicketObject;

	fullyLoaded: boolean = false;
	workItemDataLoaded: boolean = false;
	clientDataLoaded: boolean = false;
	projectDataLoaded: boolean = false;
	workItemTypeDataLoaded: boolean = false;
	statusDataLoaded: boolean = false;
	labelDataLoaded: boolean = false;
	labelUniqueDataLoaded: boolean = false;
	milestoneDataLoaded: boolean = false;
	milestoneUniqueDataLoaded: boolean = false;
	userDataLoaded: boolean = false;
	sprintsLoaded: boolean = false;
	sprintTasksDataLoaded: boolean = false;
	currentlyInSprint: boolean = false;
	currentSprintId: number;
	currentSprintName: string = '';

	groupSelection: string = "projects";

	unassignedUserValue: string = "unassignedId";

	searchFilterText: string = '';
	searchFilterInputBox: string;

	priorities = [{name: "Lowest", id:1},{name: "Low", id:2},
	{name: "Medium", id:3},{name: "High", id:4},{name: "Highest", id:5}]

	getUserManagerSettingsPromise; // promise to toggle myTasks after currentId is set

	@ViewChild('filterSelectProject') filterSelectProject;
	@ViewChild('filterSelectAssignee') filterSelectAssignee;
	@ViewChild('filterSelectType') filterSelectType;
	@ViewChild('filterSelectSearch') filterSelectSearch;
	@ViewChild('filterSelectStatus') filterSelectStatus;
	@ViewChild('filterSelectLabel') filterSelectLabel;
	@ViewChild('filterSelectMilestone') filterSelectMilestone;
	@ViewChild('selectSprint') selectSprint;

	constructor(private _settingsService: SetttingsService, private _projectsService: ProjectsService, private _authService: AuthService, public router: Router,
		public dialog: MatDialog, public snackBar: MatSnackBar, private shareProject: ShareProject, private _sprintService: SprintService) { 
			this._settingsService.getUserSettings().subscribe(data => {
				if(data != null) this.currentUserId.push(data['userId']);
				this.refreshFilter(this.currentUserId, "Assignees");
			  });
		}

	ngOnDestroy(): void {
		this.shareProject.reset();
	}

	ngOnInit() {
		this.fullyLoaded = false;
		this.getUserManagerSettingsPromise = new Promise((resolve, reject) => {
			try {
				this._authService.getUserPermissions().subscribe(userPermissions => {
					if (userPermissions != null) {
						this.isManager = userPermissions.includes('manage:projects');
						resolve("");
					}
				});	
			} catch (ex) {
				reject(ex);
			}
		});

		var idParam = new URL(window.location.href).searchParams.get("sprint");
		if(idParam != null){
			this.currentSprintId = Number(idParam);
			this.currentlyInSprint = true;
		}

		this.getTasks();
		this.getSprints();
		this.getTasksForSprints();
	}

	onKeyProjects(value) {
		this.selectedProjects = this.filterProjects(value);
	}

	filterProjects(value: string) {
		let filter = value.toLowerCase();

		let filteredClientsAndProjects = this.clients.filter(option => option['name'].toLowerCase().includes(filter));
		for(var i = 0; i < this.clients.length; i++){
			if(filteredClientsAndProjects.find(option => option['id'] == this.clients[i]['id']) == undefined){
				if(this.clients[i]['projects'] != null){
					let tempClient = cloneDeep(this.clients[i]);
					tempClient['projects'] = tempClient['projects'].filter(project => project['name'].toLowerCase().includes(filter))
					if(tempClient['projects'].length > 0){
						filteredClientsAndProjects.push(tempClient);   
					}
				}
			}
		}
		return filteredClientsAndProjects.sort((a, b) => { return (a['name'] > b['name']) ? 1 : ((b['name'] > a['name']) ? -1 : 0); });
	}

	onKeyLabels(value) {
		this.selectedLabels = this.filterLabels(value);
	}

	onKeyMilestones(value) {
		this.selectedMilestones = this.filterMilestones(value);
	}

	onKeySprints(value) {
		this.selectedSprints = this.filterSprints(value);
	}

	filterLabels(value: string) {
		let filter = value.toLowerCase();
		return this.allLabels.filter(option => option['name'].toLowerCase().includes(filter));
	}

	filterMilestones(value: string) {
		let filter = value.toLowerCase();
		return this.allMilestones.filter(option => option['name'].toLowerCase().includes(filter));
	}

	filterSprints(value: string) {
		let filter = value.toLowerCase();
		return this.allSprints.filter(option => option['name'].toLowerCase().includes(filter) && option['status'] == 1);
	}

	onKeyType(value) {
		this.selectedTypes = this.filterTypes(value);
	
	}

	filterTypes(value: string) {
		let filter = value.toLowerCase();
		return this.taskTypes.filter(option => option['typeName'].toLowerCase().includes(filter));
	}

	onKeyAssignees(value) {
		this.selectedAssignees = this.filterAssignees(value);
	}

	filterAssignees(value: string) {
		let filter = value.toLowerCase();
		return this.users.filter(option => option['fullName'].toLowerCase().includes(filter));
	}

	//Sets focus of the combobox inputs on click
	setFocus(inputId: string) {
		if (document.getElementById(inputId) != null) {
			document.getElementById(inputId).focus();
		}
	}

	getClients() {
		this.clientDataLoaded = false;

		this.clients = [];
		this.selectedProjects = [];
		let result = [];
		this._settingsService.getUserClients(true).subscribe(data => {
			result = Object.keys(data).map(function (key) {
				return [Number(key), data[key]];
			});

			if(result.length > 0)
				result = result.sort((a, b) => a['1'].name > b['1'].name ? 1 : -1)
			for (let client of result) {
				this.clients.push(client['1']);
				this.selectedProjects.push(client['1']);
			}
			this.clientDataLoaded = true;

			this.getProjects();
		});
	}

	getSprints() {
		this.sprintsLoaded = false;
		let result = []
		this._sprintService.getSprints().subscribe(data => {
			result = Object.keys(data).map(function (key) {
				return data[key];
			});
			result = result.sort((a, b) => (a.name > b.name) ? 1 : -1);
			this.allSprints = result;
			//Only shows active sprints for dropdown
			this.selectedSprints = result.filter(sprint => sprint.status == 1);
			this.sprintsLoaded = true;

			if(this.currentSprintId != null){
				var currentSprint = this.allSprints.find(sprint => sprint['id'] == this.currentSprintId);
				if(currentSprint != null){
					this.currentSprintName = currentSprint['name'];
				}
				else {
					this.snackBar.open("Sprint does not exist", "", { duration: 2000 });
					this.router.navigateByUrl('kanban-board');
					
					this.currentSprintId = null;
					this.currentlyInSprint = false;
					this.getTasks();
				}
			}
		});
	}

	getTasksForSprints() {
		this.sprintTasksDataLoaded = false;

		let result = [];
		this._projectsService.getProjectTasksForSprintSelection().subscribe(data => {
			result = Object.keys(data).map(function (key) {
				return data[key];
			});
			result = result.sort((a, b) => (a.id > b.id) ? 1 : -1);
			this.tasksForSprints = result;
			this.sprintTasksDataLoaded = true;
		});
	}

	getLabels() {
		this.labelDataLoaded = false;

		let result = [];
		this._projectsService.getAllLabels().subscribe(data => {
			result = Object.keys(data).map(function (key) {
				return data[key];
			});
			result = result.sort((a, b) => (a.name > b.name) ? 1 : -1);
			this.allLabels = result;
		});

		this.labelDataLoaded = true;
	}

	getUniqueLabels() {
		this.labelUniqueDataLoaded = false;
		let uniqueResult = [];
		this._projectsService.getAllTaskLabels('true').subscribe(data => {
			uniqueResult = Object.keys(data).map(function (key) {
				return data[key];
			});
			uniqueResult = uniqueResult.sort((a, b) => (a.name > b.name) ? 1 : -1);
			this.selectedLabels = uniqueResult;
		});
		this.labelUniqueDataLoaded = true;
	}

	getMilestones() {
		this.milestoneDataLoaded = false;

		let result = [];
		this._projectsService.getAllMilestones().subscribe(data => {
			result = Object.keys(data).map(function (key) {
				return data[key];
			});
			this.allMilestones = result.sort((a, b) => (a.name > b.name) ? 1 : -1);
		});
		this.milestoneDataLoaded = true;
	}

	getUniqueMilestones() {
		this.milestoneUniqueDataLoaded = false;
		let uniqueResult = [];
		this._projectsService.getAllTaskMilestones('true').subscribe(data => {
			uniqueResult = Object.keys(data).map(function (key) {
				return data[key];
			});
			this.selectedMilestones = uniqueResult.sort((a, b) => (a.name > b.name) ? 1 : -1);
		});
		this.milestoneUniqueDataLoaded = true;
	}

	getProjects() {
		this.projectDataLoaded = false;

		this._settingsService.getProjectsByClientId(this.clients, true, true).subscribe(data => {
			
			for (let client of this.clients) {
				
				if (data[client['id']].length > 0) {
					client['projects'] = data[client['id']];
					if (client['projects'].length > 0) {
						this.userHasActiveProjects = true;
					}
				}
			}

			for(let i in data) {
				for(let y = 0; y < data[i].length; y++){
					
					this.projects.push(data[i][y])
				}
			}

			
			this.setClickTicketObject();
			
			this.projectDataLoaded = true;
		});
	}

	getTaskTypesAndStatuses() {
		var count = 0;

		this.statusDataLoaded = false;
		this._settingsService.getStatuses().subscribe(data => {
			this.taskStatuses = data;
			this.taskStatuses = this.taskStatuses.sort((a, b) => a.order - b.order);
			count = count + 1;

			this.statusDataLoaded = true;

			if (count == 3) {
				if (this.workItemDataLoaded) {
					this.getTasks();
				}
				this.toggleTasks(true);
				this.setClickTicketObject();
			}
		});

		this.workItemTypeDataLoaded = false;
		this._settingsService.getTaskTypes().subscribe(data => {
			this.taskTypes = data;
			this.selectedTypes = data;
			count = count + 1;

			this.workItemTypeDataLoaded = true;

			if (count == 3) {
				this.toggleTasks(true);
				this.setClickTicketObject();
			}
		});

		this.userDataLoaded = false;
		this._settingsService.getUsers(true).subscribe(data => {
			this.users = data
			this.selectedUsers = data;
			//adding an unassigned user to filter not assigned tasks
			this.selectedUsers.push({userId: "unassignedId", fullName: "Unassigned"})
			this.selectedAssignees = this.selectedUsers.sort((a, b) => (a.fullName > b.fullName) ? 1 : -1);
			count = count + 1;

			this.userDataLoaded = true;

			if (count == 3) {
				this.toggleTasks(true);
				this.setClickTicketObject();
			}
		});
	}

	toggleTasks(toggle) {
		this.myTasks = toggle;
		if (this.myTasks) {
			this.getUserManagerSettingsPromise.then(() => {
				this.filterSelectAssignee.options.forEach(x => x.value == this.currentUserId ? x.select() : x.deselect());
				this.refreshFilter(this.currentUserId, 'Assignees')
			});
		} else {
			this.filterSelectAssignee.options.forEach(x => x.deselect());
			this.refreshFilter([], 'Assignees')
		}
	}

	setClickTicketObject() {
		this.clickTicketObject = new Object({
			"taskStatuses": this.taskStatuses,
			"taskTypes": this.taskTypes,
			"users": this.users,
			"projects": this.projects,
			"sprints": this.allSprints
		});
	}

	// due to the implementation of the kanban page in the old view I needed to comment out the users from the filtertypes so that the error won't occur anymore on page load.
	// this also allows for the kanban page to see the tiles/tasks in the board as they other implementation did not do this.
	refreshFilter(filterItems, type, refreshForProject = false) {
		if (refreshForProject) {
			this.filterSelectProject.options.forEach(x => x.value == filterItems ? x.select() : x.deselect());
			this.filterSelectAssignee.options.forEach(x => x.deselect());
			this.filterSelectType.options.forEach(x => x.deselect());
			this.searchFilterInputBox = '';
			this.filterSelectStatus.options.forEach(x => x.deselect());
			this.filterSelectLabel.options.forEach(x => x.deselect());
			this.filterSelectMilestone.options.forEach(x => x.deselect());
			this.selectSprint.options.forEach(x => x.deselect());

			this.allFilters = [[filterItems], [], [], [], [], [], [], []];
		}
		else {
			var tempFilter = Object.assign({}, this.allFilters);

			if (type == 'Projects') {
				tempFilter[0] = filterItems;
			}
			else if (type == 'Assignees') {
				tempFilter[1] = filterItems;
			}
			else if (type == 'Types') {
				tempFilter[2] = filterItems;
			}
			else if (type == 'Search') {
				tempFilter[3] = [filterItems];
			}
			else if (type == 'Statuses') {
				tempFilter[4] = filterItems;
			}
			else if (type == 'Labels') {
				tempFilter[5] = filterItems;
			}
			else if (type == 'Milestones') {
				tempFilter[6] = filterItems;
			}
			else if (type == 'Sprints') {
				tempFilter[7] = filterItems;
			}
			//Refreshes allFilters
			this.allFilters = tempFilter;
		
		}
	}

	projectSelected(value) {
		this.refreshFilter(value, 'Project', true);
	}

	upsertSprint(type: number){
		let sendData = { 
			type: type,
			clients: this.clients,
			tasks: this.tasksForSprints
		};

		if(type == 1){
			sendData['SprintInfo'] = this.allSprints.find(sprint => sprint['id'] == this.currentSprintId);
			//Only can edit a Sprint when in the sprint page, so allTasks should contain all tasks in sprint
			sendData['SprintInfo']['CurrentTasks'] = this.allTasks;
		}
	
		const dialogRef = this.dialog.open(SprintDialogComponent, {
			width: '800px',
			height: 'auto',
			data: sendData
		});
		dialogRef.afterClosed().subscribe(result => {
			if (result !== undefined) {
				if (result['delete']){
					this._sprintService.deleteSprint(this.currentSprintId).subscribe(data => {
						this.snackBar.open("Sprint Deleted Successfully", "", { duration: 2000 });
						this.router.navigateByUrl('kanban-board');
					
						this.currentSprintId = null;
						this.currentlyInSprint = false;
						this.getSprints();
						this.getTasks();
					}, (err) => {
						this.snackBar.open("Sprint Failed to be Deleted", "", { duration: 2000 });
					});
				}
				else {
					this._sprintService.addUpdateSprint(result['returnModel']).subscribe(data => {
						this.snackBar.open("Sprint Upserted Successfully", "", { duration: 2000 });					
						this.getSprints();
						this.getTasks();
					}, (err) => {
						this.snackBar.open("Sprint Failed to be Uploaded", "", { duration: 2000 });
					});
				}
			}
		});
	}

	addTask(type) {
		let names = []; //array for the names for task status, task type, and priority
		let sendData = { newData: true };
		sendData['newData'] = true;
		sendData['taskTypes'] = this.taskTypes;
		sendData['taskStatuses'] = this.taskStatuses;
		sendData['users'] = this.users;
		sendData['Sprints'] = this.allSprints;
		let entry = new Object();
		sendData['entry'] = entry;
		sendData['entry']['isActive'] = true;
		sendData['entry']['labels'] = [];
		sendData['entry']['milestones'] = [];
	
		const dialogRef = this.dialog.open(ProjectsTaskDialogComponent, {
			width: '800px',
			height: 'auto',
			data: sendData
		});
		dialogRef.afterClosed().subscribe(result => {
			if (result !== undefined) {
				if (result['submit']) {
					var task = sendData['taskStatuses'].find(t => t.statusId == result['data']['taskStatus']);
					names.push("Task Status: "+task.statusName);

					if(result['data']['typeId'] != null){
                    	var type = sendData['taskTypes'].find(t => t.typeId == result['data']['typeId']);
						names.push("Task Type: "+type.typeName);
					}

                	var priority = this.priorities.find(p => p.id == result['data']['priority']);
					names.push("Task Priority: "+priority.name);
					result['data'].names = names;

					var project = this.projects.find(project => project["id"] == result['data']["projectId"]);
					result['data'].projectName = ("project: " + project['name']);
				
					this.addUpdateTask(result['data'], type);
				}
			}
		});
	}

	addUpdateTask(entry, type) {
	
		this._projectsService.putProjectTask(entry).subscribe(
			data => {
				if (data[0] == undefined) this.getTasks(); // if error sending back task, grab tasks slower way
				else {
					data[0]['assignedUser'] = data[0]['assignedUserId'];
					async.forEachOf(this.taskStatuses, (taskStatus, i, innerCallback) => {
						try {
							var tempdata = this.allTasks[i];
							if(data[0].taskStatus == this.taskStatuses[i].statusId){
								tempdata.push(data[0]);
							}
							//sorting by priority descending, then commit date asc, finally by due date asc
							tempdata = tempdata.sort((a, b)=> {
								if (a['priority'] === b['priority']){
									if (a['commitDate'] === b['commitDate']){
										return a['plannedEndDate'] < b['plannedEndDate'] ? -1 : 1
									} else {
										return a['commitDate'] < b['commitDate'] ? -1 : 1
									}
								} else {
								  return b['priority'] < a['priority'] ? -1 : 1
								}
							});
							this.allTasks[i] = tempdata;
							this.taskStatuses[i].taskCount += tempdata.length;
							innerCallback(null)
						} catch (ex) {
							innerCallback(ex);
						}
					}, (err) => {
						if (err) { console.log(err) }
						else {
							var tempFilter = Object.assign({}, this.allFilters);
							//Refreshes filters to propegate to task-group
							this.allFilters = tempFilter;
						}
					});
				}
				this.getLabels();
				this.getUniqueLabels();
				this.getMilestones();
				this.getUniqueMilestones();
				this.getTasksForSprints();
				if (type == "task") this.taskAdd = true;
				else if (type == "kanban") this.kanbanAdd = true;

				this.snackBar.open("Task Added Successfully", "", { duration: 2000 });
			},
			err => {
				this.snackBar.open("Error Adding Work Item", "", { duration: 2000 });
			}
		);
	}

	toggleToTask(group) {
		group.value = "tasks";
	}

	getTasks() {
		this.getTaskTypesAndStatuses();
		this.getClients();
		this.getLabels();
		this.getUniqueLabels();
		this.getMilestones();
		this.getUniqueMilestones();

		this.workItemDataLoaded = false;
		this.allTasks = [];
		if(this.currentlyInSprint && this.currentSprintId != null) {
			this._sprintService.getTasksForSprintAndUser(this.currentSprintId).subscribe(
				data => {
					this.fulfillTaskReturn(data);
				},
				err => console.error(err)
			);
		}
		else {
			this._projectsService.getProjectTaskNameIdStatus().subscribe(
				data => {
					this.fulfillTaskReturn(data);
				},
				err => console.error(err)
			);
		}
	}

	//Removes duplicate work between retrieving all tasks and retrieving only a sprint's tasks
	fulfillTaskReturn(data){
		this.workItemDataLoaded = true;
		if (data != null) {
			let tempdata = Object.keys(data).map(i => data[i]);
			async.forEachOf(this.taskStatuses, (taskStatus, i, innerCallback) => {
				try {
					var temp = tempdata.filter(x => x.taskStatus == this.taskStatuses[i].statusId);
					//sorting by priority descending, then commit date asc, finally by due date asc
					temp.sort((a, b)=> {
						if (a.priority === b.priority){
							if (a.commitDate === b.commitDate){
								return a.plannedEndDate < b.plannedEndDate ? -1 : 1
							} else {
								return a.commitDate < b.commitDate ? -1 : 1
							}
						} else {
						  return b.priority < a.priority ? -1 : 1
						}
					});
					
					this.allTasks.push(temp);
					this.taskStatuses[i].taskCount = temp.length;
					innerCallback(null)
				} catch (ex) {
					innerCallback(ex);
				}
			}, (err) => {
				if (err) console.log(err)
				else {
					var tempFilter = Object.assign({}, this.allFilters);
					//Refreshes filters to propegate to task-group
					this.allFilters = tempFilter;

				}
			});
		}
		this.fullyLoaded = true;
	}
}
