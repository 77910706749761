import { Component, OnInit, Injectable } from '@angular/core';
import { AuthService } from '../../../services/auth/auth.service'
import { QuickbooksService } from '../../../api/quickbooks.service';

@Component({
  selector: 'app-oauth',
  templateUrl: './QBOAuthRecieverComponent.component.html',
  styleUrls: ['./QBOAuthRecieverComponent.component.scss']
})

@Injectable({
  providedIn: 'root'
})

export class QBOAuthRecieverComponent implements OnInit {
  _QuickbooksService: QuickbooksService;

  constructor(public authService: AuthService,
    private quickbooksService: QuickbooksService) {
    this._QuickbooksService = quickbooksService;
  }

  ngOnInit() { 
      this._QuickbooksService.updateQBOAuth(window.location.search).subscribe(
        data => {
          window.close();
        },
        err => {}
      );
  }
}