import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
    selector: 'app-delete-confirmation-dialog',
    templateUrl: './delete-confirmation-dialog.component.html',
    styleUrls: ['./delete-confirmation-dialog.component.scss']
})
export class DeleteConfirmationDialogComponent implements OnInit {

    constructor(public dialogRef: MatDialogRef<DeleteConfirmationDialogComponent>, 
        @Inject(MAT_DIALOG_DATA) public verbiage) { }
    
    title: string;
    button: string;

    ngOnInit() {
        if(this.verbiage != null){
            this.title = this.verbiage.title;
            this.button = this.verbiage.button;
        }
        else {
            this.title = "Deletion";
            this.button = "Delete";
        }
    }

    close() {
        this.dialogRef.close();
    }

    delete() {
        this.dialogRef.close({toDelete: true});
    }

}
