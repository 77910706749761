import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tenant-signup',
  templateUrl: './tenant-signup.component.html',
  styleUrls: ['./tenant-signup.component.scss']
})
export class TenantSignupComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
