import { Component, OnInit, Injectable } from '@angular/core';
import { AuthService } from '../services/auth/auth.service'

@Component({
  selector: 'app-jwt-tester',
  templateUrl: './jwt-tester.component.html',
  styleUrls: ['./jwt-tester.component.scss']
})

@Injectable({
  providedIn: 'root'
})

export class JwtTesterComponent implements OnInit {
  accessToken: string;
  userJwt: string;
  userPermissions: string[];

  constructor(public authService: AuthService) {
    this.accessToken = null;
    this.userJwt = null;
    this.userPermissions = [];
  }

  ngOnInit() { }

  /**
   * Refreshes the user's JWT to ensure the latest JWT is displayed in the UI.
   */
  refreshJwt() {
    // Gets the user's Auth0 access token
    this.authService.getAccessTokenSilently()
      .subscribe(accessToken => this.accessToken = accessToken);

    // Gets the user's JWT
    this.authService.getUserJwt()
      .subscribe(userJwt => this.userJwt = userJwt.__raw);

    this.authService.getUserPermissions()
      .subscribe(userPermissions => this.userPermissions = userPermissions);
  }
}