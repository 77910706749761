import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatSnackBar } from '@angular/material';
import { Moment } from 'moment';
import { GlobalService } from '../api/globalVars';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    withCredentials: true
};

@Injectable({
    providedIn: 'root'
})
export class TimesheetService {
    path = this.globalVar.retrievePath();

    constructor(private _http: HttpClient,
        public globalVar: GlobalService, public snackBar: MatSnackBar) { }

    // TIMESHEET
    // Gets the timesheet data for the given week
    getTimesheetWeek(periodStartDate: Moment) {
        const geturl = this.path + 'timeentry?periodEndDate=' + periodStartDate.format('MM/DD/YYYY');
        return this._http.get(geturl, httpOptions);
    }

    // Get the client/service/class/project data for the selectors in the timesheets page
    getClientServiceClassData(onlyActive = true) {
        const geturl = this.path + 'timeentry?showOnlyActive=' + onlyActive;
        return this._http.get(geturl, httpOptions);
    }

    // adds or updates the rows from the timesheet to the db
    setTimesheetWeek(timesheetObject) {
        const posturl = this.path + 'timeentry';
        return this._http.post(posturl, timesheetObject, httpOptions);
    }

    // deltes a timesheet row from the db
    deleteTimesheetRow(timesheetObject) {
        const deleteurl = this.path + 'timeentry?deleteType=TimeEntry';
        const options = {
            headers: httpOptions.headers,
            withCredentials: true,
            body: timesheetObject
        };
        return this._http.delete(deleteurl, options);
    }


    // APPROVAL
    // Gets the timesheet week data for approval page
    getTimesheetWeekApproval(periodStartDate: Moment) {
        const geturl = this.path + 'timeentryapproval?periodEndDate=' + periodStartDate.format('MM/DD/YYYY');
        return this._http.get(geturl, httpOptions);
    }

    // Takes the row entry and approves or disapproves it
    // status: 1 = approved; 0 = not approved
    updateTimesheetWeekApproval(entry, periodStartDate, status, deleteQB = false) {
        const posturl = this.path + 'timeentryapproval?status=' + status + '&periodStartDate='
            + periodStartDate.format('MM/DD/YYYY') + '&deleteQB=' + deleteQB;
        return this._http.post(posturl, entry, httpOptions);
    }

    updateTimesheetEntry(newModel: Object)	{
        const posturl = this.path + 'timeentryapproval?updateTimesheetFlag=1';
        return this._http.post(posturl, newModel, httpOptions);
    }

    getTimesheetUsers(periodStartDate) {
        const geturl = this.path + 'timeentryapproval?userFlag=1&periodEndDate=' + periodStartDate.format('MM/DD/YYYY');
        return this._http.get(geturl, httpOptions);
    }

    postReminderMessage(emailModel) {
        const posturl = this.path + 'timeentryapproval?reminderFlag=1';
        return this._http.post(posturl, emailModel, httpOptions);
    }

    postDenialMessage(emailModel) {
        const posturl = this.path + 'timeentryapproval?reminderFlag=1';
        return this._http.post(posturl, emailModel, httpOptions);
    }
}
