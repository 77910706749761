import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatTableDataSource } from '@angular/material';
import { FormControl, Validators } from '@angular/forms';
import { SetttingsService } from '../../../api/setttings.service';

@Component({
    selector: 'app-management-expenses-policy-dialog',
    templateUrl: './management-expenses-policy-dialog.component.html',
    styleUrls: ['./management-expenses-policy-dialog.component.scss']
})

export class ManagementExpensesPolicyDialogComponent implements OnInit {

    clientDisplayedColumns = ['name', 'options'];
    clientDataArray = this.expenseData['policyClients'];
    clientDataSource = new MatTableDataSource(this.expenseData['policyClients']);
    clientDataSourceLoading: boolean;

    classDisplayedColumns = ['name', 'options'];
    classDataArray = this.expenseData['policyClasses'];
    classDataSource = new MatTableDataSource(this.expenseData['policyClasses']);
    classDataSourceLoading: boolean;
    error: string = null;
    errorMessage: String;
    rulesMessage: String;
    validEntries: boolean = true;
    followed: boolean = true;

    clients = [];
    classes = [];
    rules = [];
    individualRules = [];
    unusedClasses = [];
    unusedClients = [];

	selectedClasses = [];
    selectedClients = [];

    policyName: FormControl = new FormControl(this.expenseData['name'], [Validators.required]);
    maxAge: FormControl = new FormControl(this.expenseData['maxExpenseAge'], [Validators.required]);
    maxAmount: FormControl = new FormControl(this.expenseData['maxExpenseAmount'], [Validators.required]);
    // receiptAmount: FormControl = new FormControl(this.expenseData['policySetting']['receiptAmount'], [Validators.required]); //Not included in the new object need to verify if that should be the case
    defaultReimbursable: FormControl = new FormControl(this.expenseData['defaultReimbursable'], [Validators.required]);
    defaultBillable: FormControl = new FormControl(this.expenseData['defaultBillable'], [Validators.required]);
    ratePerMile: FormControl = new FormControl(this.expenseData['ratePerMile'], [Validators.required]);
    isActive: FormControl = new FormControl(this.expenseData['isActive'] == null ? true : this.expenseData['isActive']);

    constructor(public dialogRef: MatDialogRef<ManagementExpensesPolicyDialogComponent>,
        private _settingsService: SetttingsService,
        @Inject(MAT_DIALOG_DATA) public expenseData) {
    }

    ngOnInit() {
        this.getPolicyRules(this.expenseData.id);
        this.getClientsAndClasses();
    }



	onKeyClasses(value) {
		this.selectedClasses = this.filterClasses(value);
	}

	filterClasses(value: string) {
	  let filter = value.toLowerCase();
	  return this.unusedClasses.filter(option => option['name'].toLowerCase().includes(filter));
	}

	onKeyClient(value) {
		this.selectedClients = this.filterClients(value);
	}

	filterClients(value: string) {
	  let filter = value.toLowerCase();
	  return this.unusedClients.filter(option => option['name'].toLowerCase().includes(filter));
	}

	//Sets focus of the combobox inputs on click
	setFocus(inputId:string){
		if(document.getElementById(inputId) != null){
			document.getElementById(inputId).focus();
		}
	}

    findClient(clientId) {
        return this.clients.find(c => c['id'] == clientId);
    }

    findUnusedClient(clientId) {
        return this.unusedClients.find(c => c['id'] == clientId);
    }

    findClass(classId) {
        return this.classes.find(c => c['id'] == classId);
    }

    findUnusedClass(classId) {
        return this.unusedClasses.find(c => c['id'] == classId);
    }

    addClient(clientId) {
        let objToAdd = this.findUnusedClient(clientId);
        if (this.clientDataArray == null) {
            this.clientDataArray = [];
        }
        if (objToAdd != undefined && !this.clientDataArray.includes(objToAdd)) {
            this.clientDataArray.push({ clientId: objToAdd['id'], name: objToAdd['name'] });
            this.clientDataSource = new MatTableDataSource(this.clientDataArray);
            this.processData();
        }
    }

    addClass(classId) {
        let objToAdd = this.findUnusedClass(classId);
        if (this.classDataArray == null) {
            this.classDataArray = [];
        }
        if (objToAdd != undefined && !this.classDataArray.includes(objToAdd)) {
            this.classDataArray.push({ workClassId: objToAdd['id'], name: objToAdd['name'] });
            this.classDataSource = new MatTableDataSource(this.classDataArray);
            this.processData();
        }
    }

    deleteClient(clientIndex) {
        this.clientDataArray = this.clientDataArray.filter(obj => obj != this.clientDataArray[clientIndex]);
        this.clientDataSource = new MatTableDataSource(this.clientDataArray);
        this.processData();
    }

    deleteClass(classIndex) {
        this.classDataArray = this.classDataArray.filter(obj => obj != this.classDataArray[classIndex]);
        this.classDataSource = new MatTableDataSource(this.classDataArray);
        this.processData();
    }

    addNamesToArrays() {
        if (this.clientDataArray != null){
            this.clientDataArray.forEach(client => {
                client['name'] = this.findClient(client['clientId'])['name'];
            });
        }
        if (this.classDataArray != null){
            this.classDataArray.forEach(classdata => {
                var foundItem = this.findClass(classdata['workClassId']);
                if(foundItem != null) classdata['name'] = foundItem['name'];
            });
        }
    }

    processData() {
        //Resets unused all clients/classes
        this.unusedClients = this.clients;
        this.unusedClasses = this.classes;

        if (this.clientDataArray != null){
            //Filters out unusedClients already used for clients
            this.clientDataArray.forEach(client => {
                this.unusedClients = this.unusedClients.filter(x => x.id != client.clientId);
            });
            this.unusedClients = this.unusedClients.filter(x => x.isActive != false);
        }
        if (this.classDataArray != null){
            //Filters out unusedClasses already used for classes
            this.classDataArray.forEach(classdata => {
                this.unusedClasses = this.unusedClasses.filter(x => x.id != classdata.workClassId);
            });
            this.unusedClasses = this.unusedClasses.filter(x => x.isActive != false);
		}
		this.selectedClients = this.unusedClients;
		this.selectedClasses = this.unusedClasses;
        this.ruleBreakHide();
        this.individualRulesFollowed();
        
    }

    //Filters out items that are present in other polices, client filters out class and
    //class filters out client
    ruleBreakHide(){
        for(var i = 0; i < this.clientDataArray.length; i++){
            var foundRules = this.rules.filter(x => x.clientId == this.clientDataArray[i].clientId);
            if(foundRules.length > 0){
                this.rules.forEach(rule => {
                    var foundClass = this.unusedClasses.find(x => x.id == rule.workClassId);
                    if(foundClass != undefined){
                        this.unusedClasses = this.unusedClasses.filter(x => x.id != rule.workClassId);
                    }
                    else {
                        //This checks to be sure that unusedClasses id didn't turn into workClassId
                        foundClass = this.unusedClasses.find(x => x.workClassId == rule.workClassId);
                        if(foundClass != undefined){
                            this.unusedClasses = this.unusedClasses.filter(x => x.workClassId != rule.workClassId);
                        }
                    }
                });
            }
        }

        for(var i = 0; i < this.classDataArray.length; i++){
            var foundRules = this.rules.filter(x => x.workClassId == this.classDataArray[i].workClassId);
            if(foundRules.length > 0){
                this.rules.forEach(rule => {
                    var foundClient = this.unusedClients.find(x => x.id == rule.clientId);
                    if(foundClient != undefined){
                        this.unusedClients = this.unusedClients.filter(x => x.id != rule.clientId);
                    }
                    else {
                        //This checks to be sure that unusedClients id didn't turn into clientId
                        foundClient = this.unusedClients.find(x => x.clientId == rule.clientId);
                        if(foundClient != undefined){
                            this.unusedClients = this.unusedClients.filter(x => x.clientId != rule.clientId);
                        }
                    }
                });
            }
        }
    }

    getClientsAndClasses() {
        this._settingsService.getClasses().subscribe(
            data => {
                this.classes = [];
                this.classes.push.apply(this.classes, data);
                this._settingsService.getClients().subscribe(
                    data => {
                        this.clients = [];
                        this.clients.push.apply(this.clients, data);
                        this.addNamesToArrays();
                        this.processData();
                    },
                    err => console.error(err)
                );
            },
            err => console.error(err)
        );
    }

    getPolicyRules(policyId){
        this._settingsService.getPolicyFilterRules(policyId).subscribe(
            data => {
                this.rules = [];
                this.individualRules = [];
                this.rules.push.apply(this.rules, data["rules"]);
                this.individualRules.push.apply(this.individualRules, data["individualRules"]);
            },
            err => console.error(err)
        );
    }

    isValid() {
        if (this.policyName.value == null || this.policyName.value == '') {
            this.errorMessage = 'Policy Name is required';
            return false;
        }
        if (this.maxAge.value == null || this.maxAge.value == '') {
            this.errorMessage = 'Max Age is required';
            return false;
        } 
        if (this.maxAmount.value == null || this.maxAmount.value == '') {
            this.errorMessage = 'Max Amount is required';
            return false;
        }
        if (this.ratePerMile.value == null || this.ratePerMile.value == '') {
            this.errorMessage = 'Rate Per Mile is required';
            return false;
        }
        else {
            this.errorMessage = '';
            return true;
        }
    }

    individualRulesFollowed(){
        var followed = true;
        if(this.classDataArray.length == 0 || this.clientDataArray.length == 0){
            for(var i = 0; i < this.clientDataArray.length; i++){
                var item = this.individualRules.find(x => x.clientId == this.clientDataArray[i].clientId);
                if(!followed) break;
                if(item != null) {
                    followed = false;
                    this.rulesMessage = 'Invalid Clients/Classes: another policy has this exact combination';
                };
            }
            for(var i = 0; i < this.classDataArray.length; i++){
                var item = this.individualRules.find(x => x.workClassId == this.classDataArray[i].workClassId);
                if(!followed) break;
                if(item != null) {
                    followed = false;
                    this.rulesMessage = 'Invalid Clients/Classes: another policy has this exact combination';
                };
            }
        }
        return followed;
    }

    closeDialog() {
        this.dialogRef.close();
    }

    saveAndCloseDialog() {
        console.log(this.individualRulesFollowed() + " rules");
        console.log(this.isValid() + " isValid");
        if(this.individualRulesFollowed() && this.isValid()){
            this.validEntries = true;
            this.expenseData['name'] = this.policyName.value;
            this.expenseData['maxExpenseAge'] = Number(this.maxAge.value);
            this.expenseData['maxExpenseAmount'] = Number(this.maxAmount.value);
            // this.expenseData['receiptAmount'] = this.receiptAmount.value;
            this.expenseData['defaultReimbursable'] = Boolean(this.defaultReimbursable.value);
            this.expenseData['defaultBillable'] = Boolean(this.defaultBillable.value);
            this.expenseData['ratePerMile'] = Number(this.ratePerMile.value);
            this.expenseData['isActive'] = this.isActive.value;
            this.expenseData['policyClients'] = this.clientDataArray;
            this.expenseData['policyClasses'] = this.classDataArray;
            this.dialogRef.close({ submit: true, data: this.expenseData });
        }
        else {
            this.validEntries = false;
        }
    }

}
