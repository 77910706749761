import { Component, OnInit } from '@angular/core';
import { SetttingsService } from '../../api/setttings.service';
import { ThemeService } from '../../services/theme/theme.service';
import { GlobalService } from '../../api/globalVars';
import { MatSnackBar } from '@angular/material';
import { HomeViewService } from "../../services/home-view/home-view.service";
import { AuthService } from "../../services/auth/auth.service";

@Component({
    selector: 'app-tenant-customization',
    templateUrl: './tenant-customization.component.html',
    styleUrls: ['./tenant-customization.component.scss']
})
export class TenantCustomizationComponent implements OnInit {
    selectedFile = <File>null;
    fileName1: string = null;
    fileName2: string = null;
    themeSelection: string = 'default';
    startDaySelection: string = "0";
    homeViewSelection: string = "time-entry";
    siteLogo = '';
    statusLogo = '';
    homeViewNames: object[] = [];

    path = this.globalVar.retrievePath() + 'tenantconfig';

    constructor(private themeService: ThemeService, public globalVar: GlobalService,
        private _settingService: SetttingsService, private homeViewService: HomeViewService, public snackBar: MatSnackBar, private authService: AuthService) { }

    ngOnInit() {
        // this.getLogo();
        this._settingService.getTenantWeekStart().subscribe(data => {
            this.startDaySelection = data + "";
        });

        this.themeService.getAppThemeFromDB().subscribe(
            data => {
                this.themeSelection = data['response'].toString().replace('prosight-', '');
            },
            err => {
                console.error(err);
            }
        );

        let homeViewSelection = this.homeViewService.getAppHomeViewFromCookie();
        if (homeViewSelection != undefined) {
            this.homeViewSelection = homeViewSelection;
        }
        else {
            this.homeViewService.getAppHomeViewFromDB().subscribe(
                (data) => {
                    this.homeViewSelection = data["response"].toString();
                    this.homeViewService.saveAppHomeViewToCookie(homeViewSelection);
                },
                err => {
                    console.error(err);
                }
            );
        }

        this.homeViewNames = this.authService.getHomeViews();
        if(this.homeViewNames.length == 0){
            this.authService.getTenantFeaturesPipe().subscribe(
                (features) => {
                    this.homeViewNames = this.authService.getHomeViews();
                },
                err => {
                    console.error(err);
                }
            );
        }
    }

    saveHomeView(){
        this.homeViewService.saveAppHomeViewToDB(this.homeViewSelection);
    }

    // getLogo() {
    //     this._settingService.getTenantSettings().subscribe(
    //         data => {
    //             this.getImage(data['siteLogoPath'], 1);
    //             this.getImage(data['statusLogoPath'], 2);
    //         },
    //         err => console.error(err)
    //     );
    // }

    // getImage(filename, place) {
    //     this._settingService.getTenantConfigImage(filename).subscribe(
    //         data => {
    //             document.getElementsByTagName('img')[place].src = data['response'];
    //         },
    //         err => console.error(err)
    //     );
    // }

    // onFileSelected(event, place) {
    //     this.selectedFile = event.target.files[0];
    //     if (place == 1) {
    //         this.fileName1 = this.selectedFile.name;
    //     } else if (place == 2) {
    //         this.fileName2 = this.selectedFile.name;
    //     }

    //     let nameArray = this.selectedFile.name.split('.');
    //     let ext = '.' + nameArray[nameArray.length - 1];
    //     let mime = this.selectedFile.type;
    //     this.makearray(this.selectedFile, place);
    // }

    // makearray(file, place) {
    //     var preview = document.getElementsByTagName('img')[place];
    //     var reader  = new FileReader();
    //     reader.addEventListener('load', function () {
    //         // TODO: Add support for case where reader.result is an ArrayBuffer
    //         if(typeof reader.result === 'string')   {
    //             preview.src = reader.result;
    //         }
    //     }, false);
    //     if (file) {
    //         reader.readAsDataURL(file);
    //     }
    // }

    // saveLogo(place) {
    //     var preview = document.getElementsByTagName('img')[place];
    //     this._settingService.postTenantImage({img: preview.src}, place == 1 ? 'logo' : 'report').subscribe(
    //         data => {
    //         },
    //         err => console.error(err)
    //     );
    // }

    changeAppTheme(event) {
        this.themeService.setAppTheme('prosight-' + event.value, true, true);
    }

    changeStartDate(event) {
        this.startDaySelection = event;
        this._settingService.updateStartDate(this.startDaySelection).subscribe(
            data => {
                this.snackBar.open('Successfully changed Start Day', '', { duration: 2000 });
            },
            err => {
                this.snackBar.open('Error with changing Start Day', '', { duration: 2000 });
            }
        );
    }
}
