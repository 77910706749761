import { Component, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRouteSnapshot, Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './services/auth/auth.service';
import { ThemeService } from './services/theme/theme.service';
import { SetttingsService } from './api/setttings.service';

import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';

import 'hammerjs';
import { AuthGuard } from './guards/auth/auth.guard';
import { Title } from '@angular/platform-browser';
import { filter, map } from 'rxjs/operators';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
    title = 'ProSight - Home'

    username: string;
    userPermissions: string[];
    tenantFeatures: number[];
    themeSelection: Observable<string>;
    logoFound: boolean;
    isAdmin: boolean;
    isManager: boolean;

    public pinned: boolean;
    public disableClose: boolean;
    idleState = '';
    timedOut = false;
    lastPing?: Date = null;
    isIdle = false;

    constructor(
        public authService: AuthService,
        private _settingsService: SetttingsService,
        private themeService: ThemeService,
        public router: Router,
        private activatedRoute: ActivatedRoute,
        private idle: Idle,
        private keepalive: Keepalive,
        private renderer: Renderer2,
        private authGuard: AuthGuard,
        private titleService: Title) {
        this.username = '';
        this.userPermissions = [];
        this.tenantFeatures = [];
        this.logoFound = false;
        this.pinned = false;
        this.disableClose = false;

    }

    loadPermissions(){
        if(this.userPermissions.length == 0)
            this.authService.getUserPermissions()
                .subscribe(userPermissions => {
                    this.userPermissions = userPermissions;

                    this.isAdmin = this.userPermissions.includes('administer-tenant:administration');
                    this.isManager = this.userPermissions.includes('manage:expenses') ||
                        this.userPermissions.includes('manage:project-portfolios') ||
                        this.userPermissions.includes('manage:projects') ||
                        this.userPermissions.includes('manage:time-entries') ||
                        this.userPermissions.includes('manage:time-off');
                });

        if(this.tenantFeatures.length == 0)
            this.tenantFeatures = this.authService.getTenantFeatures();
    }

    ngOnInit() {
        const appTitle = this.titleService.getTitle();
        this.router
            .events.pipe(
                filter(event => event instanceof NavigationEnd),
                map(() => {
                var child = this.activatedRoute.firstChild;
                if (child != null && child.children.length > 0) {
                    child = child.children[0];
                }
                if (child != null && child.snapshot.data.title) {
                    return child.snapshot.data.title;
                }
                return appTitle;
                })
            ).subscribe((title: string) => {
                this.titleService.setTitle(title);
            });


        /*
        The line below is necessary to account for cases in which the user either
        does not have the prosight-theme cookie or their version of the cookie
        has expired (and ProSight needs to establish the prosight-theme cookie
        in the user's browser). Without this line, ProSight will appear to be
        relatively unstyled.
        */
        this.renderer.addClass(document.body, 'prosight-default');

        this.themeSelection = this.themeService.themeSelection;

        this.themeSelection.subscribe((themeClass: string) => {
            let themeNames: string[] = this.themeService.getThemeNames();

            for (var i = 0; i < themeNames.length; i++) {
                this.renderer.removeClass(document.body, themeNames[i]);
            }

            this.renderer.addClass(document.body, themeClass);

        });

        let appTheme = this.themeService.getAppThemeFromCookie();

        if(appTheme != null)    {
            this.themeService.setAppTheme('prosight-' + appTheme, false, false);
        }

        let appStartDay = this._settingsService.getStartDayFromCookie();

        if(appStartDay != null)    {
            this._settingsService.setStartDayToCookie(appStartDay);
        }

        this._settingsService.getTenantWeekStart().subscribe(
            data => {
                var tenantStartDayValue = parseInt(data + "");
                var tenantStartDayConfig = tenantStartDayValue == 6 ? 0 : tenantStartDayValue + 1;
                this._settingsService.setStartDayToCookie(tenantStartDayConfig  + "");
            }, err => {}
        );

        this.getLogo();

        this.authService.getExpiredUserSeconds().subscribe((seconds) => {
            if(seconds != null){
                if(seconds < 0) {
                    this.authService.logout();
                }
                else {
                    this.idle.setIdle(seconds);
                    this.idle.setTimeout(60); //One minute
                }

                this.idle.onIdleEnd.subscribe(() => {
                    this.idleState = 'No longer idle.';
                    this.reset();
                });
                this.idle.onTimeout.subscribe(() => {
                this.idleState = 'Automatically logging you out...';
                this.timedOut = true;
                this.authService.logout();
                });
                this.idle.onIdleStart.subscribe(() => {
                    this.isIdle = true;
                });
                this.idle.onTimeoutWarning.subscribe((countdown) => this.idleState = 'You will be automatically logged out in ' + countdown + ' seconds!');
                this.keepalive.interval(5);

                this.keepalive.onPing.subscribe(() => this.lastPing = new Date());

                this.reset();
            }
        });

        this.username = '';
        this.userPermissions = [];
        this.tenantFeatures = [];
        this.logoFound = false;
    }

    reset() {
        this.idle.watch();
        this.timedOut = false;
        this.isIdle = false;
      }

    pinToggle() {
        if(this.pinned != true) this.pinned = true;
        else this.pinned = false;
    }

    backdropClick() {
        if(this.pinned = true) this.disableClose = true;
        else this.disableClose = false;
    }

    getLogo() {
        // TODO: Once Auth0 integration is more solid, uncomment this block of code (and remove the temporary logic)
        // this._settingsService.getTenantSettings().subscribe(
        //     data => {
        //         this.getImage(data['siteLogoPath']);
        //     },
        //     err => console.error(err)
        // );
    }

    getImage(filename) {
        // TODO: Once Auth0 integration is more solid, uncomment this block of code (and remove the temporary logic)
        // this._settingsService.getTenantConfigImage(filename).subscribe(
        //     data => {
        //         if (data['response'] != null) {
        //             this.logoFound = true;
        //             document.getElementsByTagName('img')[0].src = data['response'];
        //         } else {
        //             this.logoFound = false;
        //         }
        //     },
        //     err => console.error(err)
        // );
    }

    goToLink(url: string) { // This closes the sidenav and redirects with the router rather than an href
        var nextPageSlug = "/" + url;
        var userCanNavigate: boolean = this.authGuard.urlPermissionValidation(nextPageSlug, this.userPermissions, this.tenantFeatures);
	
        if (userCanNavigate) {
            this.router.navigateByUrl(url);
			return true;
        }
    }
}

