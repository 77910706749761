import { BrowserModule, Title } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { HttpClientModule } from "@angular/common/http";

// Authentication/Guard imports
import { CookieModule } from "ngx-cookie";
import { AuthGuard } from "./guards/auth/auth.guard";
import { AdminGuard } from "./guards/admin/admin.guard";
import { HomeGuard } from "./guards/home/home.guard";
import { GlobalService } from "./api/globalVars";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { A11yModule } from "@angular/cdk/a11y";
import { NumbersOnlyDirective } from "./directives/numbers-only.directive";
import { GooglePlacesAutocompleteDirective } from "./directives/google-places-autocomplete.directive";

// Application-specific DateAdapter
import { DateAdapter } from "@angular/material";
import { ProSightDateAdapter } from "./extra/prosight-date-adapter";

// Material/Chart/Calendar Imports
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatToolbarModule, MatSortModule } from "@angular/material";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatDividerModule } from "@angular/material/divider";
import { MatListModule } from "@angular/material/list";
import { MatTableModule } from "@angular/material/table";
import { MatSelectModule } from "@angular/material/select";
import { MatInputModule } from "@angular/material/input";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatMenuModule } from "@angular/material/menu";
import { MatCardModule } from "@angular/material/card";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatDialogModule } from "@angular/material/dialog";
import { MatTabsModule } from "@angular/material/tabs";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule } from "@angular/material";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatRadioModule } from "@angular/material/radio";
import { MatChipsModule } from "@angular/material/chips";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { ChartModule } from "angular2-chartjs";
import { CalendarModule } from "angular-calendar";
import { DragDropModule } from '@angular/cdk/drag-drop';

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { FooterComponent } from "./footer/footer.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { BreadcrumbNavComponent } from "./breadcrumb-nav/breadcrumb-nav.component";

import { ExpensesComponent } from "./expenses/expenses.component";
import { ExpenseReportDialogComponent } from "./expenses/expense-report-dialog/expense-report-dialog.component";
import { ExpensesMoveDialogComponent } from "./expenses/expense-move-dialog/expense-move-dialog.component";
import { ExpensesApprovalComponent } from "./expenses/expenses-approval/expenses-approval.component";
import { ExpensePolicyConfirmDialogComponent } from "./expenses/expense-policy-confirm-dialog/expense-policy-confirm-dialog.component";

import { ManagementComponent } from "./management/management.component";
import { ManagementClientsComponent } from "./management/management-clients/management-clients.component";
import { ManagementDashboardComponent } from "./management/management-dashboard/management-dashboard.component";
import { ManagementHrComponent } from "./management/management-hr/management-hr.component";
import { ManagementExpensesComponent } from "./management/management-expenses/management-expenses.component";
import { ManagementClassesServicesComponent } from "./management/management-classes-services/management-classes-services.component";
import { ManagementProjectsComponent } from "./management/management-projects/management-projects.component";
import { ManagementSettingComponent } from "./management/management-setting/management-setting.component";
import { ManagementTasksStatusComponent } from "./management/management-tasks/management-tasks-status.component";
import { ManagementTasksTypeComponent } from "./management/management-tasks/management-tasks-type/management-tasks-type.component";

import { TenantComponent } from "./tenant/tenant.component";
import { TenantAdminInformationComponent } from "./tenant/tenant-admin-information/tenant-admin-information.component";
import { TenantEmailVerificationComponent } from "./tenant/tenant-email-verification/tenant-email-verification.component";
import { TenantInformationComponent } from "./tenant/tenant-information/tenant-information.component";
import { TenantCustomizationComponent } from "./tenant/tenant-customization/tenant-customization.component";
import { TenantSignupComponent } from "./tenant/tenant-signup/tenant-signup.component";

import { TimeoffComponent } from "./timeoff/timeoff.component";
import { TeamCalendarComponent } from "./timeoff/team-calendar/team-calendar.component";
import { TimeoffEntryDialogComponent } from "./timeoff/timeoff-entry-dialog/timeoff-entry-dialog.component";
import { TimeoffApprovalComponent } from "./timeoff/timeoff-approval/timeoff-approval.component";
import { TimeoffRejectDialogComponent } from "./timeoff/timeoff-approval/timeoff-reject-dialog/timeoff-reject-dialog.component";

import { TimesheetComponent } from "./timesheet/timesheet.component";
import { TimesheetDialogComponent } from "./timesheet/timesheet-dialog/timesheet-dialog.component";
import { TimesheetApprovalComponent } from "./timesheet/timesheet-approval/timesheet-approval.component";
import { TimesheetApprovalDialogComponent } from "./timesheet/timesheet-approval/timesheet-approval-dialog/timesheet-approval-dialog.component";
import { TimesheetApprovalSendRemindersDialogComponent } from "./timesheet/timesheet-approval/timesheet-approval-send-reminders-dialog/timesheet-approval-send-reminders-dialog.component";
import { ExpensesDetailDialogComponent } from "./expenses/expenses-detail-dialog/expenses-detail-dialog.component";
import { TimesheetDenialDialogComponent } from "./timesheet/timesheet-approval/timesheet-denial-dialog/timesheet-denial-dialog.component";

import { ReportContainerComponent } from "./report-container/report-container.component";
import { ExpenseReportComponent } from "./report-container/expense-report/expense-report.component";
import { PTOReportComponent } from "./report-container/pto-report/pto-report.component";
import { StatusReportComponent } from "./report-container/status-report/status-report.component";
import { TimeoffReportComponent } from "./report-container/timeoff-report/timeoff-report.component";

import { DeleteConfirmationDialogComponent } from "./extra/delete-confirmation-dialog/delete-confirmation-dialog.component";
import { NameTableComponent } from "./extra/name-table/name-table.component";
import { ManagementExpensesCategoryDialogComponent } from "./management/management-expenses/management-expenses-category-dialog/management-expenses-category-dialog.component";
import { ManagementExpensesPolicyDialogComponent } from "./management/management-expenses/management-expenses-policy-dialog/management-expenses-policy-dialog.component";
import { ManagementClientsDialogComponent } from "./management/management-clients/management-clients-dialog/management-clients-dialog.component";
import { ManagementClassDialogComponent } from "./management/management-classes-services/management-class-dialog/management-class-dialog.component";
import { ManagementHrTitlePositionsComponent } from "./management/management-hr/management-hr-title-positions/management-hr-title-positions.component";
import { ManagementProjectDialogComponent } from "./management/management-projects/management-project-dialog/management-project-dialog.component";
import { ManagementServiceDialogComponent } from "./management/management-classes-services/management-service-dialog/management-service-dialog.component";
import { ManagementHrInviteDialogComponent } from "./management/management-hr/management-hr-invite-dialog/management-hr-invite-dialog.component";
import { ManagementHrEmployeeDialogComponent } from "./management/management-hr/management-hr-employee-dialog/management-hr-employee-dialog.component";
import { ManagementTimeoffComponent } from "./management/management-timeoff/management-timeoff.component";
import { ManagementTimeoffCategoryDialogComponent } from "./management/management-timeoff/management-timeoff-category-dialog/management-timeoff-category-dialog.component";
import { ManagementTimeoffBlackOutDialogComponent } from "./management/management-timeoff/management-timeoff-blackout-dialog/management-timeoff-blackout-dialog.component";
import { ManagementTimeoffHolidayDialogComponent } from "./management/management-timeoff/management-timeoff-holiday-dialog/management-timeoff-holiday-dialog.component";
import { ManagementTimeoffUserDialogComponent } from "./management/management-timeoff/management-timeoff-user-dialog/management-timeoff-user-dialog.component";
import { QuickbooksIntegrationComponent } from "./management/integrations/quickbooks-integration/quickbooks-integration.component";
import { OrderEventsPipe } from "./pipes/order-events.pipe";
import { TransformUTCToLocalDate } from "./pipes/timezone.pipe";
import { OrderTimeEntriesPipe } from "./pipes/order-timesheets.pipe";
import { CopyHoursDialogComponent } from "./timesheet/copy-hours-dialog/copy-hours-dialog.component";
import { ManagementHrGroupDialogComponent } from "./management/management-hr/management-hr-group-dialog/management-hr-group-dialog.component";
import { ManagementTasksDialogComponent } from "./management/management-tasks/management-tasks-dialog/management-tasks-dialog.component";
import { ManagementTasksTypeDialogComponent } from "./management/management-tasks/management-tasks-type-dialog/management-tasks-type-dialog.component";
import { ProjectStatusDialogComponent } from "./management/management-projects/management-project-status-dialog/project-status-dialog.component";
import { HomeComponent } from "./home/home.component";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";

import { ProjectPortfoliosComponent } from "./project-portfolios/project-portfolios.component";
import { ProjectPortfoliosDetailsComponent } from "./project-portfolios/project-portfolios-details/project-portfolios-details.component";
import { ProjectPortfoliosDialogComponent } from "./project-portfolios/project-portfolios-dialog/project-portfolios-dialog.component";
import { ProjectPortfolioRelationshipDialogComponent } from "./project-portfolios/project-portfolio-relationship-dialog/project-portfolio-relationship-dialog.component";
import { ProjectsComponent } from "./projects/projects.component";
import { WorkItemComponent } from "./work-item/work-item.component";
import { ProjectsPageComponent } from "./projects/project-page/projects-page.component";
import { TasksPageComponent } from "./projects/task-page/task-page.component";
import { KanbanPageComponent } from "./kanban-board/kanban-page/kanban-page.component";
import { TaskGroupComponent } from "./kanban-board/kanban-page/task-group/task-group.component";
import { ProjectsTaskDialogComponent } from "./projects/projects-task-dialog/projects-task-dialog.component";
import { SprintDialogComponent } from "./kanban-board/sprint-dialog/sprint-dialog.component";

import { QBOAuthRecieverComponent } from "./management/integrations/QBOAuthReciever/QBOAuthRecieverComponent.component";
import { JwtTesterComponent } from "./jwt-tester/jwt-tester.component";
import { KanbanBoardComponent } from "./kanban-board/kanban-board.component";

import { NgIdleKeepaliveModule } from "@ng-idle/keepalive";
import { MomentModule } from "ngx-moment";

@NgModule({
	declarations: [
		// MAIN
		AppComponent,
		FooterComponent,
		DashboardComponent,
		NumbersOnlyDirective,
		GooglePlacesAutocompleteDirective,
		BreadcrumbNavComponent,

		// EXPENSES
		ExpensesComponent,
		ExpenseReportDialogComponent,
		ExpensesDetailDialogComponent,
		ExpensesMoveDialogComponent,
		ExpensePolicyConfirmDialogComponent,
		ExpensesApprovalComponent,

		// INTEGRATIONS
		QuickbooksIntegrationComponent,

		// MANAGEMENT
		ManagementComponent,
		ManagementClientsComponent,
		ManagementExpensesComponent,
		ManagementClassesServicesComponent,
		ManagementHrComponent,
		ManagementExpensesCategoryDialogComponent,
		ManagementExpensesPolicyDialogComponent,
		ManagementClientsDialogComponent,
		ManagementClassDialogComponent,
		ManagementDashboardComponent,
		ManagementProjectsComponent,
		ManagementSettingComponent,
		ManagementTasksStatusComponent,
		ManagementTasksDialogComponent,
		ManagementTasksTypeComponent,
		ManagementTasksTypeDialogComponent,
		ProjectStatusDialogComponent,

		// TENANT
		TenantComponent,
		TenantAdminInformationComponent,
		TenantEmailVerificationComponent,
		TenantInformationComponent,
		TenantCustomizationComponent,
		TenantSignupComponent,

		// TIMEOFF
		TimeoffComponent,
		TeamCalendarComponent,
		TimeoffEntryDialogComponent,
		TimeoffApprovalComponent,
		TimeoffRejectDialogComponent,

		// TIMESHEET
		TimesheetComponent,
		TimesheetDialogComponent,
		CopyHoursDialogComponent,
		TimesheetApprovalComponent,
		TimesheetApprovalDialogComponent,
		TimesheetApprovalSendRemindersDialogComponent,
		TimesheetDenialDialogComponent,
		OrderTimeEntriesPipe,

		// REPORTS
		ReportContainerComponent,
		ExpenseReportComponent,
		PTOReportComponent,
		StatusReportComponent,
		TimeoffReportComponent,

		DeleteConfirmationDialogComponent,
		NameTableComponent,
		ManagementHrTitlePositionsComponent,
		ManagementProjectDialogComponent,
		ManagementServiceDialogComponent,
		ManagementHrInviteDialogComponent,
		ManagementHrEmployeeDialogComponent,
		ManagementTimeoffComponent,
		ManagementTimeoffCategoryDialogComponent,
		ManagementTimeoffBlackOutDialogComponent,
		ManagementTimeoffHolidayDialogComponent,
		ManagementTimeoffUserDialogComponent,
		OrderEventsPipe,
		TransformUTCToLocalDate,
		CopyHoursDialogComponent,
		QuickbooksIntegrationComponent,
		ManagementHrGroupDialogComponent,
		ProjectPortfoliosDetailsComponent,
		ProjectPortfoliosDialogComponent,
		ProjectPortfolioRelationshipDialogComponent,

		ProjectPortfoliosComponent,
		ProjectsComponent,
		WorkItemComponent,
		ProjectsPageComponent,
		TasksPageComponent,
		KanbanPageComponent,
		TaskGroupComponent,
		ProjectsTaskDialogComponent,
		SprintDialogComponent,
		QBOAuthRecieverComponent,
		JwtTesterComponent,
		KanbanBoardComponent,
		HomeComponent,
		PageNotFoundComponent,
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		HttpClientModule,
		NgIdleKeepaliveModule.forRoot(),
		MomentModule,
		CookieModule.forRoot(),
		CalendarModule.forRoot(),
		FormsModule,
		A11yModule,
		ReactiveFormsModule,

		// Router
		RouterModule.forRoot([
			{ path: "", redirectTo: "home", pathMatch: "full" },
			{
				path: "home",
				component: HomeComponent,
				canActivate: [HomeGuard],
				data: {title: 'Home - ProSight'},
			},
			{
				path: "404",
				component: PageNotFoundComponent,
				canActivate: [AuthGuard],
				data: {title: '404 Error - ProSight'},
			},
			{
				path: "time-entry",
				component: TimesheetComponent,
				canActivate: [AuthGuard],
				data: {title: 'Time Entry - ProSight'},
			},
			{
				path: "projects",
				component: ProjectsComponent,
				canActivate: [AuthGuard],
				data: {title: 'Projects - ProSight'},
			},
			{
				path: "work-item",
				component: WorkItemComponent,
				canActivate: [AuthGuard],
				data: {title: 'Work Item - ProSight'},
			},
			{
				path: "kanban-board",
				component: KanbanBoardComponent,
				canActivate: [AuthGuard],
				data: {title: 'Kanban Board - ProSight'},
			},
			{
				path: "project-portfolios",
				component: ProjectPortfoliosComponent,
				canActivate: [AuthGuard, AdminGuard],
				data: {title: 'Project Portfolios - ProSight'},
			},
			{
				path: "time-off",
				component: TimeoffComponent,
				canActivate: [AuthGuard],
				data: {title: 'Time Off Calendar - ProSight'},
			},
			{
				path: "team-calendar",
				component: TeamCalendarComponent,
				canActivate: [AuthGuard],
				data: {title: 'Team Calendar - ProSight'},
			},
			{
				path: "expenses",
				component: ExpensesComponent,
				canActivate: [AuthGuard],
				data: {title: 'Expenses - ProSight'},
			},
			{
				path: "approval",
				canActivate: [AuthGuard, AdminGuard],
				children: [
					{
						path: "",
						redirectTo: "time-entry-approval",
						pathMatch: "full",
					},
					{
						path: "time-entry-approval",
						component: TimesheetApprovalComponent,
						canActivate: [AuthGuard, AdminGuard],
						data: {title: 'Time Entry Approval - ProSight'},
					},
					{
						path: "time-off-approval",
						component: TimeoffApprovalComponent,
						canActivate: [AuthGuard, AdminGuard],
						data: {title: 'Time Off Approval - ProSight'},
					},
					{
						path: "expenses-approval",
						component: ExpensesApprovalComponent,
						canActivate: [AuthGuard, AdminGuard],
						data: {title: 'Expenses Approval - ProSight'},
					},
				],
				data: {title: 'Approvals - ProSight'},
			},
			{
				path: "settings",
				component: ManagementSettingComponent,
				canActivate: [AuthGuard],
				data: {title: 'Settings - ProSight'},
			},
			{
				path: "administration",
				component: ManagementComponent,
				canActivate: [AuthGuard, AdminGuard],
				data: {title: 'Administration - ProSight'},
			},
			{
				path: "dashboard",
				component: ManagementDashboardComponent,
				canActivate: [AuthGuard, AdminGuard],
				data: {title: 'Dashboard - ProSight'},
			},
			{
				path: "reports",
				component: ReportContainerComponent,
				canActivate: [AuthGuard],
				data: {title: 'Reports - ProSight'},
			},
			{
				path: "qbOAuthReciever",
				component: QBOAuthRecieverComponent,
				canActivate: [AuthGuard],
			},
		]),

		ChartModule,

		// Material Imports
		BrowserAnimationsModule,
		MatToolbarModule,
		MatSidenavModule,
		MatIconModule,
		MatButtonModule,
		MatDialogModule,
		MatDividerModule,
		MatListModule,
		MatTableModule,
		MatSelectModule,
		MatInputModule,
		MatButtonToggleModule,
		MatMenuModule,
		MatCardModule,
		MatGridListModule,
		MatCheckboxModule,
		MatProgressSpinnerModule,
		MatSnackBarModule,
		MatDialogModule,
		MatTabsModule,
		MatExpansionModule,
		MatPaginatorModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatTooltipModule,
		MatSortModule,
		MatSlideToggleModule,
		MatRadioModule,
		MatChipsModule,
		MatAutocompleteModule,
		DragDropModule,
		MatProgressBarModule
	],
	providers: [
		{ provide: DateAdapter, useClass: ProSightDateAdapter },
		Title,
	],
				
	bootstrap: [AppComponent],
	entryComponents: [
		TimesheetDialogComponent,
		ExpenseReportDialogComponent,
		TimesheetApprovalDialogComponent,
		TimesheetDenialDialogComponent,
		TimesheetApprovalSendRemindersDialogComponent,
		ExpensesDetailDialogComponent,
		ExpensesMoveDialogComponent,
		DeleteConfirmationDialogComponent,
		TimeoffEntryDialogComponent,
		TimeoffRejectDialogComponent,
		ManagementExpensesCategoryDialogComponent,
		ManagementExpensesPolicyDialogComponent,
		ManagementClientsDialogComponent,
		ManagementClassDialogComponent,
		ManagementHrTitlePositionsComponent,
		ManagementProjectDialogComponent,
		ManagementServiceDialogComponent,
		ManagementTimeoffCategoryDialogComponent,
		ManagementTimeoffBlackOutDialogComponent,
		ManagementTimeoffHolidayDialogComponent,
		ManagementHrInviteDialogComponent,
		ManagementHrEmployeeDialogComponent,
		CopyHoursDialogComponent,
		ManagementHrGroupDialogComponent,
		ProjectsTaskDialogComponent,
		SprintDialogComponent,
		ManagementTasksDialogComponent,
		ManagementTasksTypeDialogComponent,
		ProjectStatusDialogComponent,
		ProjectPortfoliosDetailsComponent,
		ProjectPortfoliosDialogComponent,
		ProjectPortfolioRelationshipDialogComponent,
		ExpensePolicyConfirmDialogComponent,
	], // Any dialog components need to be added to this array
})
export class AppModule {}
