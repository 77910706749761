/// <reference types="@types/googlemaps" />

import { Component, OnInit, Inject, NgZone } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { FormControl, Validators, FormGroup, AbstractControl } from '@angular/forms';
import { ExpensesService } from '../../api/expenses.service';
import { ExpensePolicyConfirmDialogComponent } from '../expense-policy-confirm-dialog/expense-policy-confirm-dialog.component';
import { timingSafeEqual } from 'crypto';

@Component({
	selector: 'app-expenses-detail-dialog',
	templateUrl: './expenses-detail-dialog.component.html',
	styleUrls: ['./expenses-detail-dialog.component.scss']
})
export class ExpensesDetailDialogComponent implements OnInit {


	merchant: FormControl = new FormControl({ value: this.expenseData['merchantName'], disabled: this.expenseData['isNotEditable'] }, [Validators.required]);
	date: FormControl = new FormControl({ value: this.expenseData['date'], disabled: this.expenseData['isNotEditable'] }, [Validators.required]);
	amount: FormControl = new FormControl({ value: this.expenseData['amount'] != null ? this.expenseData['amount'].toFixed(2) : null, disabled: this.expenseData['isNotEditable'] }, [Validators.min(.01)]);
	categoryId: FormControl = new FormControl({ value: this.expenseData['categoryId'], disabled: this.expenseData['isNotEditable'] }, [Validators.required]);
	description: FormControl = new FormControl({ value: this.expenseData['description'], disabled: this.expenseData['isNotEditable'] });
	reimbursable: FormControl = new FormControl({ value: this.expenseData['isReimbursable'], disabled: this.expenseData['isNotEditable'] });
	billable: FormControl = new FormControl({ value: this.expenseData['isBillable'], disabled: this.expenseData['isNotEditable'] });
	origin: FormControl = new FormControl();
	destination: FormControl = new FormControl();
	selectedFile = <File>null;
	selectedCategories = this.expenseData['categories'];
	fileName: string = null;
	isMileage: boolean = false;
	receiptFile;
	distanceValue: number = 0;
	expenseAge: number = 0;
	cost = '0.00';
	rate;
	error: string;
	errorDisplay: string;
	stops = [];
	stop;
	form: FormGroup = new FormGroup({});
	addresses = [];
	origins = [];
	destinations = [];
	locationsData = [];
	isMileageDataLoading: boolean = false;
	updatedMileage: boolean = false;
	hasViolation: boolean = false;
	urlToUploadedReceipt = '';
	isClicked: boolean = false;

	constructor(private _expenseService: ExpensesService,
		public dialogRef: MatDialogRef<ExpensesDetailDialogComponent>,
		private zone: NgZone,
		public dialog: MatDialog,
		@Inject(MAT_DIALOG_DATA) public expenseData) {
		this.rate = this.expenseData['rate'].toFixed(3);


		if (this.expenseData['mileage']) {
			this.isMileage = true;
			this.distanceValue = parseFloat(this.expenseData['mileage'].toFixed(3));
			this.cost = this.expenseData['amount'];
			this.rate = Number(this.expenseData['amount'] / this.expenseData['mileage']).toFixed(3);
		}
	}

	ngOnInit() {
		if (this.expenseData['stops'] != undefined && this.expenseData['stops'].length > 0) {
			for (var i = 0; i < this.expenseData['stops'].length; i++) {
				var addr1 = this.expenseData['stops'][i].locationName != "" ? this.expenseData['stops'][i].locationName + ', ' : "";
				var addr3 = this.expenseData['stops'][i].city != "" ? this.expenseData['stops'][i].city + ', ' : "";

				var fullName = addr1 + addr3 + this.expenseData['stops'][i].country;
				var key = "";
				if (this.expenseData['stops'][i].position == 0) {
					this.origin.setValue(fullName);
					key = "origin";
				}
				else if (this.expenseData['stops'][i].position == this.expenseData['stops'].length - 1) {
					this.destination.setValue(fullName);
					key = "destination";
				}
				else {
					var stopLocation = this.expenseData['stops'][i].position - 1;
					key = "stop" + stopLocation;

					this.addStop(key, fullName);
				}
				this.locationsData.push({
					country: this.expenseData['stops'][i].country,
					key: key,
					locality: this.expenseData['stops'][i].city,
					miles: this.expenseData['stops'][i].miles,
					postal_code: this.expenseData['stops'][i].postCode,
					id: this.expenseData['stops'][i].id,
					previousEntry: this.expenseData['stops'][i]
				});
			}
		}
	}


	onKeyCategories(value) {
		this.selectedCategories = this.filterCategories(value);
	}

	filterCategories(value: string) {
		let filter = value.toLowerCase();
		return this.expenseData['categories'].filter(option => option['name'].toLowerCase().includes(filter));
	}

	//Sets focus of the combobox inputs on click
	setFocus(inputId: string) {
		if (document.getElementById(inputId) != null) {
			document.getElementById(inputId).focus();
		}
	}

	addStop(stopNumber = 'stop' + this.stops.length, fullName = null) {
		let stop = {
			stopNumber: stopNumber,
			address: ''
		};
		var obj = new Object();
		obj[stop.stopNumber] = new FormControl(stop.address);
		this.stops.push(stop);
		this.form.addControl(stop.stopNumber, obj[stop.stopNumber]);
		//Adds the name of what the address for this new item is
		if (fullName != null) {
			this.form.controls[stop.stopNumber].setValue(fullName);
		}
	}

	setGoogleMapsLocation(locationKey, locationData) {
		this.zone.run(() => {
			this.addUpdateLocations(locationKey, locationData);
			if (locationKey === 'origin') {
				this.origin.setValue(locationData.formatted_address);
			} else if (locationKey === 'destination') {
				this.destination.setValue(locationData.formatted_address);
			} else {
				this.form['controls'][locationKey].setValue(locationData.formatted_address);
			}
		});
	}

	addUpdateLocations(locationKey, locationData) {
		if (this.locationsData.length > 0) {
			var foundLocation = this.locationsData.find(x => x.key == locationKey);
			if (foundLocation != undefined) {
				foundLocation["name"] = locationData.name;
				foundLocation["key"] = locationKey;
				foundLocation["previousEntry"] = null;
				for (var j = 0; j < locationData.address_components.length; j++) {
					foundLocation[locationData.address_components[j].types[0]] = locationData.address_components[j].long_name;
				}

			}
			else {
				var location = {};
				location["name"] = locationData.name;
				location["key"] = locationKey;
				for (var j = 0; j < locationData.address_components.length; j++) {
					location[locationData.address_components[j].types[0]] = locationData.address_components[j].long_name;
				}
				this.locationsData.push(location);
			}
		}
		else {
			var location = {};
			location["name"] = locationData.name;
			location["key"] = locationKey;
			for (var j = 0; j < locationData.address_components.length; j++) {
				location[locationData.address_components[j].types[0]] = locationData.address_components[j].long_name;
			}
			this.locationsData.push(location);
		}
	}

	eventFire(el, etype) {
		if (el.fireEvent) {
			el.fireEvent('on' + etype);
		} else {
			var evObj = document.createEvent('Events');
			evObj.initEvent(etype, true, false);
			el.dispatchEvent(evObj);
		}
	}

	getIsMileage() {
		if (this.isMileage) {
			return '1';
		}
		return '0';
	}

	refresh() {
		return '0';
	}

	closeDialog() {
		this.dialogRef.close();
	}

    /**
	 * Checks the required form fields for valid input and returns either true
	 * (indicating valid form entry) or false (indicating one or more invalid)
	 * fields in the form.
	 */
	getFormValidationState() {
		if (this.merchant.value == undefined || this.date.value == undefined || this.categoryId.value == undefined) {
			return false;
		}
		return true;
	}

	validateData() {
		if (this.merchant.value == undefined || this.merchant.value == '') {
			this.error = 'Merchant';
			this.errorDisplay = this.error + ' is required. ';
			return false;
		}
		if (this.date.value == undefined) {
			this.error = 'Date';
			this.errorDisplay = this.error + ' is required. ';
			return false;
		}
		if (this.categoryId.value == undefined) {
			this.error = 'Category';
			this.errorDisplay = this.error + ' is required. ';
			return false;
		}
		if (!this.isMileage && this.amount.value == undefined) {
			this.error = 'Amount';
			this.errorDisplay = this.error + ' is required. ';
			return false;
		}
		if (this.isMileage && (Number(this.cost) == undefined || Number(this.cost) <= 0)) {
			this.error = 'Mileage';
			this.errorDisplay = this.error + ' is required. ';
			return false;
		}
		else {
			this.errorDisplay = '';
			return true;
		}

		return true;
	}

	openExpensePolicyConfirmDialog(data) {
		let dialog = this.dialog.open(ExpensePolicyConfirmDialogComponent, {
			width: '585px',
			height: 'auto',
		});
		dialog.afterClosed().subscribe(selection => {
			if (selection) {
				this.dialogRef.close(data);
			}
			else {

			}
		});
	}

	getAge(date1, date2) {
		// To calculate the time difference of two dates
		var Difference_In_Time = date2.getTime() - date1.getTime();

		// To calculate the no. of days between two dates
		var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
		return Difference_In_Days
	}


	save() {
		this.clearError();

		if (this.validateData()) {
			if (!this.isMileage) {
				if (!this.amount.hasError('required') && !this.amount.hasError('min')) {
					this.expenseData['merchantName'] = this.merchant.value;
					this.expenseData['date'] = this.date.value;
					this.expenseData['amount'] = Number(this.amount.value);
					this.expenseData['categoryId'] = this.categoryId.value;
					this.expenseData['description'] = this.description.value;
					this.expenseData['isReimbursable'] = this.reimbursable.value;
					this.expenseData['isBillable'] = this.billable.value;
					if (this.expenseData['hasReceipt'] == 1) {
						this.expenseData['receiptFile'] = document.getElementsByTagName('img')[1].src;
					}
					//Resets the mileage components if switching from mileage to expense
					this.expenseData['isMileage'] = this.isMileage;
					this.expenseData['mileage'] = 0;
					this.expenseAge = Math.abs(this.getAge(new Date(this.expenseData['date']), new Date));
					if (this.expenseData['amount'] > this.expenseData['reportPolicySettings']['maxAmount']
						|| this.expenseAge > this.expenseData['reportPolicySettings']['maxAge']
						|| (this.expenseData['reportPolicySettings']['eReceipt'] == true && this.expenseData['hasReceipt'] != true)) {
						this.openExpensePolicyConfirmDialog(this.expenseData);
					}
					else {
						this.dialogRef.close(this.expenseData);
					}
				}
			}
			else if (this.distanceValue != 0) {
				this.expenseData['isMileage'] = this.isMileage;
				this.expenseData['merchantName'] = this.merchant.value;
				this.expenseData['date'] = this.date.value;
				this.expenseData['categoryId'] = this.categoryId.value;
				this.expenseData['description'] = this.description.value;
				this.expenseData['isReimbursable'] = this.reimbursable.value;
				this.expenseData['isBillable'] = this.billable.value;
				this.expenseData['mileage'] = this.distanceValue;
				this.expenseData['amount'] = Number(this.cost);
				if (this.updatedMileage)
					this.expenseData['locations'] = this.locationsData;
				this.expenseAge = Math.abs(this.getAge(new Date(this.expenseData['date']), new Date));
				if (this.expenseData['amount'] > this.expenseData['reportPolicySettings']['maxAmount']
					|| this.expenseAge > this.expenseData['reportPolicySettings']['maxAge']
					|| (this.expenseData['reportPolicySettings']['eReceipt'] == true && this.expenseData['hasReceipt'] != true)) {
					this.openExpensePolicyConfirmDialog(this.expenseData);
				}
				else {
					this.dialogRef.close(this.expenseData);
				}
			} 
			else {
				this.error = 'Mileage';
				this.errorDisplay = this.error + ' is required';
			}
		}

	}

	getErrorMessage(event) {
		return this.amount.hasError('required') ? 'You must enter a value. ' :
			this.amount.hasError('min') ? ' Not a valid amount. ' :
				'';
	}

	onFileSelected(event) {
		var urlToUploadedReceipt = ''
		if (event.target.files && event.target.files[0]) {
			var reader = new FileReader();
			reader.readAsDataURL(event.target.files[0]);
			reader.onload = (event) => {
				this.urlToUploadedReceipt = reader.result.toString();
			}
		}
		this.selectedFile = event.target.files[0];
		this.fileName = this.selectedFile.name;

		let nameArray = this.selectedFile.name.split('.');
		let ext = '.' + nameArray[nameArray.length - 1];
		let mime = this.selectedFile.type;
		this.expenseData['receiptFileMimeType'] = mime;
		this.expenseData['receiptFileExtension'] = ext;
		this.makearray(this.selectedFile);
		this.expenseData['hasReceipt'] = 1;
	}

	makearray(file) {
		var preview = document.getElementsByTagName('img')[1];
		var reader = new FileReader();
		reader.addEventListener('load', function () {
			// TODO: Add support for case where reader.result is an ArrayBuffer
			if (typeof reader.result === 'string') {
				preview.src = reader.result;
			}
		}, false);
		if (file) {
			reader.readAsDataURL(file);
		}
	}

	showReceiptPriorToUpload() {
		let pdfWindow = window.open('');
		pdfWindow.document.write('<iframe width=\'100%\' height=\'100%\' src=\'' + this.urlToUploadedReceipt + '\'></iframe>');
	}

	showReceipt() {
		this._expenseService.getExpenseReceipt(this.expenseData['id']).subscribe(data => {
			let pdfWindow = window.open('');
			if (data['response'] == undefined) {
				pdfWindow.document.write('<iframe width=\'100%\' height=\'100%\' src=\'' +
					document.getElementsByTagName('img')[1].src + '\'></iframe>');
				this.isClicked = false;
			} else {
				pdfWindow.document.write('<iframe width=\'100%\' height=\'100%\' src=\'' + data['response'] + '\'></iframe>');
				this.isClicked = false;
			}
		});
	}

	toggleIsMileage() {
		if (this.isMileage == false) {
			this.isMileage = true;
		} else {
			this.isMileage = false;
		}
	}

	async getMileage() {
		//Since getting mileage will update rate to new rate if exists
		this.rate = this.expenseData['rate'].toFixed(3);

		this.updatedMileage = true;
		this.clearError();
		this.isMileageDataLoading = true;
		var distance;
		var fullDistance = 0;

		if (this.origin.value != undefined && this.destination.value != undefined && this.origin.value != '' && this.destination.value != '') {
			var previousPosition = this.origin.value;
			this.locationsData.find(function (location) {
				return location.key == "origin";
			}).miles = 0;

			for (let stop of this.stops) {
				distance = await this.getMileageDistance(previousPosition, this.form['controls'][stop.stopNumber]['value']);
				previousPosition = this.form['controls'][stop.stopNumber]['value'];
				this.locationsData.find(function (location) {
					return location.key == stop.stopNumber;
				}).miles = parseFloat(distance);
				fullDistance = fullDistance + parseFloat(distance);
			}

			distance = await this.getMileageDistance(previousPosition, this.destination.value);
			this.locationsData.find(function (location) {
				return location.key == "destination";
			}).miles = parseFloat(distance);

			fullDistance = fullDistance + parseFloat(distance);
			let perMile: number = this.expenseData['rate'];
			this.distanceValue = parseFloat(fullDistance.toFixed(3));
			this.cost = (this.distanceValue * perMile).toFixed(2);
			this.isMileageDataLoading = false;
			this.eventFire(document.getElementById('refresh'), 'click');
		} else {
			this.error = 'Mileage';
			this.errorDisplay = 'Origin and Destination are required.';
		}
		this.origins = [];
		this.destinations = [];
	}

	getMileageDistance(start, end) {
		return new Promise(function (resolve, reject) {
			var distanceValue = 0;
			let distanceMatrixService = new google.maps.DistanceMatrixService();
			distanceMatrixService.getDistanceMatrix(
				{
					origins: [start],
					destinations: [end],
					travelMode: google.maps.TravelMode.DRIVING,
					unitSystem: google.maps.UnitSystem.IMPERIAL
				},
				(response, status) => {
					if (status.toString() === 'OK') {
						//Checks if it is a miles comparison to get rid of feet change
						if (response['rows'][0]['elements'][0]['distance']['text'].toString().split(' ')['1'] == 'mi') {
							let distance = response['rows'][0]['elements'][0]['distance']['text'].toString().split(' ')['0'];
							resolve(distance);
						}
						else {
							resolve(0);
						}
					} else {
						this.isMileageDataLoading = false;
						this.error = 'Mileage';
						this.errorDisplay = 'Could not retrieve mileage.';
						resolve(0);
					}
				}
			);
		});
	}

	clearError() {
		this.error = '';
		this.errorDisplay = '';
	}
}
