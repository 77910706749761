import { Component, OnInit, Inject } from '@angular/core';
import { TimesheetService } from '../../../api/timesheet.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormControl } from '@angular/forms';
import { AuthService } from '../../../services/auth/auth.service';
@Component({
    selector: 'app-timesheet-approval-send-reminders-dialog',
    templateUrl: './timesheet-approval-send-reminders-dialog.component.html',
    styleUrls: ['./timesheet-approval-send-reminders-dialog.component.scss']
})
export class TimesheetApprovalSendRemindersDialogComponent implements OnInit {
    users: object;
	isUsersLoading: boolean;
	auth: AuthService;
	tenantId: string;

    title = new FormControl('ProSight Time & Expense Reminder');
	body = new FormControl('This is just a reminder to enter your time and expenses.\n\n');

    constructor(private _timesheetService: TimesheetService,
        public dialogRef: MatDialogRef<TimesheetApprovalSendRemindersDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public timesheetApprovalData) { }

    ngOnInit() {

		this.getUserData();
    }

    closeDialog() {
        this.dialogRef.close();
    }

    getUserData() {
        this.isUsersLoading = true;
        this._timesheetService.getTimesheetUsers(this.timesheetApprovalData).subscribe(
            data => {
                this.users = data;
                this.isUsersLoading = false;
            },
            err => console.error(err)
        );
    }

    selectToggle(type, selectRef) {
        type ? selectRef.selectAll() : selectRef.deselectAll();
    }

    sendReminders(selectRef) {
        let returnEmails = [];
        selectRef.selectedOptions.selected.forEach(user => {
            returnEmails.push(user.value);
		});
        let returnModel = {
            emails: returnEmails,
            title: this.title.value,
            body: this.body.value
        };
        this.dialogRef.close(returnModel);
    }

}
