import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tenant-email-verification',
  templateUrl: './tenant-email-verification.component.html',
  styleUrls: ['./tenant-email-verification.component.scss']
})
export class TenantEmailVerificationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
