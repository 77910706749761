import { Component, OnInit, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { forEach } from '@angular/router/src/utils/collection';

@Component({
  selector: 'app-management-hr-group-dialog',
  templateUrl: './management-hr-group-dialog.component.html',
  styleUrls: ['./management-hr-group-dialog.component.scss']
})
export class ManagementHrGroupDialogComponent implements OnInit {
    groupData = Object();
    admin = false;
    error;

    groupName: FormControl = new FormControl(this.data['group']['name']);
    parentControl: FormControl = new FormControl(this.data['group']['parentId']);
    // timeEntry: FormControl = new FormControl(this.data['group']['systemGroupList']['0']['level'].toString());
    // timeOff: FormControl = new FormControl(this.data['group']['systemGroupList']['1']['level'].toString());
    // expense: FormControl = new FormControl(this.data['group']['systemGroupList']['2']['level'].toString());
	// management: FormControl = new FormControl(this.data['group']['systemGroupList']['3']['level'].toString());
	selectedParents = this.data['parents'];
    constructor(public dialogRef: MatDialogRef<ManagementHrGroupDialogComponent>, @Inject(MAT_DIALOG_DATA) public data) {
        this.groupData = data['group'];
    }

    ngOnInit() {
        if (this.groupData['systemGroupList']['0']['level'] == 10) {
            this.admin = true;
            // this.timeEntry.disable();
            // this.timeOff.disable();
            // this.expense.disable();
            // this.management.disable();
        }
	}

	onKeyParents(value) {
		this.selectedParents = this.filterParents(value);
	}

	filterParents(value: string) {
	  let filter = value.toLowerCase();
	  return this.data['parents'].filter(option => option['name'].toLowerCase().includes(filter));
	}

	//Sets focus of the combobox inputs on click
	setFocus(inputId:string){
		if(document.getElementById(inputId) != null){
			document.getElementById(inputId).focus();
		}
	}

    toggleAdmin() {
        if (this.admin == false) {
            this.admin = true;
            // this.timeEntry.disable();
            // this.timeOff.disable();
            // this.expense.disable();
            // this.management.disable();

        } else {
            this.admin = false;
            // this.timeEntry.enable();
            // this.timeOff.enable();
            // this.expense.enable();
            // this.management.enable();
        }
    }

    closeDialog() {
        this.dialogRef.close();
    }

    saveAndCloseDialog() {
        if (this.groupName.invalid) {
            this.error = 'Name is required';
        } else {
            this.groupData['name'] = this.groupName.value;
            this.groupData['parentId'] = this.parentControl.value;
            this.groupData['isTenantAdmin'] = this.admin;
            if (this.admin) {
                // this.groupData['systemGroupList']['0']['level'] = 10;
                // this.groupData['systemGroupList']['1']['level'] = 10;
                // this.groupData['systemGroupList']['2']['level'] = 10;
                // this.groupData['systemGroupList']['3']['level'] = 10;
                // this.groupData['permissions'] = [10, 10, 10, 10];
            } else {
                // this.groupData['systemGroupList']['0']['level'] = this.timeEntry.value;
                // this.groupData['systemGroupList']['1']['level'] = this.timeOff.value;
                // this.groupData['systemGroupList']['2']['level'] = this.expense.value;
                // this.groupData['systemGroupList']['3']['level'] = this.management.value;
                // this.groupData['permissions'] = [this.timeEntry.value == 10 ? 1 : this.timeEntry.value, this.timeOff.value == 10 ? 1 : this.timeOff.value,
                //     this.expense.value == 10 ? 1 : this.expense.value, this.management.value == 10 ? 1 : this.management.value];
            }
            // for (let systemGroup of this.groupData['systemGroupList']) {
            //     if (systemGroup['level'] == undefined) {
            //         systemGroup['level'] = 1;
            //     }
            // }
            if (this.groupData['id'] == undefined) {
                this.groupData['id'] = -1;
            }
            this.dialogRef.close(this.groupData);
        }


    }

}
