import { NativeDateAdapter } from '@angular/material';

export class ProSightDateAdapter extends NativeDateAdapter {
    
    // Overrides the built-in date adapter to set the first day of the week to Monday
    getFirstDayOfWeek(): number {
        if(document.cookie.includes("prosight-start-day="))
            return parseInt(document.cookie.split("prosight-start-day=")[1]);
        else
            return 1;
    }

}