import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
    selector: 'app-timesheet-dialog',
    templateUrl: './timesheet-dialog.component.html',
    styleUrls: ['./timesheet-dialog.component.scss']
})
export class TimesheetDialogComponent implements OnInit {
    error: String;

    constructor(public dialogRef: MatDialogRef<TimesheetDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public timesheetData) { }

    ngOnInit() {
    }

    closeDialog() {
        this.dialogRef.close();
    }

    saveAndCloseDialog() {
        if (this.timesheetData.completed == null || this.timesheetData.completed == '') {
            this.error = 'Completed work must be entered.';
            return false;
        } else {
            this.error = '';
            this.dialogRef.close({delete: false, submit: true});
        }
    }

    delete() {
        if(this.timesheetData.approvalStatusId == 2) {
            this.error = "Can''t delete an approved entry";
            this.dialogRef.close({ delete: false, submit: false });
        } else {
            this.dialogRef.close({ delete: true , submit: false});
        }
    }
}
