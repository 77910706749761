import { Component, OnInit, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
import * as moment from 'moment';
import { SetttingsService } from '../../api/setttings.service';
var cloneDeep = require('lodash.clonedeep');

@Component({
    selector: 'app-expense-report-dialog',
    templateUrl: './expense-report-dialog.component.html',
    styleUrls: ['./expense-report-dialog.component.scss']
})
export class ExpenseReportDialogComponent implements OnInit {

    date: FormControl = new FormControl(this.expenseData.reportData['periodEnd']);
    projectId: FormControl = new FormControl(this.expenseData.reportData['projectId']);
    workClassId: FormControl = new FormControl(this.expenseData.reportData['workClassId']);
    clients;
	selectedClasses: any[];
	selectedProjects: any[];
	allProjects: any[];
    pendingLoad: boolean = true;
    activeProjects = [];
    error: String;


    constructor(private _settingsService: SetttingsService, public dialogRef: MatDialogRef<ExpenseReportDialogComponent>, public snackbar: MatSnackBar,
        @Inject(MAT_DIALOG_DATA) public expenseData) { }

    ngOnInit() {
        this.pendingLoad = true;
        this.clients = this.expenseData.clients;
        this.selectedProjects = cloneDeep(this.expenseData.clients);
        this.selectedClasses = this.expenseData['classes'];
        this.onlyActiveProjects();
	}

    onlyActiveProjects() {
        let x = 0;
        let tempProjects = this.selectedProjects;
        let garbage = [];
		tempProjects.forEach((item: any) => {
            let t = 0;
            tempProjects[x].projects.forEach((item2: any) => {
                    tempProjects[x].projects = item.projects.filter(data => data.isActive === true);
                t = t + 1;
            });
            x = x + 1;
        });
        let c = 0;
        tempProjects.forEach((item: any) => {
            tempProjects = tempProjects.filter(data => data.projects.length !== 0);
        });
        this.selectedProjects = tempProjects;
    }
    
	onKeyClasses(value) {
		this.selectedClasses = this.filterClasses(value);
	}

	filterClasses(value: string) {
	  let filter = value.toLowerCase();
	  return this.expenseData['classes'].filter(option => option['name'].toLowerCase().includes(filter));
	}

	onKeyProjects(value) {
		this.selectedProjects = this.filterProjects(value);
	}

	filterProjects(value: string) {
        let filter = value.toLowerCase();

		let filteredClientsAndProjects = this.clients.filter(option => option['clientName'].toLowerCase().includes(filter));
		for(var i = 0; i < this.clients.length; i++){
			if(filteredClientsAndProjects.find(option => option['clientId'] == this.clients[i]['clientId']) == undefined){
				if(this.clients[i]['projects'] != null){
					let tempClient = cloneDeep(this.clients[i]);
					tempClient['projects'] = tempClient['projects'].filter(project => project['name'].toLowerCase().includes(filter))
					if(tempClient['projects'].length > 0){
						filteredClientsAndProjects.push(tempClient);   
					}
				}
			}
		}
		return filteredClientsAndProjects.sort((a, b) => { return (a['clientName'] > b['clientName']) ? 1 : ((b['clientName'] > a['clientName']) ? -1 : 0); });		
	}

	//Sets focus of the combobox inputs on click
	setFocus(inputId:string){
		if(document.getElementById(inputId) != null){
			document.getElementById(inputId).focus();
		}
	}

    getClients() {
        this.clients = [];
		this.selectedProjects = [];
        let result = [];
        this._settingsService.getClients().subscribe(data => {
            result = Object.keys(data).map(function (key) {
                return [Number(key), data[key]];
            });

            this.clients = [];

            for (let client of result) {
				this.clients.push(client['1']);
				this.selectedProjects.push(client['1']);
			}
            this.getProjects();
        });
    }

    getProjects() {
        this._settingsService.getProjectsByClientId(this.clients, true).subscribe(data => {
            for (let client of this.clients) {
                if (data[client['id']].length > 0)
                    client['projects'] = data[client['id']];
					this.allProjects = client['projects'];
            }
            this.pendingLoad = false;
        });
    }




    closeDialog() {
        this.dialogRef.close();
    }

    saveAndCloseDialog() {
        if (this.date.value !== null) {
            this.expenseData.reportData['periodEnd'] = new Date(JSON.stringify(moment.utc(this.date.value).endOf('isoWeek').toISOString().slice(0, 10).replace('T', ' '))).toLocaleDateString();
            this.expenseData.reportData['projectId'] = this.projectId.value;
            this.expenseData.reportData['workClassId'] = this.workClassId.value;
            this.dialogRef.close(this.expenseData.reportData);
        } else {
            //this.snackbar.open('Complete Form to Save', '', { duration: 2000 });
            this.error = 'Complete all fields to submit';
        }
    }
}
