import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatDialog, MatSnackBar, MatButtonToggleGroup } from '@angular/material';
import { ManagementTasksDialogComponent } from './management-tasks-dialog/management-tasks-dialog.component';
import { DeleteConfirmationDialogComponent } from '../../extra/delete-confirmation-dialog/delete-confirmation-dialog.component';
import { SetttingsService } from '../../api/setttings.service';

@Component({
    selector: 'app-management-tasks-status',
    templateUrl: './management-tasks-status.component.html',
    styleUrls: ['./management-tasks-status.component.scss']
})

export class ManagementTasksStatusComponent implements OnInit {

    displayedColumns = ['name', 'order', 'options', 'delete'];
    taskStatusesArray = [];
    dataSourceLoading: boolean;
    taskStatusDataSource = new MatTableDataSource();

    constructor(private _settingsService: SetttingsService,
        public dialog: MatDialog,
        public snackBar: MatSnackBar) { }

    @ViewChild(MatPaginator) paginator;
    @ViewChild(MatButtonToggleGroup) buttonToggleGroup;

    ngOnInit() {
        this.dataSourceLoading = true;
        this.getTaskStatusData();
    }

    getTaskStatusData() {
        this.taskStatusDataSource = new MatTableDataSource([]);
        this.dataSourceLoading = true;
        this._settingsService.getStatuses().subscribe(
            data => {
                if(data != null){
					let tempdata = Object.keys(data).map(i => data[i]);
                    //Code for eventual matsortcolumns
                    /*
                    this.taskStatusDataSource.sort = this.sort.first;
                    this.taskStatusDataSource.sortingDataAccessor = (item, property) => {
                        switch (property) {
                            case 'statusName': return item['statusName'];
                            case 'order': return item['order'];
                            default: return item[property];
                        }
                    };
                    */

				   tempdata = tempdata.sort(function(a, b) {
					return a.order - b.order;
					});
					this.taskStatusDataSource = new MatTableDataSource(tempdata);
                    this.taskStatusDataSource.paginator = this.paginator;
					this.dataSourceLoading = false;
                }
                else{
                    this.displayedColumns = [];
                    this.taskStatusDataSource = new MatTableDataSource(); // No task Statuses exist for tenant
                    this.dataSourceLoading = false;
                }

            }, err => {
                this.snackBar.open(err.statusText, '', { duration: 2000 });
                this.dataSourceLoading = false;
                this.taskStatusDataSource.paginator = this.paginator;
                console.error(err);
            }
        );
	}


	/* This method given a taskStatus object and a boolean value to signal if it is a delete
	   or a add/update will update the table source and refresh the task statuses table in the admin view so that
	   it is populated with the most current set of data */
	refreshTable(taskStatus){
        const index: number = this.taskStatusDataSource.data.indexOf(taskStatus);
        const length: number = this.taskStatusDataSource.data.length;
		this.taskStatusDataSource.data.splice(length);
        this.taskStatusDataSource._updateChangeSubscription();
    }

    /* Removes the taskStatus entry from the data table */
    removeFromTable(taskStatus){
        const index: number = this.taskStatusDataSource.data.indexOf(taskStatus);
        this.taskStatusDataSource.data.splice(index, 1);
        this.taskStatusDataSource._updateChangeSubscription();
    }


    getTaskStatusDataById(statusId){
        return this.taskStatusDataSource.data.find(taskStatus => taskStatus['statusId'] === statusId);
    }

    updateTaskData(taskStatus){
        this.snackBar.open('Updating Data', '', { duration: 2000 });
        this._settingsService.putStatus(taskStatus).subscribe(
            data => {
                this.refreshTable(taskStatus);                                
                this.snackBar.open('Updated', '', { duration: 2000 });
            }, err => {
                this.snackBar.open(err.statusText, '', { duration: 2000 });
            }
        );
        this.getTaskStatusData();
    }

    deleteTaskStatus(taskStatus){
        const dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
            width: '585px',
            height: 'auto'
        });
        dialogRef.afterClosed().subscribe(result => {// Check if the entry is to be deleted
            if (result !== undefined) {
                if (result['toDelete']) {
                    this.snackBar.open('Deleting Data', '', { duration: 2000 });
                    this._settingsService.deleteStatus(taskStatus.statusId).subscribe(
                        data => {
                            this.removeFromTable(taskStatus);
                            this.snackBar.open('Deleted', '', { duration: 2000 });
                        }, err => {
                            this.snackBar.open(err.statusText, '', { duration: 2000 });
                        }
                    );
                }
            }
        });
    }

    loadDialog(entryId) {
        let sendData = { newData: true };
        sendData['newData'] = true;
        let entry = new Object();

        sendData['entry'] = entry;
        sendData['entry']['isActive'] = true;

        if (entryId != 'newData') {
            sendData['entry'] = this.getTaskStatusDataById(entryId);
            sendData['newData'] = false;
        }
        const dialogRef = this.dialog.open(ManagementTasksDialogComponent, {
            width: '800px',
            height: 'auto',
            data: sendData  //This is where data is passed to dialog
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result !== undefined) {
                if (result['submit']) {
                    this.updateTaskData(result['data']);
                }
            }
        });
        this.getTaskStatusData();
    }
}
