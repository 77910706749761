import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { GlobalService } from "./app/api/globalVars";

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

let globalService = new GlobalService()
if (environment.production || globalService.currentToggle() == 'AzureDev' || globalService.currentToggle() == 'Production') {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
