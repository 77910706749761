import { Component, OnInit, ViewChild, Input, Output, EventEmitter, OnDestroy} from '@angular/core';
import { SetttingsService } from '../api/setttings.service';
import { MatPaginator, MatTableDataSource, MatDialog, MatSnackBar, MatSort, MatSelect } from '@angular/material';
import { ProjectsTaskDialogComponent } from './projects-task-dialog/projects-task-dialog.component';
import { ProjectsService } from '../api/projects.service';
import { ShareProject } from '../services/sharing/share-project.service';
import {TasksPageComponent} from './task-page/task-page.component';
import { SprintService } from '../api/sprint.service';
var async = require('async');
var cloneDeep = require('lodash.clonedeep');

@Component({
  selector: 'projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss'],
  providers: [TasksPageComponent],
})

export class ProjectsComponent implements OnInit, OnDestroy {
  taskTypes: any;
  taskStatuses: any;
  users: any;
  clients: any[];
  projects = [];
  entry: object = null;
  allFilters: object[] = [[],[],[],[],[],[],[]]; //Projects, Types, Status, Label, Assignees, Milestones
  allLabels: any[] = [];
  allMilestones: any[] = [];
  allSprints: object[] = [];
  TaskEntry: any;

  filteredProjects: object = [];
  filteredUsers: object = [];
  filteredTypes: object = [];
  filteredStatuses: object = [];

  selectedTypes: any = [];
  selectedProjects: any = [];
  selectedUsers: any = [];
  selectedLabels: any = [];
  selectedMilestones: any = [];
  selectedAssignees: any = [];
  selectedSprints: any = [];
  fullyLoaded: boolean = false;
  userHasActiveProjects: boolean = false;
  taskAdd: boolean = false;
  kanbanAdd: boolean = false;

  groupSelection: string = "projects";

  unassignedUserValue: string = "unassignedId";

  searchFilterText: string = '';
  searchFilterInputBox: string;
  userProjectsDataSource = new MatTableDataSource([]);

  priorities = [{name: "Lowest", id:1},{name: "Low", id:2},
	{name: "Medium", id:3},{name: "High", id:4},{name: "Highest", id:5}]

  @ViewChild('filterSelectProject') filterSelectProject;
  @ViewChild('filterSelectType') filterSelectType;
  @ViewChild('filterSelectStatus') filterSelectStatus;
  @ViewChild('filterSelectLabel') filterSelectLabel;
  @ViewChild('filterSelectMilestone') filterSelectMilestone;
  @ViewChild('filterSelectAssignees') filterSelectAssignees;
	projectUserMappings: any;
  currentUserId = [];

  @Output() refreshBoardAdd = new EventEmitter<any>();

  constructor(private _settingsService: SetttingsService, private _projectsService: ProjectsService,
    public dialog: MatDialog, public snackBar: MatSnackBar, private shareProject: ShareProject,
    private TasksPageComponent: TasksPageComponent, private _sprintService: SprintService) 
    { 
      this._settingsService.getUserSettings().subscribe(data => {
        if(data != null) this.currentUserId.push(data['userId']);
        this.refreshFilter(this.currentUserId, "Assignees");
      });
    }

	ngOnDestroy(): void {
		this.shareProject.reset();
	}

	ngOnInit() {
		this.shareProject.reset();
		this.fullyLoaded = false;
		this.getTaskTypesAndStatuses();
		this.getClients();
		this.getLabels();
    this.getUniqueLabels();
    this.getMilestones();
    this.getUniqueMilestones();
    this.getSprints();
	}

	onTabChange(event){
		this.clearSearchBox();
	}

	onKeyProjects(value) {
			this.selectedProjects = this.filterProjects(value);
	}

	filterProjects(value: string) {
      let filter = value.toLowerCase();

      let filteredClientsAndProjects = this.clients.filter(option => option['name'].toLowerCase().includes(filter));
      for(var i = 0; i < this.clients.length; i++){
        if(filteredClientsAndProjects.find(option => option['id'] == this.clients[i]['id']) == undefined){
            if(this.clients[i]['projects'] != null){
                let tempClient = cloneDeep(this.clients[i]);
                tempClient['projects'] = tempClient['projects'].filter(project => project['name'].toLowerCase().includes(filter))
                if(tempClient['projects'].length > 0){
                    filteredClientsAndProjects.push(tempClient);   
                }
            }
        }
      }
	  return filteredClientsAndProjects.sort((a, b) => { return (a['name'] > b['name']) ? 1 : ((b['name'] > a['name']) ? -1 : 0); });
  	}

	onKeyLabels(value) {
		this.selectedLabels = this.filterLabels(value);
	}

  onKeyMilestones(value) {
	  this.selectedMilestones = this.filterMilestones(value);
  }

  filterLabels(value: string) {
    let filter = value.toLowerCase();
    return this.allLabels.filter(option => option['name'].toLowerCase().includes(filter));
	}

	filterMilestones(value: string) {
		let filter = value.toLowerCase();
		return this.allMilestones.filter(option => option['name'].toLowerCase().includes(filter));
	}

	onKeyType(value) {
		this.selectedTypes = this.filterTypes(value);
	}

	filterTypes(value: string) {
  		let filter = value.toLowerCase();
  		return this.taskTypes.filter(option => option['typeName'].toLowerCase().includes(filter));
	}

	onKeyUsers(value) {
		this.selectedUsers = this.filterUsers(value);
	}

	filterUsers(value: string) {
  		let filter = value.toLowerCase();
  		return this.users.filter(option => option['userInfo']['fullName'].toLowerCase().includes(filter));
	}

	onKeyAssignees(value){
		this.selectedAssignees = this.filterAssignees(value);
	}

	filterAssignees(value: string) {
		let filter = value.toLowerCase();
		return this.users.filter(option => option['fullName'].toLowerCase().includes(filter));
  	}

	//Sets focus of the combobox inputs on click
	setFocus(inputId:string){
		if(document.getElementById(inputId) != null){
			document.getElementById(inputId).focus();
		}
	}

  getClients() {
    var sharedClients = this.shareProject.getClients();
    if(sharedClients == undefined){
      this.clients = [];
      this.selectedProjects = [];
      let result = [];
      this._settingsService.getClients().subscribe(data => {
        result = Object.keys(data).map(key => [Number(key), data[key]]);
        this.clients = [];

        async.forEach(result,(client,inner_callback)=>{
          this.clients.push(client['1']);
          this.selectedProjects.push(client['1']);
          inner_callback(null);
        },() => this.getProjects());
      });
    } else this.clients = sharedClients;
  }

  getSprints() {
		let result = []
		this._sprintService.getSprints().subscribe(data => {
			result = Object.keys(data).map(function (key) {
				return data[key];
			});
			result = result.sort((a, b) => (a.name > b.name) ? 1 : -1);
			this.allSprints = result;
			this.selectedSprints = result;
		});
	}

  getMilestones() {
	  let result = [];
	  this._projectsService.getAllMilestones().subscribe(data => {
		  result = Object.keys(data).map(function (key) {
			  return data[key];
		  });
		  this.allMilestones = result.sort((a, b) => (a.name > b.name) ? 1 : -1);
	  })
  }

  getUniqueMilestones() {
    let uniqueResult = [];
    this._projectsService.getAllTaskMilestones('true').subscribe(data => {
      uniqueResult = Object.keys(data).map(function (key) {
        return data[key];
      });
      this.selectedMilestones = uniqueResult.sort((a, b) => (a.name > b.name) ? 1 : -1);
    });
  }
  
  getLabels() {
    let result = [];
      this._projectsService.getAllLabels().subscribe(data => {
        result = Object.keys(data).map(function (key) {
          return data[key];
        });
        this.allLabels = result.sort((a, b) => (a.name > b.name) ? 1 : -1);
      });
  }

  getUniqueLabels() {
    let uniqueResult = [];
    this._projectsService.getAllTaskLabels('true').subscribe(data => {
      uniqueResult = Object.keys(data).map(function (key) {
        return data[key];
      });
      this.selectedLabels = uniqueResult.sort((a, b) => (a.name > b.name) ? 1 : -1);;
    });
  }

  getProjects() {
      this._settingsService.getProjectsByClientId(this.clients, true, true).subscribe(data => {
          async.forEach(this.clients,(client,inner_callback)=>{
			if (data[client['id']].length > 0) {
				client['projects'] = data[client['id']];
				if (client['projects'].length > 0) this.userHasActiveProjects = true;
			}
			inner_callback(null);
		  },() => this.shareProject.setClients(this.clients));
      for(let i in data) {
				for(let y = 0; y < data[i].length; y++){
					this.projects.push(data[i][y])
				}
			}
      });
  }

  getTaskTypesAndStatuses(){
    var count = 0;
    this._settingsService.getStatuses().subscribe(data => {
		this.taskStatuses = data;
		this.taskStatuses = this.taskStatuses.sort(function(a, b) {
			return a.ordinal - b.ordinal;
			});
        count = count + 1;
        if(count == 3) {
          this.fullyLoaded = true;
        }
    });
    this._settingsService.getTaskTypes().subscribe(data => {
        this.taskTypes = data;
		this.selectedTypes = data;
        count = count + 1;
        if(count == 3) {
          this.fullyLoaded = true;
        }
    });
    this._settingsService.getUsers(true).subscribe(data => {
		this.users = data;
		this.selectedUsers = data;
    //adding an unassigned user to filter not assigned tasks
		this.selectedUsers.push({userId: "unassignedId", fullName: "Unassigned"})
		this.selectedAssignees = this.selectedUsers.sort((a, b) => (a.fullName > b.fullName) ? 1 : -1);
        count = count + 1;
        if(count == 3) {
          this.fullyLoaded = true;
        }
    });
  }

  refreshFilter(filterItems, type, refreshForProject = false) {
    if(refreshForProject){
      this.filterSelectProject.options.forEach(x => x.value == filterItems ? x.select() : x.deselect());
      this.filterSelectAssignees.options.forEach(x => x.deselect());
      this.filterSelectType.options.forEach(x => x.deselect());
      this.filterSelectStatus.options.forEach(x => x.deselect());
      this.filterSelectLabel.options.forEach(x => x.deselect());
	  this.filterSelectMilestone.options.forEach(x => x.deselect());

      this.allFilters = [[filterItems],[],[],[],[],[],[]];
    }
    else {
      var tempFilter = Object.assign({}, this.allFilters);
      if(type == 'Projects'){
        tempFilter[0] = filterItems;
      }
      else if(type == 'Assignees'){
        tempFilter[1] = filterItems;
      }
      else if(type == 'Types'){
        tempFilter[2] = filterItems;
      }
      else if(type == 'Statuses'){
        tempFilter[3] = filterItems;
      }
      else if(type == 'Labels'){
        tempFilter[4] = filterItems;
      }
	  else if(type == 'Milestones'){
		  tempFilter[5] = filterItems;
	  }
      //Refreshes allFilters
      this.allFilters = tempFilter;
    }
  }

  projectSelected(value) {
    this.refreshFilter(value, 'Project', true);
  }

  addTask(type){
    let names = []; //array for the names for task status, task type, and priority
    let sendData = { newData: true };
    sendData['newData'] = true;
    sendData['taskTypes'] = this.taskTypes;
		sendData['taskStatuses'] = this.taskStatuses;
		sendData['users'] = this.users;
    sendData['Sprints'] = this.allSprints;
    let entry = new Object();
    sendData['entry'] = entry;
    sendData['entry']['isActive'] = true;
    sendData['entry']['labels'] = [];
	  sendData['entry']['milestones'] = [];

    const dialogRef = this.dialog.open(ProjectsTaskDialogComponent, {
        width: '800px',
        height: 'auto',
        data: sendData
    });
    dialogRef.afterClosed().subscribe(result => {
        if (result !== undefined) {
          
            if (result['submit']) { 
              var task = sendData['taskStatuses'].find(t => t.statusId == result['data']['taskStatus']);
					names.push("Task Status: "+task.statusName);

					if(result['data']['typeId'] != null){
                    	var type = sendData['taskTypes'].find(t => t.typeId == result['data']['typeId']);
						names.push("Task Type: "+type.typeName);
					}

                	var priority = this.priorities.find(p => p.id == result['data']['priority']);
					names.push("Task Priority: "+priority.name);
					result['data'].names = names;
        
					var project = this.projects.find(project => project["id"] == result['data']["projectId"]);
       
					result['data'].projectName = ("project: " + project['name']);
                this._projectsService.triggerTableUpdate();
                this.addUpdateTask(result['data'], type);
            }
        }
    });
  }
  

  addUpdateTask(entry, type){
  this._projectsService.putProjectTask(entry).subscribe(
        data => {
          this.getLabels();
          this.getUniqueLabels();
		      this.getMilestones();
          this.getUniqueMilestones();
          if(type == "task"){
            this.taskAdd = true;
          }
          else if(type == "kanban"){
            this.kanbanAdd = true;
          }
          this._projectsService.EntrySet(data);
          this.snackBar.open("Work Item Added Successfully", "", { duration: 2000 });
        },
        err => {
            this.snackBar.open("Error Adding Work Item", "", { duration: 2000 });
        }
      );
  }

  toggleToTask(group){
    group.value = "tasks";
  }

  filterProjectsSearchInput(event){
	  this.searchFilterText = event.target.value;
  }

  clearSearchBox(){
	this.searchFilterInputBox = "";
  }
}
