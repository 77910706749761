import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatSnackBar } from '@angular/material';
import { Moment } from 'moment';
import { GlobalService } from '../api/globalVars';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    withCredentials: true
};

@Injectable({
    providedIn: 'root'
})
export class ProjectsService {
    path = this.globalVar.retrievePath();

    private tableUpdated = new Subject<void>();
    tableUpdated$ = this.tableUpdated.asObservable();

    private entryGrabbed = new Subject<object>();
    entryGrabbed$ = this.entryGrabbed.asObservable();

    private TasksListSet = new Subject<object>();
    TasksListSet$ = this.TasksListSet.asObservable();


    constructor(private _http: HttpClient,
        public globalVar: GlobalService, public snackBar: MatSnackBar) { }

    getProjects() {
        const geturl = this.path + 'projectstab';
        return this._http.get(geturl, httpOptions);
    }

    getStatus() {
        const geturl = this.path + 'status';
        return this._http.get(geturl, httpOptions);
    }

    getProjectTaskNameId(){
        const geturl = this.path + 'tasks';
        return this._http.get(geturl, httpOptions);
    }

    getProjectTaskNameIdStatus(){
        const geturl = this.path + 'tasks?status=all&type=all';
        return this._http.get(geturl, httpOptions);
    }

    getProjectTaskById(taskId){
        const geturl = this.path + 'tasks?taskId=' + taskId;
        return this._http.get(geturl, httpOptions);
    }

    getProjectTasksForSprintSelection(){
        const geturl = this.path + 'tasks?forSprints=yes';
        return this._http.get(geturl, httpOptions); 
    }
 
    putProjectTask(entry, updateStatusOnly = false){
        const puturl = this.path + 'tasks?updateStatusOnly=' + updateStatusOnly;
        return this._http.put(puturl, entry, httpOptions);
    }

    getTaskCommentsByTaskId(taskId){
        const geturl = this.path + 'taskcomments?taskId=' + taskId;
        return this._http.get(geturl, httpOptions);
    }

	putCommentToTask(addCommentObj){
        const puturl = this.path + 'taskcomments';
		return this._http.put(puturl, addCommentObj, httpOptions);
    }

	putUpdateCommentFullName(updateCommentObj){
		const puturl = this.path + 'taskcomments';
		return this._http.put(puturl,updateCommentObj,httpOptions);
	}

    getAllLabels(){
        const puturl = this.path + 'labels';
        return this._http.get(puturl, httpOptions);
    }

    getAllTaskLabels(uniqueLabel) {
		const puturl = this.path + 'labels?uniqueLabel=' + uniqueLabel;
        return this._http.get(puturl, httpOptions);
	}

	getAllMilestones(){
		const geturl = this.path + 'milestones';
		return this._http.get(geturl, httpOptions);
	}

    getAllTaskMilestones(uniqueMilestone) {
		const puturl = this.path + 'milestones?uniqueMilestone=' + uniqueMilestone;
        return this._http.get(puturl, httpOptions);
	}

	getAllProjectRelationships(projectId){
		const geturl = this.path + "projectrelationship?projectId="+ projectId;
		return this._http.get(geturl, httpOptions);
	}
	putNewRelationship(relationshipObj){
		const puturl = this.path + 'projectrelationship';
		return this._http.put(puturl,relationshipObj,httpOptions);
	}
	putEditRelationship(relationshipObj){
		const puturl = this.path + 'projectrelationship';
		return this._http.put(puturl,relationshipObj,httpOptions);
	}
    putStatus(status){
        const puturl = this.path + 'status';
        return this._http.put(puturl, status, httpOptions);
    }

    deleteTaskById(taskId){
        const deleteurl = this.path + 'tasks?taskId=' + taskId;
        return this._http.delete(deleteurl, httpOptions);
    }

    deleteStatus(statusId){
        const deleteurl = this.path + 'status?statusId=' + statusId;
        return this._http.delete(deleteurl, httpOptions);
    }
	deleteRelationship(projectIdPrimary,projectIdSecondary){
		const deleteurl = this.path + 'projectrelationship?projectIdPrimary=' + projectIdPrimary + '&projectIdSecondary='+projectIdSecondary;
        return this._http.delete(deleteurl, httpOptions);
    }

    triggerTableUpdate() {
        this.tableUpdated.next();
      }
    

    EntrySet(entry: object){
        this.entryGrabbed.next(entry);
    }

    TasksList(tasksList: any[][]){
        this.TasksListSet.next(tasksList);
    }
}
