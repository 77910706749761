import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FormControl, Form } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatTableDataSource, MatSnackBar, MatPaginator, MatDialog, MatButtonToggleGroup } from '@angular/material';
import { SetttingsService } from '../../../api/setttings.service';

@Component({
    selector: 'app-management-tasks-type-dialog',
    templateUrl: './management-tasks-type-dialog.component.html',
    styleUrls: ['./management-tasks-type-dialog.component.scss']
})

export class ManagementTasksTypeDialogComponent implements OnInit {

    name: FormControl = new FormControl(this.taskTypeData['entry']['typeName']);
    order: FormControl = new FormControl(this.taskTypeData['entry']['order']);
    taskTypeArray = [];
    valid: boolean = true;
    error: String;

    @ViewChild('nameInput')
    nameElement: any;
    @ViewChild('orderInput')
    orderElement: any;

    @ViewChild(MatPaginator) paginator;

    constructor(private _settingsService: SetttingsService, public dialogRef: MatDialogRef<ManagementTasksTypeDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public taskTypeData) {
    }

    ngOnInit() {
        this.getTaskTypes();
    }

    closeDialog() {
        this.dialogRef.close();
    }

    saveAndCloseDialog() {
        if(this.taskTypeData['entry']['id'] == undefined){
            this.taskTypeData['entry']['reqVerification'] = null;
        }
        if(this.isValid()) {
            this.taskTypeData['entry']['typeName'] = this.name.value;
            this.taskTypeData['entry']['order'] = this.order.value;
            this.dialogRef.close({ submit: true, data: this.taskTypeData['entry'] });
        }
    }

    getTaskTypes() {
        this._settingsService.getTaskTypes().subscribe(
            data => {
                this.taskTypeArray = Object.keys(data).map(i => data[i]);
            }, err => {
                console.error(err);
            }
        );
    }

    //Rejects duplicates since table does not allow duplicate Name/Order pairs
    isNotDuplicate(){
        let tempTT = this.taskTypeArray.find(taskType => taskType['order'] == this.order.value);
        if(tempTT !== undefined){
            if(tempTT.typeId != this.taskTypeData.entry.typeId){
                return false;
            }
        }
    }

    isValid() {
      // Should validate form entry
        if(this.name.value == null || this.name.value == ''){   //Second check prevents empty strings
            this.error = 'Type Name is required';
            this.nameElement.nativeElement.focus();
            return false;
        }
        if(this.order.value == null || this.order.value == ''){
            this.error = 'Task Type Order is required';
            this.orderElement.nativeElement.focus();
            return false;
        }
        if(this.order.value < 0 || Number.isInteger(+this.order.value) == false){   // plus sign is necessary in front of "this.order..." so that it is not interpreted as a string
            this.error = 'Task Type Order must be a positive integer';
            this.orderElement.nativeElement.focus();
            return false;
        }
        if(this.isNotDuplicate() == false){ //ignores duplicate
            this.error = 'Task Type Order must be unique';
            this.orderElement.nativeElement.focus();
            return false;
        }
        else{
            this.valid = true;
            return true;
        }
    }
}
