import { Component, OnInit, Inject } from '@angular/core';
import { FormControl } from '../../../../../node_modules/@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '../../../../../node_modules/@angular/material';

@Component({
  selector: 'app-management-hr-title-positions',
  templateUrl: './management-hr-title-positions.component.html',
  styleUrls: ['./management-hr-title-positions.component.scss']
})
export class ManagementHrTitlePositionsComponent implements OnInit {

    nameFormControl: FormControl = new FormControl(this.titlePositionsData['name']);
    isActiveFormControl: FormControl = new FormControl(this.titlePositionsData['isActive'] == null ? true : this.titlePositionsData['isActive']);

    constructor(public dialogRef: MatDialogRef<ManagementHrTitlePositionsComponent>,
        @Inject(MAT_DIALOG_DATA) public titlePositionsData) { }

    ngOnInit() {

    }

    closeDialog() {
        this.dialogRef.close();
    }

    saveAndCloseDialog() {
        this.titlePositionsData['name'] = this.nameFormControl.value;
        this.titlePositionsData['isActive'] = this.isActiveFormControl.value;
        this.dialogRef.close({ submit: true, data: this.titlePositionsData });
    }

}
