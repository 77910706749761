import { Component, OnInit, Inject } from "@angular/core";
import { FormControl } from "../../../../../node_modules/@angular/forms";
import {
	MatDialogRef,
	MAT_DIALOG_DATA,
} from "../../../../../node_modules/@angular/material";

@Component({
	selector: "app-management-hr-employee-dialog",
	templateUrl: "./management-hr-employee-dialog.component.html",
	styleUrls: ["./management-hr-employee-dialog.component.scss"],
})
export class ManagementHrEmployeeDialogComponent implements OnInit {
	emailFormControl: FormControl = new FormControl({
		value: this.employeeData["email"],
		disabled: true,
	});
	firstNameFormControl: FormControl = new FormControl(
		this.employeeData["firstName"]
	);
	middleInitialFormControl: FormControl = new FormControl(
		this.employeeData["middleInitial"]
	);
	lastNameFormControl: FormControl = new FormControl(
		this.employeeData["lastName"]
	);
	rateFormControl: FormControl = new FormControl(
		this.employeeData["defaultRate"]
	);
	isContractorFormControl: FormControl = new FormControl(
		this.employeeData["isContractor"]
	);
	isActiveFormControl: FormControl = new FormControl(
		this.employeeData["isActive"]
	);
	hireDate: FormControl = new FormControl(this.employeeData["hireDate"]);
	terminationDate: FormControl = new FormControl(
		this.employeeData["terminationDate"]
	);
	positionInfo: string;
	groupInfo: string;
	selectedPositions = this.employeeData["positions"];
	selectedGroups = this.employeeData["groups"];
	toggle0 = false;
	toggle1 = false;
	toggle2 = false;
	toggle3 = false;
	toggle4 = false;
	toggle5 = false;
	toggle6 = false;
	toggle7 = false;
	toggle8 = false;
	toggle9 = false;
	rolesList = [null, null, null, null, null,
		null, null, null, null, null
	];

	constructor(
		public dialogRef: MatDialogRef<ManagementHrEmployeeDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public employeeData
	) {}

	ngOnInit() {
		for(var i = 0; i< this.employeeData.roles.length; i++){
			var temp = this.employeeData.roles[i];
			if(this.employeeData.roles[i] != null){
				temp.name = temp.name.replace(" (Test)", "");
				temp.show = true;
			}
			else {
				temp = { name: '', show: false };
			}
			this.rolesList[i] = temp;
		}

		this.setButtons();
	}

	//Sets focus of the combobox inputs on click
	setFocus(inputId: string) {
		if (document.getElementById(inputId) != null) {
			document.getElementById(inputId).focus();
		}
	}

	closeDialog() {
		this.dialogRef.close();
	}

	setButtons(){
		for(var i = 0; i < this.employeeData.assignedRoles.length; i++){
			var index = this.employeeData.roles.findIndex(role => role != null && role.id == this.employeeData.assignedRoles[i].roleId);
			if(index != -1) {
				switch(index){
					case 0:
						this.toggle0 = true;
						break;
					case 1:
						this.toggle1 = true;
						break;
					case 2:
						this.toggle2 = true;
						break;
					case 3:
						this.toggle3 = true;
						break;
					case 4:
						this.toggle4 = true;
						break;
					case 5:
						this.toggle5 = true;
						break;
					case 6:
						this.toggle6 = true;
						break;
					case 7:
						this.toggle7 = true;
						break;
					case 8:
						this.toggle8 = true;
						break;
					case 9:
						this.toggle9 = true;
						break;
				}
			}
		}
	}

	toggleButton(toggleId){
		switch(toggleId){
			case 0:
				this.toggle1 = false;
				break;
			case 1:
				this.toggle0 = false;
				break;
			case 2:
				this.toggle3 = false;
				break;
			case 3:
				this.toggle2 = false;
				break;
			case 4:
				this.toggle5 = false;
				break;
			case 5:
				this.toggle4 = false;
				break;
			case 6:
				this.toggle7 = false;
				break;
			case 7:
				this.toggle6 = false;
				break;
			case 8:
				this.toggle9 = false;
				break;
			case 9:
				this.toggle8 = false;
				break;
		}
	}

	save() {
		this.employeeData["emailAddress"] = this.emailFormControl.value;
		this.employeeData["firstName"] = this.firstNameFormControl.value;
		this.employeeData["middleInitial"] =
			this.middleInitialFormControl.value;
		this.employeeData["lastName"] = this.lastNameFormControl.value;
		this.employeeData["defaultRate"] = this.rateFormControl.value;
		this.employeeData["isContractor"] = this.isContractorFormControl.value;
		this.employeeData["isActive"] = this.isActiveFormControl.value;
		this.employeeData["hireDate"] = this.hireDate.value;
		this.employeeData["terminationDate"] = this.terminationDate.value;

		var userRoles = [];
		if(this.toggle0) userRoles.push(this.employeeData.roles[0]);
		if(this.toggle1) userRoles.push(this.employeeData.roles[1]);
		if(this.toggle2) userRoles.push(this.employeeData.roles[2]);
		if(this.toggle3) userRoles.push(this.employeeData.roles[3]);
		if(this.toggle4) userRoles.push(this.employeeData.roles[4]);
		if(this.toggle5) userRoles.push(this.employeeData.roles[5]);
		if(this.toggle6) userRoles.push(this.employeeData.roles[6]);
		if(this.toggle7) userRoles.push(this.employeeData.roles[7]);
		if(this.toggle8) userRoles.push(this.employeeData.roles[8]);
		if(this.toggle9) userRoles.push(this.employeeData.roles[9]);
		this.employeeData['userRoles'] = userRoles;

		this.dialogRef.close({ submit: true, data: this.employeeData });
	}
}
