import { Component, OnInit } from '@angular/core';
import { SetttingsService } from '../../api/setttings.service';
import { Observable } from '../../../../node_modules/rxjs';
import { ManagementExpensesCategoryDialogComponent } from './management-expenses-category-dialog/management-expenses-category-dialog.component';
import { ManagementExpensesPolicyDialogComponent } from './management-expenses-policy-dialog/management-expenses-policy-dialog.component';

@Component({
    selector: 'app-management-expenses',
    templateUrl: './management-expenses.component.html',
    styleUrls: ['./management-expenses.component.scss']
})
export class ManagementExpensesComponent implements OnInit {

    categoryDialogComponent = ManagementExpensesCategoryDialogComponent;
    policyDialogComponent = ManagementExpensesPolicyDialogComponent;

    constructor(private _settingsService: SetttingsService) { }

    ngOnInit() {
    }

    getExpenseCategoriesFunction() {
        return this._settingsService.getExpenseCategories();
    }

    getExpensePoliciesFunction() {
        return this._settingsService.getExpensePolicies();
    }

    putExpenseCategoriesFunction() {
        return (category) => this._settingsService.putExpenseCategory(category);
    }

    postExpensePoliciesFunction() {
        return (policy) => this._settingsService.postExpensePolicy(policy);
    }

}
