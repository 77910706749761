import { Component, OnInit, Inject } from "@angular/core";
import { FormControl, Validators } from "../../../../../node_modules/@angular/forms";
import {
	MatDialogRef,
	MAT_DIALOG_DATA,
} from "../../../../../node_modules/@angular/material";

@Component({
	selector: "app-management-hr-invite-dialog",
	templateUrl: "./management-hr-invite-dialog.component.html",
	styleUrls: ["./management-hr-invite-dialog.component.scss"],
})
export class ManagementHrInviteDialogComponent implements OnInit {
	nameFormControl: FormControl = new FormControl("", [Validators.required, Validators.email]);
	passwordFormControl: FormControl = new FormControl("", [Validators.required, Validators.pattern(/^(?=.{8,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\W).*$/)]);
	middleInitialFormControl: FormControl = new FormControl("");
	lastNameFormControl: FormControl = new FormControl("");
	firstNameFormControl: FormControl = new FormControl("");
	titleNameFormControl: FormControl = new FormControl("");
	defaultRateFormControl: FormControl = new FormControl(0, Validators.required);
	isActiveFormControl: FormControl = new FormControl(true);
	isContractorFormControl: FormControl = new FormControl(false);
	toggle0 = false;
	toggle1 = false;
	toggle2 = false;
	toggle3 = false;
	toggle4 = false;
	toggle5 = false;
	toggle6 = false;
	toggle7 = false;
	toggle8 = false;
	toggle9 = false;
	rolesList = [null, null, null, null, null,
		null, null, null, null, null
	];

	constructor(
		public dialogRef: MatDialogRef<ManagementHrInviteDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public inviteData
	) {}

	ngOnInit() {
		for(var i = 0; i< this.inviteData.roles.length; i++){
			var temp = this.inviteData.roles[i];
			if(this.inviteData.roles[i] != null){
				temp.name = temp.name.replace(" (Test)", "");
				temp.show = true;
			}
			else {
				temp = { name: '', show: false };
			}
			this.rolesList[i] = temp;
		}
	}

	closeDialog() {
		this.dialogRef.close();
	}

	/**
	 * Checks to ensure the keypress is a letter or hyphen
	 *
	 * @param event
	 */
	keyPressAlphabetical(event) {
		var inp = String.fromCharCode(event.keyCode);

		if (/^[a-zA-Z\-]$/.test(inp)) {
			//hyphen included for last name or using - instead of having a middle initial
			return true;
		} else {
			event.preventDefault();
			return false;
		}
	}

	toggleButton(toggleId){
		switch(toggleId){
			case 0:
				this.toggle1 = false;
				break;
			case 1:
				this.toggle0 = false;
				break;
			case 2:
				this.toggle3 = false;
				break;
			case 3:
				this.toggle2 = false;
				break;
			case 4:
				this.toggle5 = false;
				break;
			case 5:
				this.toggle4 = false;
				break;
			case 6:
				this.toggle7 = false;
				break;
			case 7:
				this.toggle6 = false;
				break;
			case 8:
				this.toggle9 = false;
				break;
			case 9:
				this.toggle8 = false;
				break;
		}
	}

	addNewEmployee() {
		//PROS-889
		if (this.nameFormControl.value != "" && this.passwordFormControl.value != "" && /^(?=.{8,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\W).*$/.test(this.passwordFormControl.value)) {
			var emailObj = {
				email: this.nameFormControl.value,
				password: this.passwordFormControl.value,
				firstName: this.firstNameFormControl.value,
				middleInitial: this.middleInitialFormControl.value,
				lastName: this.lastNameFormControl.value,
				title: this.titleNameFormControl.value,
				defaultRate: this.defaultRateFormControl.value,
				isActive: this.isActiveFormControl.value,
				isContractor: this.isContractorFormControl.value,
			};

			var userRoles = [];
			if(this.toggle0) userRoles.push(this.inviteData.roles[0]);
			if(this.toggle1) userRoles.push(this.inviteData.roles[1]);
			if(this.toggle2) userRoles.push(this.inviteData.roles[2]);
			if(this.toggle3) userRoles.push(this.inviteData.roles[3]);
			if(this.toggle4) userRoles.push(this.inviteData.roles[4]);
			if(this.toggle5) userRoles.push(this.inviteData.roles[5]);
			if(this.toggle6) userRoles.push(this.inviteData.roles[6]);
			if(this.toggle7) userRoles.push(this.inviteData.roles[7]);
			if(this.toggle8) userRoles.push(this.inviteData.roles[8]);
			if(this.toggle9) userRoles.push(this.inviteData.roles[9]);
			emailObj['userRoles'] = userRoles;

			this.dialogRef.close({ submit: true, data: emailObj });
		}
	}
	getEmailErrorMessage() {
		return this.nameFormControl.hasError('required') ? 'Please enter valid email address' :
			this.nameFormControl.hasError('email') ? 'Not a valid email' :
				'';
	}

	getPasswordErrorMessage() {
		return this.passwordFormControl.hasError('required') ? 'Please enter valid temporary password' :
			this.passwordFormControl.hasError('pattern') ? 'Invalid Password' :
				'';
	}
}
