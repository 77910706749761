import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '../../../../../node_modules/@angular/material';
import { FormControl } from '../../../../../node_modules/@angular/forms';

@Component({
    selector: 'app-timeoff-reject-dialog',
    templateUrl: './timeoff-reject-dialog.component.html',
    styleUrls: ['./timeoff-reject-dialog.component.scss']
})
export class TimeoffRejectDialogComponent implements OnInit {

    message: FormControl = new FormControl('There was something wrong with your current data.');
    deleteQB = new FormControl(false);

    constructor(public dialogRef: MatDialogRef<TimeoffRejectDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public timeoffData) { }

    ngOnInit() {
    }

    closeDialog() {
        this.dialogRef.close();
    }

    saveAndCloseDialog() {
        this.dialogRef.close({ reject: true, message: this.message.value, deleteQB: this.deleteQB.value });
    }

}
