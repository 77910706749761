import { Component, OnInit, Inject, ViewChild, ViewChildren, ElementRef, NgZone, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '../../../../node_modules/@angular/forms';
import { SetttingsService } from '../../api/setttings.service';
import { Auth0ManagementApiService } from '../../services/auth0-management-api/auth0-management-api.service';
import { ProjectsService } from '../../api/projects.service';
import { SprintService } from '../../api/sprint.service';
import { MatDialogRef, MatTableDataSource, MAT_DIALOG_DATA, MatSelect, MatPaginator, MatDialog, MatSnackBar, MatSort } from '../../../../node_modules/@angular/material';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatAutocompleteSelectedEvent, MatAutocomplete} from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';
import {Observable} from 'rxjs';
import { ShareProject } from '../../services/sharing/share-project.service';
import { AuthService } from '../../services/auth/auth.service';
var async = require('async');
var cloneDeep = require('lodash.clonedeep');
import { map, startWith, take } from 'rxjs/operators';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { DeleteConfirmationDialogComponent } from '../../extra/delete-confirmation-dialog/delete-confirmation-dialog.component';

@Component({
    selector: 'app-projects-task-dialog',
    templateUrl: './projects-task-dialog.component.html',
    styleUrls: ['./projects-task-dialog.component.scss']
})
export class ProjectsTaskDialogComponent implements OnInit {

    taskDataSource = new MatTableDataSource();
    dataSourceLoading: boolean;
    columnsToDisplay = ['name', 'projectName'];
    hideDetailsPage: boolean = true;

    id: FormControl = new FormControl(this.taskData['entry']['id']);
    name: FormControl = new FormControl(this.taskData['entry']['name']);
    reporter: FormControl = new FormControl(this.taskData['entry']['reporter']);
    taskType: FormControl = new FormControl(this.taskData['entry']['typeId']);
    taskStatus: FormControl = new FormControl(this.taskData['entry']['taskStatus']);
    assignedUser: FormControl = new FormControl(this.taskData['entry']['assignedUser']);
    sprint: FormControl = new FormControl(this.taskData['entry']['sprintId']);
    plannedStartDate: FormControl = new FormControl(this.taskData['entry']['plannedStartDate']);
    actualStartDate: FormControl = new FormControl(this.taskData['entry']['actualStartDate']);
    plannedEndDate: FormControl = new FormControl(this.taskData['entry']['plannedEndDate']);
    actualEndDate: FormControl = new FormControl(this.taskData['entry']['actualEndDate']);
    description: FormControl = new FormControl(this.taskData['entry']['description']);
    projectId: FormControl = new FormControl(this.taskData['entry']['projectId'])
    commitDate: FormControl = new FormControl(this.taskData['entry']['commitDate']);
    priority: FormControl = new FormControl(this.taskData['entry']['priority'] != null ? this.taskData['entry']['priority']: 3);
    valid: boolean = true;
    error1: String;
    error2: String;
    error3: String;

    showProject: boolean = false;
    disableComment: boolean = false;
    clients;
    entry: object = null;
    selectedProjects: any[];
    selectedUsers: any[];
    selectedReporterUsers: any[];
	taskTypes: any;
    taskStatuses: any;
    users: any;
    projectUserMappings: any[];
    selectedTypes: any;
    selectedSprints: any[];
    allProjects: any[];
    allSprints: object[] = [];;
    priorities = [{name: "Lowest", id:1},{name: "Low", id:2},
        {name: "Medium", id:3},{name: "High", id:4},{name: "Highest", id:5}]

    visible = true;
    selectable = true;
    removable = true;
    separatorKeysCodes: number[] = [ENTER, COMMA];
	milestoneSeperatorKeysCodes: number[] = [ENTER, COMMA];
    labelCtrl = new FormControl();
	milestoneCtrl = new FormControl();
    filteredLabels: Observable<any[]>;
	filteredMilestones: Observable<any[]>;
    labels: any[] = this.taskData['entry']['labels'];
	milestones: any[] = this.taskData['entry']['milestones'];
    allLabels: any[] = [];
	allMilestones: any[] = [];
    canDeleteTasks: boolean = false;

   /*Keep track of current user id and fullname*/ /*Adding commnets dependant upon variables PS-473*/
    currentUserId:any;
    userFullName:any;

    reporterList = [];

    comments: any[] = [];
	commentTextarea = "";

    unassignedUserValue: string = "unassignedId";
    unassignedValue = null;
    addProjectTaskTitle: boolean;

	usersPromise: Promise<void> = Promise.resolve(); //resolves immediately if users already exist
	projectUserMappingsPromise: Promise<void> = Promise.resolve(); //resolves immediately if projectUserMappings already exist
	gotAllClientsTheirProjects = false;

    @Input() searchFilterText = '';
    @Input() filters: object[] = [];
    @Input() addATask: boolean = false;
    @Output() labelUpdate = new EventEmitter<any>();
	@Output() milestoneUpdate = new EventEmitter<any>();

    @ViewChild(MatPaginator) paginator;
    @ViewChild('nameInput') nameElement: any;
    @ViewChild('PSDElement') plannedStartElement: any;
    @ViewChild('ASDElement') actualStartElement: any;
    @ViewChild('PEDElement') plannedEndElement: any;
    @ViewChild('desc') descriptionElement: any;
    @ViewChild('labelInput') labelInput: ElementRef<HTMLInputElement>;
	@ViewChild('milestoneInput') milestoneInput: ElementRef<HTMLInputElement>;
	//@ViewChild('auto') matAutocomplete: MatAutocomplete;
    @ViewChild('autosize') autosize: CdkTextareaAutosize;
    @ViewChild(MatPaginator) projectsPaginator: MatPaginator;
    @ViewChildren(MatSort) sort;

	constructor(private _settingsService: SetttingsService, private _projectsService: ProjectsService, public dialogRef: MatDialogRef<ProjectsTaskDialogComponent>, public dialog: MatDialog, private _sprintService: SprintService,
        @Inject(MAT_DIALOG_DATA) public taskData, private shareProject: ShareProject,  private _authService: AuthService, public snackBar: MatSnackBar, private _ngZone: NgZone, private _auth0ManagementApiService: Auth0ManagementApiService) {}

    ngOnInit() {
        if(this.taskData['entry']['reporter'] == null) this.getUserId()
        if(this.taskData['Sprints'] == null) {
            this.getSprints();
        }
        else {
            this.allSprints = this.taskData['Sprints'];

            if(this.projectId.value != null){
                var sprintList = this.allSprints.filter(x => x['projectId'] == this.projectId.value);
                this.selectedSprints = sprintList;
            }
            else {
                this.selectedSprints = this.taskData['Sprints'];
            }
        }
		this.getUsers();
		this.getClients();
        this.getLabels();
    	this.getMilestones();
		this.getTaskTypes();
		this.getTaskStatuses();
        this._authService.getUserPermissions().subscribe(userPermissions => this.canDeleteTasks = userPermissions.includes('manage:projects'));

        /*get user id and store into to currentUserId*/ /*PS-473*/
        this._settingsService.getUserManagerSettings().subscribe(data => {
            if(data != null){
              this.currentUserId = data['userId'];
            }
          });

          /*fetch all users and find the current user id*/ /*PS-473*/
          this._settingsService.getUsers(true).subscribe(data => {
            this.users = data

            async.forEachOf(data,(user,i,innerCallback)=>{
                try {
                     if(user.userId == this.currentUserId){
                            this.userFullName = user.fullName;
                            return;
                     }
                } catch(ex) {
                    innerCallback(ex);
                }
            },(err)=>{

            });
            });

            /*fetch all comments when work item is selected PS-473*/
            this.id.setValue(this.taskData['entry']['id']);

			if(this.taskData['entry']['id'] != undefined) {
				this.getComments(this.id);
			}


		// projectUserMapping
		var sharedProjectUserMappings = this.shareProject.getProjectUserMappings();
		if(sharedProjectUserMappings == undefined){
			if (this.taskData['entry']['projectId'] == null) {
				this.showProject = true;
				this.getProjectUserMappings();
			} else {
				this.showProject = false;
				this.getProjectUserMappings(this.taskData['entry']['projectId']);
			}
		}else{
			this.projectUserMappings = sharedProjectUserMappings;
			if (this.taskData['entry']['projectId'] == null) this.showProject = true;
			else{
				this.showProject = false;
                this.filterUsersByProject(this.taskData['entry']['projectId']);
			}
		}

        if (this.taskData['entry']['name'] == null) this.addProjectTaskTitle = true;
        else this.addProjectTaskTitle = false;

        if(this.taskData['users'] != null) this.taskData['users'] = this.taskData['users'].sort((a, b) => (a.fullName > b.fullName) ? 1 : -1);
    }

	getUserId(){
		var sharedUserId = this.shareProject.getCurrentUserId();
		if(sharedUserId == undefined){
			this._authService.getUserId().subscribe(userId=>{
				this.reporter.setValue(userId);
				this.shareProject.setCurrentUserId(userId)
			});
		}else this.reporter.setValue(sharedUserId);
	}

    getSprints() {
		let result = []
		this._sprintService.getSprints().subscribe(data => {
			result = Object.keys(data).map(function (key) {
				return data[key];
			});
			result = result.sort((a, b) => (a.name > b.name) ? 1 : -1);
			this.allSprints = result;

            if(this.projectId.value != null){
                var sprintList = this.allSprints.filter(x => x['projectId'] == this.projectId.value);
                this.selectedSprints = sprintList;
            }
            else {
                this.selectedSprints = result;
            }
		});
	}

	getClients() {
		var sharedClients = this.shareProject.getClients();
		if(sharedClients == null) {
			this.clients = [];
			this.selectedProjects = [];
			let result = [];
			this._settingsService.getClients().subscribe(data => {
				result = Object.keys(data).map((key) => [Number(key), data[key]]);

                this.clients = [];
				async.forEach(result,(client,inner_callback)=>{
					this.clients.push(client['1']);
					this.selectedProjects.push(client['1']);
					inner_callback(null);
                },() => this.getProjects());
			});
		}else{
            this.selectedProjects = sharedClients;
            this.clients = cloneDeep(sharedClients);
           // this.gotAllClientsTheirProjects = false; unused

		}
	}

	getProjects() {
		this._settingsService.getProjectsByClientId(this.clients, true, true).subscribe(data => {
			async.forEach(this.clients,(client,inner_callback)=>{
				if (data[client['id']].length > 0) client['projects'] = data[client['id']];
				inner_callback(null)
			},() => this.shareProject.setClients(this.clients)
			);
		});
	}

	getTaskStatuses(){
		var sharedTaskStatuses = this.shareProject.getTaskStatuses();
		if(sharedTaskStatuses==undefined){
			this._settingsService.getStatuses().subscribe(data => {
				this.taskStatuses = data;
				this.taskStatuses = this.taskStatuses.sort(function (a, b) {
					return a.order - b.order;
				});
				this.shareProject.setTaskStatuses(this.taskStatuses);
			});
		}else this.taskStatuses = sharedTaskStatuses;
	}

	getTaskTypes(){
		var sharedTaskTypes = this.shareProject.getTaskTypes();
		if(sharedTaskTypes == undefined){
			this._settingsService.getTaskTypes().subscribe(data => {
				this.taskTypes = data;
				this.selectedTypes = data;
				this.shareProject.setTaskTypes(this.taskTypes);
			});
		}else{
			this.taskTypes = sharedTaskTypes;
			this.selectedTypes = sharedTaskTypes;
		}
	}

    add(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;
        if ((value || '').trim()) {
          this.labels.push({id: -1, name: value.trim()});
        }
        // Reset the input value
        if (input) {
          input.value = '';
        }
        this.labelCtrl.setValue(null);
    }

	addMilestone(event: MatChipInputEvent): void {
		const input = event.input;
        const value = event.value;
        if ((value || '').trim()) {
          this.milestones.push({id: -1, name: value.trim()});
        }
        // Reset the input value
        if (input) {
          input.value = '';
        }
        this.milestoneCtrl.setValue(null);
	}

    remove(label: any): void {
        if(label.id == -1){
            const index = this.labels.indexOf(label);

            if (index >= 0) {
              this.labels.splice(index, 1);
            }
        }
        else {
            this.labels = this.labels.filter(curr => curr.id != label.id)
            this.labelCtrl.setValue(null);
        }
    }

	removeMilestone(milestone: any): void {
		if(milestone.id == -1){
            const index = this.milestones.indexOf(milestone);

            if (index >= 0) {
              this.milestones.splice(index, 1);
            }
        }
        else {
            this.milestones = this.milestones.filter(curr => curr.id != milestone.id)
            this.milestoneCtrl.setValue(null);
        }
	}

    selected(event: MatAutocompleteSelectedEvent): void {
        this.labels.push(event.option.value);
        this.labelInput.nativeElement.value = '';
        this.labelCtrl.setValue(null);
    }

	selectedMilestone(event: MatAutocompleteSelectedEvent): void {
		this.milestones.push(event.option.value);
        this.milestoneInput.nativeElement.value = '';
        this.milestoneCtrl.setValue(null);
	}

    private _filter(value): any[] {
        if(typeof value == 'string'){
            const filterValue = value.toLowerCase();
            let tempLabels = JSON.parse(JSON.stringify(this.allLabels));
            if(this.labels != undefined)
                for(var i = 0; i < this.labels.length; i++){
                    tempLabels = tempLabels.filter(tempLabel => tempLabel.id != this.labels[i].id)
                }
            return tempLabels.filter(label => label.name.toLowerCase().indexOf(filterValue) != -1);
        }
        else {
            return this._filterCurrent();
        }
    }

	private _filterMilestone(value): any[] {
		if(typeof value == 'string'){
			const filterValue = value.toLowerCase();
            let tempMilestones = JSON.parse(JSON.stringify(this.allMilestones));
            if(this.milestones != undefined)
                for(var i = 0; i < this.milestones.length; i++){
                    tempMilestones = tempMilestones.filter(tempMilestone => tempMilestone.id != this.milestones[i].id)
                }
            return tempMilestones.filter(milestone => milestone.name.toLowerCase().indexOf(filterValue) != -1);
        }
        else {
            return this._filterCurrent();
        }
	}

    private _filterCurrent(): any[] {
        let tempLabels = JSON.parse(JSON.stringify(this.allLabels));
        if(this.labels != undefined){
            for(var i = 0; i < this.labels.length; i++){
                tempLabels = tempLabels.filter(tempLabel => tempLabel.id != this.labels[i].id)
            }
        }
        return tempLabels.slice()
    }

	private _filterCurrentMilestone(): any[] {
		let tempMilestones = JSON.parse(JSON.stringify(this.allMilestones));
        if(this.milestones != undefined){
            for(var i = 0; i < this.milestones.length; i++){
                tempMilestones = tempMilestones.filter(tempMilestone => tempMilestone.id != this.milestones[i].id)
            }
        }
        return tempMilestones.slice()
	}

    clearLabels(){
        this.labels = [];
        this.labelCtrl.setValue(null);
    }

	clearMilestones() {
		this.milestones = [];
		this.milestoneCtrl.setValue(null);
	}

    onKeyUsers(value) {
        this.selectedUsers = this.filterUsers(value);
    }

    onKeyReporterUsers(value) {
        this.selectedReporterUsers = this.filterReporterUsers(value);
    }

    filterUsers(value: string) {
        if (this.projectId.value != null) {
            this.filterUsersByProject(this.projectId.value, value);
        }
        else {
            return this.taskData['users'].filter(user => user['fullName'].toLowerCase().includes(value.toLowerCase()) && user['userId'] != 'unassignedId');
        }
    }

    filterReporterUsers(value: string) {
        return this.taskData['users'].filter(user => user['fullName'].toLowerCase().includes(value.toLowerCase()) && user['userId'] != 'unassignedId');
    }

    onKeyProjects(value) {
        this.selectedProjects = this.filterProjects(value);
    }

    filterProjects(value: string) {
        let filter = value.toLowerCase();

        let filteredClientsAndProjects = this.clients.filter(option => option['name'].toLowerCase().includes(filter));
        for(var i = 0; i < this.clients.length; i++){
            if(filteredClientsAndProjects.find(option => option['id'] == this.clients[i]['id']) == undefined){
                if(this.clients[i]['projects'] != null){
                    let tempClient = cloneDeep(this.clients[i]);
                    tempClient['projects'] = tempClient['projects'].filter(project => project['name'].toLowerCase().includes(filter))
                    if(tempClient['projects'].length > 0){
                        filteredClientsAndProjects.push(tempClient);   
                    }
                }
            }
        }
        return filteredClientsAndProjects.sort((a, b) => { return (a['name'] > b['name']) ? 1 : ((b['name'] > a['name']) ? -1 : 0); });
    }

    onKeySprints(value) {
		this.selectedSprints = this.filterSprints(value);
	}

    filterSprints(value: string) {
		let filter = value.toLowerCase();
        var sprintList = this.allSprints;
        if(this.projectId.value != null)
		    sprintList = this.allSprints.filter(x => x['projectId'] == this.projectId.value);
		return sprintList.filter(option => option['name'].toLowerCase().includes(filter));
	}

    onKeyType(value) {
        this.selectedTypes = this.filterTypes(value);
    }

    filterTypes(value: string) {
        let filter = value.toLowerCase();
        return this.taskTypes.filter(option => option['typeName'].toLowerCase().includes(filter));
    }

    //Sets focus of the combobox inputs on click
    setFocus(inputId: string) {
        if (document.getElementById(inputId) != null) {
            document.getElementById(inputId).focus();
        }
    }

    deleteTask() {
        const id: string = this.taskData['entry']['id'].toString();
        if(this.canDeleteTasks){
            const dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
                width: '585px',
                height: 'auto'
            });
            dialogRef.afterClosed().subscribe(result => {// Check if the entry is to be deleted
                if (result !== undefined) {
                    if (result['toDelete']) {
                        this.snackBar.open('Deleting Work Item', '', { duration: 5000 });
                        this._projectsService.deleteTaskById(id).subscribe(data3 => {
                            if (data3 == null) {
                                this.snackBar.open('Task Deleted Successfully', '', {duration: 2000});
                                this.dialogRef.close({delete: true});
                            }
                        }, err => {
                            this.snackBar.open("Error Deleting Work Item", "", { duration: 2000 });
                        })
                    }
                }
            });   
        };
    }

    refreshFilter() {
        this.taskDataSource.filterPredicate = (data:
            { id: string }, filter: string) => {
            let searchlist =  JSON.parse(filter);
            var found = false;

            var foundLabel = false;
            for(var i = 0; i < data["labels"].length; i++){
                if(searchlist[4].indexOf(data["labels"][i]["id"]) !== -1){
                    foundLabel = true;
                    break;
                }
            }

			var foundMilestone = false;
			for(var i = 0; i< data["milestones"].length; i++){
				if(searchlist[6].indexOf(data["milestones"][i]["id"]) !== -1){
					foundMilestone = true;
					break;
				}
			}

            if ((searchlist[0].length == 0 || searchlist[0].indexOf(data["projectId"]) !== -1) &&
                (searchlist[1].length == 0 || searchlist[1].indexOf(data["assignedUserId"]) !== -1) &&
                (searchlist[2].length == 0 || searchlist[2].indexOf(data["typeId"]) !== -1) &&
                (searchlist[3].length == 0 || searchlist[3].indexOf(data["taskStatus"]) !== -1) &&
                (searchlist[4].length == 0 || foundLabel) &&
				(searchlist[5].length == 0 || data['name'].trim().toLowerCase().includes(searchlist[5][0]) ||
					data['projectName'].trim().toLowerCase().includes(searchlist[5][0]) ||
					data['id'].toString().includes(searchlist[5][0])))
				{
                found = true;
            }
            return found
		}

        if (this.entry == null || this.taskDataSource.filteredData.filter(x => x["id"] == this.entry["id"]).length == 0) {
            if (this.taskDataSource.data.length == 0) {
                this.hideDetailsPage = true;
            }
            else if (this.taskDataSource.filteredData.length == 0) {
                this.hideDetailsPage = false;
                this.selectTask(this.taskDataSource.data[0]);
            }
            else {
                this.hideDetailsPage = false;
                this.selectTask(this.taskDataSource.filteredData[0]);
            }
        }

		if(this.searchFilterText.trim().toLowerCase() != undefined)
			this.filters[5] = [this.searchFilterText.trim().toLowerCase()];
		this.taskDataSource.filter = JSON.stringify(this.filters);

        var maxPageAmount = Math.ceil(this.taskDataSource.filteredData.length / this.taskDataSource.paginator.pageSize);
        var actualPage = this.taskDataSource.paginator.pageIndex + 1;
        if (actualPage > maxPageAmount) {
            if (maxPageAmount == 0) {
                this.projectsPaginator.pageIndex = 0;
                this.taskDataSource.paginator = this.projectsPaginator;
            }
            else {
                this.projectsPaginator.pageIndex = maxPageAmount - 1;
                this.taskDataSource.paginator = this.projectsPaginator;
            }
        }
    }

    getLabels() {
		var sharedLabels = this.shareProject.getLabels();
		if(sharedLabels ==undefined){
			let result = [];
			this._projectsService.getAllLabels().subscribe(data => {
				result = Object.keys(data).map(function (key) {
					return data[key];
				});
				this.allLabels = result.sort((a, b) => (a.name > b.name) ? 1 : -1);
				this.shareProject.setLabels(this.allLabels);
				this.filteredLabels = this.labelCtrl.valueChanges.pipe(
					startWith(null),
					map((label: string | null) => label ? this._filter(label) : this._filterCurrent()));
			});
		}else{
			this.allLabels = sharedLabels;
			this.filteredLabels = this.labelCtrl.valueChanges.pipe(
				startWith(null),
				map((label: string | null) => label ? this._filter(label) : this._filterCurrent()));
		}
    }

	getMilestones() {
		let result = [];
		this._projectsService.getAllMilestones().subscribe(data => {
            result = Object.keys(data).map(function (key) {
                return data[key];
            });
            this.allMilestones = result.sort((a, b) => (a.name > b.name) ? 1 : -1);
            this.filteredMilestones = this.milestoneCtrl.valueChanges.pipe(
                startWith(null),
                map((milestone: string | null) => milestone ? this._filterMilestone(milestone) : this._filterCurrentMilestone()));
        });
	}

    closeDialog() {
        this.dialogRef.close();
    }

    saveAndCloseDialog() {
        if (this.isValid()) {
            //Fill in and set data
            this.taskData['entry']['id'] = this.id.value;
            this.taskData['entry']['name'] = this.name.value;
            this.taskData['entry']['typeId'] = this.taskType.value;
            this.taskData['entry']['taskStatus'] = this.taskStatus.value;
            this.taskData['entry']['reporter'] = this.reporter.value;
            this.taskData['entry']['plannedStartDate'] = this.plannedStartDate.value;
            this.taskData['entry']['actualStartDate'] = this.actualStartDate.value;
            this.taskData['entry']['plannedEndDate'] = this.plannedEndDate.value;
            this.taskData['entry']['actualEndDate'] = this.actualEndDate.value;
            this.taskData['entry']['description'] = this.description.value;
            this.taskData['entry']['assignedUser'] = this.assignedUser.value;
            this.taskData['entry']['priority'] = this.priority.value;
            this.taskData['entry']['commitDate'] = this.commitDate.value;
            this.taskData['entry']['labels'] = this.labels;
			this.taskData['entry']['milestones'] = this.milestones;
            this.taskData['entry']['sprintId'] = this.sprint.value;
            this.taskData['entry']['modifiedDate'] = new Date();
            if (this.showProject)
                this.taskData['entry']['projectId'] = this.projectId.value;
            this.dialogRef.close({ submit: true, data: this.taskData['entry'] });
            this.shareProject.reset();
        }

    }

    getProjectUserMappings(projectId = null) {
		this.projectUserMappingsPromise = new Promise((resolve,reject)=>{
			try{
				this._settingsService.getProjectsWithUsers().subscribe(data => {
					this.projectUserMappings = Object.assign([], data);
					this.shareProject.setProjectUserMappings(this.projectUserMappings);
					resolve(null);
				});
			}catch(ex){
				reject(ex);
			}
		}).then(()=>{
			if (projectId != null) this.filterUsersByProject(projectId);
		});

    }

	getUsers(){
		var sharedUsers = this.shareProject.getUsers();
		if(sharedUsers == undefined){
			this.usersPromise = new Promise((resolve,reject)=>{
				try{
					this._settingsService.getUsers(1).subscribe(data => {
                        this.users = data;
                        this.users = this.users.sort((a, b) => (a.fullName > b.fullName) ? 1 : -1);
						this.shareProject.setUsers(this.users);
						this.selectedUsers = this.users;
                        this.selectedReporterUsers = this.users;
						resolve(null);
					});
				}catch(ex){
					reject(ex);
				}
            });
        }
		else{
			this.selectedUsers = sharedUsers;
            this.selectedReporterUsers = sharedUsers;
			this.users = sharedUsers;
        }
	}

    filterUsersByProject(projectId, filterValue="") {
        var sprintList = this.allSprints.filter(x => x['projectId'] == projectId);
        this.selectedSprints = sprintList;

        // wait for projectUserMappings to be defined
        this.projectUserMappingsPromise.then(()=>{
        let filteredUsers = this.projectUserMappings.filter(project => project['projectId'] == projectId);
        // wait for users to be defined
        this.usersPromise.then(()=>{
            if (filteredUsers != null && filteredUsers.length > 0) {
                let projectUsersList: string[] = filteredUsers[0]['userIds'];
                this.selectedUsers = this.users.filter(user => projectUsersList.includes(user['userId']));
                if(filterValue != "")
                    this.selectedUsers = this.selectedUsers.filter(user => user['fullName'].toLowerCase().includes(filterValue.toLowerCase()));
                this.selectedUsers = this.selectedUsers.sort((a, b) => (a.fullName > b.fullName) ? 1 : -1);
            }else this.selectedUsers = [];
            }, err => console.error(err));
        } , err => console.error(err));
    }

    selectTask(item) {
        this._projectsService.getProjectTaskById(item.id).subscribe(
            data => {
                this.loadDialog(data);
            },
            err => {
                this.snackBar.open("Error Retriving Work Item", "", { duration: 2000 });
            }
        );
    }

    loadDialog(entry) {
        this.entry = entry;
		this.id.setValue(entry.id);
        this.name.setValue(entry.name);
        this.taskType.setValue(entry.typeId);
        this.taskStatus.setValue(entry.taskStatus);
        this.assignedUser.setValue(entry.assignedUser);
        this.plannedStartDate.setValue(entry.plannedStartDate);
        this.actualStartDate.setValue(entry.actualStartDate);
        this.plannedEndDate.setValue(entry.plannedEndDate);
        this.actualEndDate.setValue(entry.actualEndDate);
        this.commitDate.setValue(entry.commitDate);
        this.reporter.setValue(entry.reporter);
        this.description.setValue(entry.description);
        this.projectId.setValue(entry.projectId);
        this.priority.setValue(entry.priority);
        this.sprint.setValue(entry.sprintId);
        this.error1 = '';
        this.error2 = '';
        this.error3 = '';
        this.labels = entry.labels;
		this.milestones = entry.milestones;
        this.filterUsersByProject(entry.projectId);

        this.filteredLabels = this.labelCtrl.valueChanges.pipe(
            startWith(null),
            map((label: string | null) => label ? this._filter(label) : this._filterCurrent()));

		this.filteredMilestones = this.milestoneCtrl.valueChanges.pipe(
			startWith(null),
			map((milestone: string | null) => milestone ? this._filterMilestone(milestone) : this._filterCurrentMilestone())
		)
		if(this.id != undefined){
			this.getComments(this.id);
		}
    }

    getUserProjects(firstLoad = false) {
        this.taskDataSource = new MatTableDataSource([]);
        this.dataSourceLoading = true;
        this._projectsService.getProjectTaskNameId().subscribe(
            data => {
                if (data != null) {
                    let tempdata = Object.keys(data).map(i => data[i]);
                    let tempFilter = Object.keys(this.filters).map(i => this.filters[i]);
                    //This used because if a filter was applied before getting into this method
                    //it will have a run condition with the filter's selection
                    let filterEmpty = tempFilter[0].length == 0 && tempFilter[1].length == 0 &&
                        tempFilter[2].length == 0 && tempFilter[3].length == 0 && tempFilter[4].length == 0;
                    if (firstLoad && tempdata != null && tempdata.length > 0 && filterEmpty) {
                        this.selectTask(tempdata[0]);
                    }

                    this.taskDataSource = new MatTableDataSource(tempdata);
                    this.taskDataSource.paginator = this.projectsPaginator;
                    this.dataSourceLoading = false;

                    this.taskDataSource.sort = this.sort.first;
                    this.taskDataSource.sortingDataAccessor = (item, property) => {
                        switch (property) {
                            case 'name': return item['name'];
                            default: return item[property];
                        }
                    };
                    this.refreshFilter();
                }
                else {
                    this.columnsToDisplay = [];
                    this.taskDataSource = new MatTableDataSource(); // User isn't involved in any projects
                    this.dataSourceLoading = false;
                }
            },
            err => console.error(err)
        );
    }

    checkAndUpdate() {
        if (this.isValid()) {
            this.addUpdateTask();
        }
    }

    isValid() {
        if(this.description.value !== null){
            if (this.description.value.length > 4000) {
                this.error1 = 'Description exceeds character limit of 4000';
                return false;
            }
        }
        if (this.name.value == null || this.name.value == '') {
            this.error1 = 'Work Item Name is required';
            this.nameElement.nativeElement.focus();
            return false;
        } else {
            this.error1 = '';
        }
        if (this.taskStatus.value == null) {
            this.error2 = 'Work Item Status is required';
            return false;
        }
        if (this.reporter.value == null) {
            this.error2 = 'Reporter is required';
            return false;
        }
        if (this.showProject && this.projectId.value == null) {
            this.error2 = 'Project is required';
            return false;
        }
        if (this.priority.value == undefined) {
            this.error2 = 'Priority is required';
            return false;
        }
        else {
            this.error2 = '';
        }
        if (this.plannedStartDate.value != null && this.plannedEndDate.value != null && this.plannedStartDate.value > this.plannedEndDate.value) {
            this.error3 = 'Planned Start Date must be before Planned End Date';
            this.plannedStartElement.nativeElement.focus();
            return false;
        }
        if (this.actualStartDate.value != null && this.actualEndDate.value != null && this.actualStartDate.value > this.actualEndDate.value) {
            this.error3 = 'Actual Start Date must be before Actual End Date';
            this.actualStartElement.nativeElement.focus();
            return false;
        }
        else {
            this.error3 = '';
            this.valid = true;
            return true;
        }
    }

    addUpdateTask(entry = null) {
        if (entry == null) {
            //An Update for a task
            entry = {
                id: this.entry["id"],
                tenantId: this.entry["tenantId"],
                projectId: this.projectId.value,
                priority: this.priority.value,
                isActive: this.entry["isActive"],
                name: this.name.value,
                typeId: this.taskType.value,
                reporter: this.reporter.value,
                taskStatus: this.taskStatus.value,
                assignedUser: this.assignedUser.value,
                plannedStartDate: this.plannedStartDate.value,
                actualStartDate: this.actualStartDate.value,
                plannedEndDate: this.plannedEndDate.value,
                actualEndDate: this.actualEndDate.value,
                commitDate: this.commitDate.value,
                description: this.description.value,
                labels: this.labels,
				milestones: this.milestones,
                sprintid: this.sprint.value
            }
        }
        this._projectsService.putProjectTask(entry).subscribe(
            data => {
                this.snackBar.open("Work Item Added Successfully", "", { duration: 2000 });
                this.labelUpdate.emit();
				this.milestoneUpdate.emit();
                this.getLabels();
				this.getMilestones();
                this.getUserProjects();
            },
            err => {
                this.snackBar.open("Error Adding Work Item", "", { duration: 2000 });
                this.getUserProjects();
            }
        );
    }

    triggerResize() {
        // Wait for changes to be applied, then trigger textarea resize.
        this._ngZone.onStable.pipe(take(1)).subscribe(() => this.autosize.resizeToFitContent(true));
    }

    getComments(taskId){
		this._projectsService.getTaskCommentsByTaskId(taskId.value).subscribe(
			data =>{
				this.comments = [];
				if(Object.keys(data).length == 0 || data == null || data == undefined){
				}else{
					this.parseDataIntoComments(data,(commentsData)=>{
                        for (let i=0; i<commentsData.length; i++){
							if (commentsData[i].fullName == 'User'){
								this.getFullNameFromUserId(commentsData[i].userId,(fullName) =>{
									commentsData[i].fullName = fullName;
									this.updateFullName(commentsData[i]);
							});
						}
					}
					this.putNewCommentsIntoCommentSection(commentsData)
				});
			}
		},
			err =>{
				// error while grabbing work item comments
			}
		)
	}

	addComment(commentContent:string){
		if (commentContent.length <= 500){
            /*added key:value pair for fullname and userId PS-473*/
			let addCommentObj = {taskId: this.id.value, comment: this.escapeSingleQuote(commentContent),userId: this.currentUserId,fullName:this.userFullName};
			this.disableComment = true;
            this._projectsService.putCommentToTask(addCommentObj).subscribe(
				data=>{
                    this.disableComment = false;
					if (data==null){
                        this.commentTextarea = '';
						this.getComments(this.id);
					}
                    else {
                        this.snackBar.open("Error Posting Comment", "", { duration: 2000 });
                    }
				},
				err=>{
                    this.disableComment = false;
                    console.log(err);
                    this.snackBar.open("Error Posting Comment", "", { duration: 2000 });
					// error adding comment
					// comment not added to database
				}
			);
		}
	}

    updateFullName(commentData){
		let updateCommentObj = {fullName: commentData.fullName,
			 				taskId: this.id.value,
			 				userId: commentData.userId};
		this._projectsService.putUpdateCommentFullName(updateCommentObj).subscribe(
			data => {
				// fullName updated
			},
			err => {
				// fullName not updated
			}
		)
	}

    getFullNameFromUserId(userId, callback){
		this._auth0ManagementApiService.getUserDetails(userId).subscribe(
			fullNameData =>{
				callback(fullNameData.fullName)
			},
			err =>{
				// error grabbing username, userId may not exist.
				// defaulting to 'User' when user doesn't exist
				callback('User');
			}
		);
	}

    parseDataIntoComments(data, callback){
		let fullNames = [];
		for (let i=0; i<data.length;i++){
			fullNames.push(data[i]['fullName'])
		}
		if (fullNames.includes('User')){
			callback(data)
		}else{
			this.putNewCommentsIntoCommentSection(data);
		}
	}

	putNewCommentsIntoCommentSection(commentsData){
		for (let i=0;i<Object.keys(commentsData).length;i++){
			commentsData[i].timestamp = new Date(commentsData[i].timestamp);
			this.comments.push(commentsData[i]);
			this.commentTextarea = '';
		}
	}

    escapeSingleQuote(string){
		let regex = /'/g;
		return string.replace(regex,"''");
	}
}
