import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: '[NumbersOnly]'
})
export class NumbersOnlyDirective {// Checks to see if the input is numbers only and only allows matching the regex

    // private regex: RegExp = new RegExp("[0-9]+\.?[0-9]*");
    private regex: RegExp = new RegExp(/^[0-9]+(\.[0-9]*){0,1}$/g);
    private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home'];

    constructor(public el: ElementRef) {

    }

    @HostListener('kedown', ['$event']) onKeydown(event: KeyboardEvent) {
        // Allow Backspace, tab, end, and home keys
        if (this.specialKeys.indexOf(event.key) !== -1) {
            return;
        }

        let current: string = this.el.nativeElement.value;

        let next: string = current.concat(event.key);
        if (next && !String(next).match(this.regex)) {
            event.preventDefault();
        }
    }

}
