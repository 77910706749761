import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';

@Component({
	selector: 'app-timesheet-approval-dialog',
	templateUrl: './timesheet-approval-dialog.component.html',
	styleUrls: ['./timesheet-approval-dialog.component.scss']
})
export class TimesheetApprovalDialogComponent implements OnInit {
	isManager: boolean = false;
	selectedServices: any[];
	selectedProjects: any[];
	selectedClients:  any[];

	constructor(public dialogRef: MatDialogRef<TimesheetApprovalDialogComponent>, public snackBar: MatSnackBar,
		@Inject(MAT_DIALOG_DATA) public rowData) { }

	dateArr = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

	ngOnInit() {
		this.isManager = this.rowData.isManager;
		//Loads filter arrays with data
		this.selectedServices = this.rowData['allServices'];
		this.selectedProjects = this.rowData['allProjects'];
		this.selectedClients = 	this.rowData['allClients'];

	}

	closeDialog() {
		this.dialogRef.close();
	}

	onKeyServices(value) {
		this.selectedServices = this.filterServices(value);
	}

	filterServices(value: string) {
		let filter = value.toLowerCase();
		return this.rowData['services'].filter(option => option['name'].toLowerCase().includes(filter));
	}

	onKeyProjects(value) {
		this.selectedProjects = this.filterProjects(value);
	}

	filterProjects(value: string) {
	  let filter = value.toLowerCase();
	  return this.rowData['projects'].filter(option => option['name'].toLowerCase().includes(filter));
	}

	onKeyClients(value) {
		this.selectedClients = this.filterClients(value);
	}

	filterClients(value: string) {
	  let filter = value.toLowerCase();
	  return this.rowData['clients'].filter(option => option['clientName'].toLowerCase().includes(filter));
	}

	//Resets the arrays that are used to display in the combobox
	resetDisplayArrays(){
		this.selectedServices = this.rowData['allServices'];
		this.selectedProjects = this.rowData['allProjects'];
		this.selectedClients =  this.rowData['allClients'];
	}

	//Sets focus of the combobox inputs on click
	setFocus(inputId: string) {
		if (document.getElementById(inputId) != null) {
			document.getElementById(inputId).focus();
		}
	}

	checkInColumn(activeColumns, currentColumn, type = "id") {
		if(activeColumns != undefined){
			if(type == "id"){
				return !activeColumns.some(e => e.id === currentColumn.id);
			}
			else if(type == "clientId"){
				return !activeColumns.some(e => e.clientId === currentColumn.clientId);
			}
		}
		return false;
	}

	saveAndCloseDialog() {
		if (this.rowData.entryRow.statusDetail['completed'] != "" && this.rowData.entryRow.statusDetail['completed'] != " " &&
			this.rowData.entryRow.statusDetail['completed'] != undefined && this.rowData.entryRow['clientId'] != "" &&
			this.rowData.entryRow['workClassId'] != "" && this.rowData.entryRow['projectId'] != "" && this.rowData.entryRow['serviceId'] != "") {

			this.dialogRef.close({ save: true });
		} else {
			this.snackBar.open("Entry not complete, fill completely to save.", "", {
				duration: 4000,
				panelClass: ["snackbar-incomplete"]
			});
		}

	}

	refreshCell(day: number, event) {// When the cell is being changed this metod is called to update totals and refresh the table
		let val = 0;
		if (event.target.value != '') {
			val = parseFloat(event.target.value);
		}
		this.rowData.entryRow.weekHour[day].timeHour = val;
	}
}
