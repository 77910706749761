import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { FormControl } from '../../../../node_modules/@angular/forms';

@Component({
    selector: 'app-project-portfolios-dialog',
    templateUrl: './project-portfolios-dialog.component.html',
    styleUrls: ['./project-portfolios-dialog.component.scss']
})
export class ProjectPortfoliosDialogComponent implements OnInit {
    portfolioName: FormControl = new FormControl(this.portfolioData['entry']['name']);
    status: FormControl = new FormControl(this.portfolioData['entry']['status']);
    plannedStartDate: FormControl = new FormControl(this.portfolioData['entry']['plannedStart']);
    startDate: FormControl = new FormControl(this.portfolioData['entry']['actualStart']);
    plannedEndDate: FormControl = new FormControl(this.portfolioData['entry']['plannedCompletion']);
    endDate: FormControl = new FormControl(this.portfolioData['entry']['actualCompletion']);
    isPortfolioValid: boolean = true;
    portfolioValid: String;

    portfolioStatuses = [
        {
            name: 'On Track',
            id: 1
        },
        {
            name: 'Slight Risk',
            id: 2
        },
        {
            name: 'High Risk',
            id: 3
        }
    ];

    constructor(public dialogRef: MatDialogRef<ProjectPortfoliosDialogComponent>, public dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public portfolioData) { }

    ngOnInit() {
    }

    saveAndCloseDialog() {
        if (this.isPortfolioDetailsValid()) {
            this.portfolioData['entry']['name'] = this.portfolioName.value;
            this.portfolioData['entry']['status'] = this.status.value;
            this.portfolioData['entry']['plannedStart'] = this.plannedStartDate.value;
            this.portfolioData['entry']['plannedCompletion'] = this.plannedEndDate.value;
            this.portfolioData['entry']['actualStart'] = this.startDate.value;
            this.portfolioData['entry']['actualCompletion'] = this.endDate.value;

            this.dialogRef.close({ submit: true, data: this.portfolioData['entry'] });
        }
    }

    isPortfolioDetailsValid() {
        if (this.portfolioName.value == null || this.portfolioName.value == '' ) {
            this.portfolioValid = 'Portfolio Name is required';
            return false;
        }
        if (this.status.value == null) {
            this.portfolioValid = 'Status is required';
            return false;
        }
        if (this.plannedStartDate.value == null) {
            this.portfolioValid = 'Planned Start Date is required';
            return false;
        }
        if (this.plannedEndDate.value == null) {
            this.portfolioValid = 'Planned End Date is required';
            return false;
        } else {
            this.isPortfolioValid = true;
            return true;
        }
    }

    closeDialog() {
        this.dialogRef.close();
    }
}
