import { Component, OnInit, Inject } from '@angular/core';
import { FormControl } from '../../../../../node_modules/@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatSnackBar } from '../../../../../node_modules/@angular/material';
import * as moment from 'moment';

@Component({
  selector: 'app-management-timeoff-holiday-dialog',
  templateUrl: './management-timeoff-holiday-dialog.component.html',
  styleUrls: ['./management-timeoff-holiday-dialog.component.scss']
})
export class ManagementTimeoffHolidayDialogComponent implements OnInit {

    holidayDate: FormControl = new FormControl(this.entry['holidayDate'] != null ? moment(this.entry['holidayDate'].slice(0, 10)).local(true).toDate() : null);
    reason: FormControl = new FormControl(this.entry['reason']);
	error: String;

    constructor(public snackBar: MatSnackBar, public dialogRef: MatDialogRef<ManagementTimeoffHolidayDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public entry) { }

    ngOnInit() {
	}
	
	closeDialog() {
		this.dialogRef.close();
	}
	
	
	//Sets focus of the combobox inputs on click
	setFocus(inputId:string){
		if(document.getElementById(inputId) != null){
			document.getElementById(inputId).focus();
		}
	}
	
	save() {
		this.entry['reason'] = this.reason.value;
		this.entry['holidayDate'] = this.holidayDate.value;
		if (this.validator())
			this.dialogRef.close({ submit: true, data: this.entry });
		else
			this.snackBar.open('All fields are not filled', '', { duration: 2000 });
			this.error = "All fields must be filled";
	}
	
	validator() {
		if (this.entry['reason'] != null && this.entry['holidayDate'] != null) {
			return true;
		} else {
			return false;
		}
	}
}
