import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '../../../node_modules/@angular/common/http';
import { MatSnackBar } from '../../../node_modules/@angular/material';
import { GlobalService } from '../api/globalVars';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    withCredentials: true
};

@Injectable({
  providedIn: 'root'
})
export class TimeoffService {
    path = this.globalVar.retrievePath();

    constructor(private _http: HttpClient,
        public globalVar: GlobalService, public snackBar: MatSnackBar) { }

    getTimeoffCategories() {
        const geturl = this.path + 'timeoffcategory';
        return this._http.get(geturl, httpOptions);
    }

    getAllTimeoffCategories() {
        const geturl = this.path + 'timeoffcategory?allFlag=1';
        return this._http.get(geturl, httpOptions);
    }

    getTimeoffEntries(startDate, endDate) {
        const geturl = this.path + `timeoffentry?startDate=${startDate}&endDate=${endDate}`;
        return this._http.get(geturl, httpOptions);
    }

    getTimeoffEntriesForTeam(startDate, endDate) {
        const geturl = this.path + `timeoffentry?startDate=${startDate}&endDate=${endDate}&teamView=true`;
        return this._http.get(geturl, httpOptions);
    }

    getTimeoffUsersAndProjectsForTeam() {
        const geturl = this.path + `timeoffentry?&teamView=true&retrieveInfo=true`;
        return this._http.get(geturl, httpOptions);
    }

    getTimeoffApprovalEntries(startDate, endDate) {
        const geturl = this.path + `timeoffentry?tenantFlag=1&startDate=${startDate}&endDate=${endDate}`;
        return this._http.get(geturl, httpOptions);
    }

    getUsers() {
        const geturl = this.path + 'tenantuser?isActive=-1';
        return this._http.get(geturl, httpOptions);
    }

    postUpdateTimeoffEntry(timeoffEntry) {
        const posturl = this.path + 'timeoffentry?updateFlag=1';
        return this._http.post(posturl, timeoffEntry, httpOptions);
    }

    postTimeoffEntry(timeoffEntry, isSubmitting) {
        const posturl = this.path + 'timeoffentry?updateFlag=' + isSubmitting;
        return this._http.post(posturl, timeoffEntry, httpOptions);
    }

    postTimeoffApprovalEntry(timeoffEntry, isApproving, deleteQB = false) {
        const posturl = this.path + 'timeoffentry?approveFlag=' + isApproving + '&deleteQB=' + deleteQB;
        return this._http.post(posturl, timeoffEntry, httpOptions);
    }

    DeleteTimeoffEntry(entryId) {
        const deleteurl = this.path + 'timeoffentry?entryId=' + entryId;
        return this._http.delete(deleteurl, httpOptions);
    }

    postPTOPreview(){
        const posturl = this.path + 'ptopreview';
        return this._http.post(posturl, httpOptions);
    }

    getTimeOffBlackOutDates(startDate = null, endDate = null){
        const geturl = this.path + `timeoffblackout?startDate=${startDate}&endDate=${endDate}`;
        return this._http.get(geturl, httpOptions);
    }

    addUpdateTimeOffBlackOutDate(blackOutDate){
        const posturl = this.path + 'timeoffblackout';
        return this._http.post(posturl, blackOutDate, httpOptions);
    }

    deleteTimeOffBlackOutDate(id){
        const deleteurl = this.path + 'timeoffblackout?entryId=' + id;
        return this._http.delete(deleteurl, httpOptions);
    }

    getTimeOffHolidayDates(startDate = null, endDate = null){
        const geturl = this.path + `timeoffholiday?startDate=${startDate}&endDate=${endDate}`;
        return this._http.get(geturl, httpOptions);
    }

    addUpdateTimeOffHolidayDate(holidayDate){
        const posturl = this.path + 'timeoffholiday';
        return this._http.post(posturl, holidayDate, httpOptions);
    }

    deleteTimeOffHolidayDate(id){
        const deleteurl = this.path + 'timeoffholiday?entryId=' + id;
        return this._http.delete(deleteurl, httpOptions);
    }

}
