import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate } from '@angular/router';
import { iif, Observable, of } from 'rxjs';
import { AuthService } from '../../services/auth/auth.service';
import { Router } from "@angular/router";
import { Location } from "@angular/common";
import { HomeViewService } from "../../services/home-view/home-view.service";
import { concatMap, map } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material';

@Injectable({
  providedIn: 'root'
})

export class HomeGuard implements CanActivate {

  constructor(private location: Location, private homeViewService: HomeViewService,
    public router: Router, private authService: AuthService,
		public snackBar: MatSnackBar) {}
  validHomeViews: string[] = ["time-entry", "kanban-board", "project-portfolios", "time-off", "team-calendar", "expenses"];

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean|UrlTree> | boolean {
    return this.authService.isAuthenticated.pipe(
      concatMap(() => this.authService.handleAuthCallback()),
      concatMap((result: { loggedIn: boolean, targetUrl: string }) => {
        var currentURL = state.url.split("?")[0];
        if(result.loggedIn && currentURL == "/home"){
          this.homeRedirectLocation()
          return of(true);
        }
        else {
          return this.authService.login(state.url)
          .pipe(map(() => false));
        }
    }));
  }

  homeRedirectLocation(){
    let homeViewSelection = this.homeViewService.getAppHomeViewFromCookie();
    if (homeViewSelection != undefined) {
      if(this.validHomeViews.includes(homeViewSelection))
        this.redirectUrlFromHomepage(homeViewSelection);
      else
        this.router.navigateByUrl("404");
    } 
    else {
      this.homeViewService.getAppHomeViewFromDB().subscribe((data) => {
          homeViewSelection = data["response"].toString();
          if(this.validHomeViews.includes(homeViewSelection)){
            this.redirectUrlFromHomepage(homeViewSelection);
            this.homeViewService.saveAppHomeViewToCookie(homeViewSelection);
          }
          else {
            this.router.navigateByUrl("404");
          }
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }

  redirectUrlFromHomepage(url: string) {
    this.location.replaceState(url);
    // This closes the sidenav and redirects with the router rather than an href
    this.router.navigateByUrl(url);
  }
}
