import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { SetttingsService } from '../../api/setttings.service';
import { FormControl } from '@angular/forms';
import { MatDialog, MatSnackBar } from '@angular/material';
import { ReportService } from '../../api/report.service';
import { DomSanitizer } from '@angular/platform-browser';
import * as moment from 'moment';
import { CookieService } from 'ngx-cookie';
import { AuthService } from '../../services/auth/auth.service'
import {ViewSDKClient} from '../view-sdk.service'

@Component({
    selector: 'app-pto-report',
    templateUrl: './pto-report.component.html',
    styleUrls: ['./pto-report.component.scss']
})
export class PTOReportComponent implements OnInit, OnChanges{
    users = [];
    selectedUsers: object[];
    reportData = Object();
    display: any;
    title: any;
    url: any;
    adminAccess: boolean = false;
    currentId: string;
	isUsersLoading: boolean = true;
    isReportLoading: boolean = false;
	currentUserObject;
	promisedUser;

    userId: FormControl = new FormControl(this.reportData['userId']);
    year: FormControl = new FormControl(this.reportData['year']);

	@Input() senderUsers = [];
	usersForFilter;

	constructor(private sanitizer: DomSanitizer, private _settingsService: SetttingsService,
        private _reportService: ReportService, public snackBar: MatSnackBar,
        private _cookieService: CookieService, private authService: AuthService,private viewSDKClient: ViewSDKClient) { }

    ngOnInit() {
        this.authService.getUserPermissions().subscribe(userPermissions =>{
            this.adminAccess = userPermissions.includes('manage-application-settings:administration');
		});

        this.year.setValue(new Date().getFullYear());

		//get current user information
		this._settingsService.getUserSettings().subscribe(data => {
			if(data != null) {
				//set current user object
				this.currentUserObject = data;
				//set current Id to populate default dropdown value.
				this.currentId = this.currentUserObject.userId
				//if not an admin set available dropdown users to only the current user.
				if(!this.adminAccess) {
					this.users.push(this.currentUserObject)
				}
			}
		});
    };

	ngOnChanges(changes: SimpleChanges){
		if(changes.senderUsers != undefined){
			//if user is an admin let them see all users.
			if(this.adminAccess) {
				this.usersForFilter = this.senderUsers;
				this.users = this.senderUsers;
				this.isUsersLoading = false;
			}

		}
	}

    onKeyUser(value) {
        this.users = this.filterUsers(value);
    }

    filterUsers(value: string) {
        let filter = value.toLowerCase();
        return this.usersForFilter.filter(option => option['fullName'].toLowerCase().includes(filter));
    }

    //Sets focus of the combobox inputs on click
    setFocus(inputId: string) {
        if (document.getElementById(inputId) != null) {
            document.getElementById(inputId).focus();
        }
    }

      
    generateReport() {
        if (this.year.value != null) {
            this.reportData['userId'] = this.userId.value;
            this.isReportLoading = true;
            this.reportData['start'] = new Date(this.year.value, 0, 1);
            this.reportData['end'] = new Date(this.year.value, 11, 31);
            this._reportService.generatePTOReport(this.reportData).subscribe(data => {
                this.isReportLoading = false;
                var pdfData = data['response'];
                this.title = data['name'];
                this.url = data['response'];
                this.display = true;
                const previewConfig = {
                    embedMode: "FULL_WINDOW",
                    showDownloadPDF: false,
                    showPrintPDF: false
                 }
                 
                const adobeDCView = new ViewSDKClient();
                adobeDCView.ready().then(() => {
                    adobeDCView.previewFile('pdf-div',previewConfig, pdfData, this.title)
                        .then(() => {

                        })
                        .catch(error => {
                            console.error('Error loading PDF:', error);
                        });
                });
    
            }, err => {
                this.isReportLoading = false;
                this.snackBar.open('No Report Data Found', '', { duration: 2000 });
            });
        } else {
            this.isReportLoading = false;
            this.snackBar.open('Not all fields filled', '', { duration: 2000 });
        }
    }


    downloadReport() {
        let reportUrl: string = this.url;
        var a = document.createElement('a');
        a.href = reportUrl;
        a.setAttribute('download', this.title);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }
}
