import { Component, OnInit, Inject, ViewChild, OnChanges, ViewChildren, ChangeDetectorRef } from '@angular/core';
import { FormControl, Form, FormGroup } from '../../../../../node_modules/@angular/forms';
import { ProjectsService } from '../../../api/projects.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatTableDataSource, MatSnackBar, MatPaginator, MatSort, PageEvent } from '../../../../../node_modules/@angular/material';
import { SetttingsService } from '../../../api/setttings.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { first } from 'rxjs/operators';
import { userInfo } from 'os';
var cloneDeep = require('lodash.clonedeep');
@Component({
    selector: 'app-management-project-dialog',
    templateUrl: './management-project-dialog.component.html',
    styleUrls: ['./management-project-dialog.component.scss']
})
export class ManagementProjectDialogComponent implements OnInit{
	projectId: FormControl = new FormControl(this.projectData['entry']['id']);
    projectName: FormControl = new FormControl(this.projectData['entry']['name']);
	plannedStartDate:  FormControl = new FormControl(this.projectData['entry']['plannedStartDate']);
	plannedEndDate:  FormControl = new FormControl(this.projectData['entry']['plannedEndDate'])
    startDate: FormControl = new FormControl(this.projectData['entry']['actualStartDate']);
    endDate: FormControl = new FormControl(this.projectData['entry']['actualEndDate']);
    plannedHours: FormControl = new FormControl(this.projectData['entry']['budgetedHours']);
	budgetedAmount: FormControl = new FormControl(this.projectData['entry']['budgetedAmount']);
    managerId: FormControl = new FormControl(this.projectData['entry']['projectManagerUserId']);
    managerRate: FormControl = new FormControl(this.projectData['entry']['projectManagerRate']);
	clientName: FormControl = new FormControl(this.projectData['entry']['client']);
    clientId: FormControl = new FormControl(this.projectData['entry']['clientId']);
    description: FormControl = new FormControl(this.projectData['entry']['description']);
    statusId: FormControl = new FormControl(this.projectData['entry']['statusId']);
    projectNumber: FormControl = new FormControl({value: this.projectData['entry']['number'], disabled: this.projectData['entry']['number'] != null});
    clientPO: FormControl = new FormControl(this.projectData['entry']['clientPoNumber']);
    isActiveFormControl: FormControl = new FormControl(this.projectData['entry']['isActive']);
    displayedColumnsUsers = ['name', 'rate', 'title', 'delete', 'edit'];
    valid: boolean = true;
    error: String;
	userError: String;
    isProjectUserLoading: boolean = false;
    buttonIsClicked: boolean = false;
    saveAndContinueIsClicked: boolean = false;

    @ViewChild('nameInput')
    nameElement: any;
    @ViewChild('hours')
    hoursElement: any;
    @ViewChild('desc')
    descriptionElement: any;
    @ViewChild('titleInput')
    titleElement: any;

    dataArray = [];

    currentUser = Object({
        userId: '',
        projectRole: '',
        projectRate: null
    });
    editing: boolean = false;
    user: FormControl = new FormControl(this.currentUser['userId']);
    title: FormControl = new FormControl();
    rate: FormControl = new FormControl();
    userList = [];
	assignableUsers = [];
    userModel;
    assignedUsers = [];
    statuses: object[] = [];
    userDataSource = new MatTableDataSource();
	activeProjects: object[] = [];

    currentManager;
	selectedManagers: object[] = [];
	loadingProjectSelect: Boolean = false;
	relationshipClients: object[] = [];
	selectedClients: object[] = [];

	@ViewChild(MatPaginator) userPaginator: MatPaginator;
	@ViewChild("relationshipPaginator") relationshipPaginator: MatPaginator;
	@ViewChildren(MatSort) sort;

	editRelationshipObj = Object({ // this object needs to be in this format for inserting
		name: '',
		projects: [{
			projectIdPrimary: null,
			projectIdSecondary: null,
			projectNamePrimary: null,
			name: null,
			relationship: null
			}]
	});

	displayedColumnsRelationships = ['Client','Project',"Relationship",'delete','edit','open'];
	relationshipTypes = ["is Child to" , "is Sibling to" , "is Parent to"];
	currentProjectRelationships:any = [];
	primaryProjectIds:any[] = [];
	secondaryProjectIds:any[] = [];
	projectsForRelationshipsSelect:any = [];
	filterInputProjectsForRelationshipsSelect:any = [];
	relationshipDisplayDataSource:MatTableDataSource<any>;
	relationshipDisplayDataSourceLoading;
	editingRelationship = false;
	tabIndex = 0;

	relationshipFormGroup = new FormGroup({
		projectNamePrimary: new FormControl({value: this.projectName.value, disabled: true}),
		projectNameSecondary:  new FormControl(this.editRelationshipObj['projects'][0]['projectNameSecondary']),
		relationship: new FormControl(this.editRelationshipObj['projects'][0]['relationship'])
	});

    constructor(private _settingsService: SetttingsService,
        public dialogRef: MatDialogRef<ManagementProjectDialogComponent>,
		public snackBar: MatSnackBar,
        public _projectsService: ProjectsService,
        @Inject(MAT_DIALOG_DATA) public projectData,
		public _authService: AuthService) {
    }

    ngOnInit() {
		this.loadingProjectSelect = true;
        this.valid = true;
        if (this.projectData['entry']['isActive'] == null) {
            this.projectData['entry']['isActive'] = false;
        }

        if (this.projectData['entry']['projectManagerUserId']) {
            for (let user of this.projectData['entry']['assignedUsers']) {
                if (user['userId'] == this.managerId.value) {
                    this.currentManager = user;
                    this.managerRate.setValue(user.projectRate);
                    this.projectData['entry']['projectManagerRate'] = user.projectRate;
                }
            }
        }

		if(this.projectData['managers'] != null){
			this.assignableUsers = Object.assign([], this.projectData['managers']);
			this.selectedManagers = Object.assign([], this.projectData['managers']);
			this.assignableUsers = this.assignableUsers.sort(function(a,b) { 
				if (a.fullName > b.fullName) return 1 
				if (b.fullName > a.fullName) return -1 
				return 0
			});
			this.selectedManagers = this.selectedManagers.sort(function(a,b) { 
				if (a['fullName'] > b['fullName']) return 1 
				if (b['fullName'] > a['fullName']) return -1 
				return 0
			});
		}
		this.selectedClients = cloneDeep(this.projectData.clients);
		this.selectedClients = this.sortClientsForAdding();

		this.displayAssignedUsers();
        this.getProjectStatus();

		if(this.projectId.value != null){
			this.loadRelationshipDialog();
		}
	}

	/*sorts clients alphabetically for easier selection*/
	sortClientsForAdding() {
		let tempData = this.selectedClients;

		tempData = tempData.sort((a, b) => a['name'].toLowerCase().localeCompare(b['name'].toLowerCase()))
		return tempData;
	}

	onKeyManager(value) {
		this.selectedManagers = this.filterManagers(value);
	}

	filterManagers(value: string) {
	  let filter = value.toLowerCase();
	  return this.projectData['managers'].filter(option => option['fullName'].toLowerCase().includes(filter));
	}

	onKeyClient(value) {
		this.selectedClients = this.filterClients(value);
	}

	filterClients(value: string) {
	  let filter = value.toLowerCase();
	  return this.projectData['clients'].filter(option => option['name'].toLowerCase().includes(filter));
	}

	//Sets focus of the combobox inputs on click
	setFocus(inputId:string){
		if(document.getElementById(inputId) != null){
			document.getElementById(inputId).focus();
		}
	}

    getProjectStatus(){
        this._projectsService.getStatus().subscribe(data => {
            this.statuses = Object.assign([], data);
        })
    }

    deleteUser(user) {
        this.userDataSource = new MatTableDataSource([]);
        this.isProjectUserLoading = true;
        this._settingsService.deleteProjectUser(user['userId'], user['projectId']).subscribe(data => {
            this.getProjectUsers();
        });
    }

    addUser() {
        if (this.isValidUser()) {
			this.userDataSource = new MatTableDataSource([]);
        	this.isProjectUserLoading = true;
            this.userModel = new Object({
                projectId: this.projectData['entry']['id'],
                userId: this.user.value,
                projectRole: this.title.value,
                projectRate: Number(this.rate.value),
				newUser: true
			});
            this._settingsService.postProjectUser(this.userModel).subscribe(data => {
                this.getProjectUsers();
                this.user.reset();
                this.title.reset();
                this.rate.reset();
            });
            this.userModel['name'] = this.userList[this.user.value];
        }
    }

    finishEditingUser()	{
        this.editing = false;
            this.user.enable();
            this.currentUser = new Object({
                userId: '',
                projectRole: '',
                projectRate: 0
            });
    }

    updateUser() {
        this.userDataSource = new MatTableDataSource([]);
		this.isProjectUserLoading = true;
        if (this.title.value != null) this.currentUser.projectRole = this.title.value;
        if (this.rate.value != null) this.currentUser.projectRate = this.rate.value;
		this.currentUser.projectRate = parseInt(this.currentUser.projectRate);
		this.currentUser.newUser = false;
		
        this._settingsService.postProjectUser(this.currentUser).subscribe(data => {
            this.getProjectUsers();
            this.finishEditingUser();
        });
    }

    cancelUserUpdate()	{
        this.finishEditingUser();
    }

    isNotUsed() {
        for (let user of this.assignedUsers){
            if (this.user.value == user.userId) {
                return false;
            }
        }
        return true;
    }

    editUser(user) {
        this.editing = true;
        this.currentUser = user;
        this.user.disable();
        this.titleElement.nativeElement.focus();
    }

    closeDialog(saveAndContinueIsClicked = false) {
        if(!saveAndContinueIsClicked){
            this.dialogRef.close();
        }
        else {
            this.dialogRef.close({ submit: true, data: this.projectData['entry'] });
        }
    }

	formatDate(date) {
        if(date == null){
            return new Date().toISOString().slice(0, 19).replace('T', ' ');
        }
        else {
            return new Date(date).toISOString().slice(0, 19).replace('T', ' ');
        }
    }

    saveAndCloseDialog(closeAndSave = true) {
        if (this.currentManager != undefined) {
            if (this.managerId.value != this.currentManager['userId'] || this.managerRate.value != this.currentManager['projectRate']) {
				this.deleteUser(this.currentManager);
				this.currentManager['oldUserId'] = this.currentManager['userId'];
				this.currentManager['userId'] = this.managerId.value;
				this.currentManager['projectRate'] = this.managerRate.value;
				this.currentManager['projectRole'] = 'Project Manager';
                this._settingsService.postProjectUser(this.currentManager).subscribe(data => {
                });
            }
        }

        if (this.isValid()) {
            this.projectData['entry']['name'] = this.projectName.value;
			if(this.plannedStartDate.value != null) this.projectData['entry']['plannedStartDate'] = new Date (this.formatDate(this.plannedStartDate.value).slice(0, 10) + 'T24:00:00.000Z');
			if(this.plannedEndDate.value != null) this.projectData['entry']['plannedEndDate'] = new Date (this.formatDate(this.plannedEndDate.value).slice(0, 10) + 'T24:00:00.000Z');
            this.projectData['entry']['actualStartDate'] = new Date (this.formatDate(this.startDate.value).slice(0, 10) + 'T24:00:00.000Z');
            if(this.endDate.value != null) this.projectData['entry']['actualEndDate'] = new Date (this.formatDate(this.endDate.value).slice(0, 10) + 'T24:00:00.000Z');
            this.projectData['entry']['budgetedHours'] = this.plannedHours.value == null ? 0 : this.plannedHours.value;
			this.projectData['entry']['budgetedAmount'] = this.budgetedAmount.value == null ? 0 : this.budgetedAmount.value;
            this.projectData['entry']['projectManagerUserId'] = this.managerId.value;
            this.projectData['entry']['projectManagerRate'] = this.managerRate.value;
            this.projectData['entry']['clientId'] = this.clientId.value;
            this.projectData['entry']['description'] = this.description.value;
            this.projectData['entry']['statusId'] = this.statusId.value;
            this.projectData['entry']['clientPoNumber'] = this.clientPO.value;
            this.projectData['entry']['number'] = this.projectNumber.value;
			this.projectData['entry']['isActive'] = this.isActiveFormControl.value;

            if(closeAndSave){
                this.buttonIsClicked = false;
                this.dialogRef.close({ submit: true, data: this.projectData['entry'] });
            }
            else {
                //This is for the save and continue to do assigned users
                this.addContinueProjectData(this.projectData['entry']);
            }
        }
    }

    displayAssignedUsers() {
        this.isProjectUserLoading = true;
		this.assignedUsers = this.projectData['entry']['assignedUsers'];
		//populate userList from users related to the project
		if (this.projectData['allUsers'].length > 0)
            for (let user of this.projectData['allUsers']) {
                this.userList[user['userId']] = user['fullName'];
            }
		//populate assigned users name from the userList
		if (this.assignedUsers) {
            for (let user of this.assignedUsers) {
                user['name'] = this.userList[user['userId']];
            }
        }
		//sort by project manager then alphabetically
		if (this.assignedUsers) {
			this.assignedUsers.sort(function(a,b) { 
				//if two project managers sort them alphabetically
				if(a.projectRole == "Project Manager" && b.projectRole == "Project Manager") {
					if (a.name > b.name) return 1 
					if (b.name > a.name) return -1 
					return 0
				}
				//put project managers first
				if(a.projectRole == "Project Manager") return -1
				if(b.projectRole == "Project Manager") return 1
				//if not project managers sort alphabetically
				else {
					if (a.name > b.name) return 1 
					if (b.name > a.name) return -1 
					return 0
				}
			});
		}

		//add users to the data source
		this.userDataSource = new MatTableDataSource(this.assignedUsers)
		this.userDataSource.paginator = this.userPaginator;

		let filterIds = [];
		if (this.assignedUsers) {
			for (var i=0; i < this.assignedUsers.length ; ++i)
				filterIds.push(this.assignedUsers[i]['userId']);
		}
		
		this.assignableUsers = Object.assign([], this.projectData['managers']);
		this.assignableUsers = this.assignableUsers.filter(function(item) {
			return !(filterIds.indexOf(item.userId) !== -1);
		});
		this.assignableUsers = this.assignableUsers.sort(function(a,b) { 
			if (a.fullName > b.fullName) return 1 
			if (b.fullName > a.fullName) return -1 
			return 0
		});
		this.isProjectUserLoading = false;
    }

    getProjectUsers() {
        this._settingsService.getProjectUsers(this.projectId.value).subscribe(
            data => {
				this.projectData['entry']['assignedUsers'] = data;
                this.displayAssignedUsers();
            }, err => {
            }
        );
    }

	async createRelationship(){
		this.isProjectUserLoading = true;
		let relationship = this.parseRelationship();
		let projectIdPrimary = this.projectId.value;
		let	projectNamePrimary = this.projectName.value;
		let projectIdSecondary = this.relationshipFormGroup.get('projectNameSecondary').value.id;
		let projectNameSecondary = this.relationshipFormGroup.get('projectNameSecondary').value.name;
		let clientNameSecondary = this.relationshipFormGroup.get('projectNameSecondary').value.client;
		let userId = await this._authService.getUserId().pipe(first()).toPromise();
		if(projectIdPrimary != null && projectNameSecondary != null && relationship != null && projectNamePrimary != null && clientNameSecondary != null && projectIdSecondary != null && userId != null){
			this.error = "";
			let relationshipObj = {projectIdPrimary:projectIdPrimary,
								projectNamePrimary:projectNamePrimary,
								projectIdSecondary:projectIdSecondary,
								projectNameSecondary:projectNameSecondary,
								relationship:relationship,
								createdBy:userId,
								clientNamePrimary: this.clientName.value,
								clientNameSecondary: clientNameSecondary
								};
			if (relationshipObj.relationship == 'Child'){
				this.reverseRelationship(relationshipObj);
			}
			this._projectsService.putNewRelationship(relationshipObj).subscribe(
				data => {
					this.loadRelationshipDialog();
					this.relationshipFormGroup.get('relationship').reset();
					this.relationshipFormGroup.get('projectNameSecondary').reset();
					this.isProjectUserLoading = false;
				},
				err => {
					console.log("Issue creating new relationship");
					this.isProjectUserLoading = false;
				}
			);
		}
		else{
			this.error = "All Fields are required to create a relationship";
			this.isProjectUserLoading = false;
		}
	}

	parseRelationship() {
		let relationship = this.relationshipFormGroup.get('relationship').value;
		// swap relationship  of child and parent because relationship is swapped later for insert
		if(relationship.includes('Child')) return "Parent";
		if(relationship.includes('Sibling')) return "Sibling";
		if(relationship.includes('Parent')) return "Child";
		return null;
	}

	loadRelationshipDialog() {
		this.loadingProjectSelect = true;
		this.relationshipDisplayDataSource = new MatTableDataSource([]);
		this.relationshipDisplayDataSourceLoading = true;
		this._projectsService.getAllProjectRelationships(this.projectId.value).subscribe(
			data => {
				this.currentProjectRelationships = data;
				this.relationshipDisplayDataSource = new MatTableDataSource(this.parseRelationshipsForDisplay(this.currentProjectRelationships));
				this.relationshipClients = cloneDeep(this.projectData.clients);
				this.setAllProjectIdsInRelationship(); // grabs all project ids for parsing project select
				this.filterProjectsForRelationshipsSelect(); // filters out current project and projects already in relationship for select
				this.configRelationshipHeadingSort();

				this.relationshipDisplayDataSource.paginator = this.relationshipPaginator;
				this.relationshipDisplayDataSourceLoading = false;
				this.loadingProjectSelect = false;
			},
			err => {
				console.log("Issue loading relationship dialog");
			}
		)
	}

	filterProjectsForRelationshipsSelect() {
		this.relationshipClients = cloneDeep(this.projectData.clients);
		for(var clientIndex = this.relationshipClients.length - 1; clientIndex >= 0; clientIndex--){
			var client = this.relationshipClients[clientIndex];
			if(client['projects'] && client['projects'].length != 0){
				for(var projectsIndex = client['projects'].length - 1; projectsIndex >= 0; projectsIndex--){
					var project = client['projects'][projectsIndex];
					if(this.isCurrentProject(project['id']) || (this.areProjectsInRelationship(project['id']))) this.relationshipClients[clientIndex]['projects'].splice(projectsIndex,1);
					if(client['projects'].length == 0) this.relationshipClients.splice(clientIndex,1); // remove client if no projects
				}
			}else{
				this.relationshipClients.splice(clientIndex,1); // remove client if no projects
			}
		}
		this.relationshipClients.sort((a,b) => (a['name'] > b['name']) ? 1 : (b['name'] > a['name'] ? -1 : 0)) // sort by client name
	}

	isCurrentProject(projectForRelationshipsSelect) {
		if(projectForRelationshipsSelect == this.projectId.value) return true;
		return false;
	};

	areProjectsInRelationship(projectForRelationshipsSelectId){
		if(this.primaryProjectIds.includes(projectForRelationshipsSelectId) ||
		this.secondaryProjectIds.includes(projectForRelationshipsSelectId)) return true
		return false;
	}

	parseRelationshipsForDisplay(Relationships){
		let parsedRelationships = []
		for (var i = 0;i<Relationships.length;i++){
			let relationship = this.ifSecondaryId(Relationships[i]);
			relationship = this.determineRelationshipUsingDepth(relationship);
			if (relationship.siblingExclusion == undefined){
				parsedRelationships.push(relationship);// parses out non-immediate siblings
			}
		}
		return parsedRelationships;
	}

	ifSecondaryId(relationship){
		if(relationship.projectIdSecondary == this.projectId.value || relationship.depth > 0) relationship = this.reverseRelationship(relationship);
		return relationship;
	}

	determineRelationshipUsingDepth(relationship) {
		if (relationship.depth <= -1 || relationship.depth >= 1){
			if (relationship.relationship == 'Sibling'){
				relationship.siblingExclusion = true;
				return relationship;
			}
		}
		if (relationship.depth<0) relationship.relationship = 'Ancestor';
		else if(relationship.depth>0) relationship.relationship = 'Descendant';
		return relationship;
	}

	reverseRelationship(relationship){
		let tempProjectNamePrimary = relationship.projectNamePrimary;
		let tempProjectNameSecondary = relationship.projectNameSecondary;
		let tempClientNamePrimary = relationship.clientNamePrimary;
		let tempClientNameSecondary = relationship.clientNameSecondary;
		let tempProjectIdPrimary = relationship.projectIdPrimary;
		let tempProjectIdSecondary = relationship.projectIdSecondary;

		relationship.projectNamePrimary = tempProjectNameSecondary;
		relationship.projectNameSecondary = tempProjectNamePrimary;
		relationship.clientNamePrimary = tempClientNameSecondary;
		relationship.clientNameSecondary = tempClientNamePrimary;
		relationship.projectIdPrimary = tempProjectIdSecondary;
		relationship.projectIdSecondary = tempProjectIdPrimary;

		return relationship;
	}

	setAllProjectIdsInRelationship(){
		this.primaryProjectIds = [];
		this.secondaryProjectIds = [];

		this.currentProjectRelationships.forEach( relationship =>{
			if(!this.primaryProjectIds.includes(relationship.projectIdPrimary)) this.primaryProjectIds.push(relationship.projectIdPrimary);
			if(!this.secondaryProjectIds.includes(relationship.projectIdSecondary)) this.secondaryProjectIds.push(relationship.projectIdSecondary);
		});
	}

    addContinueProjectData(entry) {
        this._settingsService.putProject(entry).subscribe(
            data => {
                this.projectData['entry']['id'] = data[0]['id'];
				this.projectData['entry']['assignedUsers'] = data[0]['assignedUsers'];
				this.projectId.setValue(data[0]['id']);
                this.projectNumber.setValue(data[0]['number']);
                this.projectNumber.disable();
				var client = this.projectData.clients.find(client => client.id == data[0]['clientId']);
				this.clientName.setValue(client.name)

				this.tabIndex = 1;
                this.displayAssignedUsers();
				this.loadRelationshipDialog();
                this.buttonIsClicked = false;
            }, err => {
                this.buttonIsClicked = false;
            }
        );
    }

	deleteRelationship(relationship){
		this._projectsService.deleteRelationship(relationship.projectIdPrimary,relationship.projectIdSecondary)
		.subscribe(
			data => {
				this.loadRelationshipDialog();
			},
			err => {
				console.log("Issue deleting relationship");
			}
		)
	}

	editRelationship(projectRelationship){
		this.editingRelationship = true;
		var project = {
			projectIdPrimary: projectRelationship.projectIdPrimary,
			projectIdSecondary: projectRelationship.projectIdSecondary,
			projectNamePrimary: projectRelationship.projectNamePrimary,
			name: projectRelationship.projectNameSecondary,
			relationship: projectRelationship.relationship
		}
		this.editRelationshipObj = {
			name: '',
			projects: [project]
		}
		this.relationshipClients.push(this.editRelationshipObj);

		this.relationshipFormGroup.get('relationship').setValue("is "+this.editRelationshipObj['projects'][0]['relationship']+" to");
		this.relationshipFormGroup.get('projectNameSecondary').setValue(this.editRelationshipObj['projects'][0]);

		this.removeItemFromArray(this.displayedColumnsRelationships,"edit")
		this.removeItemFromArray(this.displayedColumnsRelationships,"delete")
		this.relationshipFormGroup.controls.projectNameSecondary.disable();
	}

	updateRelationship(){
		let relationship = this.relationshipFormGroup.get('relationship').value;
		if(relationship.includes('Child')) this.editRelationshipObj['projects'][0].relationship = "Child";
		else if(relationship.includes('Sibling')) this.editRelationshipObj['projects'][0].relationship = "Sibling";
		else if(relationship.includes('Parent')) this.editRelationshipObj['projects'][0].relationship = "Parent";
		this._projectsService.putEditRelationship(this.editRelationshipObj['projects'][0]).subscribe(
			data => {
				this.cancelEditRelationship();
				this.loadRelationshipDialog();
			err => {
				console.log("Error editing project relationship");
				console.log(err);
			}
			}
		)
	}

	cancelEditRelationship(){
		this.relationshipClients.pop();
		this.editRelationshipObj.projects = [];

		this.insertItemToArrayByIndex(this.displayedColumnsRelationships,"delete",3);
		this.insertItemToArrayByIndex(this.displayedColumnsRelationships,"edit",4);

		this.relationshipFormGroup.controls.projectNameSecondary.enable();
		this.relationshipFormGroup.controls.relationship.reset();
		this.relationshipFormGroup.controls.projectNameSecondary.reset();
		this.editingRelationship = false;
	}

	openProjectRelationship(targetProjectId){
		this.dialogRef.close({ openProjectFromRelationship: true, projectId: targetProjectId });
	}

	filterProjectForRelationshipByInput(inputText){
		this.relationshipClients = cloneDeep(this.projectData.clients);
		for(var i = this.relationshipClients.length - 1; i >= 0; i--){
			if(this.relationshipClients[i]['projects'] && this.relationshipClients[i]['projects'].length != 0){
				// filter projects by name
				this.relationshipClients[i]['projects'] = this.relationshipClients[i]['projects'].filter(project => project['name'].toLowerCase().includes(inputText.toLowerCase()));

				// remove client if no projects
				if (this.relationshipClients[i]['projects'].length == 0) this.relationshipClients.splice(i,1);
			}
			else {
				this.relationshipClients.splice(i,1); // remove client if no projects
			}
		}
	}

	configRelationshipHeadingSort(){
		if(this.sort != undefined){
			this.relationshipDisplayDataSource.sort = this.sort.first;
			this.relationshipDisplayDataSource.sortingDataAccessor = (relationship, property) => {
				switch (property) {
					case 'Client': return relationship['clientNameSecondary'];
					case 'Project': return relationship['projectNameSecondary'];
					case 'Relationship':return relationship['relationship'];
					default: return relationship[property];
				}
			};
		}
	}

    findEntry(entryId) {
        return this.dataArray.find(entry => entry['id'] == entryId);
    }

	removeItemFromArray(array,item){
		const index = array.indexOf(item);
		if (index > -1){
			array.splice(index,1);
		}
		return array;
	}

	insertItemToArrayByIndex(array,item,index){
		array.splice(index,0,item);
		return array;
	}

    isValid() {
        if (this.projectName.value == null || this.projectName.value == "") {
            this.error = 'Name is required';
            return false;
        }
        if (this.startDate.value == null || this.startDate.value == "") {
            this.error = 'Start Date is required';
            return false;
        }
        if (this.managerId.value == null) {
            this.error = 'Project Manager is required';
            return false;
        }
        if (this.clientId.value == null) {
            this.error = 'Client is required';
            return false;
        }
        if (this.description.value == null || this.description.value == "") {
            this.error = 'Description is required';
            return false;
        }
        const regex = new RegExp('^([a-zA-Z0-9]+-?[a-zA-Z0-9]+$)|(^[a-zA-Z0-9]?$)'); //OR Covers 1 character case
        const hyphenEndStart = new RegExp('(^-|--|-$)');
        if (this.clientPO.value != "" && !regex.test(this.clientPO.value)) {
            if (hyphenEndStart.test(this.clientPO.value)) {
                if (/^-/.test(this.clientPO.value)) { //Starting Hyphen
                    this.error = "Client P.O. cannot start with \"-\".";
                }
                else if (/-$/.test(this.clientPO.value)) { // Ending Hyphen
                    this.error = "Client P.O. cannot end with \"-\".";
                }
                else { //Sequential Hyphens in the middle
                    this.error = "Client P.O. cannot contain sequential \"-\".";
                }
            }
            else {
                this.error = "Client P.O. contains unsupported characters.";
            }
            return false;
        }
        else {
            this.valid = true;
            this.error = "";
            return true;
        }
	}

	isValidUser() {
		if (this.user.value == null || this.user.value == "") {
			this.userError = 'User is required';
			return false;
		}
		else if(!this.isNotUsed()){
			this.userError = 'User is already added';
			return false;
		}
		else if (this.title.value == null || this.title.value == "") {
			this.userError = 'Title is required';
			return false;
		}
		else if (this.rate.value == null || this.rate.value == "") {
			this.userError = 'Rate is required';
			return false;
		}
		else {
			this.valid = true;
			this.userError = "";
			return true;
		}
    }
}

