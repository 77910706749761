import { Component, OnInit, EventEmitter } from '@angular/core';
import { CalendarEvent } from 'calendar-utils';
import * as moment from 'moment';
import { TimeoffService } from '../api/timeoff.service';
import { Observable, Subject } from 'rxjs';
import { TimeoffEntryDialogComponent } from './timeoff-entry-dialog/timeoff-entry-dialog.component';
import { MatDialog, MatSnackBar } from '@angular/material';
import { FormControl } from '@angular/forms';
import { DeleteConfirmationDialogComponent } from '../extra/delete-confirmation-dialog/delete-confirmation-dialog.component';
import { CalendarEventTitleFormatter } from 'angular-calendar';
import { CustomEventTitleFormatter } from './CustomEventTitleFormatter';
import { SetttingsService } from '../api/setttings.service';
import { takeUntil } from 'rxjs/operators';
var _ = require("lodash");

@Component({
    selector: 'app-timeoff',
    templateUrl: './timeoff.component.html',
    styleUrls: ['./timeoff.component.scss'],
    providers: [
        {
            provide: CalendarEventTitleFormatter,
            useClass: CustomEventTitleFormatter,
        }
    ]
})

export class TimeoffComponent implements OnInit {
    colorFilter = ['submitted', 'pending', 'approved', 'rejected'];
    viewDate: Date = moment().toDate(); // Current date selected for the calendar
    viewDateTwoWeek: Date = moment().add(1, 'week').toDate(); // Same as viewDate, but 1 week later
    dateRange: string; // Formated range for the date range button
    activeView: string = 'month'; // Which calendar view to show
    activeDayIsOpen: boolean; // If the grey bar is open with the events of that day
    refresh: Subject<any> = new Subject(); // Used to refresh the calendar with 'this.refresh.next()'
    highlightDay: EventEmitter<any> = new EventEmitter();
    unhighlightDay: EventEmitter<any> = new EventEmitter();
    filters: object[] = []; // Data that populates filter selector
    selectedFilters: object[] = [];
    allCategories: object[] = [];

    blackOutDataArray: any[] = [];
    holidayDataArray: any[] = [];

	eventsParsedObj = {
		blackOut: false,
		holiday:false,
		timeoff:false
	}

    filterSelect: FormControl = new FormControl(); // Reference tothe selector to get which elements selected
    events: CalendarEvent[] = []; // Actual events in the calendar
    timeoffEvents: object[] = []; // All loaded events, to be used to filter the data
    color: any = {// Colors for the dot on calendar and highlghts
        approved: {
            primary: '#62C479',
            secondary: 'rgba(218,241,223,0.8)'
        },
        pending: {
            primary: '#FFCA28',
            secondary: 'rgba(251,240,208,0.8)'
        },
        submitted: {
            primary: '#999999',
            secondary: 'rgba(223,223,223,0.8)'
        },
        rejected: {
            primary: '#E12C2C',
            secondary: 'rgba(249,210,210,0.8)'
        },
        blackOut: {
            primary: '#253337',
            secondary: 'rgb(37,51,55,0.8)'
        },
        holiday: {
            primary: '#82b4c9',
            secondary: 'rgba(130,180,201,0.8)'
        }
    };

    tenantStartDayValue;
    tenantStartDayConfig = null;

	firstDayOnCalendar;
	lastDayOnCalendar;

	ngUnsubscribe: Subject<void> = new Subject<void>();

    constructor(private _timeoffService: TimeoffService,
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        private _settingService: SetttingsService) { }

    ngOnInit() {
        this._settingService.getTenantWeekStart().subscribe(data => {
            this.tenantStartDayValue = parseInt(data + "");
            this.tenantStartDayConfig = this.tenantStartDayValue == 6 ? 0 : this.tenantStartDayValue + 1;
            this._settingService.setStartDayToCookie(this.tenantStartDayConfig  + "");

            this.formatDateRange();
            this.getTimeoffCategory();
			this.loadAllEvents();
        });
    }

    onKeyFilter(value) {
        this.selectedFilters = this.filterFilters(value);
    }

    filterFilters(value: string) {
        let filter = value.toLowerCase();
        return this.filters.filter(option => option['name'].toLowerCase().includes(filter));
    }

    //Sets focus of the combobox inputs on click
    setFocus(inputId: string) {
        if (document.getElementById(inputId) !== null) {
            document.getElementById(inputId).focus();
        }
    }

    dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
        // If the date is the same and the day is open, or there is no events close the active day
        if ((moment(this.viewDate) == moment(date) && this.activeDayIsOpen === true) || events.length === 0) {
            this.activeDayIsOpen = false;
        } else {// Open day view and set viewdate and the two week
            this.activeDayIsOpen = true;
            this.viewDate = date;
            this.viewDateTwoWeek = moment(date).add(1, 'week').toDate();
        }
    }

    refreshFilter() {
		this.events = [];
        // Build the arrays of time off entries and calendar events with time off entry objects
        this.processTimeoffData();
        this.processBlackOutData();
        this.processHolidayData();
    }

	processTimeoffData(){
        try{
			var promises = [];
            for (var entry of this.timeoffEvents){
				if ((this.filterSelect.value == null || this.filterSelect.value.length == 0)){
					promises.push(new Promise(resolve =>{
						this.processEvents(entry);
						resolve('done')
					}));
				}else if(this.filterSelect.value.includes(entry['categoryId'])){
					promises.push(new Promise(resolve =>{
						this.processEvents(entry);
						resolve('done')
					}));
				}
            };
			Promise.all([promises]).then(()=>{
				this.eventsParsedObj.timeoff = true;
				this.refreshCalendar();
			})
        }
        catch(exception){
			console.log(exception.message)
            this.snackBar.open('Error While Loading Time Off Events', '', { duration: 2000 });
        }
    }

    processBlackOutData(){
        try{
			var promises = []
            for (var entry of this.blackOutDataArray){
				promises.push(new Promise(resolve =>{
					this.parseBlackOutData(entry);
					resolve('done')
				}));
            };
			Promise.all([promises]).then(()=>{
				this.eventsParsedObj.blackOut = true;
				this.refreshCalendar();
			})
        }catch(exception){
            this.snackBar.open('Error While Loading Black Out Events', '', { duration: 2000 });
        }
    }

    parseBlackOutData(entry){
        this.events.push({
            id: entry['id'],
            start: moment(entry['blackOutDate'].substring(0, 10)).toDate(),
            end: moment(entry['blackOutDate'].substring(0, 10)).toDate(),
            title: entry['reason'],
            color: this.color["blackOut"],
            cssClass: 'white'
        });
    }

    processHolidayData(){
        try{
			var promises = [];
            for (var entry of this.holidayDataArray){
                promises.push(new Promise(resolve =>{
					this.parseHolidayData(entry)
					resolve('done')
				}));
            };
			Promise.all([promises]).then(()=>{
				this.eventsParsedObj.holiday = true;
				this.refreshCalendar();
			})
        }catch(exception){
            this.snackBar.open('Error While Loading Holiday Events', '', { duration: 2000 });
        }
    }

    parseHolidayData(entry){
        this.events.push({
            id: entry.id,
            start: moment(entry.holidayDate.substring(0, 10)).toDate(),
            end: moment(entry.holidayDate.substring(0, 10)).toDate(),
            title: entry.reason,
            color: this.color["holiday"]
        });
    }

    clear() {
        this.filterSelect.reset();
        this.filterSelect.setValue([]);
        this.refreshFilter();
    }

    beforeMonthViewRender({ body }) {
        // Used to make badge totals not display on calendar load
        body.forEach(day => {
            day.badgeTotal = 0;
        });
    }

    findCategory(id) {
        // Gets the name of a category from the id and returns it
        let filterFind = this.filters.find(f => f['id'] == id);

        if (filterFind !== undefined) {
            return filterFind['name'];
        } else {
            return 'Event';
        }
    }

    loadTimeoffDialog(entryId?) {
        let dialogData = {};
        let viewOnly = false;

        if (entryId) {// An event on the calendar was selected
            let data = this.timeoffEvents.find(event => event['id'] == entryId);
            if(data != undefined){
                let split = false;

                data['dateList'].forEach(date => {
                    if (date['isExcluded'] == true || (date['isMorning'] == true || date['isAfternoon'] == true)) {
                        split = true;
                    }
                });

                if (data['approvalStatusId'] === 2) {
                    viewOnly = true;
                }

                /**
                 * Manually adjusts the "from" and "to" dates to pass into the Edit
                 * Time Off dialog to address an issue where dates were displayed
                 * incorrectly due to time zone mismatch (fixed under PROS-598).
                 */
                let startDateMoment: moment.Moment = moment(data['startDate']);
                let endDateMoment: moment.Moment = moment(data['endDate']);
                let fromDate: Date = startDateMoment.subtract(startDateMoment.utcOffset(), 'minutes').toDate();
                let toDate: Date = endDateMoment.subtract(endDateMoment.utcOffset(), 'minutes').toDate();
                dialogData = {
                    fullData: data, // The full entries data
                    types: this.filters, // The pto categories to populate the selector
                    typeId: data['categoryId'], // Categoryid from the event
                    allTypes: this.filters, //For if there are disabled categories
                    fromDate: fromDate, // Start date of event
                    toDate: toDate, // End date of the event
                    description: data['description'], // Description of the event
                    split: split, // Boolean of if the event is split or not
                    status: data['status'], // Status of the event
                    rejectedMessage: data['rejectMessage'], // Rejected message of the event
                    blackOutDates: this.blackOutDataArray,
                    holidayDates: this.holidayDataArray,
                    viewOnly: viewOnly,
                    approvalStatusId: data['approvalStatusId'],
                    eventData: this.timeoffEvents
                };
            }
        } else {// The add Entry button was clicked
            dialogData = {
                allTypes: this.filters,
                blackOutDates: this.blackOutDataArray,
                holidayDates: this.holidayDataArray,
                types: this.filters, // The pto categories to populate the selector
                eventData: this.timeoffEvents
            };
        }

        if(Object.keys(dialogData).length != 0){
            const dialogRef = this.dialog.open(TimeoffEntryDialogComponent, {
                width: '960px',
                height: 'auto',
                data: dialogData
            });

            dialogRef.afterClosed().subscribe(result => {
                if (result !== undefined) {
                    if (result['draft']) {// Draft button clicked
                        this.snackBar.open('Drafting entry', '', { duration: 2000 });
                        this._timeoffService.postTimeoffEntry(result['data'], 0).subscribe(
                            data => {
								this.resetEvents()
								this.loadAllEvents();
                                this.snackBar.open('Entry Drafted Successfully', '', { duration: 2000 });

                            },
                            err => {
								this.resetEvents()
								this.loadAllEvents();
								this.snackBar.open('ERROR: Entry Drafted Unsuccessfully', '', { duration: 2000 });
								console.error(err)

							}
                        );
                    } else if (result['submit']) {// Submit button clicked
                        this.snackBar.open('Submitting entry', '', { duration: 2000 });
                        this._timeoffService.postTimeoffEntry(result['data'], 1).subscribe(
                            data => {
								this.resetEvents()
								this.loadAllEvents();
                                this.snackBar.open('Entry Submitted Successfully', '', { duration: 2000 });
                            },
                            err => {
								this.resetEvents()
								this.loadAllEvents();
								this.snackBar.open('ERROR: Entry Submitted Unsuccessfully', '', { duration: 2000 });
								console.error(err);
							}
                        );
                    } else if (result['delete']) {// Delete button clicked
                        let entryId = result['entryId'];
                        const deleteDialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
                            width: '585px',
                            height: 'auto'
                        });

                        deleteDialogRef.afterClosed().subscribe(result => {// Check if the entry is to be deleted
                            if (result !== undefined) {
                                if (result['toDelete']) {
                                    this.snackBar.open('Deleting Entry', '', { duration: 2000 });
                                    this._timeoffService.DeleteTimeoffEntry(entryId).subscribe(
                                        data => {
											this.resetEvents()
											this.loadAllEvents();
                                            this.snackBar.open('Entry Successfully Deleted', '', { duration: 2000 });
                                        },
                                        err => {
											this.resetEvents()
											this.loadAllEvents();
											this.snackBar.open('ERROR: Entry Not Deleted', '', { duration: 2000 });
											console.error(err);

										}
                                    );
                                }
                            }
                        });
                    }
                }
            });
        }
    }

    processEvents(entry) {
        // Create and initialize an array to be used to distinguish between normal and special dates (special dates are half days and excluded days)
        let specialDateReferenceArray: boolean[] = [entry.dateList.length];
		let colorReference = "";
        // Populate the special date reference array from the current entry data
        for (let index = 0; index < entry.dateList.length; index++) {
			specialDateReferenceArray[index] = (entry.dateList[index].isMorning === true || entry.dateList[index].isAfternoon === true || entry.dateList[index].isExcluded === true );
		}


        // Declare variables to be used to reference the next special date index and the end date for date ranges
        let nextSpecialDate: number;
        let endDate: Date;
        // Iterate over the special date reference array, and handle each date represented within either as a normal date or a special date
        for (let index = 0; index < specialDateReferenceArray.length; index++) {
            // Set nextSpecialDate to be the index of the next special date after the current index
			nextSpecialDate = specialDateReferenceArray.indexOf(true, index);
            // If the date at the current index is a normal date, process it as such
            if (!specialDateReferenceArray[index]) {
                // If there is a special date after the date at the current index, set the end date to the day before the next special date
                if (nextSpecialDate > 0) {
                    endDate = moment(entry.dateList[nextSpecialDate - 1].date.substring(0, 10)).toDate();
                } else {
                    endDate = moment(entry.dateList[entry.dateList.length - 1].date.substring(0, 10)).toDate();
                }
				//Push the new normal date range to the calendar views
				//Takes the approval status id and assigns a color based on that id
				if(entry['approvalStatusId'] === 0){
					colorReference = "submitted";
				}
				else if(entry['approvalStatusId'] === 1){
					colorReference = "pending";
				}
				else if(entry['approvalStatusId'] === 2){
					colorReference = "approved"
				}
				else if(entry['approvalStatusId'] === 3){
					colorReference = "rejected"
				}

					this.events.push({
						id: entry.id,
						start: moment(entry.dateList[index].date.substring(0, 10)).toDate(),
						end: endDate,
						title: this.findCategory(entry['categoryId']),
						color: this.color[colorReference]
					});

                // If there is a special date after the date at the current index, set the index to the day just before that date
                if (nextSpecialDate > -1) {
                    index = nextSpecialDate - 1;
                } else {
                    index = entry.dateList.length - 1;
                }
            } else if (entry.dateList[index].isMorning === true || entry.dateList[index].isAfternoon === true ) {
                // Build the event title string from the event category and timeframe for the time off
                let timeframeString: string = (entry.dateList[index].isMorning) ? 'Morning' : 'Afternoon';
				let eventTitle: string = this.findCategory(entry['categoryId']) + ' (' + timeframeString + ' Only)';
				if(entry['approvalStatusId'] === 0){
					colorReference = "submitted";
				}
				else if(entry['approvalStatusId'] === 1){
					colorReference = "pending";
				}
				else if(entry['approvalStatusId'] === 2){
					colorReference = "approved"
				}
				else if(entry['approvalStatusId'] === 3){
					colorReference = "rejected"
				}

                // Push the half day to the calendar views
                this.events.push({
                    id: entry.id,
                    start: moment(entry.dateList[index].date.substring(0, 10)).toDate(),
                    end: moment(entry.dateList[index].date.substring(0, 10)).toDate(),
                    title: eventTitle,
                    color: this.color[colorReference],
                    cssClass: 'half-day'
                });
            }
		}
    }

    getTimeoffCategory() {
        this._timeoffService.getTimeoffCategories().subscribe(
            data => {
                this.filters.push.apply(this.filters, data); // add the categories to the filters array
				this.selectedFilters = this.filters;
			},
            err => {
				this.snackBar.open('Error While Loading Events', '', { duration: 2000 });
				console.error(err)
			}
        );
    }

    getTimeoffEntries() {
		this.eventsParsedObj.timeoff = false;
		this.timeoffEvents = [];
        this._timeoffService.getTimeoffEntries(this.firstDayOnCalendar, this.lastDayOnCalendar)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(
            data => {
				this.timeoffEvents = [];
				this.timeoffEvents.push.apply(this.timeoffEvents, data);
                this.processTimeoffData();
            },
            err => {
                if (err.status == 404) {
                    this.activeDayIsOpen = false;
                    this.timeoffEvents, this.events = []; // Reset values
                    this.refresh.next();
                } else {
                    console.error(err);
                }
				this.snackBar.open('Error While Loading Events', '', { duration: 2000 });
            }
        );
    }

    getBlackOutDates() {
		this.eventsParsedObj.blackOut = false;
        this.blackOutDataArray = [];
        this._timeoffService.getTimeOffBlackOutDates(this.firstDayOnCalendar, this.lastDayOnCalendar)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(
            data => {
				this.blackOutDataArray = [];
                this.blackOutDataArray.push.apply(this.blackOutDataArray, data);
                this.processBlackOutData();
				this.eventsParsedObj.blackOut = true;
            }, err => {
                this.snackBar.open('Error While Loading Events', '', { duration: 2000 });
                console.error(err);
            }
        );

    }

    getHolidayDates() {
		this.eventsParsedObj.holiday = false;
        this.holidayDataArray = [];
        this._timeoffService.getTimeOffHolidayDates(this.firstDayOnCalendar, this.lastDayOnCalendar)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(
            data => {
				this.holidayDataArray = [];
                this.holidayDataArray.push.apply(this.holidayDataArray, data);
                this.processHolidayData();
				this.eventsParsedObj.holiday = true;
            }, err => {
                this.snackBar.open('Unknown Error While Loading Events', '', { duration: 2000 });
                console.error(err);
            }
        );
    }

    increaseDate() {
		this.resetEvents();
        this.activeDayIsOpen = false;

        if (this.activeView == 'month') {
            this.viewDate = moment(this.viewDate).add(1, 'month').toDate();
            this.viewDateTwoWeek = moment(this.viewDateTwoWeek).add(1, 'month').toDate();
        } else {
            this.viewDate = moment(this.viewDate).add(1, 'week').toDate();
            this.viewDateTwoWeek = moment(this.viewDateTwoWeek).add(1, 'week').toDate();
        }

        this.formatDateRange();
        this.loadAllEvents();
    }

    decreaseDate() {
		this.resetEvents();
        this.activeDayIsOpen = false;

        if (this.activeView == 'month') {
            this.viewDate = moment(this.viewDate).subtract(1, 'month').toDate();
            this.viewDateTwoWeek = moment(this.viewDateTwoWeek).subtract(1, 'month').toDate();
        } else {
            this.viewDate = moment(this.viewDate).subtract(1, 'week').toDate();
            this.viewDateTwoWeek = moment(this.viewDateTwoWeek).subtract(1, 'week').toDate();
        }

        this.formatDateRange();
        this.loadAllEvents();
    }

    setDate(selectedDate: Date) {
		this.resetEvents()
        this.viewDate = moment(selectedDate).toDate();
        this.viewDateTwoWeek = moment(selectedDate).add(1, 'week').toDate();

        this.formatDateRange();
        this.loadAllEvents();
    }

    setView(selectedView: string) {
        this.activeView = selectedView;
    }

    formatDateRange(selectedView?: string) {
        // Set the view variable if selectedView was passed in
        if (selectedView !== undefined) {
            this.setView(selectedView);
        }

        // Based on the view selected, generate a date range string
        if (this.activeView == 'month') {
            // Get the first day of the selected month
            let monthStart = moment(this.viewDate).startOf('month').toDate();

            // Set the date range string
            this.dateRange = moment(monthStart).format('MMMM YYYY');
        } else if (this.activeView == 'week' || this.activeView == 'two-week') {
            // Get the start and end date of the date range based on which view is selected
            let startDate = moment(this.viewDate).startOf('isoWeek').toDate();
            let endDate: Date = new Date();

            if (this.activeView == 'week') {
                endDate = moment(this.viewDate).endOf('isoWeek').toDate();
            } else {
                endDate = moment(this.viewDateTwoWeek).endOf('isoWeek').toDate();
            }

            /** Check if the start and end dates are in the same month and year
			 * and respond accordingly
			 **/
            let sameMonth = startDate.getMonth() == endDate.getMonth();
            let sameYear = startDate.getFullYear() == endDate.getFullYear();
            let start = 'MMM D - ';
            let end = 'D, YYYY';

            if (!sameMonth) {
                end = 'MMM D, YYYY';
            }

            if (!sameYear) {
                start = 'MMM D, YYYY - ';
            }

            // Set the date range string
            this.dateRange = moment(startDate).format(start) + moment(endDate).format(end);
        }
		this.setFirstAndLastDayOnCalendar();
    }


	setFirstAndLastDayOnCalendar(){
		var selection = 6 - this.tenantStartDayValue;
		this.firstDayOnCalendar = new Date(this.viewDate.getFullYear(), this.viewDate.getMonth(), 1)
        this.firstDayOnCalendar.setDate(this.firstDayOnCalendar.getDate() - (this.firstDayOnCalendar.getDay() + selection) % 7)
        this.lastDayOnCalendar = new Date(this.viewDate.getFullYear(), this.viewDate.getMonth() + 1, 0);
        this.lastDayOnCalendar.setDate(this.lastDayOnCalendar.getDate() + (this.tenantStartDayConfig + 7 - this.lastDayOnCalendar.getDay() - 1) % 7);
	}

	loadAllEvents(){
		this.getHolidayDates()
		this.getBlackOutDates();
		this.getTimeoffEntries();
	}

	unsubscribeFromOldRequests(){ // unsubscribes from old request to grab events
		this.ngUnsubscribe.next();
		//this.ngUnsubscribe.complete();
	}

	refreshCalendar(){
		if(this.eventsParsedObj.timeoff && this.eventsParsedObj.blackOut && this.eventsParsedObj.holiday)
			this.refresh.next();
	}

	resetEvents(){
		this.unsubscribeFromOldRequests();
		this.clearEvents();
	}

	clearEvents() {
		this.events = [];
		this.blackOutDataArray = [];
		this.holidayDataArray = [];
		this.timeoffEvents = [];
		this.eventsParsedObj = _.mapValues(this.eventsParsedObj, () => false); //refresh event object back to false
    }
}
