import { Component, OnInit, Inject } from '@angular/core';
import { FormControl } from '../../../../../node_modules/@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '../../../../../node_modules/@angular/material';

@Component({
  selector: 'app-management-class-dialog',
  templateUrl: './management-class-dialog.component.html',
  styleUrls: ['./management-class-dialog.component.scss']
})
export class ManagementClassDialogComponent implements OnInit {

    nameFormControl: FormControl = new FormControl(this.classData['name']);
    isActiveFormControl: FormControl = new FormControl(this.classData['isActive'] == null ? true : this.classData['isActive']);

    valid: boolean = true;
    error: String;

    constructor(public dialogRef: MatDialogRef<ManagementClassDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public classData) { }

    ngOnInit() {

    }

    isValid() {
        // Validates form entry
        if(this.nameFormControl.value == null || this.nameFormControl.value == ''){
            this.error = 'Name is required';
            return false;
        }
        else{
            this.valid = true;
            return true;
        }
    }

    closeDialog() {
        this.dialogRef.close();
    }

    save() {
        if (this.isValid()) {
            this.classData['name'] = this.nameFormControl.value;
            this.classData['isActive'] = this.isActiveFormControl.value;
            this.dialogRef.close({ submit: true, data: this.classData });
        }
    }

}
