import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { CookieService } from 'ngx-cookie';
import * as moment from 'moment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatSnackBar } from '@angular/material';
import { GlobalService } from '../../api/globalVars';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    withCredentials: true
};

@Injectable({
    providedIn: 'root'
})

export class ThemeService {
    path = this.globalVar.retrievePath();

    private _themeSelection: Subject<string>;
    themeSelection;
    private themeNames: string[];

    constructor(private _cookieService: CookieService,
        private _http: HttpClient, public globalVar: GlobalService, 
        public snackBar: MatSnackBar) {
        this._themeSelection = new Subject<string>();
        this.themeSelection = this._themeSelection.asObservable();
        this.themeNames = new Array('prosight-default', 'prosight-blue', 'prosight-pink', 'prosight-dark');
    }

    /**
	 * Retrieves the currently set theme for the tenant associated with the
	 * current user from the backend
	 */
    getAppThemeFromDB() {
        const geturl = this.path + 'tenantconfig';
        return this._http.get(geturl, httpOptions);
    }

    /**
	 * Retrieves the currently set theme for the tenant associated with the
	 * current user from the theme cookie stored in the user's browser
	 */
    getAppThemeFromCookie() {
        if (this._cookieService.get('prosight-theme')) {
            return this._cookieService.get('prosight-theme').replace('prosight-', '');
        } else {
            return null;
        }
    }

    /**
 * Retrieves a list of theme names from the theme service as a string array
 */
    getThemeNames(): string[] {
        return this.themeNames;
    }

    /**
	 * Removes the application theme cookie from the user's browser
	 */
    removeAppThemeCookie() {
        if (this._cookieService.get('prosight-theme')) {
            this._cookieService.remove('prosight-theme');
        }
    }

    /**
	 * Saves the theme selection to the application database through the backend
	 * for the tenant associated with the current user and returns a boolean
	 * indicating whether or not the operation was successful
	 */
    saveAppThemeToDB(themeSelection: string): boolean {
        let operationSuccessful = false;
        const posturl = `${this.path}tenantconfig?themeSelection=${themeSelection}`;
        this._http.post(posturl, null, httpOptions).subscribe(
            data => { // Take additional steps after theme has been successfully set
                this.snackBar.open('Theme Selection Saved', '', { duration: 2000 });
                operationSuccessful = true;
            },
            err => { // Handle error returned by the backend
                this.snackBar.open('Error Saving Theme', '', { duration: 2000 });
            }
        );
        return operationSuccessful;
    }

    /**
	 * Updates the cookie which stores the current theme selection to reflect the
	 * current selection for the tenant associated with the current user
	 */
    saveAppThemeToCookie(themeSelection: string) {
        this.removeAppThemeCookie();
        this._cookieService.put('prosight-theme', themeSelection, { expires: moment().add(1, 'year').toString() });
    }

    /**
	 * Sets the theme for the tenant associated with the current user with
	 * options to chose whether to save the theme setting to the database and
	 * in the theme cookie
	*/
    setAppTheme(themeSelection: string, saveToDB: boolean, saveToCookie: boolean) {
        this._themeSelection.next(themeSelection);
        if (saveToDB) {
            this.saveAppThemeToDB(themeSelection);
        }
        if (saveToCookie)	{
            this.saveAppThemeToCookie(themeSelection);
        }
    }
}
