import { Component, OnInit, ViewChild, ViewChildren, EventEmitter } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatDialog, MatSnackBar, MatSort } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { TimeoffService } from '../../api/timeoff.service';
import { SetttingsService } from '../../api/setttings.service';
import { FormControl } from '../../../../node_modules/@angular/forms';
import * as moment from 'moment';
import { from, Subject, Subscription } from 'rxjs';
import { CalendarEvent } from 'calendar-utils';
import { TimeoffEntryDialogComponent } from '../timeoff-entry-dialog/timeoff-entry-dialog.component';
import { TimeoffRejectDialogComponent } from './timeoff-reject-dialog/timeoff-reject-dialog.component';
import { DeleteConfirmationDialogComponent } from '../../extra/delete-confirmation-dialog/delete-confirmation-dialog.component';
import { AuthService } from '../../services/auth/auth.service';
import { QuickbooksService } from '../../api/quickbooks.service';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-timeoff-approval',
    templateUrl: './timeoff-approval.component.html',
    styleUrls: ['./timeoff-approval.component.scss']
})

export class TimeoffApprovalComponent implements OnInit {
    displayedColumns = ['select', 'employee', 'requestDate', 'startDate', 'endDate', 'type', 'info', 'approve', 'warning', 'deny'];
    isTimeOffDataLoading: boolean;
    allCategories: object[] = [];
    selection = new SelectionModel(true, []);
    dataSource = new MatTableDataSource();
    timeOffApprovalDataSources = {
        'all': new MatTableDataSource(),
        'denied': new MatTableDataSource(),
        'approved': new MatTableDataSource(),
        'pending': new MatTableDataSource()
    };
    displayData = 'pending';
    isManager: boolean = false;
    users: object[] = [];
    selectedUsers: object[] = [];
    filters: object[] = []; // Data that populates filter selector
    selectedFilters: object[] = [];
    typeFilterSelect: FormControl = new FormControl([]); // Reference to the selector to get which elements selected
    userFilterSelect: FormControl = new FormControl([]);
    highlightDay: EventEmitter<any> = new EventEmitter();
    unhighlightDay: EventEmitter<any> = new EventEmitter();
    viewDate: Date = moment().toDate(); // Current date selected for the calendar
    viewDateTwoWeek: Date = moment().add(1, 'week').toDate(); // Same as viewDate, but 1 week later
    dateRange: string; // Formated range for the date range button
    activeView: string = 'list'; // Which calendar view to show
    activeDayIsOpen: boolean; // If the grey bar is open with the events of that day
    refresh: Subject<any> = new Subject(); // Used to refresh the calendar with 'this.refresh.next()'
    events: CalendarEvent[] = []; // Actual events in the calendar
	timeoffEvents: object[] = []; // All loaded events, to be used to filter the data
	timeOffEntries = [];
	firstDayOfMonth: Date; // First Day of the month used for the list view
	lastDayOfMonth: Date; // Last Day of the month used for the list view
	firstDayOnCalender: Date;
	lastDayOnCalender: Date;

    blackOutDataArray: any[] = [];
    holidayDataArray: any[] = [];

	ngUnsubscribe: Subject<void> = new Subject<void>();

    color: any = {// Colors for the dot on calendar and highlghts
        approved: {
            primary: '#62C479',
            secondary: 'rgba(218,241,223,0.8)'
        },
        pending: {
            primary: '#FFCA28',
            secondary: 'rgba(251,240,208,0.8)'
        },
        submitted: {
            primary: '#999999',
            secondary: 'rgba(223,223,223,0.8)'
        },
        rejected: {
            primary: '#E12C2C',
            secondary: 'rgba(249,210,210,0.8)'
        },
        blackOut: {
            primary: '#253337',
            secondary: 'rgb(37,51,55,0.8)'
        },
        holiday: {
            primary: '#82b4c9',
            secondary: 'rgba(130,180,201,0.8)'
        }
    };
    tenantStartDayValue;
    tenantStartDayConfig = null;

    @ViewChildren(MatPaginator) paginators;
    @ViewChildren(MatSort) sort;

    constructor(private _timeoffService: TimeoffService,
        public dialog: MatDialog, private _settingService: SetttingsService,
        public snackbar: MatSnackBar, private authService: AuthService, private quickbooksService: QuickbooksService) { }

    ngOnInit() {
		this.isTimeOffDataLoading = true;
        this._settingService.getTenantWeekStart().subscribe(data => {
            this.tenantStartDayValue = parseInt(data + "");
            this.tenantStartDayConfig = this.tenantStartDayValue == 6 ? 0 : this.tenantStartDayValue + 1;
            this._settingService.setStartDayToCookie(this.tenantStartDayConfig  + "");

            this.authService.getUserPermissions().subscribe(userPermissions => this.isManager = userPermissions.includes('manage:time-off'));

            this.formatDateRange();
            this.getUsers();
			this.getTimeoffCategory();
            this.getBlackOutDates();
            this.getHolidayDates();

            this.dataSource = this.timeOffApprovalDataSources['pending'];
        });
    }

    onKeyUser(value) {
        this.selectedUsers = this.filterUsers(value);
    }

    filterUsers(value: string) {
        let filter = value.toLowerCase();

        return this.users.filter(option => option['fullName'].toLowerCase().includes(filter));
    }

    onKeyFilter(value) {
        this.selectedFilters = this.filterType(value);
    }

    filterType(value: string) {
        let filter = value.toLowerCase();

        return this.filters.filter(option => option['name'].toLowerCase().includes(filter));
    }

    //Sets focus of the combobox inputs on click
    setFocus(inputId: string) {
        if (document.getElementById(inputId) != null) {
            document.getElementById(inputId).focus();
        }
    }

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;

        return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
        this.dataSource = this.timeOffApprovalDataSources[this.displayData];

        this.isAllSelected() ?
            this.selection.clear() :
            this.dataSource.data.forEach(row => this.selection.select(row));
    }

    dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
        // If the date is the same and the day is open, or there is no events close the active day
        if ((moment(this.viewDate) == moment(date) && this.activeDayIsOpen === true) || events.length === 0) {
            this.activeDayIsOpen = false;
        } else {// Open day view and set viewdate and the two week
            this.activeDayIsOpen = true;
            this.viewDate = date;
        }

        this.formatDateRange();
    }

    beforeMonthViewRender({ body }) {
        // Used to make badge totals not display on calendar load
        body.forEach(day => {
            day.badgeTotal = 0;
        });
    }

    loadTimeoffDialog(entryId?) {
        let dialogData = {};
        let data = this.timeoffEvents.find(event => event['id'] == entryId);
        if(data != undefined){
            let split = false;


            data['dateList'].forEach(date => {
                if (date['isMorning'] === true || date['isAfternoon'] == true || date['isExcluded'] == true) {
                    split = true;
                }
            });

            if(data['approvalStatusId'] == 1){
                for(var j = 0; j < this.blackOutDataArray.length; j++){

                    var conflictBlackOut = data['dateList'].find(timeOff => {
                        if(timeOff.date != undefined){
                            return timeOff.date.substring(0, 10)
                            == this.blackOutDataArray[j].blackOutDate.substring(0, 10)
                        }
                        else {
                            return timeOff.entryDate.substring(0, 10)
                            == this.blackOutDataArray[j].blackOutDate.substring(0, 10)
                        }
                    });
                    if(conflictBlackOut != null && conflictBlackOut['isExcluded'] == false){
                        data['conflictBlackOut'] = true;
                    }
                }
            }

            /**
             * Manually adjusts the "from" and "to" dates to pass into the Edit
             * Time Off dialog to address an issue where dates were displayed
             * incorrectly due to time zone mismatch (fixed under PROS-598).
             */
            let startDateMoment: moment.Moment = moment(data['startDate']);
            let endDateMoment: moment.Moment = moment(data['endDate']);
            let fromDate: Date = startDateMoment.subtract(startDateMoment.utcOffset(), 'minutes').toDate();
            let toDate: Date = endDateMoment.subtract(endDateMoment.utcOffset(), 'minutes').toDate();
            dialogData = {
                fullData: data, // The full entries data
                types: this.filters, // The pto categories to populate the selector
                typeId: data['categoryId'], // Categoryid from the event
                allTypes: this.filters,
                fromDate: fromDate, // Start date of event
                toDate: toDate, // End date of the event
                description: data['description'], // Decription of the event
                split: split, // Boolean of if the event is split or not
                status: data['status'], // Status of the event
                rejectedMessage: data['rejectMessage'], // Rejected message of the event
                blackOutDates: this.blackOutDataArray,
                holidayDates: this.holidayDataArray,
                viewOnly: false,
                approver: true,
                eventData: this.timeoffEvents,
                isManager: this.isManager,
                conflictBlackOut: data['conflictBlackOut']
            };

            const dialogRef = this.dialog.open(TimeoffEntryDialogComponent, {
                width: '960px',
                height: 'auto',
                data: dialogData
            });

            dialogRef.afterClosed().subscribe(result => {
                if (result !== undefined) {
                    if (result['update']) {
                        this.snackbar.open('Updating entry', '', { duration: 2000 });
                        this._timeoffService.postUpdateTimeoffEntry(result['data']).subscribe(data => {
                            this.snackbar.open('Entry updated successfully', '', { duration: 2000 });
                            this.getTimeoffApprovalData();
                        }, err => {console.error();});
                    } else if (result['delete']) {// Delete button clicked
                        let entryId = result['entryId'];

                        const deleteDialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
                            width: '585px',
                            height: 'auto'
                        });

                        deleteDialogRef.afterClosed().subscribe(result => {// Check if the entry is to be deleted
                            if (result !== undefined) {
                                if (result['toDelete']) {
                                    this._timeoffService.DeleteTimeoffEntry(entryId).subscribe(
                                        data => {
                                            this.snackbar.open('Entry Successfully Deleted', '', { duration: 2000 });
                                            this.getTimeoffApprovalData();
                                        },
                                        err => console.error(err)
                                    );
                                }
                            }
                        });
                    }
                }
            });
        }
    }

    loadTimeoffRejectDialog(entry) {
        const dialogRef = this.dialog.open(TimeoffRejectDialogComponent, {
            width: '585px',
            height: 'auto',
            data: entry
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result !== undefined) {
                if (result['reject']) {
                    this.approveEntry(entry.id, 0, result['message'], result['deleteQB']);
                }
            }
        });
    }

    processTimeOffData(passedTimeOffData?) {
        let timeOffData = [];
		this.timeOffEntries = [];

        if (passedTimeOffData !== undefined) {
            timeOffData = passedTimeOffData;
        } else {
            timeOffData = this.timeoffEvents;
		};

		var timeoffPromises = []
		for(var i = timeOffData.length - 1;i>=0;i--){
			timeoffPromises.push(new Promise((resolve)=>{
				this.processEntry(timeOffData[i]);
				resolve('done');
			}));
		}

        // when all time off entries are processed, move onto parsing into approved
		Promise.all(timeoffPromises).then(()=>{
            this.parseAllEntriesByApproval();
		});
    }

	processEntry(entry){
        // Clear the array of calendar events
		let colorReference = "";

		// If the current entry from the master event list has the same category, then add it to events
		if ((this.typeFilterSelect.value.includes(entry['categoryId']) || this.typeFilterSelect.value.length == 0) && (this.userFilterSelect.value.includes(entry['userId']) || this.userFilterSelect.value.length == 0)) {
			/**
			 * Manually adjusts the "from" and "to" dates to pass into the Edit
			 * Time Off dialog to address an issue where dates were displayed
			 * incorrectly due to time zone mismatch (fixed under PROS-598).
			 */
			let startDateMoment: moment.Moment = moment(entry['startDate']);
			let endDateMoment: moment.Moment = moment(entry['endDate']);
			let fromDate: Date = startDateMoment.subtract(startDateMoment.utcOffset(), 'minutes').toDate();
			let toDate: Date = endDateMoment.subtract(endDateMoment.utcOffset(), 'minutes').toDate();

			/*
				Only adds to the list view if either the
				start date or end date is within the date range
			*/
			if((fromDate >= this.firstDayOfMonth)){
				// Push the entry to the list view
				if(entry['approvalStatusId'] == 1){
					for(var j = 0; j < this.blackOutDataArray.length; j++){
						var conflictBlackOut = entry['dateList'].find(timeOff => timeOff.date.substring(0, 10)
							== this.blackOutDataArray[j].blackOutDate.substring(0, 10));
						if(conflictBlackOut != null && conflictBlackOut['isExcluded'] == false){
							entry['conflictBlackOut'] = true;
						}
					}
				}
				this.timeOffEntries.push({
					employee: this.findUser(entry['userId']),
					requestDate: entry['createdDate'],
					startDate: fromDate,
					endDate: toDate,
					type: this.findCategory(entry['categoryId']),
					id: entry['id'],
					approvalStatusId: entry['approvalStatusId'],
					isApproved: entry['approvalStatusId'] === 2,
					isDenied: entry['approvalStatusId'] === 3,
					conflictBlackOut: entry['conflictBlackOut']
				});
			}

			// Create and initialize an array to be used to distinguish between normal and special dates (special dates are half days and excluded days)
			let specialDateReferenceArray: boolean[] = [entry.dateList.length];

			// Populate the special date reference array from the current entry data
			for (let index = 0; index < entry.dateList.length; index++) {
				specialDateReferenceArray[index] = (entry.dateList[index].isMorning === true || entry.dateList[index].isAfternoon === true || entry.dateList[index].isExcluded === true);
			}

			// Declare variables to be used to reference the next special date index and the end date for date ranges
			let nextSpecialDate: number;
			let endDate: Date;

			// Iterate over the special date reference array, and handle each date represented within either as a normal date or a special date
			for (let index = 0; index < specialDateReferenceArray.length; index++) {
				// Set nextSpecialDate to be the index of the next special date after the current index
				nextSpecialDate = specialDateReferenceArray.indexOf(true, index);

				// If the date at the current index is a normal date, process it as such
				if (!specialDateReferenceArray[index]) {
					// If there is a special date after the date at the current index, set the end date to the day before the next special date
					if (nextSpecialDate > -1) {
						endDate = moment(entry.dateList[nextSpecialDate - 1].date).toDate();
					} else {
						endDate = moment(entry.dateList[entry.dateList.length - 1].date).toDate();
					}

					/**
					 * Manually adjusts the "from" and "to" dates to pass into the Edit
					 * Time Off dialog to address an issue where dates were displayed
					 * incorrectly due to time zone mismatch (fixed under PROS-598).
					 */

					let startDateMoment: moment.Moment = moment(entry.dateList[index].date);
					let endDateMoment: moment.Moment = moment(endDate);
					let fromDate: Date = startDateMoment.subtract(startDateMoment.utcOffset(), 'minutes').toDate();
					let toDate: Date = endDateMoment.subtract(endDateMoment.utcOffset(), 'minutes').toDate();

					if(entry['approvalStatusId'] === 0){
						colorReference = "submitted";
					}
					else if(entry['approvalStatusId'] === 1){
						colorReference = "pending";
					}
					else if(entry['approvalStatusId'] === 2){
						colorReference = "approved"
					}
					else if(entry['approvalStatusId'] === 3){
						colorReference = "rejected"
					}

						// Push the new normal date range to the calendar views
						this.events.push({
							id: entry.id,
							start: fromDate,
							end: toDate,
							title: this.findCategory(entry['categoryId']) + ' - ' + this.findUser(entry['userId']),
							color: this.color[colorReference]
						});




					// If there is a special date after the date at the current index, set the index to the day just before that date
					if (nextSpecialDate > -1) {
						index = nextSpecialDate - 1;
					} else {
						index = entry.dateList.length - 1;
					}
				} else if (entry.dateList[index].isMorning === true || entry.dateList[index].isAfternoon === true) {
					// Build the event title string from the event category and timeframe for the time off
					let timeframeString: string = (entry.dateList[index].isMorning) ? 'Morning' : 'Afternoon';
					let eventTitle: string = this.findCategory(entry['categoryId']) + ' (' + timeframeString + ' Only)' + ' - ' + this.findUser(entry['userId']);

					/**
					 * Manually adjusts the "from" and "to" dates to pass into the Edit
					 * Time Off dialog to address an issue where dates were displayed
					 * incorrectly due to time zone mismatch (fixed under PROS-598).
					 */
					let halfDayMoment: moment.Moment = moment(entry.dateList[index].date);
					let halfDayDate: Date = halfDayMoment.subtract(halfDayMoment.utcOffset(), 'minutes').toDate();
					if(entry['approvalStatusId'] === 0){
						colorReference = "submitted";
					}
					else if(entry['approvalStatusId'] === 1){
						colorReference = "pending";
					}
					else if(entry['approvalStatusId'] === 2){
						colorReference = "approved"
					}
					else if(entry['approvalStatusId'] === 3){
						colorReference = "rejected"
					}
					// Push the half day to the calendar views
					this.events.push({
						id: entry.id,
						start: halfDayDate,
						end: halfDayDate,
						title: eventTitle,
						color: this.color[colorReference],
						cssClass: 'half-day'
					});
				}
			}
		}
	}

    sortingSources() {
        this.timeOffApprovalDataSources['pending'].sort = this.sort.first;
        this.timeOffApprovalDataSources['pending'].sortingDataAccessor = (item, property) => {
            switch (property) {
                case 'employee': return item['employee'];
                case 'requestDate': return item['requestDate'];
                case 'startDate': return item['startDate'];
                case 'endDate': return item['endDate'];
                case 'type': return item['type'];
                default: return item[property];
            }
        };

        this.timeOffApprovalDataSources['approved'].sort = this.sort.first;
        this.timeOffApprovalDataSources['approved'].sortingDataAccessor = (item, property) => {
            switch (property) {
                case 'employee': return item['employee'];
                case 'requestDate': return item['requestDate'];
                case 'startDate': return item['startDate'];
                case 'endDate': return item['endDate'];
                case 'type': return item['type'];
                default: return item[property];
            }
        };

        this.timeOffApprovalDataSources['denied'].sort = this.sort.first;
        this.timeOffApprovalDataSources['denied'].sortingDataAccessor = (item, property) => {
            switch (property) {
                case 'employee': return item['employee'];
                case 'requestDate': return item['requestDate'];
                case 'startDate': return item['startDate'];
                case 'endDate': return item['endDate'];
                case 'type': return item['type'];
                default: return item[property];
            }
        };

        this.timeOffApprovalDataSources['all'].sort = this.sort.first;
        this.timeOffApprovalDataSources['all'].sortingDataAccessor = (item, property) => {
            switch (property) {
                case 'employee': return item['employee'];
                case 'requestDate': return item['requestDate'];
                case 'startDate': return item['startDate'];
                case 'endDate': return item['endDate'];
                case 'type': return item['type'];
                default: return item[property];
            }
        };
    }

    parseAllEntriesByApproval(){
        /**
         * Declare and initialize variables for four arrays; three constaining
         * time off entries with different statuses, one with all entries
         */
        let allTimeOffEntries = [];
        let pendingTimeOffEntries = [];
        let approvedTimeOffEntries = [];
        let deniedTimeOffEntries = [];

        // Iterate over the time off entries and sort them into different arrays based on their status (pending, approved, denied)
        for (let timeOffEntry of this.timeOffEntries) {
            if (timeOffEntry.approvalStatusId !== 2 && timeOffEntry.approvalStatusId !== 3) {
                pendingTimeOffEntries.push(timeOffEntry);
            } else if (timeOffEntry.approvalStatusId === 2) {
                approvedTimeOffEntries.push(timeOffEntry);
            } else if (timeOffEntry.approvalStatusId === 3) {
                deniedTimeOffEntries.push(timeOffEntry);
            }
        }

        // Build a sorted array of all time off entries by pushing entry arrays in the desired display order (pending, approved, denied)
        allTimeOffEntries.push(...pendingTimeOffEntries);
        allTimeOffEntries.push(...approvedTimeOffEntries);
        allTimeOffEntries.push(...deniedTimeOffEntries);

        // Associate time off entry data with data sources for the view's tables
        this.timeOffApprovalDataSources['pending'] = new MatTableDataSource(pendingTimeOffEntries);
        this.timeOffApprovalDataSources['approved'] = new MatTableDataSource(approvedTimeOffEntries);
        this.timeOffApprovalDataSources['denied'] = new MatTableDataSource(deniedTimeOffEntries);
        this.timeOffApprovalDataSources['all'] = new MatTableDataSource(allTimeOffEntries);

        // Set up pagination for the time off entry data sources
        this.timeOffApprovalDataSources['pending'].paginator = this.paginators.toArray()[0];
        this.timeOffApprovalDataSources['approved'].paginator = this.paginators.toArray()[1];
        this.timeOffApprovalDataSources['denied'].paginator = this.paginators.toArray()[2];
        this.timeOffApprovalDataSources['all'].paginator = this.paginators.toArray()[3];

        this.sortingSources();

        this.refresh.next(); // Refresh calendar
		return;
    }

    refreshFilter() {
        this.events = [];

        this.processBlackOutData();
        this.processHolidayData();
        this.processTimeOffData();
    }

	processBlackOutData(){
		try{
			var promises = []
            for (var entry of this.blackOutDataArray){
				promises.push(new Promise(resolve =>{
					this.parseBlackOutData(entry);
					resolve('done')
				}));
            };
			Promise.all(promises).then(()=>{
				this.refresh.next();
			})
        }catch(exception){
            this.snackbar.open('Error While Loading Black Out Events', '', { duration: 2000 });
        }
	}

	parseBlackOutData(entry){
		this.events.push({
			id: entry.id,
			start: moment(entry.blackOutDate.substring(0, 10)).toDate(),
			end: moment(entry.blackOutDate.substring(0, 10)).toDate(),
			title: entry.reason,
			color: this.color["blackOut"],
			cssClass: 'white'
		});
		return;
	}

	processHolidayData(){
		try{
			var promises = []
            for (var entry of this.holidayDataArray){
				promises.push(new Promise(resolve =>{
					this.parseHolidayData(entry);
					resolve('done')
				}));
            };
			Promise.all(promises).then(()=>{
				this.refresh.next();
			})
        }catch(exception){
            this.snackbar.open('Error While Loading Holiday Events', '', { duration: 2000 });
        }
	}

	parseHolidayData(entry){
		this.events.push({
			id: entry.id,
			start: moment(entry.holidayDate.substring(0, 10)).toDate(),
			end: moment(entry.holidayDate.substring(0, 10)).toDate(),
			title: entry.reason,
			color: this.color["holiday"]
		});
		return;
	}

    clearTypeFilters() {
        this.typeFilterSelect.reset();
        this.typeFilterSelect.setValue([]);

        this.refreshFilter();
    }

    clearUserFilters() {
        this.userFilterSelect.reset();
        this.userFilterSelect.setValue([]);

        this.refreshFilter();
    }

    findCategory(id) {
        // Gets the name of a category from the id and returns it
        let filterFind = this.selectedFilters.find(f => f['id'] == id);

        if (filterFind !== undefined) {
            return filterFind['name'];
        } else {
            return 'Event';
        }
    }

    findUser(id) {
        // Gets the name of a category from the id and returns it
        let userFind = this.users.find(f => f['userId'] == id);
        if (userFind !== undefined) {
            return userFind['fullName'];
        } else {
            return 'No Name Found';
        }
    }

    getUsers() {
        this._timeoffService.getUsers().subscribe(
            data => {
                this.getTimeoffApprovalData();
                this.users.push.apply(this.users, data);
                this.selectedUsers = this.users;
            },
            err => console.error(err)
        );
    }

    getTimeoffCategory() {
        this._timeoffService.getTimeoffCategories()
		.pipe(takeUntil(this.ngUnsubscribe))
		.subscribe(
            data => {
                this.filters.push.apply(this.filters, data); // add the categories to the filters array
                this.selectedFilters = this.filters;
            },
            err => {
                console.error(err);
            }
        );
    }

    getTimeoffApprovalData() {
        this.timeoffEvents = [];
        this.dataSource.data = [];

        this.setFirstDayAndLastOnCalendar();

        this._timeoffService.getTimeoffApprovalEntries(this.firstDayOnCalender, this.lastDayOnCalender)
		.pipe(takeUntil(this.ngUnsubscribe))
		.subscribe(
            data => {
				this.timeoffEvents.push.apply(this.timeoffEvents, data);
                this.processTimeOffData();
                this.isTimeOffDataLoading = false;
            },
            err => {
                this.isTimeOffDataLoading = false;

                console.error(err);
            }
        );
    }

    // refresh method used by approve methods
    approveRefresh(entry?, isApproving?, entryId?) {
        if(entryId){
            for(let i = 0; i < this.timeOffApprovalDataSources[this.displayData].data.length; i++){
                if(entryId === this.timeOffApprovalDataSources[this.displayData].data[i]["id"]){
                    if(isApproving == false){
                        this.timeOffApprovalDataSources[this.displayData].data[i]["isApproved"] = false;
						this.timeOffApprovalDataSources[this.displayData].data[i]["isDenied"] = true;
						this.timeOffApprovalDataSources[this.displayData].data[i]["approvalStatusId"] = 3;
                        this.timeOffApprovalDataSources["denied"].data.push(this.timeOffApprovalDataSources[this.displayData].data[i]);
                        this.timeOffApprovalDataSources["denied"]._updateChangeSubscription();
                    }
                    else{
                        this.timeOffApprovalDataSources[this.displayData].data[i]["isApproved"] = true;
						this.timeOffApprovalDataSources[this.displayData].data[i]["isDenied"] = false;
						this.timeOffApprovalDataSources[this.displayData].data[i]["approvalStatusId"] = 2;
                        this.timeOffApprovalDataSources["approved"].data.push(this.timeOffApprovalDataSources[this.displayData].data[i]);
                        this.timeOffApprovalDataSources["approved"]._updateChangeSubscription();
                    }
                    this.timeOffApprovalDataSources[this.displayData].data.splice(i,1);
                    this.timeOffApprovalDataSources[this.displayData]._updateChangeSubscription();
                }
            }
        }
        else{

                if(entry.isApproving == false){
                    entry["isApproved"] = false;
					entry["isDenied"] = true;
					entry["approvalStatusId"] = 3;
                    this.timeOffApprovalDataSources["denied"].data.push(entry);
                    this.timeOffApprovalDataSources["denied"]._updateChangeSubscription();
                }
                else{
                    entry["isApproved"] = true;
					entry["isDenied"] = false;
                    entry["approvalStatusId"] = 2;
                    this.timeOffApprovalDataSources["approved"].data.push(entry);
                    this.timeOffApprovalDataSources["approved"]._updateChangeSubscription();
                }
                this.timeOffApprovalDataSources[this.displayData].data.splice(entry,1);
                this.timeOffApprovalDataSources[this.displayData]._updateChangeSubscription();

        }

    }

    approveSelected() {
        this.isTimeOffDataLoading = true;
        //Prevents multiple refreshes of data in case of error
        var remaining = this.selection.selected.length;
        this.snackbar.open('Approving selected entries', '', { duration: 2000 });

        this.quickbooksService.refreshQuickbookOAuth().subscribe(data => {
            this.selection.selected.forEach(event => {
				if (event.approvalStatusId !== 2) {
                    let entry = this.timeoffEvents.find(e => e['id'] == event.id);

                    this._timeoffService.postTimeoffApprovalEntry(entry, 1).subscribe(
                        data => {
                            remaining = remaining - 1;
                                this.approveRefresh(event, true);
                                if(remaining == 0){
                                    this.snackbar.open('Successfully approved entries', '', { duration: 2000 });
                                    this.selection.clear();
                                    this.isTimeOffDataLoading = false;
                                }
                        },
                        err => {
                            remaining = remaining - 1;
                            if (remaining == 0) {
                                this.selection.clear();
                                this.isTimeOffDataLoading = false;
                                this.getTimeoffApprovalData();
                            }

                            if (err.status == 422) {
                                this.snackbar.open('Error due to items not synced with Quickbooks', '', { duration: 2000 });
                            } else {
                                this.snackbar.open('Error on Submitting Entries', '', { duration: 2000 });
                            }
                        }
                    );
                }
            });
        });
    }

    approveEntry(entryId, isApproving, message?, deleteQB = false) {
        // Clears out the sources
       this.isTimeOffDataLoading = true;

        let entry = this.timeoffEvents.find(event => event['id'] == entryId);
        entry['rejectMessage'] = message ? message : '';

        if(isApproving == true){
            this.snackbar.open('Approving entry', '', { duration: 2000 });
        }
        else{
            this.snackbar.open('Rejecting entry', '', { duration: 2000 });
            isApproving = false;
        }
        this._timeoffService.postTimeoffApprovalEntry(entry, isApproving, deleteQB).subscribe(
            data => {
               this.approveRefresh(entry, isApproving, entryId);
               if(isApproving){
                    this.snackbar.open('Successfully approved entry', '', { duration: 2000 });
               }
               else{
                    this.snackbar.open('Successfully rejected entry', '', { duration: 2000 });
               }
               this.isTimeOffDataLoading = false;
            },
            err => {
                this.getTimeoffApprovalData();

                if (err.status == 422) {
                    this.snackbar.open('Error due to items not synced with Quickbooks', '', { duration: 2000 });
                } else {
                    this.snackbar.open('Error on Submitting Entries', '', { duration: 2000 });
                }
                this.isTimeOffDataLoading = false;
            }
        );
    }

    increaseDate() {
		this.unsubscribeFromOldRequests();
		this.activeDayIsOpen = false;
		this.isTimeOffDataLoading = true;

        if (this.activeView == 'month' || this.activeView == 'list') {
            this.viewDate = moment(this.viewDate).add(1, 'month').toDate();
        } else {
            this.viewDate = moment(this.viewDate).add(1, 'week').toDate();
            this.viewDateTwoWeek = moment(this.viewDateTwoWeek).add(1, 'week').toDate();
        }

        this.formatDateRange();
        this.getTimeoffApprovalData();
        this.getBlackOutDates();
        this.getHolidayDates();
    }

    decreaseDate() {
		this.resetEvents();
		this.activeDayIsOpen = false;
		this.isTimeOffDataLoading = true;

        if (this.activeView == 'month' || this.activeView == 'list') {
            this.viewDate = moment(this.viewDate).subtract(1, 'month').toDate();
        } else {
            this.viewDate = moment(this.viewDate).subtract(1, 'week').toDate();
            this.viewDateTwoWeek = moment(this.viewDateTwoWeek).subtract(1, 'week').toDate();
        }

        this.formatDateRange();
        this.getTimeoffApprovalData();
        this.getBlackOutDates();
        this.getHolidayDates();
    }

    setDate(selectedDate: Date) {
		this.resetEvents()
        this.viewDate = moment(selectedDate).toDate();
        this.viewDateTwoWeek = moment(selectedDate).add(1, 'week').toDate();

        this.formatDateRange();
        this.getTimeoffApprovalData();
        this.getBlackOutDates();
        this.getHolidayDates();
    }

    setView(selectedView: string) {
        this.activeView = selectedView;
    }

    formatDateRange(selectedView?: string) {
        // Set the view variable if selectedView was passed in
        if (selectedView !== undefined) {
            this.setView(selectedView);
        }

        // Based on the view selected, generate a date range string
        if (this.activeView == 'month' || this.activeView == 'list') {
            // Get the first day of the selected month
			let monthStart = moment(this.viewDate).startOf('month').toDate();
			/*	Set global variables for the first and last day of the month
				to prevent entries outside of the date range from being displayed
				in the list view
			*/
			this.firstDayOfMonth = monthStart;
			this.lastDayOfMonth = moment(this.viewDate).endOf('month').toDate();

            // Set the date range string
            this.dateRange = moment(monthStart).format('MMMM YYYY');
        } else if (this.activeView == 'week' || this.activeView == 'two-week') {
            // Get the start and end date of the date range based on which view is selected
            let startDate = moment(this.viewDate).startOf('isoWeek').toDate();
            let endDate: Date = new Date();

            if (this.activeView == 'week') {
                endDate = moment(this.viewDate).endOf('isoWeek').toDate();
            } else {
                endDate = moment(this.viewDateTwoWeek).endOf('isoWeek').toDate();
            }

            /** Check if the start and end dates are in the same month and year
			 * and respond accordingly
			 **/
            let sameMonth = startDate.getMonth() == endDate.getMonth();
            let sameYear = startDate.getFullYear() == endDate.getFullYear();
            let start = 'MMM D - ';
            let end = 'D, YYYY';

            if (!sameMonth) {
                end = 'MMM D, YYYY';
            }

            if (!sameYear) {
                start = 'MMM D, YYYY - ';
            }

            // Set the date range string
            this.dateRange = moment(startDate).format(start) + moment(endDate).format(end);
        }
    }

    toggleDataSource(event) {
        this.displayData = event.value;
        this.dataSource = this.timeOffApprovalDataSources[event.value];

        this.selection.clear();
    }

    getBlackOutDates() {
        this.blackOutDataArray = [];
        this.setFirstDayAndLastOnCalendar();

        this._timeoffService.getTimeOffBlackOutDates(this.firstDayOnCalender,this.lastDayOnCalender)
		.pipe(takeUntil(this.ngUnsubscribe))
		.subscribe(
            data => {
                this.blackOutDataArray = [];
                this.blackOutDataArray.push.apply(this.blackOutDataArray, data);
                this.blackOutDataArray.sort(function(a, b) {
                    var aDate = a.blackOutDate;
                    var bDate = b.blackOutDate;

                    return (aDate < bDate) ? -1 : (aDate > bDate) ? 1 : 0;
                });
				this.processBlackOutData();
            }, err => {
                this.snackbar.open(err.statusText, '', { duration: 2000 });
                console.error(err);
            }
        );
    }

    getHolidayDates() {
        this.holidayDataArray = [];
        this.setFirstDayAndLastOnCalendar();

        this._timeoffService.getTimeOffHolidayDates(this.firstDayOnCalender, this.lastDayOnCalender)
		.pipe(takeUntil(this.ngUnsubscribe))
		.subscribe(
            data => {
                this.holidayDataArray = [];
                this.holidayDataArray.push.apply(this.holidayDataArray, data);
                this.holidayDataArray.sort(function(a, b) {
                    var aDate = a.holidayDate;
                    var bDate = b.holidayDate;

                    return (aDate < bDate) ? -1 : (aDate > bDate) ? 1 : 0;
                });
				this.processHolidayData();
            }, err => {
                this.snackbar.open(err.statusText, '', { duration: 2000 });
                console.error(err);
            }
        );
    }

	setFirstDayAndLastOnCalendar(){
		var selection = 6 - this.tenantStartDayValue;
        this.firstDayOnCalender = new Date(this.viewDate.getFullYear(), this.viewDate.getMonth() - 1, 1)
        this.firstDayOnCalender.setDate(this.firstDayOnCalender.getDate() - (this.firstDayOnCalender.getDay() + selection) % 7)

		this.lastDayOnCalender = new Date(this.viewDate.getFullYear(), this.viewDate.getMonth() + 1, 0);
        this.lastDayOnCalender.setDate(this.lastDayOnCalender.getDate() + (this.tenantStartDayConfig + 7 - this.lastDayOnCalender.getDay() - 1) % 7);
	}

	unsubscribeFromOldRequests(){ // unsubscribes from old request to grab events
		this.ngUnsubscribe.next();
		//this.ngUnsubscribe.complete();
	}

	resetEvents(){
		this.unsubscribeFromOldRequests();
		this.clearEvents();
	}

	clearEvents() {
		this.events = [];
		this.blackOutDataArray = [];
		this.holidayDataArray = [];
		this.timeoffEvents = [];
    }
}
