import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalService } from '../api/globalVars';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    withCredentials: true
};

@Injectable({
    providedIn: 'root'
})

export class ExpensesService {
    path = this.globalVar.retrievePath();

    constructor(public globalVar: GlobalService, private _http: HttpClient) { }
    getExpensePolicy() {
        const geturl = this.path + 'expensepolicy?activeFlag=1';

        return this._http.get(geturl, httpOptions);
    }

    getExpenseData(dateRange = null) {// Returns the expense reports for the user
        var additional = dateRange != null ? `&month=${dateRange.getMonth() + 1}&year=${dateRange.getFullYear()}` : '';
        const geturl = this.path + 'expensereport?userFlag=1' + additional;

        return this._http.get(geturl, httpOptions);
    }

    getExpenseCategories() {
        const geturl = this.path + 'expensescategory?';

        return this._http.get(geturl, httpOptions);
    }

    getClientClassData(active: boolean) {
        const geturl = this.path + 'statusreport?Active=' + active;

        return this._http.get(geturl, httpOptions);
    }

    getExpenseApprovalData(approved: boolean) {// Returns the expense reports for the user
        let geturl = '';

        if (approved) {
            geturl = this.path + 'expenses?approvedFlag=1';
        }
        else {
            geturl = this.path + 'expenses?pendingFlag=1';
        }

        return this._http.get(geturl, httpOptions);
    }
    getExpenseReports(dateRange = null) {
        var additional = dateRange != null ? `&month=${dateRange.getMonth() + 1}&year=${dateRange.getFullYear()}` : '';
        const geturl = this.path + 'expensereport?' + additional;

        return this._http.get(geturl, httpOptions);

    }
    getExpenseReportApprovalData(approved: boolean) {
        let geturl = '';

        if (approved) {
            geturl = this.path + 'expensereport?approvedFlag=1';
        }
        else {
            geturl = this.path + 'expensereport?approvedFlag=0';
        }

        return this._http.get(geturl, httpOptions);
    }
    getExpenseReceipt(reportId) {
        const geturl = this.path + 'expenses?reportFileId=' + reportId;

        return this._http.get(geturl, httpOptions);
    }
    getExpenseDetails(reportId) {
        const geturl = this.path + 'expenses?reportId=' + reportId;

        return this._http.get(geturl, httpOptions);
    }
    getExpenseStops(expenseId) {
        const geturl = this.path + 'expenses?expenseId=' + expenseId;

        return this._http.get(geturl, httpOptions);
    }
    postExpenseReport(expenseReport) {
        const posturl = this.path + 'expensereport?reportFlag=1';

        return this._http.post(posturl, expenseReport, httpOptions);
    }

    putExpenseReport(expenseReport) {
        const puturl = this.path + 'expensereport?reportFlag=1';

        return this._http.put(puturl, expenseReport, httpOptions);
    }

    putExpenseDetail(expenseDetail) {
        const puturl = this.path + 'expenses?expenseFlag=1';

        return this._http.put(puturl, expenseDetail, httpOptions);
    }


    deleteExpenseReport(reportId) {
        const deleteurl = this.path + 'expensereport?reportId=' + reportId;

        return this._http.delete(deleteurl, httpOptions);
    }

    deleteExpenseReportDetail(reportId, detailId) {
        const deleteurl = this.path + 'expenses?expenseFlag=1&expenseId=' + detailId + '&reportId=' + reportId;

        return this._http.delete(deleteurl, httpOptions);
    }

    moveExpenseDetail(oldId, model) {
        const posturl = this.path + 'expenses?moveFlag=1&oldReportId=' + oldId;

        return this._http.post(posturl, model, httpOptions);
    }
    approveExpenseReports(IdList) {
        const posturl = this.path + 'expensereport?idFlag=1';

        return this._http.post(posturl, IdList, httpOptions);

    }
    denyExpenseReport(reportId) {
        const posturl = this.path + 'expensereport?reportId=' + reportId;

        return this._http.post(posturl, null, httpOptions);
    }
}
