import { Component, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { MatTableDataSource, MatSnackBar, MatSelect, MatDialog, MatSort } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import * as moment from 'moment';
import { TimesheetService } from '../../api/timesheet.service';
import { SetttingsService } from '../../api/setttings.service';
import { TimesheetApprovalDialogComponent } from './timesheet-approval-dialog/timesheet-approval-dialog.component';
import { TimesheetDenialDialogComponent } from './timesheet-denial-dialog/timesheet-denial-dialog.component';
import { TimesheetApprovalSendRemindersDialogComponent } from './timesheet-approval-send-reminders-dialog/timesheet-approval-send-reminders-dialog.component';
import { AuthService } from '../../services/auth/auth.service';
import { QuickbooksService } from '../../api/quickbooks.service';

@Component({
    selector: 'app-timesheet-approval',
    templateUrl: './timesheet-approval.component.html',
    styleUrls: ['./timesheet-approval.component.scss']
})
export class TimesheetApprovalComponent implements OnInit {

    displayedColumns = ['select', 'employee', 'class', 'client', 'project', 'hours', 'billable', 'edit', 'approve', 'deny'];

    selection = new SelectionModel(true, []);
    dataArray = [];
    dataSource = new MatTableDataSource();
    tempDataSource = [];
    timesheetDate = moment();
    isManager: boolean = false;
    isTenantAdmin:boolean = false;
    allClientsLoaded: boolean = false;
    selectedClientsLoaded: boolean = false;
    dateRange = '';
    clients: object[]; // array of client objects for the selectors
    selectedClients: object[];
    services: object[]; // array of service objects for the selectors
    allClients: object[] = [];
    allServices: object[] = [];
    allClasses: object[] = [];
    allProjects: object[] = [];
    projects: object[] = []; // array of project objects for the selectors
    selectedProjects: object[] = [];
    users: object;
    activeClients = [];
    isTimesheetApprovalLoading: boolean;

    tenantStartDayValue;
    tenantStartDayConfig = null;

    @ViewChild(MatSelect) filterSelect;
    @ViewChildren(MatSort) sort;

    constructor(private _timesheetService: TimesheetService,
        public dialog: MatDialog, private _settingService: SetttingsService,
        public snackBar: MatSnackBar, private authService: AuthService, private quickbooksService: QuickbooksService) { }

    ngOnInit() {
        this._settingService.getTenantWeekStart().subscribe(data => {
            this.tenantStartDayValue = parseInt(data + "");
            this.tenantStartDayConfig = this.tenantStartDayValue == 6 ? 0 : this.tenantStartDayValue + 1;
            this._settingService.setStartDayToCookie(this.tenantStartDayConfig  + "");

            this.authService.getUserPermissions().subscribe(userPermissions => this.isManager = ( (userPermissions.includes('manage:time-entries')) || (userPermissions.includes('administer-tenant:administration')) || (userPermissions.includes('manage-application-settings:administration')) ) );
          
            this.formatDateRange();
            this.getApprovalData();
            this.getActiveClients();
            this.getAllClientClassServiceData();
            this.getClientProjectData();
        });
    }

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
        this.isAllSelected() ?
            this.selection.clear() :
            this.dataSource.data.forEach(row => this.selection.select(row));
    }

    onKeyClientAndProjects(value) {
        this.selectedClients = this.filterClients(value);
        this.selectedProjects = this.filterProjects(value);
    }

    filterClients(value: string) {
        let filter = value.toLowerCase();
        return this.clients.filter(option => option['clientName'].toLowerCase().includes(filter));
    }

    filterProjects(value: string) {
        let filter = value.toLowerCase();
        return this.projects.filter(option => option['name'].toLowerCase().includes(filter));
    }

    //Sets focus of the combobox inputs on click
    setFocus(inputId: string) {
        if (document.getElementById(inputId) != null) {
            document.getElementById(inputId).focus();
        }
    }

    getActiveClients(){
         this._settingService.getClients().subscribe(
          data => {
            this.activeClients = Object.values(data);
            this.activeClients = this.activeClients.filter(c => c['isActive'] == true);
        }); 
    }

    getAllClientClassServiceData() {
        this.allClientsLoaded = false;
		this._timesheetService.getClientServiceClassData(false).subscribe(
			data => {
				this.allClients = data['clientProjectList'];
				this.allServices = data['serviceList'];
				this.allClasses = data['workClassList'];
				if(this.allClients != null)
					this.allClients.forEach(client => {
						if (client['projects'].length > 0) {// adds all of the projects for a client to a single array
							this.allProjects.push(...client['projects']);// Spread syntax ...client['projects'] expands the array to be pushed
						}
					});
                this.allClientsLoaded = true;
			},
			err => console.error(err)
		);
	}

     async getClientProjectData() {
        this.selectedClientsLoaded = false;
        await this.authService.getUserPermissions().subscribe(userPermissions => this.isTenantAdmin = ( (userPermissions.includes('administer-tenant:administration')) || (userPermissions.includes('manage-application-settings:administration')) ) ); //checks to see if admin
        this._timesheetService.getClientServiceClassData(!this.isTenantAdmin).subscribe( //if a tenantAdmin, will get all clients instead of just assigned ones
            data => {
                this.clients = data['clientProjectList'];

                if(this.isTenantAdmin){ //takes out any inactive clients from _timesheetService call
                    this.clients.forEach(element => {
                        element['projects'] = element['projects'].filter(p => p['isActive'] == true);  //only active projects
                    });
                  
                    this.clients = this.clients.filter(c => {
                        return this.activeClients.some(x => {
                            return c['clientId'] == x['id'] && c['projects'].length > 0; //only active clients with projects
                        });
                    });
                }
         
                this.selectedClients = this.clients;
                this.services = data['serviceList'];
                if (this.clients != null) {
                    this.clients.forEach(client => {
                        if (client['projects'].length > 0) {
                            this.projects.push(...client['projects']); // Spread syntax ...client['projects'] expands the array to be pushed
                            this.selectedProjects = this.projects;
                        }
                    });
                    if (this.projects.length > 0)
                        this.projects = this.projects.sort(function (a, b) {
                            if (a['name'] < b['name']) { return -1; }
                            if (a['name'] > b['name']) { return 1; }
                            return 0;
                        });
                }
                this.selectedClientsLoaded = true;
            },
            err => console.error(err)
        );
    }

    approveSelected() {
        this.isTimesheetApprovalLoading = true;
        this.tempDataSource = this.dataSource.data;
        this.dataSource = new MatTableDataSource();
        this.quickbooksService.refreshQuickbookOAuth().subscribe(data => {
            this.selection.selected.forEach(row => {
                if (row['isApproved'] == false || row['approvalStatusId'] !== 2)
                    this.changeApproval(row, 1);
            });
            this.selection.clear();
        });
    }

    changeApproval(row, type, deleteQB = false, singleEntry = false) {
        this.isTimesheetApprovalLoading = true;

        if(type == 0 || singleEntry)
            this.tempDataSource = this.dataSource.data;
        this.dataSource = new MatTableDataSource();
        this._timesheetService.updateTimesheetWeekApproval(row, moment(row['entryRow']['endPeriod']), type, deleteQB).subscribe(
            data => {
                this.isTimesheetApprovalLoading = false;
                if (type == 0) {
					this.snackBar.open('Timesheet Denied', '', { duration: 2000 });
                    row['isApproved'] = false;
					row["entryRow"]["isRejected"] = true;
					row["entryRow"]["approvalStatusId"] = 3;
					row["approvalStatusId"] = 3;
                } else {
					this.snackBar.open('Timesheet(s) Approved', '', { duration: 2000 });
                    row['isApproved'] = true;
					row["entryRow"]["isRejected"] = false;
					row["entryRow"]["approvalStatusId"] = 2;
					row["approvalStatusId"] = 2;
                }
                for(let val of this.tempDataSource){
                    if(val['id'] === row['id']){
                        val = row;
                    }
                }

                this.dataSource.data = this.tempDataSource;
                this.dataSource._updateChangeSubscription();
            },
            err => {
                this.isTimesheetApprovalLoading = false;
                this.dataSource.data = this.tempDataSource;
                this.dataSource._updateChangeSubscription();
                if (err.status == 422) {
                    this.snackBar.open('Error due to items not synced with Quickbooks', '', { duration: 2000 });
                } else {
                    this.snackBar.open('Sync Error, Check Connection Information', '', { duration: 2000 });
                }
                this.getApprovalData();
            }
        );
    }

    // Performs a deep copy of an object, meaning "copied" objects do not impact each other when they are modified
    copy(sourceObj) {
        var copiedObj, key, value;
        copiedObj = Array.isArray(sourceObj) ? [] : {};
        for (key in sourceObj) {
            value = sourceObj[key];
            copiedObj[key] = (typeof value === 'object') ? this.copy(value) : value;
        }
        return copiedObj;
    }

    // Calculates and returns the total number of hours for a given time entry
    calcTotalHours(rowData) {
        let totalHours = 0.0;
        for (var index in rowData.entryRow.weekHour) {
            totalHours += rowData.entryRow.weekHour[index].timeHour;
        }
        return Math.round(totalHours * 100) / 100;
    }

    loadTimeStatusDialog(rowData) {// Opens the TimesheetApprovalDialogComponent

        const dialogRef = this.dialog.open(TimesheetApprovalDialogComponent, {
            width: '585px',
            height: 'auto',
            data: {
                entryRow: rowData.entryRow, services: this.services,
                isManager: this.isManager, projects: this.projects, clients: this.clients,
                tenantStartDayValue: this.tenantStartDayValue, allProjects: this.allProjects, 
                allClients: this.allClients, allServices: this.allServices
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result != undefined && result.save === true) {
				rowData.totalHours = this.calcTotalHours(rowData);
				this._timesheetService.updateTimesheetEntry(rowData.entryRow).subscribe(
					data => {
						//refreshes table if data has changed
						this.getApprovalData();
						this.snackBar.open("Time Entry Successfully Updated", "", { duration: 2000 });
					},
					err => {
						this.snackBar.open("Error Saving Time Entry", "", { duration: 2000 });
						console.error(err);
					}
				);
            }
        });
    }

    refreshFilter() {
        let clientFilterActive = true;
        let projectFilterActive = true;
        let approvalData = [];
        if (this.filterSelect.selected) {
            this.filterSelect.selected.forEach(select => {
                if (select['group']['label'] == 'Clients') {
                    clientFilterActive = false;
                } else if (select['group']['label'] == 'Projects') {
                    projectFilterActive = false;
                }
            });
        }
        this.dataArray.forEach(entry => {
            // If the current entry from the master event list has the same category, then add it to events
            let clientBoolean = (this.filterSelect.value.includes(entry['entryRow']['clientId']) || clientFilterActive);
            let projectBoolean = (this.filterSelect.value.includes(entry['entryRow']['projectId']) || projectFilterActive);
            if ((clientBoolean && projectBoolean) || this.filterSelect.value.length == 0) {
                approvalData.push(entry);
            }
		});
        this.dataSource = this.sortTimeApprovalData(this.dataSource.filteredData);
        this.dataSource = new MatTableDataSource(approvalData);
        this.dataSource.sort = this.sort.first;
        this.dataSource.sortingDataAccessor = (item, property) => {
            switch (property) {
                case 'employee': return item['user']['fullName'];
                case 'billable': return item['service']['isBillable'];
                case 'class': return item['workClass']['name'];
                case 'hours': return item['totalHours'];
                default: return item[property];
            }
        };
    }

    LoadReminderDialog() {// Opens the TimesheetApprovalSendRemindersDialogComponent
        const dialogRef = this.dialog.open(TimesheetApprovalSendRemindersDialogComponent, {
            width: '768px',
            height: 'auto',
            data: moment(new Date(this.timesheetDate.toDate()).setDate(this.timesheetDate.toDate().getDate() + (this.tenantStartDayConfig + 7 - this.timesheetDate.toDate().getDay() - 1) % 7))
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result != undefined) {
                if (result['emails'].length > 0) {
                    result['emails'].forEach(email => {
                        this.sendReminder(email, result.title, result.body);
                    });
                }
            }

        });
    }

    LoadDeniedDialog(report) {// Opens the TimesheetDenialDialogComponent
        const dialogRef = this.dialog.open(TimesheetDenialDialogComponent, {
            width: '585px',
            height: 'auto',
            data: report
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result != undefined) {
                this._timesheetService.postDenialMessage(result).subscribe(
                    data => {
                        this.changeApproval(report, 0, result.deleteQB);
                    },
                    err => {
                        this.changeApproval(report, 0, result.deleteQB);
                    }
                );
            }
        });
    }

    processApprovalData(data) {
        let timesheetTemp = [];
        let count = 0;

        if (data !== null) {
            /*Due to the way that they data is structured
            the code below loops through each entry and
            adds the client to each entry for display*/
            data.forEach(client => {
                if (client !== null) {
                    client['approvalEntries'].forEach(entry => {
                        entry['client'] = client['client'];
                        timesheetTemp.push(...entry['approvalEntries']);
                        this.dataArray.push(...entry['approvalEntries']);
                    });
                    timesheetTemp.push(...client['approvalEntries']);
                    this.dataArray.push(...client['approvalEntries']);
                }
            });
        }

        if (timesheetTemp.length == 0) {
            this.dataSource.data = [];
        } else {
            timesheetTemp = this.sortTimeApprovalData(timesheetTemp);
            this.dataSource = new MatTableDataSource(timesheetTemp);
            this.dataSource.sort = this.sort.first;
            this.dataSource.sortingDataAccessor = (item, property) => {
                switch (property) {
                    case 'employee': return item['user']['fullName'];
                    case 'billable': return item['service']['isBillable'];
                    case 'class': return item['workClass']['name'];
                    case 'hours': return item['totalHours'];
                    case 'client': return item['client']['name'];
                    default: return item[property];
                }
            };
        }

        if (this.filterSelect.value != undefined) {
            this.refreshFilter();
        }
    }

    getApprovalData() {
        this.dataSource.data = [];
        this.isTimesheetApprovalLoading = true;
        var tempDate = moment(new Date(this.timesheetDate.toDate()).setDate(this.timesheetDate.toDate().getDate() + (this.tenantStartDayConfig + 7 - this.timesheetDate.toDate().getDay() - 1) % 7));
        this._timesheetService.getTimesheetWeekApproval(tempDate).subscribe(
            data => {
                this.dataArray = [];

                this.processApprovalData(data);
                this.isTimesheetApprovalLoading = false;
            },
            err => {
                this.dataArray = this.sortTimeApprovalData(this.dataArray);
                this.dataSource = new MatTableDataSource(this.dataArray);
                this.dataSource.sort = this.sort.first;
                this.dataSource.sortingDataAccessor = (item, property) => {
                    switch (property) {
                        case 'employee': return item['user']['fullName'];
                        case 'billable': return item['service']['isBillable'];
                        case 'class': return item['workClass']['name'];
                        case 'hours': return item['totalHours'];
                        default: return item[property];
                    }
                };
                if (this.filterSelect.value != undefined)
                    this.refreshFilter();
                console.error(err);
            }
        );
    }

    sendReminder(email, title, body) {
        let emailModel = {
            to: email,
            title: title,
            body: body
        };
        this._timesheetService.postReminderMessage(emailModel).subscribe(
            data => { },
            err => console.error(err)
        );
    }

    clearSelected(select: MatSelect) {
        select.value = [];
        this.refreshFilter();
    }

    sortTimeApprovalData(array) {
        return array.sort(function (a, b) {
            var nameA = a['user']['fullName'];
            var nameB = b['user']['fullName'];
            var dateA = new Date(a['entryRow']['createdDate']);
            var dateB = new Date(b['entryRow']['createdDate']);

            if (nameA == nameB) {
                return (dateA < dateB) ? -1 : (dateA > dateB) ? 1 : 0;
            }
            else {
                return (nameA < nameB) ? -1 : 1;
            }
        });
    }

    increaseDate() {
        this.timesheetDate.add(1, 'week');
        this.formatDateRange();
        this.getApprovalData();
        this.selection.clear();
    }

    decreaseDate() {
        this.timesheetDate.subtract(1, 'week');
        this.formatDateRange();
        this.getApprovalData();
        this.selection.clear();
    }

    setDate(selectedDate: Date) {
        this.timesheetDate = moment(selectedDate);
        this.formatDateRange();
        this.getApprovalData();
        this.selection.clear();

    }

    formatDateRange() {
        var selection = 6 - this.tenantStartDayValue;
		let day1 = moment(new Date(this.timesheetDate.toDate()).setDate(this.timesheetDate.toDate().getDate() - (this.timesheetDate.toDate().getDay() + selection) % 7));
		let day2 = moment(new Date(this.timesheetDate.toDate()).setDate(this.timesheetDate.toDate().getDate() + (this.tenantStartDayConfig + 7 - this.timesheetDate.toDate().getDay() - 1) % 7));

        let sameMonth = day1.toDate().getMonth() == day2.toDate().getMonth();
		let sameYear = day1.toDate().getFullYear() == day2.toDate().getFullYear();
        let start = 'MMM D - ';
        let end = 'D, YYYY';
        if (!sameMonth) {
            end = 'MMM D, YYYY';
        }
        if (!sameYear) {
            start = 'MMM D, YYYY - ';
        }

        this.dateRange = moment(day1).format(start) + moment(day2).format(end);
    }

    submitTimesheet(timesheet) {
        timesheet.renderRows();
    }

}
