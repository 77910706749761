import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FormControl, Form } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatTableDataSource, MatSnackBar, MatPaginator, MatDialog, MatButtonToggleGroup } from '@angular/material';
import { SetttingsService } from '../../../api/setttings.service';

@Component({
    selector: 'app-management-tasks-dialog',
    templateUrl: './management-tasks-dialog.component.html',
    styleUrls: ['./management-tasks-dialog.component.scss']
})

export class ManagementTasksDialogComponent implements OnInit {

    statusName: FormControl = new FormControl(this.taskStatusData['entry']['statusName']);
    order: FormControl = new FormControl(this.taskStatusData['entry']['order']);
    isResolved: FormControl = new FormControl(this.taskStatusData['entry']['isResolved']);
    //systemType: FormControl = new FormControl(this.taskStatusData['entry']['systemType']);    //System type code commented out until it is decided whether or not it will be used in the future
    taskStatusArray = [];
    valid: boolean = true;
    error: String;

    @ViewChild('nameInput')
    nameElement: any;
    @ViewChild('orderInput')
    orderElement: any;

    @ViewChild(MatPaginator) paginator;

    constructor(private _settingsService: SetttingsService, public dialogRef: MatDialogRef<ManagementTasksDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public taskStatusData) {
    }

    ngOnInit() {
        this.getTaskStatuses();
    }

    closeDialog() {
        this.dialogRef.close();
    }

    saveAndCloseDialog() {
        if(this.taskStatusData['entry']['statusId'] == undefined){
            this.taskStatusData['entry']['systemType'] = 1;
            this.taskStatusData['entry']['appIdentifier'] = 0;
			this.taskStatusData['entry']['requestVerification'] = null;
        }
        if(this.isValid()) {
            this.taskStatusData['entry']['statusName'] = this.statusName.value;
            this.taskStatusData['entry']['order'] = this.order.value;
            this.taskStatusData['entry']['isResolved'] = this.isResolved.value == null ? false : this.isResolved.value;
			this.taskStatusData['entry']['typeId'] = 3;
			this.taskStatusData['entry']['categoryId'] = 3;
            //this.taskStatusData['entry']['systemType'] = this.systemType.value;
            this.dialogRef.close({ submit: true, data: this.taskStatusData['entry'] });
        }
    }

    getTaskStatuses() {
        this._settingsService.getStatuses().subscribe(
            data => {
                this.taskStatusArray = Object.keys(data).map(i => data[i]);
            }, err => {
                console.error(err);
            }
        );
    }

    isNotDuplicate(){
        let tempTS = this.taskStatusArray.find(taskStatus => taskStatus['order'] == this.order.value);
        if(tempTS !== undefined){
            if(tempTS.statusId != this.taskStatusData.entry.statusId){   //Allows you to save and close a status that was being edited where the value was unchanged without labeling it as a duplicate of itself
                return false;
            }
        }
    }

    isValid() {
        // Validates form entry
        if(this.statusName.value == null || this.statusName.value == ''){   // Second check prevents empty strings
            this.error = 'Status Name is required';
            this.nameElement.nativeElement.focus();
            return false;
        }
        if(this.order.value == null || this.order.value == ''){
            this.error = 'Task Status Order is required';
            this.orderElement.nativeElement.focus();
            return false;
        }
        if(this.order.value < 0 || Number.isInteger(+this.order.value) == false){   // plus sign is necessary in front of "this.order..." so that it is not interpreted as a string
            this.error = 'Task Status Order must be a positive integer';
            this.orderElement.nativeElement.focus();
            return false;
        }

        if(this.isNotDuplicate() == false){ //Rejects duplicate order values
            this.error = 'Task Status Order must be unique';
            this.orderElement.nativeElement.focus();
            return false;
        }
        /*
        if(this.systemType.value == null){
            this.error = 'System Type is required';
            return false;
        }*/
        else{
            this.valid = true;
            return true;
        }
    }
}
