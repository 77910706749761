import { Component, OnInit, Input, ViewChild, ViewChildren } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatPaginator, MatTableDataSource, MatSnackBar, MatSort } from '@angular/material';
import { QuickbooksService } from '../../../api/quickbooks.service';
import * as moment from 'moment';
import { SetttingsService } from '../../../api/setttings.service';

@Component({
  selector: 'app-quickbooks-integration',
  templateUrl: './quickbooks-integration.component.html',
  styleUrls: ['./quickbooks-integration.component.scss']
})
export class QuickbooksIntegrationComponent implements OnInit {

  /**
   * Configuration Manager
   */
  admin_Email = new FormControl('', [Validators.required, Validators.email]);
  clientId = new FormControl('', [Validators.required]);
  clientSecret = new FormControl('', [Validators.required]);

  qb_oauth = {
    AccessSecret: '',
    AccessToken: '',
    FormatedDate: '',
    UntilRenewDay: 0
  };
  qb_config = {
    admin_Email: new FormControl('', [Validators.required, Validators.email]),
    clientId: new FormControl('', [Validators.required]),
    clientSecret: new FormControl('', [Validators.required]),
    id: ''
  };

  open: boolean;
  _QuickbooksService: QuickbooksService;

  PROSIGHT_DATA: ProSightData[] = [];
  QBRELATIONSHIP_DATA: QBRelationship[] = [];
  QUICKBOOK_DATA: QuickbookData[] = [];
  SYNCED_DATA: syncInstance[] = [];
  TIMEOFF_DATA: TimeOffData[] = [];

  TEMPORARY_CONNECTIONS = [];
  CATEGORIES: Category[] = [];
  SERVICES = [];
  TIME_OFF_RELATIONS = [];
  fullQuickbookData = null;
  isQuickbooksLoading: boolean = true;

  convertedItem = {
    quickbookId: '',
    databaseId: '',
    databaseTable: '',
    tenantId: '',
    synced: false,
    hireDate: '',
    quickbookName: ''
  };
  serviceTimeOffId = null;

  qb_type_num = 0;
  displayedColumns: string[] = ['ProSightInstance', 'Status', 'QuickbooksInstance', 'Options'];
  dataSource = new MatTableDataSource<syncInstance>([]);
  qb_type_options = [
    {
      'label': 'Client',
      'path': 'client',
      'value': 0,
      'table': 'C_CLIENT'
    }, {
      'label': 'Work Class',
      'path': 'workclass',
      'value': 1,
      'table': 'WC_WORK_CLASS'
    }, {
      'label': 'Service',
      'path': 'service',
      'value': 2,
      'table': 'SRV_SERVICE'
    }, {
      'label': 'Employee',
      'path': 'tenantuser?isActive=' + -1,
      'value': 3,
      'table': 'USR_USERS'
    },
    {
      'label': 'Time Off',
      'path': '',
      'value': 4,
      'table': ''
    }, {
      'label': 'Vendor',
      'path': 'tenantuser?isActive=' + -1,
      'value': 5,
      'table': 'USER_VENDOR'
    }, {
      'label': 'Expense Category',
      'path': 'expensescategory',
      'value': 6,
      'table': 'EC_EXPENSE_CATEGORY'
    }
  ];

  @Input()
  set opened(bool: boolean) {
    this.open = bool;
  }

  @ViewChild('tablePaginator') paginator: MatPaginator;
  @ViewChildren(MatSort) sort: any;

  constructor(private _settingsService: SetttingsService,
    private quickbooksService: QuickbooksService,
    public snackBar: MatSnackBar) {
    this._QuickbooksService = quickbooksService;
  }

  ngOnInit() {
    this.getConfigData();
    this.getOAuth();
    this.dataSource.paginator = this.paginator;
    this.getQuickbookRelation(this.qb_type_num);
  }

  /**
   * Configuration Manager
   */
  // Retrieves the Configuration Data
  getConfigData() {
    this._QuickbooksService.getQuickbookConfig().subscribe(
      data => { // Quickbooks Config worked
        this.qb_config.admin_Email.setValue(data['adminEmail']);
        this.qb_config.clientId.setValue(data['clientKey']);
        this.qb_config.clientSecret.setValue(data['clientSecret']);
        this.qb_config.id = data['id'];
      },
      err => { // Could not get Quickbook Config
        this.snackBar.open('Error Retrieving Configuration', '', { duration: 2000 });
      }
    );
  }

  // Retrieves the Authentication to Quickbooks Info
  getOAuth() {
    this._QuickbooksService.getQuickbookOAuth().subscribe(
      data => { // Quickbooks OAuth worked
        var created = moment(data['createdDate']);
        var endingTime = moment(data['createdDate']).add(data['expiration'], 'seconds');
        var current = moment();
        var diff = current.diff(endingTime, 'days') * -1;

        this.qb_oauth.AccessToken = data['accessToken'];
        this.qb_oauth.FormatedDate = created.format('L');
        this.qb_oauth.UntilRenewDay = diff;
      },
      err => { // Could not get OAuth
        this.snackBar.open('Error Retrieving Authentication', '', { duration: 2000 });
      }
    );
  }

  // Saves the current Quickbooks Config
  saveQuickBookConfigInfo() {
    var qb_config_sent = {
      admin_Email: this.qb_config.admin_Email.value,
      clientId: this.qb_config.clientId.value,
      clientSecret: this.qb_config.clientSecret.value,
      id: this.qb_config.id
    };

    this._QuickbooksService.saveQuickbooksConfig(qb_config_sent).subscribe(
      data => { // Token worked
        this.snackBar.open('Successfully Saved Configuration', '', { duration: 2000 });
      },
      err => { // Could not get Token
        this.snackBar.open('Error Saving Configuration', '', { duration: 2000 });
      }
    );
  }

  RequestToken(qb_config) {
    this._QuickbooksService.requestToken(this.qb_config.admin_Email.value).subscribe(
      requestUrl => {
        var popupWindow = window.open(requestUrl['data']);
        var eventMethod = popupWindow.addEventListener ? 'addEventListener' : 'attachEvent';
        var eventer = window[eventMethod];
        var messageEvent = eventMethod == 'attachEvent' ? 'onmessage' : 'message';
      },
      err => {}
    );
  }

  // Deletes and Saves empty Config
  clearQuickBookConfigInfo() {
    this.qb_config.admin_Email.setValue('');
    this.qb_config.clientId.setValue('');
    this.qb_config.clientSecret.setValue('');

    this.saveQuickBookConfigInfo();
  }

  /**
   * Sync Manager
   */
  // Changes value for selectItems for saves
  changeValue(item, sync) {
    if (this.qb_type_num == 0 || this.qb_type_num == 1 || this.qb_type_num == 2 || this.qb_type_num == 6) {
      var fullItem1 = this.PROSIGHT_DATA.find(i => i.id == item);
      var name = '';
      if (this.qb_type_num == 6)
        name = fullItem1.name;
      else
        name = sync.fullyQualifiedName;
      var convertedItem1 = {
        quickbookId: sync.quickbookId,
        databaseId: fullItem1.id,
        databaseTable: this.qb_type_options.find(i => i.value == this.qb_type_num).table,
        tenantId: fullItem1.tenantId,
        synced: true,
        hireDate: sync.hireDated != null ? sync.hireDated : '1900-01-01 00:00:00.0000000',
        quickbookName: name
      };
      this.TEMPORARY_CONNECTIONS = this.TEMPORARY_CONNECTIONS.filter(j => j.quickbookId != sync.quickbookId);
      this.TEMPORARY_CONNECTIONS.push(convertedItem1);
    } else if (this.qb_type_num == 3 || this.qb_type_num == 5) {
      var fullItem2 = this.QUICKBOOK_DATA.find(i => i.quickbookId == item);
      var convertedItem2 = {
        quickbookId: fullItem2.quickbookId,
        databaseId: sync.id,
        databaseTable: this.qb_type_options.find(i => i.value == this.qb_type_num).table,
        tenantId: null,
        synced: true,
        hireDate: fullItem2.hiredDate != null ? fullItem2.hiredDate : '1900-01-01 00:00:00.0000000',
        quickbookName: sync.fullyQualifiedName,
        isContractor: fullItem2.isContractor
      };
      this.TEMPORARY_CONNECTIONS = this.TEMPORARY_CONNECTIONS.filter(j => j.databaseId != sync.id);
      this.TEMPORARY_CONNECTIONS.push(convertedItem2);
    } else if (this.qb_type_num == 4) {
      this.serviceTimeOffId = item;
    }
  }

  // Clear the arrays of data after every qv_type change
  clearArrays() {
    this.dataSource = new MatTableDataSource([]);
    this.dataSource.paginator = this.paginator;

    this.SYNCED_DATA = [];
    this.PROSIGHT_DATA = [];
    this.QBRELATIONSHIP_DATA = [];
    this.QUICKBOOK_DATA = [];

    this.CATEGORIES = [];
    this.SERVICES = [];
    this.TIME_OFF_RELATIONS = [];
    this.TIMEOFF_DATA = [];
    this.TEMPORARY_CONNECTIONS = [];
  }

  // Get Quickbook Relations
  getQuickbookRelation(type: number) {
    var tempQBRelationshipData = [];
    this.isQuickbooksLoading = true;
    // Clears all arrays
    this.qb_type_num = type;
    this.clearArrays();
    this._QuickbooksService.getRelations(type).subscribe(
      data => { // Quickbooks Relations Retrieval Worked
        // This data should contain the joining factors between the two sides of the table
        for (var i = 0; i < Object.keys(data).length; i++) {
          tempQBRelationshipData.push({
            synced: data[i]['synced'],
            databaseId: data[i]['databaseId'], // join to Id of Prosight
            databaseTable: data[i]['databaseTable'],
            relationId: data[i]['id'],
            quickbookId: data[i]['quickbookId'], // join to Id of Quickbooks
            syncToken: data[i]['syncToken']
          });
        }
        this.getProSightData(type, tempQBRelationshipData);
      },
      err => { // Could not get Quickbook Relations
        this.getProSightData(type, tempQBRelationshipData);
      }
    );
  }

  // Gets the ProSight Data in our database
  getProSightData(type: number, tempQBRelationshipData) {
    var tempProSightData = [];
    var qb_type = this.qb_type_options.find(i => i.value === type);
    this._QuickbooksService.getProSightData(qb_type).subscribe(
      data => { // ProSight Data Retrieval Worked
        // This data should contain all the left side of the table
        for (var i = 0; i < Object.keys(data).length; i++) {
          tempProSightData.push({
            id: qb_type.value == 3 || qb_type.value == 5 ? data[i]['userId'] : data[i]['id'],
            isActive: data[i]['isActive'],
            name: data[i]['name'],
            tenantId: data[i]['tenantId'],
            emailAddress: data[i]['email'],
            fullUserName: data[i]['fullName']
          });
        }
        this.getQuickbookData(qb_type, tempQBRelationshipData, tempProSightData);
      },
      err => { // Could not get ProSight Data
        this.getQuickbookData(qb_type, tempQBRelationshipData, tempProSightData);
      }
    );
  }

  // Gets the data from the QuickBooks
  getQuickbookData(qb_type, tempQBRelationshipData, tempProSightData) {
    var tempQuickbooksData = [];
    this._QuickbooksService.getQuickbookData(qb_type).subscribe(
      data => { // QuickBooks Data Retrieval Worked
        // This data should contain all the right side of the table
        this.fullQuickbookData = data;
        for (var i = 0; i < Object.keys(data).length; i++) {
          tempQuickbooksData.push({
            partialName: data[i]['Name'],
            hiredDate: data[i]['HiredDate'],
            quickbookId: data[i]['Id'],
            companyName: data[i]['CompanyName'],
            displayName: data[i]['DisplayName'],
            fullyQualifiedName: data[i]['FullyQualifiedName'],
            isContractor: data[i]['IsContractor']
          });
        }
        this.createItems(qb_type, tempQBRelationshipData, tempProSightData, tempQuickbooksData);
      },
      err => { // Could not get Quickbooks Data
        this.createItems(qb_type, tempQBRelationshipData, tempProSightData, tempQuickbooksData);
      }
    );
  }

  // Creates the pre-synced three lists for what is synched, what hasn't been selected for ProSight
  // and what hasn't been selected for Quickbooks
  createItems(qb_type, tempQBRelationshipData, tempProSightData, tempQuickbooksData) {
    var tempSyncedData = [];
    // This retrieves all the data from the previous calls into one object
    // if all three items exist then it will be considered synced and then removes the
    // possiblity to add that prosight or quickbooks item on another entry
    for (var i = 0; i < tempQBRelationshipData.length; i++) {
      var prosightExists = tempProSightData.find(j => j.id.toLowerCase() == tempQBRelationshipData[i].databaseId.toLowerCase()) != null ? true : false;
      var relationshipExists = tempQBRelationshipData[i] != null ? true : false;
      var quickbookDataExists = tempQuickbooksData.find(j => j.quickbookId == tempQBRelationshipData[i].quickbookId) != null ? true : false;
      if (prosightExists && quickbookDataExists && relationshipExists) {
        tempSyncedData.push({
          synced: true,
          isActive: tempProSightData.find(j => j.id.toLowerCase() == tempQBRelationshipData[i].databaseId.toLowerCase()).isActive,
          name: tempProSightData.find(j => j.id.toLowerCase() == tempQBRelationshipData[i].databaseId.toLowerCase()).name,
          tenantId: tempProSightData.find(j => j.id.toLowerCase() == tempQBRelationshipData[i].databaseId.toLowerCase()).tenantId,
          databaseId: tempQBRelationshipData[i].databaseId, // join to Id of Prosight
          databaseTable: tempQBRelationshipData[i].databaseTable,
          hiredDate: tempQuickbooksData.find(j => j.quickbookId == tempQBRelationshipData[i].quickbookId).hiredDate,
          relationId: tempQBRelationshipData[i].relationId,
          quickbookId: tempQBRelationshipData[i].quickbookId, // join to Id of Quickbooks
          syncToken: tempQBRelationshipData[i].syncToken,
          companyName: tempQuickbooksData.find(j => j.quickbookId == tempQBRelationshipData[i].quickbookId).companyName,
          displayName: tempQuickbooksData.find(j => j.quickbookId == tempQBRelationshipData[i].quickbookId).displayName,
          fullyQualifiedName: tempQuickbooksData.find(j => j.quickbookId == tempQBRelationshipData[i].quickbookId).fullyQualifiedName,
          partialName: tempQuickbooksData.find(j => j.quickbookId == tempQBRelationshipData[i].quickbookId).partialName,
          emailAddress: tempProSightData.find(j => j.id.toLowerCase() == tempQBRelationshipData[i].databaseId.toLowerCase()).emailAddress,
          fullUserName: tempProSightData.find(j => j.id.toLowerCase() == tempQBRelationshipData[i].databaseId.toLowerCase()).fullUserName,
        });
        tempProSightData = tempProSightData.filter(j => j.id.toLowerCase() != tempQBRelationshipData[i].databaseId.toLowerCase());
        tempQuickbooksData = tempQuickbooksData.filter(j => j.quickbookId != tempQBRelationshipData[i].quickbookId);
      }
    }
    this.individualSort(qb_type, tempQBRelationshipData, tempProSightData, tempQuickbooksData, tempSyncedData);
  }

  // Where the sorts get put into individual sorts based on qb_type_num due to styleistic choices
  individualSort(qb_type, tempQBRelationshipData, tempProSightData, tempQuickbooksData, tempSyncedData) {
    // Filters out from tempSyncedData all unsynced items
    tempSyncedData = tempSyncedData.filter(
      item => item.synced == true);
    this.qb_type_num = qb_type.value;

    if (qb_type.value == 0 || qb_type.value == 1 || qb_type.value == 2 || qb_type.value == 6) {
      this.sortImportElements(tempQBRelationshipData, tempProSightData, tempQuickbooksData, tempSyncedData);
    } else if (qb_type.value == 3 || qb_type.value == 5) {
      this.sortExportElements(tempQBRelationshipData, tempProSightData, tempQuickbooksData, tempSyncedData);
    }
  }

  // Sorts the Imported type items such as Client, Work Class, Service and Expense Category
  sortImportElements(tempQBRelationshipData, tempProSightData, tempQuickbooksData, tempSyncedData) {
    this.SYNCED_DATA = tempSyncedData.sort(function (a, b) {
      if (a.synced === b.synced) {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      } else {
        return (a.synced === b.synced) ? 0 : a.synced ? -1 : 1;
      }
    });
    this.PROSIGHT_DATA = tempProSightData.sort(function (a, b) {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    });
    this.QUICKBOOK_DATA = tempQuickbooksData.sort(function (a, b) {
      if (a.partialName === b.partialName) {
        if (a.fullyQualifiedName < b.fullyQualifiedName) return -1;
        if (a.fullyQualifiedName > b.fullyQualifiedName) return 1;
        return 0;
      } else {
        if (a.partialName < b.partialName) return -1;
        if (a.partialName > b.partialName) return 1;
        return 0;
      }
    });

    this.QBRELATIONSHIP_DATA = tempQBRelationshipData;

    var array = [];
    for (var i = 0; i < this.SYNCED_DATA.length; i++) {
      array.push(this.SYNCED_DATA[i]);
    }
    for (var i = 0; i < this.QUICKBOOK_DATA.length; i++) {
      array.push(this.QUICKBOOK_DATA[i]);
    }

    this.isQuickbooksLoading = false;
    this.dataSource = new MatTableDataSource(Object.keys(array).map(i => array[i]));
    this.dataSource.sort = this.sort.first;
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'ProSightInstance': return item['name'];
        case 'QuickbooksInstance': return item['fullyQualifiedName'];
        case 'Status': if(item['synced'] == true) {return 'true';} else {return 'false';}
      }
    };
    this.dataSource.paginator = this.paginator;
  }

  // Sorts the Exported type items such as Employee and Vendor
  sortExportElements(tempQBRelationshipData, tempProSightData, tempQuickbooksData, tempSyncedData) {
    this.SYNCED_DATA = tempSyncedData.sort(function (a, b) {
      if (a.synced === b.synced) {
        let aName = a.fullUserName.split(' ');
        let bName = b.fullUserName.split(' ');
        if (aName[aName.length - 1] == bName[bName.length - 1]) {
          if (aName[0] < bName[0]) return -1;
          if (aName[0] > bName[0]) return 1;
          return 0;
        } else {
          return aName[aName.length - 1] > bName[bName.length - 1] ? 1 : -1;
        }
      } else {
        return (a.synced === b.synced) ? 0 : a.synced ? -1 : 1;
      }
    });
    this.PROSIGHT_DATA = tempProSightData.sort(function (a, b) {
      let aName = a.fullUserName.split(' ');
      let bName = b.fullUserName.split(' ');
      if (aName[aName.length - 1] == bName[bName.length - 1]) {
        if (aName[0] < bName[0]) return -1;
        if (aName[0] > bName[0]) return 1;
        return 0;
      } else {
        return aName[aName.length - 1] > bName[bName.length - 1] ? 1 : -1;
      }
    });
    this.QUICKBOOK_DATA = tempQuickbooksData.sort(function (a, b) {
      if (a.displayName < b.displayName) return -1;
      if (a.displayName > b.displayName) return 1;
      return 0;
    });

    this.QBRELATIONSHIP_DATA = tempQBRelationshipData;

    var array = [];
    for (var i = 0; i < this.SYNCED_DATA.length; i++) {
      array.push(this.SYNCED_DATA[i]);
    }
    for (var i = 0; i < this.PROSIGHT_DATA.length; i++) {
      array.push(this.PROSIGHT_DATA[i]);
    }

    this.isQuickbooksLoading = false;
    this.dataSource = new MatTableDataSource(Object.keys(array).map(i => array[i]));
    this.dataSource.sort = this.sort.first;
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'ProSightInstance': return item['fullUserName'];
        case 'QuickbooksInstance' : if(item['synced'] == undefined) {return 'false';} else {return item['fullUserName'];}
        case 'Status': if(item['synced'] == true) {return 'true';} else {return 'false';}
      }
    };
    this.dataSource.paginator = this.paginator;
  }

  // Gets the Time Off Data for the left and the right
  retrieveTimeOffFilter() {
    var tempCategories = [];
    this.isQuickbooksLoading = true;
    this.qb_type_num = 4;
    this.clearArrays();

    this._QuickbooksService.getTimeOffFilter().subscribe(
      data => { // Time Off Data Retrieval Worked
        for (var i = 0; i < Object.keys(data['categoryList']).length; i++) {
          tempCategories.push({
            activePolicyId: data['categoryList'][i].activePolicyId,
            id: data['categoryList'][i].id,
            name: data['categoryList'][i].name,
            tenantId: data['categoryList'][i].tenantId,
            synced: false,
            serviceJoinName: null
          });
        }
        this.retrieveTimeOffRelation(tempCategories, data['serviceList']);
      },
      err => { // Could not get Time Off Data
        console.log(err);
        this.snackBar.open('Error Retrieving Time Off Data', '', { duration: 2000 });
      }
    );
  }

  // Retrieves the Time Off Relationships
  retrieveTimeOffRelation(tempCategories, tempServices) {
    var tempTimeOffRelations = [];
    this._QuickbooksService.getTimeOffRelation().subscribe(
      data => { // Quickbooks Relations Retrieval Worked
        for (var i = 0; i < Object.keys(data).length; i++)
          tempTimeOffRelations.push(data[i]);
        this.sortFilterTimeOff(tempCategories, tempServices, tempTimeOffRelations);
      },
      err => { // Could not get Quickbook Relations
        this.snackBar.open('Error Retrieving Time Off Relation', '', { duration: 2000 });
      }
    );
  }

  // Sorts and Filters the Time Off Entries
  sortFilterTimeOff(tempCategories, tempServices, tempTimeOffRelations) {
    this.qb_type_num = 4;
    for (var i = 0; i < tempTimeOffRelations.length; i++) {
      if (tempCategories.find(j => j.id == tempTimeOffRelations[i].TimeOffCategoryId) != null &&
      tempServices.find(j => j.id == tempTimeOffRelations[i].ServiceId) != null) {
        this.TIMEOFF_DATA.push({
          activePolicyId: tempCategories.find(j => j.id == tempTimeOffRelations[i].TimeOffCategoryId).activePolicyId,
          categoryId: tempCategories.find(j => j.id == tempTimeOffRelations[i].TimeOffCategoryId).id,
          categoryName: tempCategories.find(j => j.id == tempTimeOffRelations[i].TimeOffCategoryId).name,
          tenantId: tempCategories.find(j => j.id == tempTimeOffRelations[i].TimeOffCategoryId).tenantId,
          serviceId: tempServices.find(j => j.id == tempTimeOffRelations[i].ServiceId).Id,
          serviceName: tempServices.find(j => j.id == tempTimeOffRelations[i].ServiceId).Name,
          id: tempTimeOffRelations[i].Id,
        });

        tempCategories.find(j => j.id == tempTimeOffRelations[i].TimeOffCategoryId).serviceJoinName =
          tempServices.find(j => j.id == tempTimeOffRelations[i].ServiceId).name;

        // Filters out from SERVICES all ones used
        tempServices = tempServices.filter(
          item => item.Id != tempServices.find(j => j.id == tempTimeOffRelations[i].ServiceId).id);
          tempCategories.find(j => j.id == tempTimeOffRelations[i].TimeOffCategoryId).synced = true;
      }
    }

    this.CATEGORIES = tempCategories.sort(function (a, b) {
      if (a.synced === b.synced) {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      } else {
        return (a.synced === b.synced) ? 0 : a.synced ? -1 : 1;
      }
    });

    this.SERVICES = tempServices.sort(function (a, b) {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    });

    this.TIME_OFF_RELATIONS = tempTimeOffRelations;

    this.isQuickbooksLoading = false;
    this.dataSource = new MatTableDataSource(Object.keys(this.CATEGORIES).map(i => this.CATEGORIES[i]));
    this.dataSource.sort = this.sort.first;
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'ProSightInstance': return item['name'];
        case 'Status': if(item['synced'] == true) {return 'true';} else {return 'false';}
        case 'QuickbooksInstance': return item['serviceJoinName'];
      }
    };
    this.dataSource.paginator = this.paginator;
  }

  // Save Time Off Relationship
  saveTimeOffRelation(categoryId, serviceId, tenantId) {
    var item = {
      TimeOffCategoryId: categoryId,
      ServiceId: serviceId,
      TenantId: tenantId
    };
    this.isQuickbooksLoading = true;
    this.clearArrays();
    this._QuickbooksService.saveTimeOffRelation(item).subscribe(
      data => { // Time Off Relation Saved
        this.retrieveTimeOffFilter();
      },
      err => { // Could not save Time Off Relation
        this.snackBar.open('Error Saving Time Off Relation', '', { duration: 2000 });
      }
    );
  }

  // Remove Time Off Relations
  removeTimeOffRelation(sync) {
    var serviceIds = this.TIME_OFF_RELATIONS.filter(
      item => item.TimeOffCategoryId == sync.id);
    var serviceId = serviceIds[0].ServiceId;
    this.isQuickbooksLoading = true;
    this.clearArrays();
    this._QuickbooksService.deleteTimeOffRelation(serviceId, sync.id).subscribe(
      data => { // Time Off Relation Deleted
        this.retrieveTimeOffFilter();
      },
      err => { // Could not delete Time Off Relation
        this.snackBar.open('Error Deleting Time Off Relation', '', { duration: 2000 });
      }
    );
  }

  // Syncs an item that has both fields filled out
  syncItem(syncItem, qb_type_num) {
    if (qb_type_num == 3 || qb_type_num == 5) {
      if (this.TEMPORARY_CONNECTIONS.find(j => j.databaseId == syncItem.id)) {
        this.saveRelation(this.TEMPORARY_CONNECTIONS.find(j => j.databaseId == syncItem.id));
      } else {
        this.snackBar.open('Please select a Quickbooks Item first', '', { duration: 2000 });
      }
    } else {
      if (this.TEMPORARY_CONNECTIONS.find(j => j.quickbookId == syncItem.quickbookId)) {
        this.saveRelation(this.TEMPORARY_CONNECTIONS.find(j => j.quickbookId == syncItem.quickbookId));
      } else {
        this.snackBar.open('Please select a ProSight Item first', '', { duration: 2000 });
      }
    }
  }

  // Creates an item and then syncs to quickbooks
  autoSync(syncItem, qb_type_num) {
    let qbObj = this.fullQuickbookData.find(o => o.Id === syncItem.quickbookId);
    var obj = null;
    if (qb_type_num == 0) {
      obj = {
        name: qbObj.FullyQualifiedName,
        email: qbObj.PrimaryEmailAddr != null && qbObj.PrimaryEmailAddr.Address != null
          ? qbObj.PrimaryEmailAddr.Address : null,
        billingBasicInformation: {
          name: qbObj.FullyQualifiedName,
          email: qbObj.PrimaryEmailAddr != null && qbObj.PrimaryEmailAddr.Address != null
            ? qbObj.PrimaryEmailAddr.Address : null,
          phone: qbObj.PrimaryPhone != null && qbObj.PrimaryPhone.FreeFormNumber != null
            ? qbObj.PrimaryPhone.FreeFormNumber : null,
          addressLine1: qbObj.BillAddr != null && qbObj.BillAddr.Line1 != null
            ? qbObj.BillAddr.Line1 : null,
          addressLine2: qbObj.BillAddr != null && qbObj.BillAddr.Line2 != null
            ? qbObj.BillAddr.Line2 : null,
          city: qbObj.BillAddr != null && qbObj.BillAddr.City != null
            ? qbObj.BillAddr.City : null,
          state: qbObj.BillAddr != null && qbObj.BillAddr.countryField != null
            ? qbObj.BillAddr.countryField : null,
          postal: qbObj.BillAddr != null && qbObj.BillAddr.PostalCode != null
            ? qbObj.BillAddr.PostalCode : null
        },
        phone: qbObj.PrimaryPhone != null && qbObj.PrimaryPhone.FreeFormNumber != null
          ? qbObj.PrimaryPhone.FreeFormNumber : null,
        countrySubDiv: qbObj.BillAddr != null && qbObj.BillAddr.CountrySubDivisionCode != null
          ? qbObj.BillAddr.CountrySubDivisionCode : null,
        isActive: qbObj.Active
      };
    } else if (qb_type_num == 1) {
      obj = {
        name: qbObj.FullyQualifiedName,
        isActive: qbObj.Active
      };
    } else if (qb_type_num == 2) {
      obj = {
        name: qbObj.FullyQualifiedName,
        description: qbObj.Description != null ? qbObj.Description : '',
        isBillable: false,
        isActive: qbObj.Active,
      };
    } else if (qb_type_num == 6) {
      obj = {
        name: qbObj.Name,
        isActive: qbObj.Active
      };
    }
    syncItem.model = obj;
    syncItem.databaseTable = this.qb_type_options[qb_type_num].table;
    if (qb_type_num == 3 || qb_type_num == 4 || qb_type_num == 5) {
      this.saveRelation(syncItem);
    } else {
      this.saveRelation(syncItem, true);
    }
  }

saveRelation(syncItem, addItem = false) {
  this.isQuickbooksLoading = true;
  this.clearArrays();
  this._QuickbooksService.saveRelation(this.qb_type_num, syncItem, addItem).subscribe(
    data => { // Quickbooks Relations Save Worked
        this.getQuickbookRelation(this.qb_type_num);
    },
    err => { // Could not save Quickbook Relations
      this.snackBar.open('Error Saving QuickBooks Relation', '', { duration: 2000 });
    }
  );
}

// Taken off for now since back end does not seem to support delete since re-syncs same named items each time it
// it loaded, so does not work how it should on even the old system
deleteRelation(item) {
  this.isQuickbooksLoading = true;
  this.clearArrays();
  this._QuickbooksService.deleteRelation(this.qb_type_num, item).subscribe(
    data => { // Quickbooks Relations Delete Worked
      this.getQuickbookRelation(this.qb_type_num);
    },
    err => { // Could not delete Quickbook Relations
      this.snackBar.open('Error Deleting QuickBooks Relation', '', { duration: 2000 });
    });
  }
}

export interface syncInstance {
  synced: boolean;
  isActive: boolean;
  name: string;
  tenantId: string;
  databaseId: string; // join to Id of Prosight
  databaseTable: string;
  hiredDate: string;
  relationId: string;
  quickbookId: string; // join to Id of Quickbooks
  syncToken: string;
  companyName: string;
  displayName: string;
  fullyQualifiedName: string;
  partialName: string;
  emailAddress: string;
  fullUserName: string;
}

export interface QBRelationship {
  synced: boolean;
  databaseId: string; // join to Id of Prosight
  databaseTable: string;
  relationId: string;
  quickbookId: string; // join to Id of Quickbooks
  syncToken: string;
}

export interface ProSightData {
  id: string;
  isActive: true;
  name: string;
  tenantId: string;
  emailAddress: string;
  fullUserName: string;
}

export interface QuickbookData {
  companyName: string;
  displayName: string;
  fullyQualifiedName: string;
  hiredDate: string;
  partialName: string;
  quickbookId: string;
  isContractor: boolean
}

export interface TimeOffData {
  activePolicyId: string;
  categoryId: string;
  categoryName: string;
  tenantId: string;
  serviceId: string;
  serviceName: string;
  id: string;
}

export interface Category {
  activePolicyId: string;
  id: string;
  name: string;
  tenantId: string;
  synced: boolean;
  serviceJoinName: string;
}