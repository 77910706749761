import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '../../../../../node_modules/@angular/material';
import { FormControl, FormBuilder, FormGroup } from '../../../../../node_modules/@angular/forms';

@Component({
    selector: 'app-management-clients-dialog',
    templateUrl: './management-clients-dialog.component.html',
    styleUrls: ['./management-clients-dialog.component.scss']
})
export class ManagementClientsDialogComponent implements OnInit {
	clientForm: FormGroup;
    billingBasicInformation = this.clientData['billingBasicInformation'] ? this.clientData['billingBasicInformation'] : {};

    constructor(public dialogRef: MatDialogRef<ManagementClientsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public clientData, private fb: FormBuilder) { }

    ngOnInit() {
		this.initForm();

    }

	initForm(){
		this.clientForm = new FormGroup({});
		this.clientForm = this.fb.group({
			nameFormControl: new FormControl(this.clientData['name']),
			address1FormControl: new FormControl(this.billingBasicInformation['addressLine1']),
			cityFormControl: new FormControl(this.billingBasicInformation['city']),
			postalFormControl: new FormControl(this.billingBasicInformation['postal']),
			emailFormControl: new FormControl(this.clientData['email']),
			address2FormControl: new FormControl(this.billingBasicInformation['addressLine2']),
			stateFormControl: new FormControl(this.billingBasicInformation['state']),
			countrySubDivFormControl: new FormControl(this.clientData['countrySubDiv']),
			phoneFormControl: new FormControl(this.billingBasicInformation['phone']),
			isActiveFormControl: new FormControl(this.clientData['isActive'] == null ? true : this.clientData['isActive'])
		});


	}

    closeDialog() {
		this.dialogRef.close();
    }

    saveAndCloseDialog() {
        if (!this.clientData['billingBasicInformation']) {
            this.clientData['billingBasicInformation'] = {};
		}

        this.clientData['name'] = this.clientForm.get('nameFormControl').value;
        this.clientData['email'] = this.clientForm.get('emailFormControl').value;
        this.clientData['billingBasicInformation']['addressLine1'] = this.clientForm.get('address1FormControl').value;
        this.clientData['billingBasicInformation']['addressLine2'] = this.clientForm.get('address2FormControl').value;
        this.clientData['billingBasicInformation']['city'] = this.clientForm.get('cityFormControl').value;
        this.clientData['billingBasicInformation']['state'] = this.clientForm.get('stateFormControl').value;
        this.clientData['billingBasicInformation']['postal'] = this.clientForm.get('postalFormControl').value;
        this.clientData['countrySubDiv'] = this.clientForm.get('countrySubDivFormControl').value;
        this.clientData['billingBasicInformation']['phone'] = this.clientForm.get('phoneFormControl').value;
		this.clientData['isActive'] = this.clientForm.get('isActiveFormControl').value;
        this.dialogRef.close({ submit: true, data: this.clientData });
    }
}
