import { Component, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatDialog, MatSnackBar, MatButtonToggleGroup } from '../../../../node_modules/@angular/material';
import { SetttingsService } from '../../api/setttings.service';
import { ManagementTimeoffCategoryDialogComponent } from './management-timeoff-category-dialog/management-timeoff-category-dialog.component';
import { ManagementTimeoffBlackOutDialogComponent } from './management-timeoff-blackout-dialog/management-timeoff-blackout-dialog.component';
import { ManagementTimeoffHolidayDialogComponent } from './management-timeoff-holiday-dialog/management-timeoff-holiday-dialog.component';
import { TimesheetService } from '../../api/timesheet.service';
import { TimeoffService } from '../../api/timeoff.service';
import { DeleteConfirmationDialogComponent } from '../../extra/delete-confirmation-dialog/delete-confirmation-dialog.component';
import * as moment from 'moment';

@Component({
    selector: 'app-management-timeoff',
    templateUrl: './management-timeoff.component.html',
    styleUrls: ['./management-timeoff.component.scss']
})
export class ManagementTimeoffComponent implements OnInit {

    clients: object[]; // array of client objects for the selectors
    classes: object[]; // array of class objects for the selectors
    services: object[]; // array of service objects for the selectors
    projects: object[] = []; // array of project objects for the selectors
    categoryDisplayedColumns = ['name', 'maxDays', 'activeState', 'options'];
    categoryDataArray = [];
    categoryDataSource = new MatTableDataSource();
    categoryDataSourceLoading: boolean;
    ptoSettingsArray = [];
    ptoSettingsId: string = null
    ptoPeriod: string = null;
    ptoEmailTime: string = null;
	notificationState: boolean;
    dataLoading: boolean=true;

    blackOutDisplayedColumns = ['Date', 'Reason', 'options', 'delete'];
    blackOutDataArray = [];
    blackOutDataSource = new MatTableDataSource();
    blackOutDataSourceLoading: boolean;

    holidayDisplayedColumns = ['Date', 'Reason', 'options', 'delete'];
    holidayDataArray = [];
    holidayDataSource = new MatTableDataSource();
    holidayDataSourceLoading: boolean;

    constructor(private _timeoffService: TimeoffService,
        private _settingsService: SetttingsService,
        private _timesheetService: TimesheetService,
        public dialog: MatDialog,
        public snackBar: MatSnackBar) { }

    @ViewChildren(MatPaginator) paginator;
    @ViewChild(MatButtonToggleGroup) buttonToggleGroup;

    ngOnInit() {
        this.getCategoryData();
        this.getClientClassServiceData();
        this.getPTOSettingsData();            //UNCOMMENT ME WHEN READY
        this.getBlackOutDates();
        this.getHolidayDates();
    }

    updateCategoryFilter(toggleActiveGroup: string, searchValue: string) {
		let dataContainer = [];
		let booleanActiveMaker = { active: true, inactive: false };
        this.categoryDataArray.forEach(entry => {
            let searchBoolean = entry['name'].toLowerCase().includes(searchValue) || searchValue == '';
            let toggleActiveBoolean = entry['isActive'] == booleanActiveMaker[toggleActiveGroup] || toggleActiveGroup == 'all';
            if (searchBoolean && toggleActiveBoolean) {
                dataContainer.push({
                    name: entry['name'],
                    maxDays: entry['defaultMaxDay'],
                    activeState: entry['isActive'],
                    id: entry['id']
                });
            }
        });
        dataContainer = dataContainer.sort(function(a, b) {
            var aName = a.name;
            var bName = b.name;
            // Makes it so that inactive is lower priority
            var aStatus = a.activeState == 0 ? 1 : 0;
            var bStatus = b.activeState == 0 ? 1 : 0;

            if (aStatus == bStatus) {
                return (aName < bName) ? -1 : (aName > bName) ? 1 : 0;
            } else {
                return (aStatus < bStatus) ? -1 : 1;
            }
        });
        this.categoryDataSource = new MatTableDataSource(dataContainer);
        this.categoryDataSource.paginator = this.paginator.toArray()[0];
    }


    findCategoryEntry(entryId) {
        return this.categoryDataArray.find(entry => entry['id'] == entryId);
    }

    loadDialog(entryId) {
        let data = { newData: true };
        if (entryId != 'newData') {
            data = this.findCategoryEntry(entryId);
        }
        data['clients'] = this.clients;
        data['classes'] = this.classes;
        data['services'] = this.services;
        data['projects'] = this.projects;
        const dialogRef = this.dialog.open(ManagementTimeoffCategoryDialogComponent, {
            width: '585px',
            height: 'auto',
            data: data
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result !== undefined) {
                if (result['submit']) {
                    this.updateCategoryData(result['data']);
                }
            }
        });
    }

    stateChange(entryId, state) {
		let returnObject = this.findCategoryEntry(entryId);
        returnObject['isActive'] = state;
        this.updateCategoryData(returnObject);
    }

    processCategoryData(data) {
        let dataContainer = [];
        data.forEach(entry => {
            dataContainer.push({
                name: entry['name'],
                maxDays: entry['defaultMaxDay'],
                activeState: entry['isActive'],
                id: entry['id']
            });
        });
        dataContainer = dataContainer.sort(function(a, b) {
            var aName = a.name;
            var bName = b.name;
            // Makes it so that inactive is lower priority
            var aStatus = a.activeState == 0 ? 1 : 0;
            var bStatus = b.activeState == 0 ? 1 : 0;

            if (aStatus == bStatus) {
                return (aName < bName) ? -1 : (aName > bName) ? 1 : 0;
            } else {
                return (aStatus < bStatus) ? -1 : 1;
            }
        });
        this.categoryDataSource = new MatTableDataSource(dataContainer);
        this.categoryDataSource.paginator = this.paginator.toArray()[0];
        this.updateCategoryFilter(this.buttonToggleGroup.value, '');
    }

    getCategoryData() {
        this.categoryDataSource.data = [];
        this.categoryDataSourceLoading = true;
        this._settingsService.getPtoCategories().subscribe(
            data => {
                this.categoryDataArray = [];
                this.categoryDataArray.push.apply(this.categoryDataArray, data);
                this.processCategoryData(data);
                this.categoryDataSourceLoading = false;
            }, err => {
                this.categoryDataSource = new MatTableDataSource(this.categoryDataArray);
                this.categoryDataSource.paginator = this.paginator.toArray()[0];
                this.snackBar.open(err.statusText, '', { duration: 2000 });
                console.error(err);
                this.categoryDataSourceLoading = false;
            }
        );
    }


    getClientClassServiceData() {
        this._timesheetService.getClientServiceClassData().subscribe(
            data => {
                this.clients = data['clientProjectList'];
                this.services = data['serviceList'];
                this.classes = data['workClassList'];
                if (this.classes != null)
                    this.classes.sort((a, b) => { return (a['name'] > b['name']) ? 1 : ((b['name'] > a['name']) ? -1 : 0); }); // sorts in alphabetical order becuase not done on backend for some reason
                if (this.services != null)
                    this.services.sort((a, b) => { return (a['name'] > b['name']) ? 1 : ((b['name'] > a['name']) ? -1 : 0); }); // sorts in alphabetical order becuase not done on backend for some reason
                if (this.clients != null)
                    this.clients.forEach(client => {
                        if (client['projects'].length > 0) {// adds all of the projects for a client to a single array
                            this.projects.push(...client['projects']); // Spread syntax ...client['projects'] expands the array to be pushed
                        }
                    });
                this.dataLoading = false;
            },
            err => console.error(err)
        );
	}

	/* This method given a category object will update the table source
	   and refresh the category table in the admin view so that
	   it is populated with the most current set of data */
	refreshTable(category){
			this.categoryDataArray = this.categoryDataArray.filter(x => x['id'] != category['id']);
			this.categoryDataArray.push(category);
			this.categoryDataSource.data = this.categoryDataSource.data.filter(x => x['id'] != category['id']);
			this.categoryDataSource.data.push({
				name: category['name'],
                maxDays: category['defaultMaxDay'],
                activeState: category['isActive'],
                id: category['id']
			});
			this.categoryDataSource._updateChangeSubscription();
			this.updateCategoryFilter(this.buttonToggleGroup.value,'');
	}

    updateCategoryData(entry) {
        this.snackBar.open('Updating Data', '', { duration: 2000 });
        this._settingsService.postPtoCategory(entry).subscribe(
            data => {
                this.refreshTable(data);
                this.snackBar.open('Updated', '', { duration: 2000 });
            }, err => {
                this.snackBar.open(err.statusText, '', { duration: 2000 });
            }
        );
    }

    ptoStateChange(state){
        this.notificationState = state;
    }

    savePTONotificationSettings(){
        let ptoSettings ={
            notificationId: this.ptoSettingsId,
            notificationPeriod: this.ptoPeriod,
            //notificationSendTime: this.ptoEmailTime,
            notificationSendTime: '11:00',  //Temporarily hardcoding this until the email send time is used and visible again in the UI
            notificationEnabled: this.notificationState
        };
        this.updatePTOSettingsData(ptoSettings);
    }

    getPTOSettingsData(){
        this._settingsService.getPTOSettings().subscribe(
            data => {
                this.ptoSettingsArray = [];
                this.ptoSettingsArray.push.apply(this.ptoSettingsArray, data);
                this.ptoSettingsId = data[0].notificationId;
                this.ptoPeriod = data[0].notificationPeriod;
                this.ptoEmailTime = data[0].notificationSendTime;
                this.notificationState = data[0].notificationEnabled;
            }, err => {
                this.snackBar.open(err.statusText, '', { duration: 2000 });
                console.error(err);
            }
        );
    }

    updatePTOSettingsData(ptoSettings){
        this.snackBar.open('Updating Data', '', { duration: 2000 });
        this._settingsService.putPtoSettings(ptoSettings).subscribe(
            data => {
				this.ptoSettingsId = ptoSettings.notificationId;
				this.ptoPeriod = ptoSettings.notificationPeriod;
				this.ptoEmailTime = ptoSettings.notificationSendTime;
				this.notificationState = ptoSettings.notificationEnabled;
                this.snackBar.open('Updated', '', { duration: 2000 });
            }, err => {
                this.snackBar.open(err.statusText, '', { duration: 2000 });
            }
        );
    }

    getBlackOutDates() {
        this.blackOutDataSource.data = [];
        this.blackOutDataSourceLoading = true;
        this._timeoffService.getTimeOffBlackOutDates().subscribe(
            data => {
                this.blackOutDataArray = [];
                this.blackOutDataArray.push.apply(this.blackOutDataArray, data);
                this.blackOutDataArray.sort(function(a, b) {
                    var aDate = a.blackOutDate;
                    var bDate = b.blackOutDate;
        
                    return (aDate < bDate) ? -1 : (aDate > bDate) ? 1 : 0;
                });
                this.blackOutDataSource = new MatTableDataSource(this.blackOutDataArray);
                this.blackOutDataSource.paginator = this.paginator.toArray()[1];
                this.blackOutDataSourceLoading = false;
            }, err => {
                this.blackOutDataSource = new MatTableDataSource(this.blackOutDataArray);
                this.blackOutDataSource.paginator = this.paginator.toArray()[1];
                this.snackBar.open(err.statusText, '', { duration: 2000 });
                console.error(err);
                this.blackOutDataSourceLoading = false;
            }
        );
    }

    updateBlackOutFilter(searchValue: string) {
		let dataContainer = [];
        this.blackOutDataArray.forEach(entry => {
            let dateBoolean = moment(entry['blackOutDate'].slice(0, 10)).local(true).toDate()
                .toLocaleDateString("en-US", { year: "numeric", month: "2-digit", day: "2-digit" }).includes(searchValue) || searchValue == '';
            let reasonBoolean = entry['reason'].toLowerCase().includes(searchValue) || searchValue == '';
            if (dateBoolean || reasonBoolean) {
                dataContainer.push(entry);
            }
        });
        dataContainer = dataContainer.sort(function(a, b) {
            var aDate = a.blackOutDate;
            var bDate = b.blackOutDate;

            return (aDate < bDate) ? -1 : (aDate > bDate) ? 1 : 0;
        });
        this.blackOutDataSource = new MatTableDataSource(dataContainer);
        this.blackOutDataSource.paginator = this.paginator.toArray()[1];
    }

    deleteBlackOutDate(blackOutDate){
        const dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
            width: '585px',
            height: 'auto'
        });
        dialogRef.afterClosed().subscribe(result => {// Check if the entry is to be deleted
            if (result !== undefined) {
                if (result['toDelete']) {
                    this.snackBar.open('Deleting Data', '', { duration: 2000 });
                    this._timeoffService.deleteTimeOffBlackOutDate(blackOutDate.id).subscribe(
                        data => {
                            this.getBlackOutDates();

                            this.snackBar.open('Deleted', '', { duration: 2000 });
                        }, err => {
                            this.snackBar.open(err.statusText, '', { duration: 2000 });
                        }
                    );
                }
            }
        });
    }

    loadBlackOutDialog(entry) {
        let data = { newData: true };
        if (entry != 'newData') {
            data = entry;
        }

        const dialogRef = this.dialog.open(ManagementTimeoffBlackOutDialogComponent, {
            width: '585px',
            height: 'auto',
            data: data
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result !== undefined) {
                if (result['submit']) {
                    this.putBlackOutDate(result['data']);
                }
            }
        });
    }

    putBlackOutDate(blackOutDate){
        this.snackBar.open('Updating Data', '', { duration: 2000 });
        this._timeoffService.addUpdateTimeOffBlackOutDate(blackOutDate).subscribe(
            data => {
				this.getBlackOutDates();
                this.snackBar.open('Updated', '', { duration: 2000 });
            }, err => {
                this.snackBar.open(err.statusText, '', { duration: 2000 });
            }
        );
    }

    getHolidayDates() {
        this.holidayDataSource.data = [];
        this.holidayDataSourceLoading = true;
        this._timeoffService.getTimeOffHolidayDates().subscribe(
            data => {
                this.holidayDataArray = [];
                this.holidayDataArray.push.apply(this.holidayDataArray, data);
                this.holidayDataArray.sort(function(a, b) {
                    var aDate = a.holidayDate;
                    var bDate = b.holidayDate;
        
                    return (aDate < bDate) ? -1 : (aDate > bDate) ? 1 : 0;
                });
                this.holidayDataSource = new MatTableDataSource(this.holidayDataArray);
                this.holidayDataSource.paginator = this.paginator.toArray()[2];
                this.holidayDataSourceLoading = false;
            }, err => {
                this.holidayDataSource = new MatTableDataSource(this.holidayDataArray);
                this.holidayDataSource.paginator = this.paginator.toArray()[2];
                this.snackBar.open(err.statusText, '', { duration: 2000 });
                console.error(err);
                this.holidayDataSourceLoading = false;
            }
        );
    }

    updateHolidayFilter(searchValue: string) {
		let dataContainer = [];
        this.holidayDataArray.forEach(entry => {
            let dateBoolean = moment(entry['holidayDate'].slice(0, 10)).local(true).toDate()
                .toLocaleDateString("en-US", { year: "numeric", month: "2-digit", day: "2-digit" }).includes(searchValue) || searchValue == '';
            let reasonBoolean = entry['reason'].toLowerCase().includes(searchValue) || searchValue == '';
            if (dateBoolean || reasonBoolean) {
                dataContainer.push(entry);
            }
        });
        dataContainer = dataContainer.sort(function(a, b) {
            var aDate = a.holidayDate;
            var bDate = b.holidayDate;

            return (aDate < bDate) ? -1 : (aDate > bDate) ? 1 : 0;
        });
        this.holidayDataSource = new MatTableDataSource(dataContainer);
        this.holidayDataSource.paginator = this.paginator.toArray()[2];
    }

    deleteHolidayDate(holidayDate){
        const dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
            width: '585px',
            height: 'auto'
        });
        dialogRef.afterClosed().subscribe(result => {// Check if the entry is to be deleted
            if (result !== undefined) {
                if (result['toDelete']) {
                    this.snackBar.open('Deleting Data', '', { duration: 2000 });
                    this._timeoffService.deleteTimeOffHolidayDate(holidayDate.id).subscribe(
                        data => {
                            this.getHolidayDates();

                            this.snackBar.open('Deleted', '', { duration: 2000 });
                        }, err => {
                            this.snackBar.open(err.statusText, '', { duration: 2000 });
                        }
                    );
                }
            }
        });
    }

    loadHolidayDialog(entry) {
        let data = { newData: true };
        if (entry != 'newData') {
            data = entry;
        }

        const dialogRef = this.dialog.open(ManagementTimeoffHolidayDialogComponent, {
            width: '585px',
            height: 'auto',
            data: data
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result !== undefined) {
                if (result['submit']) {
                    this.putHolidayDate(result['data']);
                }
            }
        });
    }

    putHolidayDate(holidayDate){
        this.snackBar.open('Updating Data', '', { duration: 2000 });
        this._timeoffService.addUpdateTimeOffHolidayDate(holidayDate).subscribe(
            data => {
				this.getHolidayDates();
                this.snackBar.open('Updated', '', { duration: 2000 });
            }, err => {
                this.snackBar.open(err.statusText, '', { duration: 2000 });
            }
        );
    }
}
