import { Component, OnInit, EventEmitter } from '@angular/core';
import { CalendarEvent } from 'calendar-utils';
import * as moment from 'moment';
import { TimeoffService } from '../../api/timeoff.service';
import { Subject } from 'rxjs';
import { TimeoffEntryDialogComponent } from '../timeoff-entry-dialog/timeoff-entry-dialog.component';
import { MatDialog, MatSnackBar } from '@angular/material';
import { FormControl } from '@angular/forms';
import { DeleteConfirmationDialogComponent } from '../../extra/delete-confirmation-dialog/delete-confirmation-dialog.component';
import { CalendarEventTitleFormatter } from 'angular-calendar';
import { CustomEventTitleFormatter } from '../CustomEventTitleFormatter';
import { SetttingsService } from '../../api/setttings.service';
import { takeUntil } from 'rxjs/operators';
var _ = require("lodash");

@Component({
    selector: 'app-team-calendar',
    templateUrl: './team-calendar.component.html',
    styleUrls: ['./team-calendar.component.scss'],
    providers: [
        {
            provide: CalendarEventTitleFormatter,
            useClass: CustomEventTitleFormatter
        }
    ]
})

export class TeamCalendarComponent implements OnInit {
    colorFilter = ['submitted', 'pending', 'approved', 'rejected'];
    viewDate: Date = moment().toDate(); // Current date selected for the calendar
    viewDateTwoWeek: Date = moment().add(1, 'week').toDate(); // Same as viewDate, but 1 week later
    dateRange: string; // Formated range for the date range button
    activeView: string = 'month'; // Which calendar view to show
    activeDayIsOpen: boolean; // If the grey bar is open with the events of that day
    refresh: Subject<any> = new Subject(); // Used to refresh the calendar with 'this.refresh.next()'
    highlightDay: EventEmitter<any> = new EventEmitter();
    unhighlightDay: EventEmitter<any> = new EventEmitter();
    categories: object[] = [];
    allCategories: object[] = [];

    usersAndProjects: object[] = [];
    users: object[] = [];
    projects: object[] = [];
    selectedUsers: object[] = [];
    selectedProjects: object[] = [];

    blackOutDataArray: any[] = [];
    holidayDataArray: any[] = [];
	firstDayOnCalender;
	lastDayOnCalender;

	eventsParsedObj = {
		blackOut: false,
		holiday:false,
		timeOff:false
	}

	ngUnsubscribe: Subject<void> = new Subject<void>(); // used to unsubscribe from old requests when date is changed

    filterUserSelect: FormControl = new FormControl(); // Reference to the selector to get which elements selected
    filterProjectSelect: FormControl = new FormControl(); // Reference to the selector to get which elements selected
    events: CalendarEvent[] = []; // Actual events in the calendar
    timeoffEvents: object[] = []; // All loaded events, to be used to filter the data
    color: any = {// Colors for the dot on calendar and highlghts
        approved: {
            primary: '#62C479',
            secondary: 'rgba(218,241,223,0.8)'
        },
        pending: {
            primary: '#FFCA28',
            secondary: 'rgba(251,240,208,0.8)'
        },
        submitted: {
            primary: '#999999',
            secondary: 'rgba(223,223,223,0.8)'
        },
        rejected: {
            primary: '#E12C2C',
            secondary: 'rgba(249,210,210,0.8)'
        },
        blackOut: {
            primary: '#253337',
            secondary: 'rgb(37,51,55,0.8)'
        },
        holiday: {
            primary: '#82b4c9',
            secondary: 'rgba(130,180,201,0.8)'
        }
    };

    tenantStartDayValue;
    tenantStartDayConfig = null;

    constructor(private _timeoffService: TimeoffService,
        public dialog: MatDialog,
        public snackbar: MatSnackBar,
        private _settingService: SetttingsService) { }

    ngOnInit() {
        this._settingService.getTenantWeekStart().subscribe(data => {
            this.tenantStartDayValue = parseInt(data + "");
            this.tenantStartDayConfig = this.tenantStartDayValue == 6 ? 0 : this.tenantStartDayValue + 1;
            this._settingService.setStartDayToCookie(this.tenantStartDayConfig  + "");

            this.formatDateRange();
			this.getBlackOutDates();
            this.getHolidayDates();
            this.getTimeoffCategory(); // This gets the categories then calls the getTimeoffEntries to populate events
            this.getUserswithProjects();

        });
    }

    onUsersKeyFilter(value) {
        this.selectedUsers = this.users.filter(option => option['userName'].toLowerCase().includes(value.toLowerCase()));
    }

    onProjectsKeyFilter(value) {
        this.selectedProjects = this.projects.filter(option => option['projectName'].toLowerCase().includes(value.toLowerCase()));
    }

    //Sets focus of the combobox inputs on click
    setFocus(inputId: string) {
        if (document.getElementById(inputId) !== null) {
            document.getElementById(inputId).focus();
        }
    }

    dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
        // If the date is the same and the day is open, or there is no events close the active day
        if ((moment(this.viewDate) == moment(date) && this.activeDayIsOpen === true) || events.length === 0) {
            this.activeDayIsOpen = false;
        } else {// Open day view and set viewdate and the two week
            this.activeDayIsOpen = true;
            this.viewDate = date;
            this.viewDateTwoWeek = moment(date).add(1, 'week').toDate();
        }
    }

    clearUsers() {
        this.filterUserSelect.reset();
        this.filterUserSelect.setValue([]);
        this.refreshFilter();
    }

    clearProjects() {
        this.filterProjectSelect.reset();
        this.filterProjectSelect.setValue([]);
        this.refreshFilter();
    }

    beforeMonthViewRender({ body }) {
        // Used to make badge totals not display on calendar load
        body.forEach(day => {
            day.badgeTotal = 0;
        });
    }

    findCategory(id) {
        // Gets the name of a category from the id and returns it
        let filterFind = this.categories.find(f => f['id'] == id);

        if (filterFind !== undefined) {
            return filterFind['name'];
        } else {
            return 'Event';
        }
    }

    findUser(id) {
        // Gets the name of a category from the id and returns it
        let userFind = this.users.find(f => f['userId'] == id);
        if (userFind !== undefined) {
            return userFind['userName'];
        } else {
            return 'No Name Found';
        }
    }

	refreshFilter() {
		this.events = [];

		this.processTimeOffEvents();
        this.processBlackOutData();
        this.processHolidayData();
    }

	processTimeOffEvents(){
		var tempFilterUsersByProject = [];
        if(this.filterProjectSelect.value != null && this.filterProjectSelect.value.length > 0)
            for(var i = 0; i < this.usersAndProjects.length; i++){
                if(this.filterProjectSelect.value.includes(this.usersAndProjects[i]['projectId']) && !tempFilterUsersByProject.includes(this.usersAndProjects[i]['userId']))
                    tempFilterUsersByProject.push(this.usersAndProjects[i]['userId']);
            }

		var timeoffPromises = []
		for(var i = this.timeoffEvents.length - 1;i>=0;i--){
			timeoffPromises.push(new Promise((resolve)=>{
				if ((this.filterUserSelect.value == null || this.filterUserSelect.value.length == 0 || this.filterUserSelect.value.includes(this.timeoffEvents[i]['userId'])) &&
                (this.filterProjectSelect.value == null || this.filterProjectSelect.value.length == 0 || tempFilterUsersByProject.includes(this.timeoffEvents[i]['userId']))) {
                	this.processEvents(this.timeoffEvents[i]);
            	}
				resolve('done');
			}));
		}

		Promise.all(timeoffPromises).then(()=>{
			this.eventsParsedObj.timeOff = true;
			this.refreshCalendar();
		});
	}

	processBlackOutData(){
		try{
			var promises = []
            for (var entry of this.blackOutDataArray){
				promises.push(new Promise(resolve =>{
					this.parseBlackOutData(entry);
					resolve('done')
				}));
            };
			Promise.all(promises).then(()=>{
				this.eventsParsedObj.blackOut = true;
				this.refreshCalendar();
			})
        }catch(exception){
            this.snackbar.open('Error While Loading Black Out Events', '', { duration: 2000 });
        }
	}

	parseBlackOutData(entry){
		this.events.push({
			id: entry.id,
			start: moment(entry.blackOutDate.substring(0, 10)).toDate(),
			end: moment(entry.blackOutDate.substring(0, 10)).toDate(),
			title: entry.reason,
			color: this.color["blackOut"],
			cssClass: 'white'
		});
		return;
	}

	processHolidayData(){
		try{
			var promises = []
            for (var entry of this.holidayDataArray){
				promises.push(new Promise(resolve =>{
					this.parseHolidayData(entry);
					resolve('done')
				}));
            };
			Promise.all(promises).then(()=>{
				this.eventsParsedObj.holiday = true;
				this.refreshCalendar();
			})
        }catch(exception){
            this.snackbar.open('Error While Loading Holiday Events', '', { duration: 2000 });
        }
	}

	parseHolidayData(entry){
		this.events.push({
			id: entry.id,
			start: moment(entry.holidayDate.substring(0, 10)).toDate(),
			end: moment(entry.holidayDate.substring(0, 10)).toDate(),
			title: entry.reason,
			color: this.color["holiday"]
		});
		return;
	}

    processEvents(entry) {
        // Create and initialize an array to be used to distinguish between normal and special dates (special dates are half days and excluded days)
        let specialDateReferenceArray: boolean[] = [entry.dateList.length];
		let colorReference = "";
        // Populate the special date reference array from the current entry data
        for (let index = 0; index < entry.dateList.length; index++) {
			specialDateReferenceArray[index] = (entry.dateList[index].isMorning === true || entry.dateList[index].isAfternoon === true || entry.dateList[index].isExcluded === true );
		}


        // Declare variables to be used to reference the next special date index and the end date for date ranges
        let nextSpecialDate: number;
        let endDate: Date;
        // Iterate over the special date reference array, and handle each date represented within either as a normal date or a special date
        for (let index = 0; index < specialDateReferenceArray.length; index++) {
            // Set nextSpecialDate to be the index of the next special date after the current index
			nextSpecialDate = specialDateReferenceArray.indexOf(true, index);
            // If the date at the current index is a normal date, process it as such
            if (!specialDateReferenceArray[index]) {
                // If there is a special date after the date at the current index, set the end date to the day before the next special date
                if (nextSpecialDate > 0) {
                    endDate = moment(entry.dateList[nextSpecialDate - 1].date.substring(0, 10)).toDate();
                } else {
                    endDate = moment(entry.dateList[entry.dateList.length - 1].date.substring(0, 10)).toDate();
                }
				//Push the new normal date range to the calendar views
				//Takes the approval status id and assigns a color based on that id
				if(entry['approvalStatusId'] === 0){
					colorReference = "submitted";
				}
				else if(entry['approvalStatusId'] === 1){
					colorReference = "pending";
				}
				else if(entry['approvalStatusId'] === 2){
					colorReference = "approved"
				}
				else if(entry['approvalStatusId'] === 3){
					colorReference = "rejected"
				}

				this.events.push({
					id: entry.id,
					start: moment(entry.dateList[index].date.substring(0, 10)).toDate(),
					end: endDate,
					title: this.findCategory(entry['categoryId']) + ' - ' + this.findUser(entry['userId']),
					color: this.color[colorReference]
				});

                // If there is a special date after the date at the current index, set the index to the day just before that date
                if (nextSpecialDate > -1) {
                    index = nextSpecialDate - 1;
                } else {
                    index = entry.dateList.length - 1;
                }
            } else if (entry.dateList[index].isMorning === true || entry.dateList[index].isAfternoon === true ) {
                // Build the event title string from the event category and timeframe for the time off
                let timeframeString: string = (entry.dateList[index].isMorning) ? 'Morning' : 'Afternoon';
				let eventTitle: string = this.findCategory(entry['categoryId']) + ' (' + timeframeString + ' Only)' + ' - ' + this.findUser(entry['userId']);
				if(entry['approvalStatusId'] === 0){
					colorReference = "submitted";
				}
				else if(entry['approvalStatusId'] === 1){
					colorReference = "pending";
				}
				else if(entry['approvalStatusId'] === 2){
					colorReference = "approved"
				}
				else if(entry['approvalStatusId'] === 3){
					colorReference = "rejected"
				}

                this.events.push({
                    id: entry.id,
                    start: moment(entry.dateList[index].date.substring(0, 10)).toDate(),
                    end: moment(entry.dateList[index].date.substring(0, 10)).toDate(),
                    title: eventTitle,
                    color: this.color[colorReference],
                    cssClass: 'half-day'
                });
            }
		}
    }

    getTimeoffCategory() {
        this._timeoffService.getTimeoffCategories().subscribe(
            data => {
                this.categories.push.apply(this.categories, data); // add the categories to the filters array
            },

            err => console.error(err)
        );
    }

    getAllTimeoffCategory() {
        this._timeoffService.getAllTimeoffCategories().subscribe(
            data => {
                this.allCategories.push.apply(this.allCategories, data);
            },
            err => console.error(err)
        );
    }

    getUserswithProjects() {
        this._timeoffService.getTimeoffUsersAndProjectsForTeam().subscribe(
            data => {
                this.getTimeoffEntries();
                this.usersAndProjects.push.apply(this.usersAndProjects, data);

                this.users = Array.from(new Set(this.usersAndProjects.map(user => user['userId'])))
                    .map(userId => {
                        return {
                            userId: userId,
                            userName: this.usersAndProjects.find(user => user['userId'] == userId)['userName']
                        }
                    }).sort(function(a, b) {
                        var keyA = a.userName,
                          keyB = b.userName;
                        if (keyA < keyB) return -1;
                        if (keyA > keyB) return 1;
                        return 0;
                      });
                this.selectedUsers = this.users;

                this.projects = Array.from(new Set(this.usersAndProjects.map(project => project['projectId'])))
                    .map(projectId => {
                        return {
                            projectId: projectId,
                            projectName: this.usersAndProjects.find(project => project['projectId'] == projectId)['projectName']
                        }
                    }).sort(function(a, b) {
                        var keyA = a.projectName,
                          keyB = b.projectName;
                        if (keyA < keyB) return -1;
                        if (keyA > keyB) return 1;
                        return 0;
                      });
                this.selectedProjects = this.projects;
            },
            err => console.error(err)
        );
    }

    getTimeoffEntries() {
        this.setFirstDayAndLastOnCalendar();

        this._timeoffService.getTimeoffEntriesForTeam(this.firstDayOnCalender, this.lastDayOnCalender)
		.pipe(takeUntil(this.ngUnsubscribe))
		.subscribe(
            data => {
				this.timeoffEvents = [];
                this.timeoffEvents.push.apply(this.timeoffEvents, data);
                this.processTimeOffEvents();
            },
            err => {
                if (err.status == 404) {
                    this.activeDayIsOpen = false;
                    this.timeoffEvents, this.events = []; // Reset values
                    this.refresh.next();
                } else {
                    console.error(err);
                }
            }
        );
    }

    increaseDate() {
		this.resetEvents()
        this.activeDayIsOpen = false;

        if (this.activeView == 'month') {
            this.viewDate = moment(this.viewDate).add(1, 'month').toDate();
            this.viewDateTwoWeek = moment(this.viewDateTwoWeek).add(1, 'month').toDate();
        } else {
            this.viewDate = moment(this.viewDate).add(1, 'week').toDate();
            this.viewDateTwoWeek = moment(this.viewDateTwoWeek).add(1, 'week').toDate();
        }

        this.formatDateRange();
        this.getTimeoffEntries();
        this.getBlackOutDates();
        this.getHolidayDates();
    }

    decreaseDate() {
		this.resetEvents()
        this.activeDayIsOpen = false;

        if (this.activeView == 'month') {
            this.viewDate = moment(this.viewDate).subtract(1, 'month').toDate();
            this.viewDateTwoWeek = moment(this.viewDateTwoWeek).subtract(1, 'month').toDate();
        } else {
            this.viewDate = moment(this.viewDate).subtract(1, 'week').toDate();
            this.viewDateTwoWeek = moment(this.viewDateTwoWeek).subtract(1, 'week').toDate();
        }

        this.formatDateRange();
        this.getTimeoffEntries();
        this.getBlackOutDates();
        this.getHolidayDates();
    }

    setDate(selectedDate: Date) {
		this.resetEvents()
        this.viewDate = moment(selectedDate).toDate();
        this.viewDateTwoWeek = moment(selectedDate).add(1, 'week').toDate();

		this.formatDateRange();
        this.getTimeoffEntries();
        this.getBlackOutDates();
        this.getHolidayDates();
    }

    setView(selectedView: string) {
        this.activeView = selectedView;
    }

    formatDateRange(selectedView?: string) {
        // Set the view variable if selectedView was passed in
        if (selectedView !== undefined) {
            this.setView(selectedView);
        }

        // Based on the view selected, generate a date range string
        if (this.activeView == 'month') {
            // Get the first day of the selected month
            let monthStart = moment(this.viewDate).startOf('month').toDate();

            // Set the date range string
            this.dateRange = moment(monthStart).format('MMMM YYYY');
        } else if (this.activeView == 'week' || this.activeView == 'two-week') {
            // Get the start and end date of the date range based on which view is selected
            let startDate = moment(this.viewDate).startOf('isoWeek').toDate();
            let endDate: Date = new Date();

            if (this.activeView == 'week') {
                endDate = moment(this.viewDate).endOf('isoWeek').toDate();
            } else {
                endDate = moment(this.viewDateTwoWeek).endOf('isoWeek').toDate();
            }

            /** Check if the start and end dates are in the same month and year
			 * and respond accordingly
			 **/
            let sameMonth = startDate.getMonth() == endDate.getMonth();
            let sameYear = startDate.getFullYear() == endDate.getFullYear();
            let start = 'MMM D - ';
            let end = 'D, YYYY';

            if (!sameMonth) {
                end = 'MMM D, YYYY';
            }

            if (!sameYear) {
                start = 'MMM D, YYYY - ';
            }

            // Set the date range string
            this.dateRange = moment(startDate).format(start) + moment(endDate).format(end);
        }
    }

    getBlackOutDates() {
        this.blackOutDataArray = [];
        this.setFirstDayAndLastOnCalendar();

        this._timeoffService.getTimeOffBlackOutDates(this.firstDayOnCalender, this.lastDayOnCalender)
		.pipe(takeUntil(this.ngUnsubscribe))
		.subscribe(
            data => {
                this.blackOutDataArray = [];
                this.blackOutDataArray.push.apply(this.blackOutDataArray, data);
                this.blackOutDataArray.sort(function(a, b) {
                    var aDate = a.blackOutDate;
                    var bDate = b.blackOutDate;

                    return (aDate < bDate) ? -1 : (aDate > bDate) ? 1 : 0;
                });
				this.processBlackOutData();
            }, err => {
                this.snackbar.open(err.statusText, '', { duration: 2000 });
                console.error(err);
            }
        );
    }

    getHolidayDates() {
        this.holidayDataArray = [];
        this.setFirstDayAndLastOnCalendar();

        this._timeoffService.getTimeOffHolidayDates(this.firstDayOnCalender, this.lastDayOnCalender)
		.pipe(takeUntil(this.ngUnsubscribe))
		.subscribe(
            data => {
                this.holidayDataArray = [];
                this.holidayDataArray.push.apply(this.holidayDataArray, data);
                this.holidayDataArray.sort(function(a, b) {
                    var aDate = a.holidayDate;
                    var bDate = b.holidayDate;

                    return (aDate < bDate) ? -1 : (aDate > bDate) ? 1 : 0;
                });
				this.processHolidayData();
            }, err => {
                this.snackbar.open(err.statusText, '', { duration: 2000 });
                console.error(err);
            }
        );
    }

	setFirstDayAndLastOnCalendar(){
		var selection = 6 - this.tenantStartDayValue;
        this.firstDayOnCalender = new Date(this.viewDate.getFullYear(), this.viewDate.getMonth() - 1, 1)
        this.firstDayOnCalender.setDate(this.firstDayOnCalender.getDate() - (this.firstDayOnCalender.getDay() + selection) % 7)

		this.lastDayOnCalender = new Date(this.viewDate.getFullYear(), this.viewDate.getMonth() + 1, 0);
        this.lastDayOnCalender.setDate(this.lastDayOnCalender.getDate() + (this.tenantStartDayConfig + 7 - this.lastDayOnCalender.getDay() - 1) % 7);
	}

	clearEvents(){
		this.events = [];
		this.eventsParsedObj = _.mapValues(this.eventsParsedObj, () => false); //refresh event object back to false
	}

	unsubscribeFromOldRequests(){ // unsubscribes from old request to grab events
		this.ngUnsubscribe.next();
		//this.ngUnsubscribe.complete();
	}

	refreshCalendar(){
		if(this.eventsParsedObj.timeOff && this.eventsParsedObj.blackOut && this.eventsParsedObj.holiday)
			this.refresh.next();
	}

	resetEvents(){
		this.unsubscribeFromOldRequests();
		this.clearEvents();
	}
}
