import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'orderTimeEntriesPipe' })
export class OrderTimeEntriesPipe implements PipeTransform {
	filteredData: any = [];
  transform(timeSheetData: any[], clients: any, projects: any, classes: any, services: any){
		timeSheetData.forEach(entry => {
			// for matching client id add client name to timeSheetData object
			for (let client of clients){
				if (entry['client'] == client.clientId){
					entry.clientName = client.clientName;
				}
			}
			// for matching project id add project name to timeSheetData object
			for (let project of projects){
				if (entry['project'] == project.id){
					entry.projectName = project.name;
				}
			}
			// for matching class id add class name to timeSheetData object
			for (let workClass of classes){
				if (entry['workClass'] == workClass.id){
					entry.className = workClass.name;
				}

			}
			// for matching service id add service name to timeSheetData object
			for (let service of services){
				if (entry['service'] == service.id){
					entry.serviceName = service.name;
				}
			}
		});

		// sort time entries by clientName, then projectName, then work class
		timeSheetData.sort(function(a, b) {
			// client names
			var clientA = a.clientName;
			var clientB = b.clientName;
			// project names
			var projectA = a.projectName;
			var projectB = b.projectName;
			// class names
			var classA = a.className;
			var classB = b.className;
			// service names
			var serviceA = a.serviceName;
			var serviceB = b.serviceName;
			switch (true){
				case (clientA > clientB):
					return 1;
				case (clientA < clientB):
					return -1;
				case (clientA === clientB):
					switch (true){
						case (projectA > projectB):
							return 1;
						case (projectA < projectB):
							return -1;
						case (projectA === projectB):
							switch (true){
								case (classA > classB):
									return 1;
								case (classA < classB):
									return -1;
								case (classA === classB):
									switch (true){
										case (serviceA > serviceB):
											return 1;
										case (serviceA < serviceB):
											return -1;
										default:
											return 0;
									}
								default:
									return 0;
							}
						default:
							return 0;
					}
				default:
					return 0;
			}

		});

		return timeSheetData;

	}

}
