import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'orderEvents'
})
export class OrderEventsPipe implements PipeTransform {

    transform(array: Array<any>, args?: any): any {
        // Sorting algorithm for the events being added to calendar
        // If a sorting block is the same, then it goes to the next sorting block
        // Ordered by length of event then staus, and then title
        array.sort((a: any, b: any) => {
            // First, sort by the length of the event, prioritizing longer events to bottom
            let aDayDif = moment(a['end']).diff(moment(a['start']), 'days');
            let bDayDif = moment(b['end']).diff(moment(b['start']), 'days');
            if (aDayDif < bDayDif) {
                return -1;
            }
            if (aDayDif > bDayDif) {
                return 1;
            }

            // Next, sort base on the color of the event, efectivley the status of the event
            if (a['color']['primary'] < b['color']['primary']) {
                return 1;
            }
            if (a['color']['primary'] > b['color']['primary']) {
                return -1;
            }

            // Next, sort based on the title of the event
            if (a['title'] < b['title']) {
                return -1;
            }
            if (a['title'] > b['title']) {
                return 1;
            }

            // Exactly the same, return 0
            return 0;
        });
        return array;
    }

}
