import { Component, OnInit } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { SetttingsService } from "../../api/setttings.service";
import { MatSnackBar } from "@angular/material";
import { AuthService } from "../../services/auth/auth.service";
import { Auth0ManagementApiService } from "src/app/services/auth0-management-api/auth0-management-api.service";
import { User } from "../../models/user";
import { flattenStyles } from "@angular/platform-browser/src/dom/dom_renderer";
import { Password } from "src/app/models/password";

@Component({
	selector: "app-management-setting",
	templateUrl: "./management-setting.component.html",
	styleUrls: ["./management-setting.component.scss"],
})
export class ManagementSettingComponent implements OnInit {
	private userId: string;
	private user: User;
	private updatedPassword: Password;

	hiddenPassword: boolean[] = [true, true, true];
	email = new FormControl({ value: "", disabled: true }, [
		Validators.required,
		Validators.email,
	]);
	firstName = new FormControl({ value: "", disabled: false }, [
		Validators.required,
	]);
	middleInitial = new FormControl({ value: "", disabled: false });
	lastName = new FormControl({ value: "", disabled: false }, [
		Validators.required,
	]);
	position = new FormControl({ value: "", disabled: true }, [
		Validators.required,
	]);
	title = new FormControl({ value: "", disabled: false }, [
		Validators.maxLength(50),
	]);

	newPassword = new FormControl({ value: "", disabled: false }, [
		Validators.required,
		Validators.minLength(8),
		Validators.maxLength(32),
	]);
	confirmPassword = new FormControl({ value: "", disabled: false }, [
		Validators.required,
	]);

	fullUserData: any; // Data needed to be sent back to the server

	constructor(
		private authService: AuthService,
		private auth0ManagementApiService: Auth0ManagementApiService,
		private settingsService: SetttingsService,
		public snackbar: MatSnackBar
	) {}

	ngOnInit() {
		this.updatedPassword = {
			password: "",
		};
		this.authService.getUserId().subscribe(
			(userId) => {
				this.userId = userId;
				this.auth0ManagementApiService
					.getUserDetails(this.userId)
					.subscribe(
						(user) => {
							this.user = user;
							this.setUserDetailsFields();
						},
						(getUserDetailsError) =>
							this.snackbar.open(
								"Error Retrieving User Details",
								"",
								{ duration: 2000 }
							)
					);
			},
			(getUserIdError) =>
				this.snackbar.open("Error Retrieving User ID", "", {
					duration: 2000,
				})
		);
	}

	setUserDetailsFields(): void {
		if (this.user !== undefined) {
			this.email.setValue(this.user.email);
			this.firstName.setValue(this.user.firstName);
			this.middleInitial.setValue(this.user.middleInitial);
			this.lastName.setValue(this.user.lastName);
			this.title.setValue(this.user.title);

			// this.settingsService.getUserSettings().subscribe(
			//     userSettings => {
			//         if (userSettings['positionInfo'] != null) {
			//             this.position.setValue(userSettings['positionInfo']['name']);
			//         }
			//     }, getUserSettingsError => this.snackbar.open('Error Retrieving Settings', '', { duration: 2000 })
			// );
		}
	}

	validateUserDetails(): boolean {
		return !(
			this.email.invalid ||
			this.firstName.invalid ||
			this.lastName.invalid ||
			this.position.invalid ||
			this.title.invalid
		);
	}

	saveUserDetails(): void {
		if (this.validateUserDetails()) {
			this.user.firstName = this.firstName.value;
			this.user.middleInitial = this.middleInitial.value;
			this.user.lastName = this.lastName.value;
			this.user.title = this.title.value;

			this.auth0ManagementApiService
				.updateUserDetails(this.userId, this.user)
				.subscribe(
					(updateUserDetailsSuccessful) =>
						this.snackbar.open(
							"Settings Updated Successfully",
							"",
							{ duration: 2000 }
						),
					(updateUserDetailsError) =>
						this.snackbar.open("Error Updating Settings", "", {
							duration: 2000,
						})
				);
		}
	}

	savePasswordReset(): void {
		this.updatedPassword.password = this.newPassword.value;
		this.auth0ManagementApiService
			.updateUserPassword(this.userId, this.updatedPassword)
			.subscribe(
				(updateUserDetailsSuccessful) =>
					this.snackbar.open("Password Updated Successfully", "", {
						duration: 2000,
					}),
				(updateUserDetailsError) =>
					this.snackbar.open("Error Setting New Password", "", {
						duration: 2000,
					})
			);
	}
}
