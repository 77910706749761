import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class GlobalService {
  private siteToggle = "Production"; // Production, AzureDev, TNG01

  constructor() { }

  public retrievePath() {
    switch (this.siteToggle) {
      case "Production":
        return 'https://api.prosightlabs.com/api/';
      case "AzureDev":
        return 'https://prosight-server-dev.azurewebsites.net/api/';
      case "TNG01":
        return 'http://teamtng01.thenormandygroup.com:3000/api/';
      default:
        return 'http://localhost:3000/api/';
    }
  }

  public retriveAPIkey() {
    switch (this.siteToggle) {
      case "Production":
        return 'be86d1a50134474eac379a4ff5de76b8';
      case "AzureDev":
        return 'b93991c929bd45509c1f9f89752ad8b4'
      default:
        return 'e51377d72dfd477c94b9dcf72077cce3';
    }
  }

  public currentToggle(){
    return this.siteToggle;
  }
}