import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '../../../node_modules/@angular/common/http';
import { MatSnackBar } from '../../../node_modules/@angular/material';
import { GlobalService } from '../api/globalVars';
import { CookieService } from 'ngx-cookie';
import * as moment from 'moment';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    withCredentials: true
};

@Injectable({
    providedIn: 'root'
})

export class SetttingsService {
    path = this.globalVar.retrievePath();

    constructor(private _http: HttpClient, private _cookieService: CookieService,
        public globalVar: GlobalService, public snackBar: MatSnackBar) { }


    getProjectsByClientId(clientList, onlyActive = false, onlyForAssignedUser = false) {
        const posturl = this.path + 'project?onlyActive=' + onlyActive + '&assignedUser=' + onlyForAssignedUser;
		return this._http.post(posturl, clientList, httpOptions);
    }

    getUserSettings() {
        const geturl = this.path + 'user';
        return this._http.get(geturl, httpOptions);
    }

    getUserManagerSettings() {
        const geturl = this.path + 'user?projectManager=true';
        return this._http.get(geturl, httpOptions);
    }

    getExpenseCategories() {
        const geturl = this.path + 'expensescategory';
        return this._http.get(geturl, httpOptions);
    }

    getExpensePolicies() {
        const geturl = this.path + 'expensepolicy';
        return this._http.get(geturl, httpOptions);
    }

    getClients() {
        const geturl = this.path + 'client?allFlag=1';
        return this._http.get(geturl, httpOptions);
    }

    getUserClients(userId) {
		const geturl = this.path + 'client?userId=' + userId;
        return this._http.get(geturl, httpOptions);
	}


	getClientsShort() {
        const geturl = this.path + 'client?allFlag=1&isShort=1';
        return this._http.get(geturl, httpOptions);
    }

    getClientByProjectId(projectId) {
        const geturl = this.path + 'client?projectId=' + projectId;
        return this._http.get(geturl, httpOptions);
    }

    getClasses() {
        const geturl = this.path + 'workclass?allFlag=1';
        return this._http.get(geturl, httpOptions);
    }

    getPolicyFilterRules(policyId) {
        const geturl = this.path + 'expensepolicy?type=rules&currentPolicyId=' + policyId;
        return this._http.get(geturl, httpOptions);
    }

    getPositions() {
        const geturl = this.path + 'position';
        return this._http.get(geturl, httpOptions);
    }

    getProjects(onlyActive = true) {
        const geturl = this.path + 'project?status=' + onlyActive;
        return this._http.get(geturl, httpOptions);
    }

    getProjectsNoUser(onlyActiveNoUser = true) {
        const geturl = this.path + 'project?statusNoUser=' + onlyActiveNoUser;
        return this._http.get(geturl, httpOptions);
    }

    getServices() {
        const geturl = this.path + 'service?allFlag=1';
        return this._http.get(geturl, httpOptions);
    }

    getUsers(active) {
        const geturl = this.path + 'tenantuser?isActive=' + active;
        return this._http.get(geturl, httpOptions);
    }

    getUserRoles() {
        const geturl = this.path + 'tenantuser?retrieveRoles=1';
        return this._http.get(geturl, httpOptions);
    }

    getRoleUsers() {
        const geturl = this.path + 'tenantuser?retrieveRoleUsers=1';
        return this._http.get(geturl, httpOptions);
    }

    getProjectUsers(projectId) {
        const geturl = this.path + 'projectuser?projectId=' + projectId;
        return this._http.get(geturl, httpOptions);
    }

    getUserInvites() {
        const geturl = this.path + 'userinvite';
        return this._http.get(geturl, httpOptions);
    }

    getPtoCategories() {
        const geturl = this.path + 'timeoffcategory?allFlag=1';
        return this._http.get(geturl, httpOptions);
    }

    getGroupById(groupId) {
        const geturl = this.path + 'group?GropuId=' + groupId;
        return this._http.get(geturl, httpOptions);
    }
    getPtoPolicy(categoryId) {
        const geturl = this.path + 'timeoffpolicy?catId=' + categoryId;
        return this._http.get(geturl, httpOptions);
    }

    getPTOSettings(){
        const geturl = this.path + 'ptoSettings';
        return this._http.get(geturl, httpOptions);
    }

    getHrGroups() {
        const geturl = this.path + 'group?allFlag=1';
        return this._http.get(geturl, httpOptions);
    }

    getTenantConfigFinancialSystem() {
        const geturl = this.path + 'tenantconfig?type=financialSystem';
        return this._http.get(geturl, httpOptions);
    }

    getTenantConfigImage(filename) {
        const geturl = this.path + 'tenantConfig?url=' + filename;
        return this._http.get(geturl, httpOptions);
    }

    getTenantSettings() {
        const geturl = this.path + 'tenantconfig?type=all';
        return this._http.get(geturl, httpOptions);
    }

    getTenantFeatures() {
        const geturl = this.path + 'tenantconfig?type=features';
        return this._http.get(geturl, httpOptions);
    }

    getTenantWeekStart() {
        const geturl = this.path + 'tenantconfig?type=weekStart';
        return this._http.get(geturl, httpOptions);
    }

    getProjectPeopleData(projectId, type) {
        const geturl = this.path + 'projecttime?projectId=' + projectId + '&type=' + type;
        return this._http.get(geturl, httpOptions);
    }

    getStatuses() {
        const geturl = this.path + 'status';
        return this._http.get(geturl, httpOptions);
    }

    getStatusById(status){
        const geturl = this.path + 'status?status=' + status;
        return this._http.get(geturl, httpOptions);
    }

    getTaskTypes(){
        const geturl = this.path + 'tasktype';
        return this._http.get(geturl, httpOptions);
    }

    postGroup(groupModel) {
        const geturl = this.path + 'group';
        return this._http.post(geturl, groupModel, httpOptions);
    }

    postTenantImage(img, type) {
        const posturl = this.path + 'tenantconfig?type=' + type;
        return this._http.post(posturl, img, httpOptions);
    }

    updateStartDate(startDate) {
        const posturl = this.path + 'tenantconfig?startDateSelection=' + startDate;
        return this._http.post(posturl, null, httpOptions);
    }

    postPtoCategory(category) {
        const posturl = this.path + 'timeoffcategory?updateFlag=1';
        return this._http.post(posturl, category, httpOptions);
    }

    putPtoSettings(ptoSettings){
        const puturl = this.path + 'ptoSettings';
        return this._http.put(puturl, ptoSettings, httpOptions);
    }

    postUsers(user) {
        const posturl = this.path + 'user';
        return this._http.post(posturl, user, httpOptions);
    }

    postProjectUser(user) {
        const posturl = this.path + 'projectuser';
        return this._http.post(posturl, user, httpOptions);
    }

    getProjectsWithUsers() {
        const geturl = this.path + 'project?projectsWithUsers=true';
        return this._http.get(geturl, httpOptions);
    }

    putService(service) {
        const puturl = this.path + 'service';
        return this._http.put(puturl, service, httpOptions);
    }

    putProject(project) {
        const puturl = this.path + 'project';
        return this._http.put(puturl, project, httpOptions);
    }
    putPositions(position) {
        const puturl = this.path + 'position';
        return this._http.put(puturl, position, httpOptions);
    }
    putNewUser(user) {
        const puturl = this.path + 'user';
        return this._http.put(puturl, user, httpOptions);
    }

    putClasses(classModel) {
        const puturl = this.path + 'workclass';
        return this._http.put(puturl, classModel, httpOptions);
    }

    putClients(client) {
        const puturl = this.path + 'client';
        return this._http.put(puturl, client, httpOptions);
    }

    putExpenseCategory(category) {
        const puturl = this.path + 'expensescategory';
        return this._http.put(puturl, category, httpOptions);
    }

    putStatus(status){
        const puturl = this.path + 'status';
        return this._http.put(puturl, status, httpOptions);
    }

    putTaskType(taskType){
        const puturl = this.path + 'tasktype';
        return this._http.put(puturl, taskType, httpOptions);
    }

    postExpensePolicy(policyModel) {
        const posturl = this.path + 'expensepolicy?updateFlag=1';
        return this._http.post(posturl, policyModel, httpOptions);
    }

    postExpensePolicyToggle(category) {
        const posturl = this.path + 'expensepolicy?toggleFlag=1';
        return this._http.post(posturl, category, httpOptions);
    }

    postUserSettings(settingsModel) {
        const posturl = this.path + 'user';
        return this._http.post(posturl, settingsModel, httpOptions);
    }

    deleteProjectUser(userId, projectId) {
        const deleteurl = this.path + 'projectuser?projectUserId=' + userId + '&projectId=' + projectId;
        return this._http.delete(deleteurl, httpOptions);
    }

    deleteStatus(statusId){
        const deleteurl = this.path + 'status?statusId=' + statusId;
        return this._http.delete(deleteurl, httpOptions);
    }

    deleteTaskType(taskTypeId){
        const deleteurl = this.path + 'tasktype?taskTypeId=' + taskTypeId;
        return this._http.delete(deleteurl, httpOptions);
    }

    /**
	 * Updates the cookie which stores the current start day selection to reflect the
	 * current selection for the tenant associated with the current user
	 */
    setStartDayToCookie(daySelection: string) {
        this.removeStartDayCookie();
        this._cookieService.put('prosight-start-day', daySelection, { expires: moment().add(1, 'year').toString() });
    }

    /**
	 * Retrieves the currently set start day for the tenant associated with the
	 * current user from the start day cookie stored in the user's browser
	 */
    getStartDayFromCookie() {
        if (this._cookieService.get('prosight-start-day')) {
            return this._cookieService.get('prosight-start-day');
        } else {
            return null;
        }
    }

    /**
	 * Removes the application start day cookie from the user's browser
	 */
    removeStartDayCookie() {
        if (this._cookieService.get('prosight-start-day')) {
            this._cookieService.remove('prosight-start-day');
        }
    }
}
