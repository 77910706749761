import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-copy-hours-dialog',
  templateUrl: './copy-hours-dialog.component.html',
  styleUrls: ['./copy-hours-dialog.component.scss']
})
export class CopyHoursDialogComponent implements OnInit {

    constructor(public dialogRef: MatDialogRef<CopyHoursDialogComponent>, ) { }

  ngOnInit() {
  }

    closeDialog() {
        this.dialogRef.close();
    }
    copyHours() {
        this.dialogRef.close(true);
    }

}
