import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate } from '@angular/router';
import { HomeViewService } from "../../services/home-view/home-view.service";
import { iif, Observable, of } from 'rxjs';
import { AuthService } from '../../services/auth/auth.service';
import { concatMap, map } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material';

@Injectable({
  providedIn: 'root'
})

export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService, private homeViewService: HomeViewService,
		public snackBar: MatSnackBar) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean|UrlTree> | boolean {
    return this.authService.getUserPermissions().pipe(
      concatMap((permissions) => {
        if(permissions == null){
          return this.authService.isAuthenticated.pipe(
            concatMap(() => this.authService.handleAuthCallback()),
            concatMap((result: { loggedIn: boolean, targetUrl: string }) => {
              var continueUrl = result.loggedIn;
              if(result.loggedIn){
                continueUrl = this.urlPermissionValidation(state, permissions, []);
              }
              return iif(() => continueUrl, of(true),
              this.authService.login(state.url)
                .pipe(map(() => false)))
          }));
        }
        else {
          return this.authService.getTenantFeaturesPipe().pipe(
            concatMap((features) => {
              if(state.url.includes("/qbOAuthReciever?code=")){
                var continueUrl = this.urlPermissionValidation(state, permissions, features);
                return iif(() => continueUrl, of(true),
                this.authService.login(state.url)
                  .pipe(map(() => false)))
              }
              else {
                return this.authService.isAuthenticated.pipe(
                  concatMap(() => this.authService.handleAuthCallback()),
                  concatMap((result: { loggedIn: boolean, targetUrl: string }) => {
                    var continueUrl = result.loggedIn;
                    if(result.loggedIn){
                      continueUrl = this.urlPermissionValidation(state, permissions, features);
                    }
                    return iif(() => continueUrl, of(true),
                    this.authService.login(state.url)
                      .pipe(map(() => false)))
                }));
              }
          }));
        }
    }))
  }

  urlPermissionValidation(stateOrUrl, permissions: string[], features: number[]){

    try {
      //Strips out any parameters
      var currentURL = stateOrUrl.url.split("?")[0];
    } catch {
      //If it is a URL no change.
      currentURL = stateOrUrl;
    }
    //If permissions are null that means that they are going to the
    //landing page, which should always be allowed
    if(permissions != null){
      var isAdmin: boolean = permissions.includes('administer-tenant:administration');
      var isManager = permissions.includes('manage:expenses') ||
        permissions.includes('manage:project-portfolios') ||
        permissions.includes('manage:projects') ||
        permissions.includes('manage:time-entries') ||
        permissions.includes('manage:time-off');
      var continueUrl = false;

      if(currentURL == '/qbOAuthReciever' || currentURL == '/settings' ||
        currentURL == '/reports' || currentURL == "/home" || currentURL == "/404"){
        return true;
      }

      switch(currentURL) {
        case '/time-entry':
          continueUrl = (permissions.includes('read:time-entries') || isAdmin)
          && features.includes(1);
          break;
        case '/projects':
          continueUrl = (permissions.includes('read:projects') || isAdmin)
          && features.includes(2);
          break;
        case '/work-item':
          continueUrl = (permissions.includes('read:projects') || isAdmin)
          && features.includes(2);
          break;
		    case '/kanban-board':
			    continueUrl = (permissions.includes('read:projects') || isAdmin)
		    	&& features.includes(2);
          break;
        case '/project-portfolios':
          continueUrl = (permissions.includes('read:project-portfolios') || isAdmin)
          && features.includes(3);
          break;
        case '/time-off':
          continueUrl = (permissions.includes('read:time-off') || isAdmin)
          && features.includes(4);
          break;
        case '/team-calendar':
          continueUrl = (permissions.includes('read:time-off') || isAdmin)
          && features.includes(4);
          break;
        case '/expenses':
          continueUrl = (permissions.includes('read:expenses') || isAdmin)
          && features.includes(5);
          break;
        case '/approval/time-entry-approval':
          continueUrl = (permissions.includes('manage:time-entries') ||
            permissions.includes('approve:time-entries') || isAdmin)
            && features.includes(1);
          break;
        case '/approval/time-off-approval':
          continueUrl = (permissions.includes('manage:time-off') ||
            permissions.includes('approve:time-off') || isAdmin)
            && features.includes(4);
          break;
        case '/approval/expenses-approval':
          continueUrl = (permissions.includes('manage:expenses') ||
            permissions.includes('approve:expenses') || isAdmin)
            && features.includes(5);
          break;
        case '/administration':
          continueUrl = permissions.includes('manage-application-settings:administration') ||
            isAdmin || isManager;
          break;
        default:
          return false;
      }

      if(continueUrl == false){
     
     if(permissions.length !=0){ //if the user has permissions and the contiue url is false find a valid view and set cookie

      //prosight-home cookie is being manipulated within each block 
  
      if(permissions.includes('read:projects') || isAdmin){ //valid home view: kanban-board
      this.homeViewService.saveAppHomeViewToCookie('kanban-board');
     }
      else if(permissions.includes('read:project-portfolios') || isAdmin){ //valid home view: project-portfolio
      this.homeViewService.saveAppHomeViewToCookie('project-portfolio');
     }
     else if(permissions.includes('read:time-off') || isAdmin){ //valid home view: team-calendar
     this.homeViewService.saveAppHomeViewToCookie('team-calendar');
     }
     else if(permissions.includes('read:expenses') || isAdmin){ //valid home view: expenses
     this.homeViewService.saveAppHomeViewToCookie('expenses');
     }
     else if(permissions.includes('read:time-entries') || isAdmin){ //valid home view:time-entries
      this.homeViewService.saveAppHomeViewToCookie('time-entries');
     }
  
     }
     else{
      this.snackBar.open('You do not have permission to access this view. Please contact your administrator for access.', '', { duration: 8000, verticalPosition: "top" });
   
     }
  
    }
      return continueUrl;
    }
    else {
      return true;
    }
  }
}
