import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatSnackBar, MatSnackBarConfig, MatTable } from '@angular/material';
import { TimesheetDialogComponent } from './timesheet-dialog/timesheet-dialog.component';
import * as moment from 'moment';
import { TimesheetService } from '../api/timesheet.service';
import { DeleteConfirmationDialogComponent } from '../extra/delete-confirmation-dialog/delete-confirmation-dialog.component';
import { FormControl, Validators } from '@angular/forms';
import { ThemeService } from '../services/theme/theme.service';
import { CopyHoursDialogComponent } from './copy-hours-dialog/copy-hours-dialog.component';
import { TouchSequence } from 'selenium-webdriver';
import { exists } from 'fs';
import { Location } from '@angular/common';
import { OrderEventsPipe } from '../pipes/order-events.pipe';
import { OrderTimeEntriesPipe } from '../pipes/order-timesheets.pipe';
import { SetttingsService } from '../api/setttings.service';
@Component({
    selector: 'app-timesheet',
    templateUrl: './timesheet.component.html',
    styleUrls: ['./timesheet.component.scss'],
	providers: [OrderTimeEntriesPipe],
})

export class TimesheetComponent implements OnInit, AfterViewInit {
	displayedColumns = ['clientclass', 'projectservice', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun', 'total', 'edit'];// Needed for the operation of the mat table
	timesheetTableData = [];// List of week models to store the row data of the table
	prevTimeSheetTableData = []; //List of week models to store the previousTimeSheetTable for items before a copy
	clients: object[]; // array of client objects for the selectors
	classes: object[]; // array of class objects for the selectors
	services: object[];// array of service objects for the selectors
	projects: object[] = []; // array of project objects for the selectors
	allClients: object[] = [];
	selectedClients: any[] = [];
	allServices: object[] = [];
	selectedServices: any[] = [];
	allClasses: object[] = [];
	selectedClasses: any[] = [];
	allProjects: object[] = [];
	selectedProjects: any[] = [];
	newRowCounter: number = 1;// Used to label the new rows with unique Ids
	isTimesheetLoading: boolean;
	isTimesheetSubmitting: boolean = false;
	timesheetDate = moment();// The time that is associated with the timesheet week being displayed
	dateRange = "";// String that show the date range on the datepicker button
	dateDisplayStrings: string[];
	saveHours = false;
	clientsLoaded = false;
	@ViewChild('timesheetTable') timesheetTable: MatTable<any >;//mat table reference to help with reloads

	tenantStartDayValue;
    tenantStartDayConfig = null;

	constructor(private _timesheetService: TimesheetService,
		public dialog: MatDialog,
		public snackBar: MatSnackBar,
		private location: Location,
        private _settingService: SetttingsService,
		private timesheetPipe: OrderTimeEntriesPipe) { }

	ngOnInit() {// on init format the daterange and load the client/class/service/project data and the timesheet data
		this.isTimesheetLoading = true;
		this._settingService.getTenantWeekStart().subscribe(data => {
            this.tenantStartDayValue = parseInt(data + "");
            this.tenantStartDayConfig = this.tenantStartDayValue == 6 ? 0 : this.tenantStartDayValue + 1;
			this._settingService.setStartDayToCookie(this.tenantStartDayConfig  + "");

            this.formatDateRange();
			this.buildDateDisplayStrings();
			this.getAllClientClassServiceData();
			this.getClientClassServiceData();
			this.getWeekData();
		});
	}

	ngAfterViewInit(): void {
		this.location.replaceState('time-entry');
	  }

	onKeyClients(value) {
			this.selectedClients = this.filterClients(value);
	}

	filterClients(value: string) {
		  let filter = value.toLowerCase();
		  return this.clients.filter(option => option['clientName'].toLowerCase().includes(filter));
	}

	onKeyService(value) {
		this.selectedServices = this.filterService(value);
	}

	filterService(value: string) {
	  let filter = value.toLowerCase();
	  return this.services.filter(option => option['name'].toLowerCase().includes(filter));
	}

	onKeyProjects(value) {
		this.selectedProjects = this.filterProjects(value);
	}

	filterProjects(value: string) {
	  let filter = value.toLowerCase();
	  return this.projects.filter(option => option['name'].toLowerCase().includes(filter));
	}

	onKeyClasses(value) {
		this.selectedClasses = this.filterClasses(value);
	}

	filterClasses(value: string) {
	  let filter = value.toLowerCase();
	  return this.classes.filter(option => option['name'].toLowerCase().includes(filter));
	}

	//Sets focus of the combobox inputs on click
	setFocus(inputId:string){
		if(document.getElementById(inputId) != null){
			document.getElementById(inputId).focus();
		}
	}

	resetDisplayArrays(){
		this.selectedClients = this.allClients;
		this.selectedServices = this.allServices;
		this.selectedClasses = this.allClasses;
		this.selectedProjects = this.allProjects;
	}

	refreshTable(tableRef) {// Needed in certain cases when the table doesn't refresh
		tableRef.renderRows();
	}

	refreshCell(index: number, day: number, value, tableRef) {// When the cell is being changed this method is called to update totals and refresh the table
		let val = 0
		if (value.target.value != "") {
			val = parseFloat(value.target.value);
		}
		this.timesheetTableData[index]['weekHour'][day]['timeHour'] = val;
		this.timesheetTableData[index]['totalHours'] = Math.round(parseFloat(this.getTotalWeek(index)) * 100) / 100; // Because the total row is counted
		this.refreshTable(tableRef);
	}

	loadTimesheetRowDialog(index: number, tableRef) {// Opens the TimesheetDialogComponent
		const dialogRef = this.dialog.open(TimesheetDialogComponent, {
			width: '585px',
			height: 'auto',
			data: this.timesheetTableData[index]
		});
		dialogRef.afterClosed().subscribe(result => {// Check if the entry is to be deleted
			if (result !== undefined) {
				if (result['delete']){
					this.deleteTimesheetRow(index, tableRef);
				}
				else if(result['submit']){
					this.submitTimesheet()
				}
			}
		});
	}

	validateHours(value) {
		let val = 0
		if (value.target.value != "") {
			val = parseFloat(value.target.value);
		}
		if (val < 0 || val > 24) {

			this.snackBar.open("Invalid Number of Hours", "", {
				duration: 3000,
				panelClass: ["snackbar-error"]
			 });
			value.target.focus();
		}
	}

	deleteTimesheetRow(index: number, tableRef) {
		const dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
			width: '585px',
			height: 'auto'
		});
		this.isTimesheetLoading = true;
		dialogRef.afterClosed().subscribe(result => {// Check if the entry is to be deleted
			if (result !== undefined) {
				if (result['toDelete']) {
					if (this.timesheetTableData[index]['isLoaded']) {// If the row to be deleted exists in the db, delete from the db, else remove the row entry
						this._timesheetService.deleteTimesheetRow(this.timesheetTableData[index]).subscribe(
							data => {
								this.snackBar.open("Row Deleted Successfully", "", { duration: 2000 });
								this.timesheetTableData.splice(index, 1); //visually represent deleted record without making sql call
								if(this.timesheetTableData.length == 0){
									this.addNewTimesheetRow(tableRef);
								}
								else{
									this.refreshTable(tableRef);
								}
							},
							err => console.error(err)
						)
					} else {// remove the row but not from db because it didn't exist
						this.timesheetTableData = this.timesheetTableData.filter(item => item !== this.timesheetTableData[index]); // removes row to be deleted that isn't in the db
						if (this.timesheetTableData.length == 0) {
							this.addNewTimesheetRow(tableRef);
						}
					}
				}
			}
		});
		this.isTimesheetLoading = false;
	}

	addNewTimesheetRow(tableRef) {
		this.timesheetTableData.push(// Pushses object with least ammount of data to populate the table with data
			{ id: this.newRowCounter, client: "", workClass: "", project: "", service: "", approvalStatusId: 0, isLoaded: false, totalHours: 0, weekHour: [{ timeHour: 0 }, { timeHour: 0 }, { timeHour: 0 }, { timeHour: 0 }, { timeHour: 0 }, { timeHour: 0 }, { timeHour: 0 }] }
		);
		this.newRowCounter += 1;
		this.refreshTable(tableRef);
	}

	getTotalCollumn(day: number) {
		if (day == 10) {// 10 means the far right column of the totals, needs to be slightely different to get to the data
			return this.timesheetTableData.map(t => t['totalHours']).reduce((acc, value) => acc + value, 0);// Sums the 'total' column
		} else {
			return this.timesheetTableData.map(t => t['weekHour'][day]['timeHour']).reduce((acc, value) => acc + value, 0);// sums the given days column
		}
	}

	getTotalWeek(index: number) {// Gets the total of the weeks hours worked for the total cell of the row
		return this.timesheetTableData[index]['weekHour'].reduce((acc, value) => acc + value['timeHour'], 0);
	}

	clone(obj) {
		if (null == obj || "object" != typeof obj) return obj;
		var copy = obj.constructor();
		for (var attr in obj) {
			if (obj.hasOwnProperty(attr)) copy[attr] = obj[attr];
		}
		return copy;
	}

	processWeekData(weekData, copy?: boolean) { // Assumes they come in date order, might want to add checking
		this.timesheetTableData = []; // Clear data table for new data
		let total = 0;
		let weekRow = {};
		if (weekData !== null) {// All of this is needed because the C# backend is weird and needs the data in a certain way
			weekData.forEach(row => {
				weekRow = {};
				total = 0;
				weekRow['weekHour'] = row['weekHour'];
				if (!copy) {// If the row is not being copied from a different week
					row.weekHour.forEach(day => {
						total += day.timeHour;
					});
					weekRow['totalHours'] = total;
					weekRow['isLoaded'] = true;
					weekRow['id'] = row['id'];
					weekRow['completed'] = row['statusDetail']['completed'];
					weekRow['planned'] = row['statusDetail']['planned'];
					weekRow['issues'] = row['statusDetail']['issues'];
					weekRow['resolution'] = row['statusDetail']['resolution'];
					weekRow['approvalStatusId'] = row['approvalStatusId'];
					weekRow['endPeriod'] = moment(new Date(this.timesheetDate.toDate()).setDate(this.timesheetDate.toDate().getDate() + (this.tenantStartDayConfig + 7 - this.timesheetDate.toDate().getDay() - 1) % 7)).format("MM/DD/YYYY");
					weekRow['weekId'] = row['weekId'];
				} else {// Row is being copied froms a different week
					if (!this.saveHours) {
						for (let day of row['weekHour']) {
							day.timeHour = 0;
						}
					}
					row.weekHour.forEach(day => {
						total += day.timeHour;
					});
					weekRow['totalHours'] = total;
					weekRow['id'] = this.newRowCounter;
					weekRow['isLoaded'] = false;
					this.newRowCounter += 1
				}
				//needed for easy sending post of the data
				//will be better when node backend rewrite
				weekRow['client'] = row['clientId'];
				weekRow['project'] = row['projectId'];
				weekRow['workClass'] = row['workClassId'];
				weekRow['service'] = row['serviceId'];
				this.timesheetTableData.push(weekRow);
			});

			//Keeps previous data before the copy
			if(copy){
				for(var i = 0; i < this.prevTimeSheetTableData.length; i++){
					if(!(this.prevTimeSheetTableData[i].client == "" && this.prevTimeSheetTableData[i].totalHours === 0)){ //if it is not an empty row
						this.timesheetTableData.push(this.prevTimeSheetTableData[i]);
					}
				}
			}
		}
		if (this.timesheetTableData.length == 0) {// Add an empty entry if the table is empty
			this.timesheetTableData.push(
				{ id: this.newRowCounter, client: "", workClass: "", project: "", service: "", approvalStatusId: 0, isLoaded: false, totalHours: 0, weekHour: [{ timeHour: 0 }, { timeHour: 0 }, { timeHour: 0 }, { timeHour: 0 }, { timeHour: 0 }, { timeHour: 0 }, { timeHour: 0 }] }
			);
			this.newRowCounter += 1;
		}
	}

	loadCopyHoursDialogComponent(date) {
		const dialogRef = this.dialog.open(CopyHoursDialogComponent, {
			width: '585px',
			height: 'auto',
		});
		dialogRef.afterClosed().subscribe(result => {
			if (result == true) {
				this.saveHours = true;
				this.getWeekData(date);
			} else {
				this.saveHours = false;
				this.getWeekData(date);
			}
		});
	}

	getWeekData(copy?: Date, noDisplayMessage?: boolean) {// gets the timesheet data for the week
		if(copy){
			this.prevTimeSheetTableData = this.clone(this.timesheetTableData);
		}
		this.timesheetTableData = [];
		this.isTimesheetLoading = true;// Used to disable buttons so no spam clicking through data till it is loaded
		let date = copy ? moment(copy) : this.timesheetDate;// if copy use the copy weeks date, else use the timesheetdate
		date = moment(new Date(date.toDate()).setDate(date.toDate().getDate() + (this.tenantStartDayConfig + 7 - date.toDate().getDay() - 1) % 7));

		this._timesheetService.getTimesheetWeek(date).subscribe(
			(data: Object[]) => {
				if (copy) {
					data = data.filter(row => this.allProjects.find(project => project['id'] == row['projectId'] && project['isActive'] == true) != undefined);
					this.processWeekData(data, true);
				} else {
					this.processWeekData(data, false);
				}
				this.isTimesheetLoading = false;// The data is loaded, enable buttons
				if(this.clientsLoaded == true) {
					//sort timesheetTableData once data is validated.
					this.timesheetTableData = this.timesheetPipe.transform(this.timesheetTableData, this.allClients, this.allProjects, this.allClasses, this.allServices);
					//re-render table for updated sort
					this.refreshTable(this.timesheetTable);
				}
			},
			err => console.error(err)
		);
	}

	getClientClassServiceData() {
		this._timesheetService.getClientServiceClassData().subscribe(
			data => {
				this.clients = data['clientProjectList'];
				this.services = data['serviceList'];
				this.classes = data['workClassList'];
				if(this.classes != null)
					this.classes.sort((a, b) => { return (a['name'] > b['name']) ? 1 : ((b['name'] > a['name']) ? -1 : 0) });// sorts in alphabetical order becuase not done on backend for some reason
				if(this.services != null)
					this.services.sort((a, b) => { return (a['name'] > b['name']) ? 1 : ((b['name'] > a['name']) ? -1 : 0) });// sorts in alphabetical order becuase not done on backend for some reason
				if(this.clients != null)
					this.clients.forEach(client => {
						if (client['projects'].length > 0) {// adds all of the projects for a client to a single array
							this.projects.push(...client['projects']);// Spread syntax ...client['projects'] expands the array to be pushed
						}
					});
			},
			err => console.error(err)
		);
	}

	getAllClientClassServiceData() {
		this._timesheetService.getClientServiceClassData(false).subscribe(
			data => {
				this.allClients = data['clientProjectList'];
				this.selectedClients = this.allClients;
				this.allServices = data['serviceList'];
				this.selectedServices = this.allServices;
				this.allClasses = data['workClassList'];
				this.selectedClasses = this.allClasses;
				if(this.allClients != null)
					this.allClients.forEach(client => {
						if (client['projects'].length > 0) {// adds all of the projects for a client to a single array
							this.allProjects.push(...client['projects']);// Spread syntax ...client['projects'] expands the array to be pushed
						}
					});
					this.selectedProjects = this.allProjects;
				this.clientsLoaded = true;
				if(this.isTimesheetLoading == false) {
					//sort timesheetTableData once data is validated.
					this.timesheetTableData = this.timesheetPipe.transform(this.timesheetTableData, this.allClients, this.allProjects, this.allClasses, this.allServices);
					//re-render table for updated sort
					this.refreshTable(this.timesheetTable);
				}
			},
			err => console.error(err)
		);
	}

	checkInColumn(activeColumns, currentColumn, type){
		if(activeColumns != undefined){
			if(type == "id"){
				return !activeColumns.some(e => e.id === currentColumn.id);
			}
			else if(type == "clientId"){
				return !activeColumns.some(e => e.clientId === currentColumn.clientId);
			}
		}
		return false;
	}

	increaseDate() {
		this.timesheetDate.add(1, "week");
		this.formatDateRange();
		this.buildDateDisplayStrings();
		this.getWeekData();// reload data for the new week
	}

	decreaseDate() {
		this.timesheetDate.subtract(1, "week");
		this.formatDateRange();
		this.buildDateDisplayStrings();
		this.getWeekData();// reload data for the new week
	}

	setDate(selectedDate: Date) {
		this.timesheetDate = moment(selectedDate);
		this.formatDateRange();
		this.buildDateDisplayStrings();
		this.getWeekData();
		// reload data for the new week
	}

	formatDateRange() {// uses moment date format to accurately change the display format of the date range
		var selection = 6 - this.tenantStartDayValue;
		let day1 = moment(new Date(this.timesheetDate.toDate()).setDate(this.timesheetDate.toDate().getDate() - (this.timesheetDate.toDate().getDay() + selection) % 7));
		let day2 = moment(new Date(this.timesheetDate.toDate()).setDate(this.timesheetDate.toDate().getDate() + (this.tenantStartDayConfig + 7 - this.timesheetDate.toDate().getDay() - 1) % 7));

		let sameMonth = day1.toDate().getMonth() == day2.toDate().getMonth();
		let sameYear = day1.toDate().getFullYear() == day2.toDate().getFullYear();
		let start = "MMM D - ";
		let end = "D, YYYY";
		if (!sameMonth) {
			end = "MMM D, YYYY";
		}
		if (!sameYear) {
			start = "MMM D, YYYY - ";
		}

		this.dateRange = moment(day1).format(start) + moment(day2).format(end);
	};

	// Constructs an array of strings with the formatted dates for each day of the week
	buildDateDisplayStrings()	{
		// Re-initialize the date display string array and initialize a temporary Moment date variable
		this.dateDisplayStrings = [];
		let tempMoment = moment(this.timesheetDate).startOf("isoWeek").add(this.tenantStartDayValue - 1, 'days')
		// Generate strings for each day of the currently-selected week
		for(var index = 0; index < 7; index++)	{
			this.dateDisplayStrings.push(tempMoment.add(1, 'days').format("ddd"));
		}
	}

	processDataForSubmit() {
		let returnObject = {};

		var selection = 6 - this.tenantStartDayValue;
		let tempDate = moment(new Date(this.timesheetDate.toDate()).setDate(this.timesheetDate.toDate().getDate() - (this.timesheetDate.toDate().getDay() + selection) % 7));
		let tempDateObject = {}
		returnObject['validSubmit'] = true;
		returnObject['day_of_week'] = [];
		for (let i = 0; i < 7; i++) {
			tempDateObject = {};
			tempDateObject['day'] = tempDate.date();
			tempDateObject['month'] = tempDate.month() + 1;// Month is 0 based but needs to start at 1
			tempDateObject['year'] = tempDate.year();
			returnObject['day_of_week'].push(tempDateObject);
			tempDate.add(1, 'day');
		}
		returnObject['grand_total'] = 0;
		returnObject['exist_row'] = [];
		returnObject['new_row'] = [];
		let returnWeekHours = {};
		let returnWeekDayIds = {};		

		this.timesheetTableData.forEach(row => {
			returnWeekHours = {};
			returnWeekDayIds = {};
			let rowCopy = Object.assign({}, row);
			rowCopy['weekHour'].forEach((day, index) => {
				returnWeekHours[index] = day['timeHour'];
				if (rowCopy['isLoaded'])
					returnWeekDayIds[index] = day['id'];
			});
			delete rowCopy['weekHour']
			rowCopy['week_hour'] = returnWeekHours;
			if (rowCopy['isLoaded'])
				rowCopy['week_ids'] = returnWeekDayIds;

			if (rowCopy['isLoaded']) {// If the week is from the db, add to exist_row array
				if(rowCopy['client'] != "" && rowCopy['project'] != "" && rowCopy['service'] != "" && rowCopy['workClass'] != ""){
					returnObject['exist_row'].push(rowCopy);
				}
				else{
					returnObject['validSubmit'] = false;
				}
			} else {
				if (rowCopy['client'] != "" && rowCopy['project'] != "" && rowCopy['service'] != "" && rowCopy['workClass'] != "") {
					returnObject['new_row'].push(rowCopy);
				} else {
					//If this is triggered it is due to an entry not filled out
					returnObject['validSubmit'] = false;
				}
			}
			returnObject['grand_total'] += rowCopy['totalHours'];
		});
		return returnObject;
	}

	submitTimesheet() {
		this.isTimesheetSubmitting = true;
		var timeSheets = this.processDataForSubmit();
		this.timesheetTableData.forEach(x => {
			if (x['completed'] == "" || x['completed'] == " " || x['completed'] == undefined || x['client'] == "" ||
					x['workClass'] == "" || x['project'] == "" || x['service'] == "") {
				timeSheets['validSubmit'] = false;
			} else {
				//extra logic to ensure valid submit is false if any row doesn't contain needed data
				if(timeSheets['validSubmit'] == false) {
					timeSheets['validSubmit'] = false;
				} else {
					timeSheets['validSubmit'] = true;
				}
			}
		})

		if(timeSheets['validSubmit']){
			//sort timesheetTableData once data is validated.
			this.timesheetTableData = this.timesheetPipe.transform(this.timesheetTableData, this.allClients, this.allProjects, this.allClasses, this.allServices);
			
			//tempStorage is used for if something is wrong with submittance
			//so that you don't lose all your data
			var tempStorage = JSON.stringify(this.timesheetTableData);
			this.timesheetTableData = [];
			this._timesheetService.setTimesheetWeek(timeSheets).subscribe(
				data => {
					var updatedData = [];
					updatedData.push.apply(updatedData, data);

					this.isTimesheetSubmitting = false;

					this.snackBar.open("Timesheet Submitted", "", {
						duration: 3000,
						panelClass: ["snackbar-success"]
					});

					this.timesheetTableData = JSON.parse(tempStorage); // in favor of making a sql request and increasing the wait time
					for(let i = 0; i < this.timesheetTableData.length; i++){ // otherwise will assume that it was not added to db
						if(!this.timesheetTableData[i]["isLoaded"]){
							var loadedWeekItem = updatedData.find(c => this.timesheetTableData[i]['id'] == c['tempId']);
							this.timesheetTableData[i].id = loadedWeekItem['id'];
							for(var j = 0; j < this.timesheetTableData[i]["weekHour"].length; j++){
								this.timesheetTableData[i]["weekHour"][j].id = loadedWeekItem["week_ids"][j];
							}
						}
						this.timesheetTableData[i]["isLoaded"] = true;
					}
				},
				err => {
					this.isTimesheetSubmitting = false;
					this.timesheetTableData = JSON.parse(tempStorage);

					this.snackBar.open("Error Submitting Timesheet", "", {
						duration: 4000,
						panelClass: ["snackbar-error"]
					 })
					console.error(err);
				}
			);
		}
		else {
			this.isTimesheetSubmitting = false;
			
			this.snackBar.open("Entry not complete, fill completely or delete", "", {
				duration: 4000,
				panelClass: ["snackbar-incomplete"]
			});
		}
	}
}
