import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { Observable, throwError } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor {

  constructor(private authService: AuthService) { }

  intercept(request: HttpRequest<any>, next?: HttpHandler): Observable<HttpEvent<any>> {
    return this.authService.getUserJwt().pipe(
      mergeMap(idTokenClaims => {
        const tokenRequest = request.clone({
          setHeaders: { Authorization: `Bearer ${idTokenClaims.__raw}` }
        });
        return next.handle(tokenRequest);
      }),
      catchError(err => throwError(err))
    );
  }
}