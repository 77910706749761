import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { FormControl, Validators } from '@angular/forms';

@Component({
    selector: 'app-management-expenses-category-dialog',
    templateUrl: './management-expenses-category-dialog.component.html',
    styleUrls: ['./management-expenses-category-dialog.component.scss']
})
export class ManagementExpensesCategoryDialogComponent implements OnInit {

    categoryName: FormControl = new FormControl({ value: this.expenseData['name'], disabled: false }, [Validators.required]);
    isActive: FormControl = new FormControl(this.expenseData['isActive'] == null ? true : this.expenseData['isActive']);
    error: String;

    constructor(public dialogRef: MatDialogRef<ManagementExpensesCategoryDialogComponent>,
        public snackbar: MatSnackBar,
        @Inject(MAT_DIALOG_DATA) public expenseData) { }

    ngOnInit() {

    }

    closeDialog() {
        this.dialogRef.close();
    }

    saveAndCloseDialog() {
        if (!this.categoryName.invalid) {
            if (this.expenseData['newData']) {
                this.expenseData['isActive'] = this.isActive.value;
            }
            this.expenseData['name'] = this.categoryName.value;
            this.dialogRef.close({ submit: true, data: this.expenseData });
        } else	{
            this.error = 'You must enter a Category Name';
        }
    }
}
