import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth/auth.service';
import { SetttingsService } from '../api/setttings.service';
import { ReportService } from '../api/report.service';
const async = require("async");

@Component({
  selector: 'app-report-container',
  templateUrl: './report-container.component.html',
  styleUrls: ['./report-container.component.scss']
})
export class ReportContainerComponent implements OnInit {
	userPermissions: string[];
	defaultReportValue: string;
	tenantFeatures: string[];

	clients;
	senderClientsProjects;
	senderUsers;

	constructor(
		public authService: AuthService,
		private _settingsService: SetttingsService,
	) {
        this.userPermissions = [];
		this.defaultReportValue = "";
		this.tenantFeatures = [];

		this.clients = [];
	 }

	ngOnInit() {
		this.getPermissions();
	}

	/* grab the current users permissions in order to determine what report options to show the user. */
	getPermissions() {
		if(this.userPermissions.length == 0){
			this.authService.getUserPermissions()
				.subscribe((userPermissions) => {
					this.userPermissions = userPermissions
					this.getDefaultReport();
					this.getSharedChildComponentData();
				})
			}
		if(this.tenantFeatures.length == 0){
			this.tenantFeatures = this.authService.getTenantFeatures();
		}
	}

	/* determine the default value for the group containing all report options. */
	getDefaultReport() {
		if(this.userPermissions.includes('read:time-entries') || this.userPermissions.includes('administer-tenant:administration') && (this.tenantFeatures.includes("1"))){
			this.defaultReportValue = 'status';
		}else if(this.userPermissions.includes('read:expenses') || this.userPermissions.includes('administer-tenant:administration') && (this.tenantFeatures.includes("5"))){
			this.defaultReportValue = 'expenses';
		}else if(this.userPermissions.includes('read:time-off') || this.userPermissions.includes('administer-tenant:administration') && (this.tenantFeatures.includes("4"))){
			this.defaultReportValue = 'pto-usage';
		}else if((this.userPermissions.includes('approve:time-off') || this.userPermissions.includes('administer-tenant:administration') || this.userPermissions.includes('manage:time-off'))
					&& (this.tenantFeatures.includes("4"))){
			this.defaultReportValue = 'timeoff-usage';
		}
	}

	getSharedChildComponentData(){
		if((this.userPermissions.includes('read:time-entries') || this.userPermissions.includes('administer-tenant:administration') && (this.tenantFeatures.includes("1"))) ||
		(this.userPermissions.includes('read:expenses') || this.userPermissions.includes('administer-tenant:administration') && (this.tenantFeatures.includes("5"))))
			this.getClients();

		if((this.userPermissions.includes('read:expenses') || this.userPermissions.includes('administer-tenant:administration') && (this.tenantFeatures.includes("5"))) ||
		(this.userPermissions.includes('read:time-off') || this.userPermissions.includes('administer-tenant:administration') && (this.tenantFeatures.includes("4"))) &&
		(this.userPermissions.includes('manage-application-settings:administration')))
			this.getUsers();
	}

	getClients() {
        let result = [];
        this._settingsService.getClients().subscribe(data => {
            result = Object.keys(data).map(function (key) {
                return [Number(key), data[key]];
            });
            result = result.sort(function(a, b) {
                if (a[1].name < b[1].name) return -1;
                if (a[1].name > b[1].name) return 1;
                return 0;
            });

            for (let client of result) this.clients.push(client['1']);

            this.getProjects();
        });
    }

    getProjects() {
        this._settingsService.getProjectsByClientId(this.clients, true).subscribe(data => {
            async.forEach(this.clients,(client,innerCallback)=>{
				if (data[client['id']].length > 0) client['projects'] = data[client['id']];
				innerCallback(null);
			},()=>{
				this.senderClientsProjects = this.clients;
			});
        });
    }


    getUsers() {
        this._settingsService.getUsers(true).subscribe((data:[]) => {
            data = data.sort(function(a, b) {
                if (a['fullName'] < b['fullName']) {
                  return -1;
                }
                if (a['fullName'] > b['fullName']) {
                  return 1;
                }
                return 0;
            });
			this.senderUsers = data;
        });
    }
}
