import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalService } from '../api/globalVars';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    withCredentials: true
};

@Injectable({
  providedIn: 'root'
})
export class PortfolioService {
    baseUrlPath = this.globalVar.retrievePath();

    constructor(public globalVar: GlobalService, private _http: HttpClient) { }

    getAllPortfolios() {
        const geturl = this.baseUrlPath + 'portfolio';
        return this._http.get(geturl, httpOptions);
    }

    putPortfolio(portfolio) {
        const puturl = this.baseUrlPath + 'portfolio';
        return this._http.put(puturl, portfolio, httpOptions);
    }

    deletePortfolio(portfolioId) {
        const deleteurl = this.baseUrlPath + 'portfolio?portfolioId=' + portfolioId;
        return this._http.delete(deleteurl, httpOptions);
    }

    getAllPortfolioRelations(portfolioId) {
        const geturl = this.baseUrlPath + 'portfoliorelation?portfolioId=' + portfolioId;
        return this._http.get(geturl, httpOptions);
    }

    getAllPortfolioJoinRelations(portfolioId){
        const geturl = this.baseUrlPath + 'portfoliorelation?portfolioId=' + portfolioId + "&relationJoinFlag=1";
        return this._http.get(geturl, httpOptions);
    }

    getPortfolioDetails(portfolioId) {
        const geturl = this.baseUrlPath + 'portfoliorelation?portfolioId=' + portfolioId + '&retrieveInfo=1';
        return this._http.get(geturl, httpOptions);
    }

    putPortfolioRelation(portfolio) {
        const puturl = this.baseUrlPath + 'portfoliorelation';
        return this._http.put(puturl, portfolio, httpOptions);
    }

    putPortfolioJoinRelation(portfolioJoin) {
        const puturl = this.baseUrlPath + 'portfoliorelation?relationJoinFlag=1';
        return this._http.put(puturl, portfolioJoin, httpOptions);
    }

    deletePortfolioRelations(idList) {
        const puturl = this.baseUrlPath + 'portfoliorelation?deleteFlag=1';
        return this._http.put(puturl, idList, httpOptions);
    }

    deletePortfolioJoinRelations(idList){
        const puturl = this.baseUrlPath + 'portfoliorelation?deleteFlag=1&relationJoinFlag=1';
        return this._http.put(puturl, idList, httpOptions);
    }
}