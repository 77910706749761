import { Component, OnInit, OnChanges, Input} from '@angular/core';
import { SetttingsService } from '../../api/setttings.service';
import { MatDialog, MatSnackBar } from '@angular/material';
import { FormControl } from '@angular/forms';
import { ReportService } from '../../api/report.service';
import { DomSanitizer } from '@angular/platform-browser';
import { SimpleChanges } from '@angular/core';
import * as moment from 'moment';
import {ViewSDKClient} from '../view-sdk.service'
var cloneDeep = require('lodash.clonedeep');

@Component({
    selector: 'app-status-report',
    templateUrl: './status-report.component.html',
    styleUrls: ['./status-report.component.scss']
})
export class StatusReportComponent implements OnInit, OnChanges {

    projects: any;
    reportData = Object();
    billable = 1;
    status = 'None';
    display: any;
    title: any;
    clients;
    url: any;

	projectId: FormControl = new FormControl(this.reportData['projectId']);
    startDate: FormControl = new FormControl(this.reportData['start']);
    endDate: FormControl = new FormControl(this.reportData['end']);
    statusDescription: FormControl = new FormControl();

	@Input() senderClientsProjects = [];
	clientsForFilter: any[];

    isProjectDataLoading: boolean = true;;
	isReportLoading: boolean = false;

    constructor(
		private _reportService: ReportService,
        public snackBar: MatSnackBar,private viewSDKClient: ViewSDKClient) { }


    dateRangeFilter = (d: Date): boolean => {
        if (this.startDate.value) {
            let isSameOrAfter = moment(d).isSameOrAfter(moment(this.startDate.value));
            return isSameOrAfter;
        } else {
            return false;
        }
    }

    ngOnInit() {

	}

	ngOnChanges(changes: SimpleChanges) {
		if(changes.senderClientsProjects != undefined){
			this.clientsForFilter = this.senderClientsProjects;
			this.clients = this.senderClientsProjects;
			this.isProjectDataLoading = false;
		}
	}

	onKeyProjects(value) {
		this.clients = this.filterProjects(value);
	}

	filterProjects(value: string) {
	  let filter = value.toLowerCase();

      let filteredClientsAndProjects = this.clientsForFilter.filter(option => option['name'].toLowerCase().includes(filter));
      for(var i = 0; i < this.clientsForFilter.length; i++){
        if(filteredClientsAndProjects.find(option => option['id'] == this.clientsForFilter[i]['id']) == undefined){
            if(this.clientsForFilter[i]['projects'] != null){
                let tempClient = cloneDeep(this.clientsForFilter[i]);
                tempClient['projects'] = tempClient['projects'].filter(project => project['name'].toLowerCase().includes(filter))
                if(tempClient['projects'].length > 0){
                    filteredClientsAndProjects.push(tempClient);   
                }
            }
        }
      }
	  return filteredClientsAndProjects.sort((a, b) => { return (a['name'] > b['name']) ? 1 : ((b['name'] > a['name']) ? -1 : 0); });
	}

	//Sets focus of the combobox inputs on click
	setFocus(inputId:string){
		if(document.getElementById(inputId) != null){
			document.getElementById(inputId).focus();
		}
	}

    toggleBillable(event) {
        this.billable = event.value;
    }

    generateReport() {
        if (this.projectId.value != null && this.startDate.value != null) {
            this.reportData['projectId'] = this.projectId.value;
            this.reportData['start'] = this.startDate.value;
            this.reportData['end'] = this.endDate.value == null ? new Date() : this.endDate.value;
            this.reportData['isBillable'] = this.billable;
            if (this.status != 'None') {
                this.reportData[this.status] = this.statusDescription.value;
            }
            this.isReportLoading = true;
            this._reportService.generateStatusReport(this.reportData).subscribe(data => {
                this.isReportLoading = false;
                var pdfData = data['response'];
                this.title = data['name'];
                this.url = data['response'];
                this.display = true;
                const adobeDCView = new ViewSDKClient();
                const previewConfig = {
                    embedMode: "FULL_WINDOW",
                    showDownloadPDF: false,
                    showPrintPDF: false
                 }
                adobeDCView.ready().then(() => {
                    adobeDCView.previewFile('pdf-div3',previewConfig, pdfData, this.title)
                        .then(() => {

                        })
                        .catch(error => {
                            console.error('Error loading PDF:', error);
                        });
                });
    
            }, err => {
                this.isReportLoading = false;
                this.snackBar.open('No Report Data Found', '', { duration: 2000 });
            });
        } else {
            this.isReportLoading = false;
            this.snackBar.open('Not all fields filled', '', { duration: 2000 });
        }
    }
    
    downloadReport() {
        let reportUrl: string = this.url;
        var a = document.createElement('a');
        a.href = reportUrl;
        a.setAttribute('download', this.title);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    toggleStatus(event) {
        this.reportData[this.status] = null;
        this.status = event.value;
        this.statusDescription.reset();
    }
}
