import { Component, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { SetttingsService } from '../../api/setttings.service';
import { MatSelect } from '../../../../node_modules/@angular/material';

@Component({
    selector: 'app-management-dashboard',
    templateUrl: './management-dashboard.component.html',
    styleUrls: ['./management-dashboard.component.scss']
})
export class ManagementDashboardComponent implements OnInit {
    // Nick was here
    horizontalBarChartOptions: object; // Total week bar options
    horizontalBarChartData: object; // Total week bar data

    pieChartOptions: object;
    pieChartData: object;

    timeViewOptions: object; // Options for the wonth/week view chart
    timeViewData: object; // Data for the wonth/week view chart

    projects = []; // All projects
    clients = []; // All Clients
    peopleMonthData = []; // People who have worked on the project and the time they have worked by month
    peopleWeekData = []; // People who have worked on the project and the time they have worked by week

    selectedDate: moment.Moment = moment().startOf('isoWeek');
    dateRange = '';

    @ViewChild('timeView') timeView; // reference to the togglegroup for if it is displaying month or week view

    constructor(private _settingsService: SetttingsService) { }

    ngOnInit() {
        this.formatDateRange();
        this.getProjectData();
    }

    reloadGraphs(projectId) {
        this.getPeopleWeekData(projectId);
    }

    hashCode(string) {
        var hash = 0, i, chr;
        if (string.length === 0) return hash;
        for (i = 0; i < string.length; i++) {
            chr = string.charCodeAt(i);
            hash = ((hash << 5) - hash) + chr;
            hash |= 0; // Convert to 32bit integer
        }
        return hash;
    }

    seedRandom(seed) {
        var x = Math.cos(Math.sin(seed)) * 10000;
        return x - Math.floor(x);
    }

    dynamicColors(string) {// Could break if the name of employee isn't 3 chars or longer
        var r = Math.floor(this.seedRandom(this.hashCode(string.slice())) * 255);
        var g = Math.floor(this.seedRandom(this.hashCode(string.slice(0, string.length - 1))) * 255);
        var b = Math.floor(this.seedRandom(this.hashCode(string.slice(0, string.length - 2))) * 255);
        return 'rgb(' + r + ',' + g + ',' + b + ')';
    }

    getPeopleWeekData(projectId) {
        this._settingsService.getProjectPeopleData(projectId, 1).subscribe(
            data => {
                this.peopleMonthData = []; // reset the data array
                this.peopleMonthData.push.apply(this.peopleMonthData, data); // add the data to the array
                let dataObjectArray = Object.keys(data).map(i => data[i]); // Needed tomake TS know that the object is an object array
                this.initHourPie(dataObjectArray);
                var sum = 0; // Gets the total hours worked by employees on the project
                for (var i = 0; i < dataObjectArray.length; i++) {
                    for (var key in dataObjectArray[i].HoursWorked) {
                        sum += dataObjectArray[i].HoursWorked[key];
                    }
                }

                this.initTotalBar(sum, this.findProject(projectId)['totalHours']); // Init the total bar with the sum and the current projects projected hours
                this.initWeekBar(this.peopleMonthData, 'Month');
            }, err => console.error(err)
        );
        this._settingsService.getProjectPeopleData(projectId, 2).subscribe(
            data => {
                this.peopleWeekData = []; // reset the data array
                this.peopleWeekData.push.apply(this.peopleWeekData, data); // add the data to the array
            }, err => console.error(err)
        );
    }

    findProject(projectId) {// returns the project object with the same Id as passed in
        return this.projects.find(p => p['id'] == projectId);
    }

    getProjectData() {// Gets all of the projects to populate the selectize
        this._settingsService.getProjects().subscribe(
            data => {
                this.projects = [];
                this.projects.push.apply(this.projects, data);
            }, err => console.error(err)
        );
    }

    processPeoplePie(people): object {
        let colordata = []; // Color of the pie slices
        let datapoints = []; // Data for the pie slices
        let names = []; // Labels forthe data
        people.forEach((person) => {// create a datapoint for each person to addtothe pie chart
            let sum = 0;
            names.push(person['name']); // Add name to label array
            for (let key in person['hoursWorked']) {
                sum += person['hoursWorked'][key]; // Sums all of the hours worked over liftime of the project
            }
            datapoints.push(sum); // Add sum to the datapoints array
            colordata.push(this.dynamicColors(person['name'])); // Add the color tothe color array
        });
        return {
            labels: names,
            datasets: [{
                borderWidth: 0,
                backgroundColor: colordata,
                data: datapoints
            }]
        };
    }

    initHourPie(people: object[]): void {
        this.pieChartData = this.processPeoplePie(people);
        this.pieChartOptions = {
            legend: {
                display: true,
                position: 'right',
                reverse: true
            },
            hover: {
                mode: null
            },
            tooltips: {// STYLING for the tooltips
                backgroundColor: '#6C7A89',
                titleFontSize: 16,
                titleFontColor: '#52B3D9',
                bodyFontColor: '#FFF',
                bodyFontSize: 14,
                displayColors: false,
                callbacks: {
                    title: function (tooltipItem, data) {// Title of the tooltip
                        return data.labels[tooltipItem[0].index];
                    },
                    label: function (tooltipItem, data) {// Content of the tooltip
                        if (data.datasets[0].data[tooltipItem.index] != 0) {
                            let label = data.datasets[0].data[tooltipItem.index] + ' Hours';
                            return label;
                        } else {
                            return '';
                        }

                    },
                    afterLabel: function (tooltipItem, data) {// Footer of the tooltip
                        // Adds the percentage to the tooltip
                        let dataset = data['datasets'][0];
                        // This gets the object the data is stored in
                        // Needed because the key is the number of the graph that has been created
                        // There is allways 1 element so this finds the key of that 1 element
                        for (let total in dataset['_meta']) {
                            var percent = Math.round((data.datasets[0].data[tooltipItem.index] / dataset['_meta'][total]['total']) * 100);
                        }
                        return '(' + percent + '%)';
                    }
                }
            },
            responsive: true
        };
    }

    processTotalBar(usedHours: number, allocatedHours: number): object {
        let usedcolor = 'rgba(46,204,113,1)'; // Green color for the used
        let allocatedcolor = 'rgba(108,122,137,.1)'; // Gray color for the allocated
        let overflow = false; // Boolean for if used more than allocated
        let labelFunction;
        if (usedHours - allocatedHours > 0) {// If the project is overbudget on hours
            overflow = true;
            usedcolor = 'rgba(240,52,52,1)'; // Because ofthe way the bars stack, this is now red for the overflow
            allocatedcolor = 'rgba(46,204,113,1)'; // This is now the green color
            usedHours -= allocatedHours; // Flip the hours, needed because of how the chart works
            labelFunction = function (tooltipItem, data) {// How the mouseover label is constructed
                let label = data.datasets[tooltipItem.datasetIndex].label || '';

                if (label == 'Allocated') {
                    label += ': ' + allocatedHours;
                } else {
                    label += ': ' + (data.datasets[0].data[tooltipItem.index] + data.datasets[1].data[tooltipItem.index]);
                }
                return label + ' Hours';
            };
        } else {// The project is within bounds for time
            allocatedHours -= usedHours;
            labelFunction = function (tooltipItem, data) {// How the mouseover label is constructed
                let label = data.datasets[tooltipItem.datasetIndex].label || '';

                if (label == 'Allocated') {
                    label += ': ' + (data.datasets[0].data[tooltipItem.index] + data.datasets[1].data[tooltipItem.index]);
                } else {
                    label += ': ' + data.datasets[0].data[tooltipItem.index];
                }
                return label + ' Hours';
            };
        }
        let datasets = [{
            label: 'Used',
            fill: false,
            backgroundColor: usedcolor,
            borderColor: 'rgba(0,0,0,1)', // black border
            borderWidth: 1,
            data: [usedHours]// add the hours to the graph
        }, {
            label: 'Allocated',
            fill: false,
            backgroundColor: allocatedcolor,
            borderColor: 'rgba(0,0,0,1)', // black border
            borderWidth: 1,
            data: [allocatedHours]// add the hours to the graph
        }];
        if (overflow) {// If there is overflow, revers the datapoints
            datasets.reverse();
        }
        return {
            overflow: overflow,
            allocatedcolor: allocatedcolor,
            usedcolor: usedcolor,
            labelFunction: labelFunction,
            usedHours: usedHours,
            allocatedHours: allocatedHours,
            datasets: datasets
        };
    }

    initTotalBar(usedHours: number, allocatedHours: number): void {
        let chartdata = this.processTotalBar(usedHours, allocatedHours);
        this.horizontalBarChartData = {
            datasets: chartdata['datasets']
        };
        this.horizontalBarChartOptions = {
            legend: {
                display: true,
                position: 'right',
                reverse: true,
                onClick: null
            },
            tooltips: {
                mode: 'index',
                intersect: false,
                itemSort: function (a, b) {// Reverses the tooltips to line up with the legend
                    return b.datasetIndex - a.datasetIndex;
                },
                backgroundColor: '#6C7A89',
                bodyFontColor: '#FFF',
                bodyFontSize: 14,
                displayColors: false,
                callbacks: {
                    label: chartdata['labelFunction']
                },
            },
            responsive: true,
            scales: {
                xAxes: [{
                    gridLines: {
                        display: true,
                        drawBorder: true,
                        drawOnChartArea: false,
                    },
                    barPercentage: .2,
                    stacked: true
                }],
                yAxes: [{
                    gridLines: {
                        display: true,
                        drawBorder: true,
                        drawOnChartArea: false,
                    },
                    stacked: true,
                    ticks: {
                        beginAtZero: true
                    }
                }]
            }
        };



    }

    refreshWeekBar(): void {
        this.formatDateRange();
        this.initWeekBar(this.peopleMonthData, this.timeView.value);
    }

    processWeekBar(people: object[], type: string): object {
        let datasets = [];
        let startDay, endDay;
        let data = {};
        let unit = 'week';
        let mode = 'index';
        let intersect = false;
        let displayColors = true;
        let titleFunction, tickFunction;
        let that = this;
        if (type == 'Week') {
            data['labels'] = [moment(this.selectedDate).subtract(7, 'days'), moment(this.selectedDate).add(1, 'day')];
            startDay = moment(this.selectedDate).subtract(7, 'days'); // Range of dates
            endDay = moment(this.selectedDate).add(1, 'day'); // Range of dates
            unit = 'day';
            titleFunction = function (tooltipItem, data) {// The title of the tooltip Ex. 10/15/1997
                return moment(tooltipItem[0].xLabel).format('MM/DD/YYYY');
            };
            tickFunction = function (value, index, values) {
                return moment(value + ' ' + that.selectedDate.year()).format('ddd, MMM Do');
            };
        } else if (type == 'Month') {
            data['labels'] = [moment(this.selectedDate).startOf('month'), moment(this.selectedDate).endOf('month').add(2, 'week')];
            startDay = moment(this.selectedDate).startOf('month').subtract(1, 'day');
            endDay = moment(this.selectedDate).endOf('month').add(1, 'week');
            mode = 'nearest';
            intersect = true;
            displayColors = false;
            titleFunction = function (tooltipItem, data) {// The title of the tooltip Ex. 06/05 - 06/11, 2017
                return moment(tooltipItem[0].xLabel).startOf('isoWeek').format('MM/DD') + ' - ' + moment(tooltipItem[0].xLabel).format('MM/DD, YYYY');
            };
            tickFunction = function (value, index, values) {
                return moment(value + ' ' + that.selectedDate.year()).startOf('isoWeek').format('MM/DD') + ' - ' + moment(value + ' ' + that.selectedDate.year()).endOf('isoWeek').format('MM/DD');
            };
        }
        people.forEach(person => {
            let datapoint = { fill: false };
            datapoint['label'] = person['name'];
            datapoint['backgroundColor'] = this.dynamicColors(person['name']);
            datapoint['data'] = [];
            data['datalabels'] = {
                align: 'center',
                anchor: 'center'
            };
            for (let key in person['hoursWorked']) {
                if (moment(key).isBetween(startDay, endDay)) {// If the datapoint is inbetween the date range add it to the dataset
                    datapoint['data'].push({ x: key, y: person['hoursWorked'][key] });
                }
            }
            datasets.push(datapoint);
        });
        data['datasets'] = datasets;
        return {
            data: data,
            mode: mode,
            intersect: intersect,
            displayColors: displayColors,
            titleFunction: titleFunction,
            unit: unit,
            tickFunction: tickFunction
        };
    }

    initWeekBar(people: object[], type): void {
        let chartdata = this.processWeekBar(people, type);
        this.timeViewData = chartdata['data'];
        this.timeViewOptions = {
            legend: {
                display: true,
                position: 'right',
                reverse: true
            },
            hover: {
                mode: null
            },
            tooltips: {
                mode: chartdata['mode'],
                intersect: chartdata['intersect'],
                position: 'nearest',
                itemSort: function (a, b) {// Reverse the tooltip to match with the legend
                    return b.datasetIndex - a.datasetIndex;
                },
                backgroundColor: '#6C7A89',
                titleFontSize: 16,
                titleFontColor: '#52B3D9',
                bodyFontColor: '#FFF',
                bodyFontSize: 14,
                displayColors: chartdata['displayColors'],
                callbacks: {
                    title: chartdata['titleFunction'],
                    label: function (tooltipItem, data) {
                        if (tooltipItem.yLabel != 0) {
                            let label = data.datasets[tooltipItem.datasetIndex].label;

                            label += ': ' + tooltipItem.yLabel + ' Hours';
                            return label;
                        } else {
                            return '';
                        }

                    }
                }
            },
            responsive: true,
            scales: {
                xAxes: [{
                    type: 'time',
                    time: {
                        round: chartdata['unit'],
                        unit: chartdata['unit']
                    },
                    gridLines: {
                        offsetGridLines: true
                    },
                    stacked: false,
                    distrobution: 'linear',
                    ticks: {
                        // change the x axis labels to a better format
                        callback: chartdata['tickFunction']
                    }
                }],
                yAxes: [{
                    ticks: {
                        beginAtZero: true
                    },
                    stacked: false
                }]
            }
        };

    }

    increaseDate() {
        if (this.timeView.value == 'Week') {
            this.selectedDate.add(1, 'week');
            this.initWeekBar(this.peopleWeekData, 'Week');

        } else {
            this.selectedDate.add(1, 'month');
            this.initWeekBar(this.peopleMonthData, 'Month');

        }
        this.formatDateRange();
    }

    decreaseDate() {
        if (this.timeView.value == 'Week') {
            this.selectedDate.subtract(1, 'week');
            this.initWeekBar(this.peopleWeekData, 'Week');
        } else {
            this.selectedDate.subtract(1, 'month').startOf('month');
            this.initWeekBar(this.peopleMonthData, 'Month');
        }
        this.formatDateRange();
    }

    setDate(selectedDate: Date) {
        this.selectedDate = moment(selectedDate);
        this.formatDateRange();
        if (this.timeView.value == 'Week') {
            this.initWeekBar(this.peopleWeekData, 'Week');
        } else {
            this.initWeekBar(this.peopleMonthData, 'Month');
        }
    }

    formatDateRange() {// uses moment date format to accurately cahnge the display format of the date range
        let day1 = this.selectedDate.startOf('isoWeek').toDate();
        let day2 = this.selectedDate.endOf('isoWeek').toDate();
        let sameMonth = day1.getMonth() == day2.getMonth();
        let sameYear = day1.getFullYear() == day2.getFullYear();
        let start = 'MMM D - ';
        let end = 'D, YYYY';
        if (this.timeView.value == 'Week') {
            if (!sameMonth) {
                end = 'MMM D, YYYY';
            }
            if (!sameYear) {
                start = 'MMM D, YYYY - ';
            }
            this.dateRange = moment(day1).format(start) + moment(day2).format(end);
        } else {
            end = 'MMM YYYY';
            if (!sameYear) {
                start = 'MMM YYYY - ';
            } else {
                start = 'MMM - ';
            }
            this.dateRange = moment(day1).format(start) + moment(day1).add(1, 'month').format(end);
        }
    }
}
