/// <reference types="@types/googlemaps" />

import { Directive, ElementRef, OnInit, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[google-places-autocomplete]'
})
export class GooglePlacesAutocompleteDirective implements OnInit {
  @Output() onSelect: EventEmitter<any> = new EventEmitter();
  private element: HTMLInputElement;

  constructor(private elRef: ElementRef) {
    // Set element to the HTML element to reference in directive logic
    this.element = elRef.nativeElement;
  }

  ngOnInit() {
    // Establish autocomplete functionality through the Google Maps library
    const autocomplete = new google.maps.places.Autocomplete(this.element);

    // Add event listener to listen for changes in the input
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      // Emit the autocompleted place
      this.onSelect.emit(autocomplete.getPlace());
    });
  }

}