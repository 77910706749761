import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatPaginator } from '@angular/material';
import { ProjectsService } from '../../../api/projects.service';

@Component({
    selector: 'app-project-status-dialog',
    templateUrl: './project-status-dialog.component.html',
    styleUrls: ['./project-status-dialog.component.scss']
})

export class ProjectStatusDialogComponent implements OnInit {

    statusName: FormControl = new FormControl(this.statusData['entry']['statusName']);
    order: FormControl = new FormControl(this.statusData['entry']['order']);
    isResolved: FormControl = new FormControl(this.statusData['entry']['isResolved']);
    statusArray = [];
    valid: boolean = true;
    error: String;

    @ViewChild('nameInput')
    nameElement: any;
    @ViewChild('ordinalInput')
    ordinalElement: any;

    @ViewChild(MatPaginator) paginator;

    constructor(private _projectsService: ProjectsService, public dialogRef: MatDialogRef<ProjectStatusDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public statusData) {
    }

    ngOnInit() {
        this.getProjectStatuses();
    }

    closeDialog() {
        this.dialogRef.close();
    }

    saveAndCloseDialog() {
        if(this.isValid()) {
            this.statusData['entry']['statusName'] = this.statusName.value;
            this.statusData['entry']['order'] = Number(this.order.value);
            this.statusData['entry']['isResolved'] = this.isResolved.value == null ? false : this.isResolved.value;
			this.statusData['entry']['typeId'] = 2;
			this.statusData['entry']['isActive'] = true;
			this.statusData['entry']['categoryId'] = 3;
            this.dialogRef.close({ submit: true, data: this.statusData['entry'] });
        }
    }

    getProjectStatuses() {
        this._projectsService.getStatus().subscribe(
            data => {
                this.statusArray = Object.keys(data).map(i => data[i]);
            }, err => {
                console.error(err);
            }
        );
    }

    isNotDuplicate(){
        let tempTS = this.statusArray.find(status => status['order'] == this.order.value);
        if(tempTS !== undefined){
            if(tempTS.statusId != this.statusData.entry.statusId){ //Allows you to save and close a status that was being edited where the value was unchanged without labeling it as a duplicate of itself
                return false;
            }
        }
    }

    isValid() {
        // Validates form entry
        if(this.statusName.value == null || this.statusName.value == ''){   // Second check prevents empty strings
            this.error = 'Project Name is required';
            this.nameElement.nativeElement.focus();
            return false;
        }
        if(this.order.value == null || this.order.value == ''){
            this.error = 'Project Status Order is required';
            this.ordinalElement.nativeElement.focus();
            return false;
        }
        if(this.order.value < 0 || Number.isInteger(+this.order.value) == false){   // plus sign is necessary in front of "this.ordinal..." so that it is not interpreted as a string
            this.error = 'Project Status Order must be a positive integer';
            this.ordinalElement.nativeElement.focus();
            return false;
        }

        if(this.isNotDuplicate() == false){ //Rejects duplicate ordinal values
            this.error = 'Project Status Order must be unique';
            this.ordinalElement.nativeElement.focus();
            return false;
        }
        else{
            this.valid = true;
            return true;
        }
    }
}
