import { Injectable } from '@angular/core';

// only to be used in projects/kanban component.
// reset in projects.component.ts and kanban-board.component.ts onDestroy
@Injectable({
	providedIn: 'root',
})
export class ShareProject{
    private labels:any = undefined;
	private projectUserMappings:any = undefined;
	private users:any = undefined;
	private clients:any = undefined;
	private taskTypes:any = undefined;
	private taskStatuses:any = undefined;
	private currentUserId:any = undefined;

    setLabels(data:any){
        this.labels = data;
    }

	setClients(data:any){
        this.clients = data;
    }

	setProjectUserMappings(data:any){
        this.projectUserMappings = data;
    }

	setUsers(data:any){
        this.users = data;
    }

	setTaskTypes(data:any){
        this.taskTypes = data;
    }

	setTaskStatuses(data:any){
        this.taskStatuses = data;
    }

	setCurrentUserId(data:any){
		this.currentUserId = data;
	}

    getLabels():any{
        return this.labels;
    }

	getClients():any{
        return this.clients;
    }

	getProjectUserMappings():any{
        return this.projectUserMappings;
    }

	getUsers():any{
        return this.users;
    }

	getTaskTypes():any{
        return this.taskTypes;
    }

	getTaskStatuses():any{
        return this.taskStatuses;
    }

	getCurrentUserId():any{
		return this.currentUserId;
	}

	reset(){
		this.labels = undefined;
		this.projectUserMappings = undefined;
		this.users = undefined;
		this.clients = undefined;
		this.taskTypes = undefined;
		this.taskStatuses = undefined;
		this.currentUserId = undefined;
	}
}
