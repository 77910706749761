import { Component, OnInit, Inject } from '@angular/core';
import { FormControl } from '../../../../../node_modules/@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '../../../../../node_modules/@angular/material';

@Component({
  selector: 'app-management-service-dialog',
  templateUrl: './management-service-dialog.component.html',
  styleUrls: ['./management-service-dialog.component.scss']
})
export class ManagementServiceDialogComponent implements OnInit {

    nameFormControl: FormControl = new FormControl(this.titlePositionsData['name']);
    descriptionFormControl: FormControl = new FormControl(this.titlePositionsData['description']);
    isBillableFormControl: FormControl = new FormControl(this.titlePositionsData['isBillable']);
    isActiveFormControl: FormControl = new FormControl(this.titlePositionsData['isActive'] == null ? true : this.titlePositionsData['isActive']);

    valid: boolean = true;
    error: String;

    constructor(public dialogRef: MatDialogRef<ManagementServiceDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public titlePositionsData) { }

    ngOnInit() {

    }

    isValid() {
        if(this.nameFormControl.value == null || this.nameFormControl.value == ''){
            this.error = 'Name is required';
            return false;
        }
        else{
            this.valid = true;
            return true;
        }
    }

    closeDialog() {
        this.dialogRef.close();
    }

    saveAndCloseDialog() {
        if (this.isValid()) {
            this.titlePositionsData['name'] = this.nameFormControl.value;
            this.titlePositionsData['description'] = this.descriptionFormControl.value;
            this.titlePositionsData['isBillable'] = this.isBillableFormControl.value;
            this.titlePositionsData['isActive'] = this.isActiveFormControl.value;
            this.dialogRef.close({ submit: true, data: this.titlePositionsData });
        }
    }
}
