import { Component, OnInit, Inject } from '@angular/core';
import { TimesheetService } from '../../../api/timesheet.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'app-timesheet-denial-dialog',
    templateUrl: './timesheet-denial-dialog.component.html',
    styleUrls: ['./timesheet-denial-dialog.component.scss']
})
export class TimesheetDenialDialogComponent implements OnInit {
    isUsersLoading: boolean;

    title = new FormControl('Time Entry Denial');
    body = new FormControl('There was something wrong with your current data.');
    deleteQB = new FormControl(false);

    constructor(private _timesheetService: TimesheetService,
        public dialogRef: MatDialogRef<TimesheetDenialDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public timesheetDenialData) { }

    ngOnInit() {
        var monthNames = [
            'January', 'February', 'March',
            'April', 'May', 'June', 'July',
            'August', 'September', 'October',
            'November', 'December'
          ];

        var newDate = new Date(this.timesheetDenialData.periodEndDate);
        var day = newDate.getDate();
        var monthIndex = newDate.getMonth();
          var year = newDate.getFullYear();

        this.title.setValue('Time Entry Denial on ' + day + ' ' + monthNames[monthIndex] + ' ' + year);
    }

    closeDialog() {
        this.dialogRef.close();
    }

    selectToggle(type, selectRef) {
        type ? selectRef.selectAll() : selectRef.deselectAll();
    }

    sendDenial() {
        let returnModel = {
            to: this.timesheetDenialData.user.email,
            title: this.title.value,
            body: this.body.value,
            deleteQB: this.deleteQB.value
        };
        this.dialogRef.close(returnModel);
    }

}
