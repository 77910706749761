import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { FormControl } from '../../../../node_modules/@angular/forms';
import { SimpleChanges } from '@angular/core/src/metadata/lifecycle_hooks';

@Component({
    selector: 'app-project-portfolio-relationship-dialog',
    templateUrl: './project-portfolio-relationship-dialog.component.html',
    styleUrls: ['./project-portfolio-relationship-dialog.component.scss']
})
export class ProjectPortfolioRelationshipDialogComponent implements OnInit {
    portfolio: FormControl = new FormControl(this.portfolioRelationshipData['entry']['portfolio']);
    relationshipTypeId: FormControl = new FormControl(this.portfolioRelationshipData['entry']['relationshipTypeId']);
    isPortfolioValid: boolean = true;
    portfolioValid: String;
    selectedPortfolios: any[] = [];

    portfolioRelations = [
    {
        name: 'Parent',
        id: 1
    },
    {
        name: 'Sibling',
        id: 2
    },
    {
        name: 'Child',
        id: 3
    }];

    constructor(public dialogRef: MatDialogRef<ProjectPortfolioRelationshipDialogComponent>, public dialog: MatDialog, 
        @Inject(MAT_DIALOG_DATA) public portfolioRelationshipData) { }

    ngOnInit() {
        this.selectedPortfolios = this.portfolioRelationshipData.entry.portfolioList;
    }

    saveAndCloseDialog() {
        if (this.isPortfolioRelationshipValid()) {
            this.portfolioRelationshipData['entry']['portfolio'] = this.portfolio.value;
            this.portfolioRelationshipData['entry']['relationshipTypeId'] = this.relationshipTypeId.value;

            this.dialogRef.close({ submit: true, data: this.portfolioRelationshipData['entry'] });
        }
    }

    isPortfolioRelationshipValid() {
        if (this.portfolio.value == null || this.portfolio.value == '' ) {
            this.portfolioValid = 'Portfolio is required';
            return false;
        }
        if (this.relationshipTypeId.value == null) {
            this.portfolioValid = 'Status is required';
            return false;
        }
        else {
            this.isPortfolioValid = true;
            return true;
        }
    }

    onKeyPortfolios(value) {
        let filter = value.toLowerCase();
        this.selectedPortfolios = this.portfolioRelationshipData.entry.portfolioList.filter(option => option['name'].toLowerCase().includes(filter));
    }

    //Sets focus of the combobox inputs on click
	setFocus(inputId:string){
		if(document.getElementById(inputId) != null){
			document.getElementById(inputId).focus();
		}
	}

    closeDialog() {
        this.dialogRef.close();
    }
}