import { Component, OnInit, ViewChild, ViewChildren, OnChanges } from '@angular/core';
import { MatPaginator, MatTableDataSource, MatDialog, MatSnackBar, MatSort } from '@angular/material';
import { ExpensesService } from '../api/expenses.service';
import { TimesheetService } from '../api/timesheet.service';
import { ExpensesDetailDialogComponent } from './expenses-detail-dialog/expenses-detail-dialog.component';
import { ExpenseReportDialogComponent } from './expense-report-dialog/expense-report-dialog.component';
import { DeleteConfirmationDialogComponent } from '../extra/delete-confirmation-dialog/delete-confirmation-dialog.component';
import { ExpensesMoveDialogComponent } from './expense-move-dialog/expense-move-dialog.component';
import { Router } from '@angular/router';
import * as moment from 'moment';
var cloneDeep = require('lodash.clonedeep');

@Component({
	selector: 'app-expenses',
	templateUrl: './expenses.component.html',
	styleUrls: ['./expenses.component.scss']
})
export class ExpensesComponent implements OnInit, OnChanges {
	expenseReportDisplayedColumns = ['date', 'client', 'project', 'expenses', 'info', 'status', 'edit', 'delete'];
	expenseReportDetailDisplayedColumns = ['date', 'name', 'category', 'reimbursable', 'billable', 'amount', 'edit', 'move', 'delete', 'policy'];
	clients: object[]; // array of client objects for the selectors
	classes: object[]; // array of class objects for the selectors
	services: object[]; // array of service objects for the selectors
	projects: object[] = []; // array of project objects for the selectors
	expenseReportSelected: object;
	expenseCategories;
	expenseReportDataLoading: boolean;
	projectsLoading: boolean;
	expenseDetailOpen: boolean = false;
	expenseReportDataSource = new MatTableDataSource();
	expenseReportDetailDataSource = new MatTableDataSource();
	expenseReport: boolean = true;
	disableUpdate: boolean = false;
	reportDetails;
	allExpenseReports: object; //all expense reports
	expenseAge: number = 0;
	viewDate: Date = moment().toDate(); // Current date selected for the calendar
	dateRange: string; // Formated range for the date range button
	dataHolder;
	projectNames;
	tempProjects;
	selectedClients: object[];

	@ViewChild(MatPaginator) reportPaginator: MatPaginator;
	@ViewChild('detailPaginator') detailPaginator: MatPaginator;
	@ViewChildren(MatSort) sort;

	constructor(private _expenseService: ExpensesService,
		public dialog: MatDialog,
		public snackBar: MatSnackBar,
		private _timesheetService: TimesheetService,
		public router: Router) {
	}

	ngOnInit() {
		this.formatDateRange();
		this.getClientClassServiceData();
		this.getExpenseData();
		this.getExpenseCategories();
	}
	ngOnChanges() {
		this.refreshFilterProjects(this.tempProjects)
	}

	// START MODALS
	loadExpenseDialogue(reportdata) {
		let data;
		if (reportdata !== undefined) {
			data = reportdata;
		}
		else {
			data = { projects: this.projects, classes: this.classes, clients: this.clients }
		}

		const dialogRef = this.dialog.open(ExpenseReportDialogComponent, {
			width: '585px',
			height: 'auto',
			data: {
				projects: this.projects,
				classes: this.classes,
				clients: this.clients,
				reportData: data,
			}
		});

		dialogRef.afterClosed().subscribe(result => {
			if (result) { // The object is changed and saved
				if (reportdata !== undefined) {
					this.snackBar.open('Updating Expense Report', '', { duration: 2000 });
					this.putExpenseReport(result);
				} else {
					this.snackBar.open('Adding Expense Report', '', { duration: 2000 });
					this.putExpenseReport(result, true);
				}
			}
		});
	}

	onKeyClient(value){
		this.selectedClients = this.filterClients(value);
	}

	filterClients(value: string){
		let filter = value.toLowerCase();

		let filteredClientsAndProjects = this.clients.filter(option => option['clientName'].toLowerCase().includes(filter));
		for(var i = 0; i < this.clients.length; i++){
			if(filteredClientsAndProjects.find(option => option['clientId'] == this.clients[i]['clientId']) == undefined){
				if(this.clients[i]['projects'] != null){
					let tempClient = cloneDeep(this.clients[i]);
					tempClient['projects'] = tempClient['projects'].filter(project => project['name'].toLowerCase().includes(filter))
					if(tempClient['projects'].length > 0){
						filteredClientsAndProjects.push(tempClient);   
					}
				}
			}
		}
		return filteredClientsAndProjects.sort((a, b) => { return (a['clientName'] > b['clientName']) ? 1 : ((b['clientName'] > a['clientName']) ? -1 : 0); });		
	}

	getAge(date1, date2) {
		// To calculate the time difference of two dates
		var Difference_In_Time = date2.getTime() - date1.getTime();

		// To calculate the no. of days between two dates
		var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
		return Difference_In_Days
	}

	loadExpenseDetailDialog(expenseDetailData) {
		this.expenseDetailOpen = true;
		let isNewDetail = false;

		if (!expenseDetailData) {// A new expense is being added
			isNewDetail = true;
			expenseDetailData = {};
			expenseDetailData['expenseReportId'] = this.expenseReportSelected['id'];
			expenseDetailData['projectId'] = this.expenseReportSelected['projectId'];
			expenseDetailData['isReimbursable'] = this.expenseReportSelected["reportPolicy"]["defaultReimbursable"];
			expenseDetailData['isBillable'] = this.expenseReportSelected["reportPolicy"]["defaultBillable"];
		}


		if (!isNewDetail) {
			// avoid query when you are not updating an existing entry
			this._expenseService.getExpenseStops(expenseDetailData.id).subscribe(stopsData => {
				expenseDetailData['reportPolicySettings'] = this.expenseReportSelected["reportPolicy"];
				expenseDetailData['disableUpdate'] = this.disableUpdate;
				expenseDetailData['rate'] = this.expenseReportSelected["reportPolicy"]["ratePerMile"];
				expenseDetailData['categories'] = this.expenseCategories;
				expenseDetailData['stops'] = stopsData;
				expenseDetailData['expenseReportId'] = this.expenseReportSelected['id'];

				this.expenseReportSelected['reportExpenses'].forEach(expense => {
					this.expenseAge = Math.abs(this.getAge(new Date(expense['date']), new Date));

					if (expense['amount'] > expenseDetailData['reportPolicySettings']['maxAmount']
						|| this.expenseAge > expenseDetailData['reportPolicySettings']['maxAge']) {
						expense['hasViolation'] = true;
					}
					else {
						expense['hasViolation'] = false;
					}
				});

				const dialogRef = this.dialog.open(ExpensesDetailDialogComponent, {
					width: '585px',
					height: 'auto',
					data: expenseDetailData
				});

				dialogRef.afterClosed().subscribe(result => {
					this.expenseDetailOpen = false;
					if (result) {
						if (isNewDetail) {
							this.putExpenseDetail(result, true);
						} else {
							this.putExpenseDetail(result, false);
						}
					}
				});
			});
		}
		else {
			expenseDetailData['reportPolicySettings'] = this.expenseReportSelected["reportPolicy"];
			expenseDetailData['disableUpdate'] = this.disableUpdate;
			expenseDetailData['rate'] = this.expenseReportSelected["reportPolicy"]["ratePerMile"];
			expenseDetailData['categories'] = this.expenseCategories;


			this.expenseReportSelected['reportExpenses'].forEach(expense => {
				this.expenseAge = Math.abs(this.getAge(new Date(expense['date']), new Date));

				if (expense['amount'] > expenseDetailData['reportPolicySettings']['maxAmount']
					|| this.expenseAge > expenseDetailData['reportPolicySettings']['maxAge']) {
					expense['hasViolation'] = true;
				}
				else {
					expense['hasViolation'] = false;
				}
			});

			const dialogRef = this.dialog.open(ExpensesDetailDialogComponent, {
				width: '585px',
				height: 'auto',
				data: expenseDetailData
			});

			dialogRef.afterClosed().subscribe(result => {
				this.expenseDetailOpen = false;
				if (result) {
					if (isNewDetail) {
						this.putExpenseDetail(result, true);
					} else {
						this.putExpenseDetail(result, false);
					}
				}
			});
		}

	}
	loadMoveExpenseDialog(expenseDetailData) {
		this.snackBar.open('Verifying validity of data', '', { duration: 2000 });

		this.snackBar.open('Successfully validated data', '', { duration: 2000 });
		const dialogRef = this.dialog.open(ExpensesMoveDialogComponent, {
			width: '585px',
			height: 'auto',
			data: {
				clients: this.clients,
				projects: this.projects,
				expenseDetailData: expenseDetailData,
				allExpenseReports: this.allExpenseReports
			}
		});


		dialogRef.afterClosed().subscribe(result => {
			if (result !== undefined) {
				this.snackBar.open('Moving Expense.', '', { duration: 2000 });
				this._expenseService.moveExpenseDetail(result.pastId, result.data).subscribe(
					data => {
						this.snackBar.open('Expense moved successfully.', '', { duration: 2000 });
						let detailId = expenseDetailData['id'];
						this.getExpenseData();

						let detailData = this.expenseReportDetailDataSource.data.filter(d => d['id'] != detailId);
						this.expenseReportDetailDataSource = new MatTableDataSource(detailData);
					}, err => {
						this.snackBar.open('Error moving expense.', '', { duration: 2000 });
					});
			}
		});
	}
	// END MODALS

	// START GETS
	getExpenseCategories() {// Gets the categories to be indexed for their names by their ids
		this._expenseService.getExpenseCategories().subscribe(
			data => {
				this.expenseCategories = data;
			},
			err => console.error(err)
		);
	}

	getClientClassServiceData() {// Gets client service class data to be indexed for their names by their ids
		this.projectsLoading = true;
		this._timesheetService.getClientServiceClassData(false).subscribe(
			data => {
				this.projectsLoading = false;
				this.clients = data['clientProjectList'];
				this.selectedClients = data['clientProjectList'];
				this.services = data['serviceList'];
				this.classes = data['workClassList'];
				if (this.classes != null)
					this.classes.sort((a, b) => { return (a['name'] > b['name']) ? 1 : ((b['name'] > a['name']) ? -1 : 0); });
				if (this.services != null)
					this.services.sort((a, b) => { return (a['name'] > b['name']) ? 1 : ((b['name'] > a['name']) ? -1 : 0); });
				if (this.clients != null)
					this.clients.forEach(client => {
						if (client['projects'].length > 0) {
							this.projects.push(...client['projects']); // Spread syntax ...client['projects'] expands the array to be pushed
						}
					});
			},
			err => {
				this.projectsLoading = false;
				console.error(err);
			}
		);
	}

	count = 0;

	getExpenseData(refreshDetails?, _callback = undefined) {// refreshDetails contains a new detail to de added
		this.expenseReportDataSource.data = []; // Clear the data table
		this.expenseReportDataLoading = true; // Enable the loading bar on the table
		this.count += 1;
		this._expenseService.getExpenseData(this.viewDate).subscribe(
			data => {
				if (Object.keys(data).length > 0 && this.viewDate.getMonth() == new Date(data[0]['periodEnd']).getMonth()) {
					this.allExpenseReports = data;

					if (data != null) {
						let tempdata = Object.keys(data).map(i => data[i]); // Needed so TS doesn't think the data is an object and not object[]
						tempdata.forEach(row => {
							let totalAmount = 0;

							for (let i = 0; i < row.reportExpenses.length; i++) {
								totalAmount += row.reportExpenses[i].amount;
							}

							row['totalAmount'] = totalAmount;

							if (_callback !== undefined) {
								_callback(this);
							}

							this.clients.forEach(client => {// Add client name to data for the client id
								client['projects'].forEach(project => {// Add client name to data for the client id
									if (row.projectId == project['id']) {
										row.client = client['clientName'];
									}
								});
							});
							this.projects.forEach(project => {// Add project name to data for hte project id
								if (row.projectId == project['id']) {
									row.project = project['name'];
								}
							});
						});
						this.expenseReportDataSource = new MatTableDataSource(tempdata);
					} else {
						this.expenseReportDataSource = new MatTableDataSource(); // No reports to display
					}

					this.expenseReportDataSource.paginator = this.reportPaginator;
					this.expenseReportDataSource.sort = this.sort.first;
					this.expenseReportDataSource.sortingDataAccessor = (item, property) => {
						switch (property) {
							case 'date': return item['periodEnd'];
							case 'client': return item['client'];
							case 'project': return item['project'];
							case 'expenses': return item['totalAmount'];
							default: return item[property];
						}
					};

					this.expenseReportDataSource.data =
						this.expenseReportDataSource.data.sort((a, b) => (a['periodEnd'] < b['periodEnd']) ? 1 : -1);

					if (refreshDetails) {
						// All of this sets up the datasource for the expense report details, inits the pagination and sorting
						let dataList = this.expenseReportDetailDataSource.data.concat(refreshDetails); // Add the new report detail
						this.expenseReportDetailDataSource = new MatTableDataSource(this.proessExpenseDetailData(dataList));
						this.expenseReportDetailDataSource.paginator = this.detailPaginator;
						this.expenseReportDetailDataSource.sort = this.sort.last;
						this.expenseReportDetailDataSource.sortingDataAccessor = (item, property) => {
							switch (property) {
								case 'date': return item['date'];
								case 'name': return item['merchantName'];
								case 'category': return item['categoryName'];
								case 'reimbursable': return item['isReimbursable'];
								case 'billable': return item['isBillable'];
								case 'amount': return item['amount'];
							}
						};

						this.expenseReportDetailDataSource.data =
							this.expenseReportDetailDataSource.data.sort((a, b) => (a['date'] < b['date']) ? 1 : -1);
					}
				}
				this.dataHolder = this.expenseReportDataSource.data;
				this.count -= 1;
				if (this.count <= 0) {
					this.expenseReportDataLoading = false; // Content is loaded
				}
			},
			err => {
				this.snackBar.open('Failed To Retrieve Expense Reports', '', { duration: 2000 });
				this.expenseReportDataLoading = false; // Content is loaded
			}
		);
	}
	// END GETS

	// START POSTS
	postExpenseReport(expenseReport, resubmit = false) {
		if (resubmit) {
			expenseReport.approvalStatusId = 1;
		}
		this.snackBar.open('Submitting Expense Report', '', { duration: 2000 });
		this._expenseService.postExpenseReport(expenseReport).subscribe(
			data => {
				expenseReport.submittedUserId = "filleruser";
				expenseReport.submittedDate = "fillerdate";
				this.expenseReportDataSource._updateChangeSubscription();
				this.snackBar.open('Expense Report Submitted', '', { duration: 2000 });
			},
			err => {
				this.snackBar.open('Expense Report Failed To Submit', '', { duration: 2000 });
				console.error(err);
			}
		);
	}
	// END POSTS

	// START PUTS
	putExpenseReport(expenseReport, isNewReport?) {
		this._expenseService.putExpenseReport(expenseReport).subscribe(
			data => {
				if (isNewReport) {
					this.toggleExpenseReportDetail(data); // toggle into the expense detail view
					this.snackBar.open('Successfully Added Expense Report', '', { duration: 2000 });
					this.getExpenseData();
				} else {
					this.navigateToExpenseReport(expenseReport.periodEnd);
					this.snackBar.open('Successfully Updated Expense Report', '', { duration: 2000 });
					this.getExpenseData();
				}
			},
			err => {
				this.snackBar.open('Error Updating Expense Report', '', { duration: 2000 });
				console.error(err);
			}
		);

	}

	putExpenseDetail(expenseDetail, isNewDetail?) {
		if (isNewDetail) {
			this.snackBar.open('Adding Expense', '', { duration: 2000 });
		} else {
			this.snackBar.open('Updating Expense', '', { duration: 2000 });
		}
		this.expenseAge = Math.abs(this.getAge(new Date(expenseDetail['date']), new Date));
		if (expenseDetail['amount'] > expenseDetail['reportPolicySettings']['maxAmount']
			|| this.expenseAge > expenseDetail['reportPolicySettings']['maxAge']
			|| (expenseDetail['reportPolicySettings']['eReceipt'] == true && expenseDetail['hasReceipt'] != true)) {
			expenseDetail['hasViolation'] = true;
		}
		else {
			expenseDetail['hasViolation'] = false;
		}
		this._expenseService.putExpenseDetail(expenseDetail).subscribe(
			data => {// If the detail is updated, refresh the expense data
				if (isNewDetail) {
					this.snackBar.open('Successfully Added Expense', '', { duration: 2000 });
					expenseDetail.id = data['id'];
					this.getExpenseData(expenseDetail);
				} else {
					this.snackBar.open('Successfully Updated Expense', '', { duration: 2000 });
					this.getExpenseData(undefined);
				}
			},
			err => {
				this.snackBar.open('Error Updating Expense', '', { duration: 2000 });
				console.error(err);
			}
		);
	}
	// END PUTS

	// START DELETES
	deleteExpenseReport(reportId) {
		const dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
			width: '585px',
			height: 'auto'
		});
		dialogRef.afterClosed().subscribe(result => {// Check if the entry is to be deleted
			if (result !== undefined) {
				if (result['toDelete']) {
					this.snackBar.open('Deleting Expense Report', '', { duration: 2000 });
					this._expenseService.deleteExpenseReport(reportId).subscribe(
						data => {// If report deleted, refresh the expense data
							this.snackBar.open('Expense Report Deleted', '', { duration: 2000 });
							//get data from matTable, delete row indicated from table
							for (var i = 0; i < this.expenseReportDataSource.data.length; i++) {
								if (this.expenseReportDataSource.data[i]["id"] == reportId) {
									this.expenseReportDataSource.data.splice(i, 1);
									this.expenseReportDataSource._updateChangeSubscription();
								}
							}

						},
						err => {
							this.snackBar.open('Error Deleting Expense Report', '', { duration: 2000 });
							console.error(err);
						}
					);
				}
			}
		});
	}

	deleteExpenseReportDetail(reportId, detailId) {
		const dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
			width: '585px',
			height: 'auto'
		});
		dialogRef.afterClosed().subscribe(result => {// Check if the entry is to be deleted
			if (result !== undefined) {
				if (result['toDelete']) {
					this.snackBar.open('Deleting Expense', '', { duration: 2000 });
					this._expenseService.deleteExpenseReportDetail(reportId, detailId).subscribe(
						data => {
							// Gets all elements except for the one being deleted and recreates the datasource
							let detailData = this.expenseReportDetailDataSource.data.filter(d => d['id'] != detailId);
							this.expenseReportDetailDataSource = new MatTableDataSource(detailData);
							this.snackBar.open('Successfully Deleted Expense', '', { duration: 2000 });
							this.getExpenseData();
						},
						err => {
							this.snackBar.open('Error Deleting Expense', '', { duration: 2000 });
							console.error(err);
						}
					);
				}
			}
		});

	}
	// END DELETES

	proessExpenseDetailData(data) {// Finds the category name from the category id
		data.forEach(row => {
			this.expenseCategories.forEach(category => {
				if (category.id == row.categoryId) {
					row['categoryName'] = category.name;
				}
			});
		});
		return data;
	}

	// Switches between expense report view and the details of a certain report
	toggleExpenseReportDetail(rowData) {
		if (rowData['id'] != undefined) {
			this._expenseService.getExpenseDetails(rowData['id']).subscribe(data => {
				this.reportDetails = data;

				if (this.reportDetails !== undefined) {
					rowData['reportExpenses'] = this.reportDetails;
				}

				this.expenseReport = !this.expenseReport; // Flips the boolean on which view to display
				if (!this.expenseReport) {// If the view is the expense reports details
					this.expenseReportSelected = rowData; // keep track of the data of the row, needed for some requests
					if (rowData.submittedDate && rowData.approvalStatusId == 2) {
						this.disableUpdate = true;
					}
					else {
						this.disableUpdate = false;
					}
					if (rowData['reportExpenses'].length > 0) {
						rowData['reportExpenses'].forEach(expense => {
							if (expense['amount'] > rowData['reportPolicy']['maxAmount']
								|| this.expenseAge > rowData['reportPolicy']['maxAge']) {
								expense['hasViolation'] = true;
							}
							else {
								expense['hasViolation'] = false;
							}
						});

						// All of this sets up the datasource for the expense report details, inits the pagination and sorting
						this.expenseReportDetailDataSource = new MatTableDataSource(this.proessExpenseDetailData(rowData['reportExpenses']));
						this.expenseReportDetailDataSource.paginator = this.detailPaginator;
						this.expenseReportDetailDataSource.sort = this.sort.last;
						this.expenseReportDetailDataSource.sortingDataAccessor = (item, property) => {
							// Property is the column name and the item is the actual row data
							switch (property) {// Because the data source is complex, the sorting needs help in where to look for sorting data
								case 'date': return item['date'];
								case 'name': return item['merchantName'];
								case 'category': return item['categoryName'];
								case 'reimbursable': return item['isReimbursable'];
								case 'billable': return item['isBillable'];
								case 'amount': return item['amount'];
							}
						};

						this.expenseReportDetailDataSource.data =
							this.expenseReportDetailDataSource.data.sort((a, b) => (a['date'] < b['date']) ? 1 : -1);
					} else {// The report has no expense details yet
						this.expenseReportDetailDataSource = new MatTableDataSource();
						this.expenseReportSelected = rowData;
						this.loadExpenseDetailDialog(false);
					}
				}
			});
		} else {
			this.expenseReport = !this.expenseReport; // Flips the boolean on which view to display
		}
	}

	/**
	 * Navigates the expense reports data table to consistently show an expense
	 * report based on the report's period end date.
	 *
	 * @param reportPeriodEndDate
	 * A Date representing the "period end" date for a given expense report.
	 *
	 * @remarks
	 * This functionality addresses cases in which the user creates an expense
	 * report, but the period end date for that report is in a month other than
	 * the month displayed in the view's data table.
	 */
	// TODO: Add type on the input parameter (e.g., Date)
	navigateToExpenseReport(reportPeriodEndDate) {
		// Convert the report period end date string to a Date
		reportPeriodEndDate = moment(reportPeriodEndDate).toDate();

		/**
		 * If the view date for the Expenses view doesn't match the month and
		 * year of the new/updated report's "period end" date, update the view
		 * date and get date for the corresponding month. Otherwise, just
		 * refresh the currently displayed month's data.
		 */
		if ((this.viewDate.getMonth() != moment(reportPeriodEndDate).toDate().getMonth()) ||
			(this.viewDate.getFullYear() != moment(reportPeriodEndDate).toDate().getFullYear())) {
			this.viewDate = reportPeriodEndDate;

			this.formatDateRange();
		}

	}
	
    refreshFilterProjects(projects: any): void {
		this.tempProjects = projects;
        let filterArray = Array.from(this.dataHolder);

        for(var i = 0; i < filterArray.length; i++){
				filterArray = filterArray.filter(function(data) {
					return ((projects.length == 0) || (projects.indexOf(data['projectId'])!==-1));
				})
			}
            this.expenseReportDataSource.data = filterArray;
	}
	
	clearSelected() {
		this.expenseReportDataSource = this.dataHolder;
	}

	increaseDate() {
		this.viewDate = moment(this.viewDate).add(1, 'month').toDate();
		this.formatDateRange();
		this.getExpenseData();
	}

	decreaseDate() {
		this.viewDate = moment(this.viewDate).subtract(1, 'month').toDate();
		this.formatDateRange();
		this.getExpenseData();
	}

	setDate(selectedDate: Date) {
		this.viewDate = moment(selectedDate).toDate();
		this.formatDateRange();
		this.getExpenseData();
	}

	formatDateRange() {
		// Get the first day of the selected month
		let monthStart = moment(this.viewDate).startOf('month').toDate();

		// Set the date range string
		this.dateRange = moment(monthStart).format('MMMM YYYY');
	}
}
