import { Injectable } from "@angular/core";
import { Injector } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { CookieService } from "ngx-cookie";
import * as moment from "moment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { MatSnackBar } from "@angular/material";
import { GlobalService } from "../../api/globalVars";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
  withCredentials: true,
};

@Injectable({
  providedIn: "root",
})
export class HomeViewService {
  path = this.globalVar.retrievePath();

  private _homeViewSelection: Subject<string>;
  public homeViewSelection;

  constructor(
    private _cookieService: CookieService,
    private _http: HttpClient,
    public globalVar: GlobalService,
    public snackBar: MatSnackBar,
    private injector: Injector
  ) {
    this._homeViewSelection = new Subject<string>();
    this.homeViewSelection = this._homeViewSelection.asObservable();
  }

  /**
   * Retrieves the currently set home page for the tenant associated with the
   * current user from the backend
   */
  getAppHomeViewFromDB() {
    const geturl = this.path + "tenantconfig?type=home";
    return this._http.get(geturl, httpOptions);
  }

  /**
   * Retrieves the currently set home page for the tenant associated with the
   * current user from the home page cookie stored in the user's browser
   */
  getAppHomeViewFromCookie() {
    return this._cookieService.get("prosight-home");
  }

  /**
   * Removes the application home page cookie from the user's browser
   */
  removeAppHomeViewCookie() {
    if (this._cookieService.get("prosight-home")) {
      this._cookieService.remove("prosight-home");
    }
  }

  /**
   * Saves the home page selection to the application database through the backend
   * for the tenant associated with the current user and returns a boolean
   * indicating whether or not the operation was successful
   */
  saveAppHomeViewToDB(homeViewSelection: string): boolean {
    let operationSuccessful = false;
    const posturl = `${this.path}tenantconfig?homeViewSelection=${homeViewSelection}`;
    this._http.post(posturl, null, httpOptions).subscribe(
      (data) => {
        // Take additional steps after home page has been successfully set
        this.snackBar.open("Home View Updated", "", {
          duration: 2000,
        });
        operationSuccessful = true;
        this.saveAppHomeViewToCookie(homeViewSelection);
      },
      (err) => {
        // Handle error returned by the backend
        this.snackBar.open("Error Updating Home View", "", {
          duration: 2000,
        });
      }
    );
    return operationSuccessful;
  }

  /**
   * Updates the cookie which stores the current home page selection to reflect the
   * current selection for the tenant associated with the current user
   */
  saveAppHomeViewToCookie(homeViewSelection: string) {
    this.removeAppHomeViewCookie();
    this._cookieService.put("prosight-home", homeViewSelection, {
      expires: moment().add(10, "hour").toString(),
    });
  }

  /**
   * Sets the home page for the tenant associated with the current user with
   * options to chose whether to save the home page setting to the database and
   * in the home page cookie
   */
  setAppHomeView(
    homeViewSelection: string,
    saveToDB: boolean,
    saveToCookie: boolean
  ) {
    this._homeViewSelection.next(homeViewSelection);

    if (saveToDB) {
      this.saveAppHomeViewToDB(homeViewSelection);
    }
    if (saveToCookie) {
      this.saveAppHomeViewToCookie(homeViewSelection);
    }
  }
} 