import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '../../../node_modules/@angular/common/http';
import { MatSnackBar } from '../../../node_modules/@angular/material';
import { GlobalService } from '../api/globalVars';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    withCredentials: true
};

@Injectable({
  providedIn: 'root'
})
export class SprintService {
    path = this.globalVar.retrievePath();

    constructor(private _http: HttpClient,
        public globalVar: GlobalService, public snackBar: MatSnackBar) { }

    getSprintById(sprintId: number){
        const geturl = this.path + `sprints?sprintId=${sprintId}`;
        return this._http.get(geturl, httpOptions);
    }

    getSprints(){
        const geturl = this.path + 'sprints?type=all';
        return this._http.get(geturl, httpOptions);
    }

    getTasksForSprintAndUser(sprintId: number){
        const geturl = this.path + `sprints?type=tasksforUser&sprintId=${sprintId}`;
        return this._http.get(geturl, httpOptions);
    }

    addUpdateSprint(sprint){
        const posturl = this.path + 'sprints';
        return this._http.post(posturl, sprint, httpOptions);
    }

    deleteSprint(sprintId: number){
        const deleteurl = this.path + `sprints?sprintId=${sprintId}`;
        return this._http.delete(deleteurl, httpOptions);
    }

    getDefaultKanbanBoard(){
        const geturl = this.path + 'defaultkanban';
        return this._http.get(geturl, httpOptions);
    }

    addUpdateKanbanBoard(defaultKanban){
        const posturl = this.path + 'defaultkanban';
        return this._http.post(posturl, defaultKanban, httpOptions);
    }
}