import { Component, OnInit, Input } from '@angular/core';
import { SetttingsService } from '../../api/setttings.service';
import { ManagementClientsDialogComponent } from './management-clients-dialog/management-clients-dialog.component';

@Component({
    selector: 'app-management-clients',
    templateUrl: './management-clients.component.html',
    styleUrls: ['./management-clients.component.scss']
})
export class ManagementClientsComponent implements OnInit {

    clientDialogComponent = ManagementClientsDialogComponent;

    constructor(private _settingsService: SetttingsService) { }

    ngOnInit() {
    }

    getExpenseCategoriesFunction() {
        return this._settingsService.getClients();
    }

    putClientsFunction() {
        return (client) => this._settingsService.putClients(client);
    }
}
